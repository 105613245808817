import { Editor } from '@tiptap/core'
import styles from '../../../Block.module.css'

import { NodeView } from 'prosemirror-view'
import { Node } from 'prosemirror-model'

export function TaskListItemNodeView(
  node: Node,
  editor: Editor,
  getPos: (() => number) | boolean,
  type: string
): NodeView {
  const dom = document.createElement('div')
  const checked = node.attrs.checked || false
  let altKey = false

  dom.className = styles.blockContent
  dom.dataset.contentType = type
  dom.dataset.checked = checked
  dom.dataset.cancelled = node.attrs.cancelled || false
  dom.dataset.scheduled = node.attrs.scheduled || false
  dom.dataset.flagged = node.attrs.flagged || 0
  dom.dataset.folded = node.attrs.folded || false
  dom.dataset.visible = node.attrs.visible.toString() || 'true'

  const label = document.createElement('label')
  const input = document.createElement('input')
  const content = document.createElement('span')

  input.type = 'checkbox'
  input.checked = checked

  content.classList.add(styles.inlineContent)

  input.addEventListener('click', (event) => {
    altKey = event.altKey
  })

  input.addEventListener('change', (event) => {
    const checked = (event.target as HTMLInputElement).checked
    if (editor.isEditable && typeof getPos === 'function') {
      // Note: 1* attempt to fix an issue where the user can't click on the checkbox on Monterey
      // getPos() always returns position 1, and tr.doc.nodeAt(position) crashes with that
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .command(({ tr }) => {
          const position = tr.selection.from - 1 // getPos(); // 1*
          const currentNode = node //tr.doc.nodeAt(position); // 1*

          // console.debug(
          //   "[TaskListItemNodeView] position, checked, node, getPos()",
          //   position,
          //   checked,
          //   node,
          //   getPos()
          // );

          tr.setNodeMarkup(position, undefined, {
            ...(currentNode === null || currentNode === void 0
              ? void 0
              : currentNode.attrs),
            checked: altKey ? false : checked,
            cancelled: altKey ? true : false,
            scheduled: false,
          })
          return true
        })
        .run()
    }
    if (!editor.isEditable) {
      // Reset state if onReadOnlyChecked returns false
      input.checked = !input.checked
    }
  })

  const foldMarker = document.createElement('span')
  foldMarker.classList.add(styles.foldMarker)
  foldMarker.textContent = '…'
  foldMarker.addEventListener('click', () => {
    // console.log('foldMarker clicked')
    if (editor.isEditable && typeof getPos === 'function') {
      // console.log('foldMarker clicked and editable')
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .command(({ tr }) => {
          const position = getPos()
          const currentNode = tr.doc.nodeAt(position)
          tr.setNodeMarkup(position, undefined, {
            // eslint-disable-next-line unicorn/no-null -- currentNode can be Node | null
            ...(currentNode == null || currentNode === void 0
              ? void 0
              : currentNode.attrs),
            folded: false,
          })
          return true
        })
        .run()
    }
  })

  if (node.attrs.folded !== true) {
    foldMarker.classList.add('hidden')
    foldMarker.classList.remove(styles.foldMarker)
  }

  label.appendChild(input)
  dom.appendChild(label)
  dom.appendChild(content)
  dom.appendChild(foldMarker)

  return {
    dom: dom,
    contentDOM: content,
    update: (updatedNode: Node) => {
      if (updatedNode.type !== node.type) {
        return false
      }

      dom.dataset.checked = updatedNode.attrs.checked || false
      dom.dataset.cancelled = updatedNode.attrs.cancelled || false
      dom.dataset.scheduled = updatedNode.attrs.scheduled || false
      dom.dataset.flagged = updatedNode.attrs.flagged || 0
      dom.dataset.folded = updatedNode.attrs.folded || false
      dom.dataset.visible = updatedNode.attrs.visible.toString() || 'true'

      if (updatedNode.attrs.checked) {
        input.setAttribute('checked', 'checked')
      } else {
        input.removeAttribute('checked')
      }

      if (updatedNode.attrs.folded === 'false' || !updatedNode.attrs.folded) {
        foldMarker.classList.add('hidden')
        foldMarker.classList.remove(styles.foldMarker)
      } else {
        foldMarker.classList.remove('hidden')
        foldMarker.classList.add(styles.foldMarker)
      }

      return true
    },
  }
}

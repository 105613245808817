/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useCallback, useEffect, useRef } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
  /*DocumentPlusIcon, FolderPlusIcon,*/ FolderIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'
import { useDebouncedState } from '../../hooks/useKeyedDebounce'
import { filenameToDate, isCalendarNote } from '../../utils/syncUtils'
import classNames from 'classnames'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import { useSearchResults, SearchResult } from './hooks/useNoteSearchResults'
import { useSidebarProvider } from '../../providers/SidebarProvider'

// const quickActions = [
//   { name: 'Add new note...', icon: DocumentPlusIcon, shortcut: 'N', url: '#' },
//   { name: 'Add new folder...', icon: FolderPlusIcon, shortcut: 'F', url: '#' },
// ];
type Props = {
  state: { visible: boolean; search: string }
  setState: (_visible: boolean, _search: string) => void
}

export default function CommandBar({ state, setState }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useDebouncedState('', 200)
  const { handleSelectRecordName, privateNotes, teamNotes } =
    useSidebarProvider()
  const notes = [
    ...(privateNotes?.values() ?? []),
    ...(teamNotes?.values() ?? []),
  ]

  // For selecting calendar notes (when searching for tags for example)
  const selectedDateDispatch = useSelectedDateDispatch()

  // Function to set the search text using the ref
  const setSearchText = useCallback(
    (text: string, shouldQuery = true) => {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.value = text

          if (shouldQuery) {
            setQuery('search', text)
          }
        }
      }, 5)
    },
    [inputRef, setQuery]
  )

  // Update the search text when open changes
  useEffect(() => {
    setSearchText(state.search, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  // Implement a shortcut so we can open the command bar on CMD+J
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setState(true, '')
      }

      if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()

        // Select the complete text ('select all') of the search field with the id 'searchfield'
        const searchField = document.getElementById(
          'searchfield'
        ) as HTMLInputElement
        if (searchField) {
          searchField.select()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setState])

  const term = query.length == 0 ? state.search : query
  const { recent, filteredResults } = useSearchResults(
    notes,
    query,
    state.search
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Transition.Root
      show={state.visible}
      as={Fragment}
      afterLeave={() => {}}
      appear
    >
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => setState(false, term)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-amber-800/20 dark:bg-[#1f1f1f]'>
              <Combobox
                onChange={(item: SearchResult) => {
                  if (item.type == 'tag') {
                    setSearchText('tag: ' + item.name)
                  } else {
                    if (
                      item.noteType != undefined &&
                      item.filename &&
                      isCalendarNote(item.noteType)
                    ) {
                      const { date, timeframe } = filenameToDate(item.filename)

                      if (timeframe == 'day') {
                        selectedDateDispatch({ type: 'setDay', date: date })
                      } else if (timeframe == 'week') {
                        selectedDateDispatch({
                          type: 'setWeek',
                          week: date.week(),
                          year: date.year(),
                        })
                      } else {
                        return
                      }

                      setState(false, term)
                      return
                    }

                    handleSelectRecordName(item.id)
                    setState(false, term)
                  }
                }}
              >
                <div className='relative'>
                  <MagnifyingGlassIcon
                    className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <Combobox.Input
                    id='searchfield'
                    ref={inputRef} // Attach the ref here
                    className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 outline-none ring-0 placeholder:text-gray-400 sm:text-sm dark:text-gray-200'
                    placeholder='Search...'
                    onChange={(event) => setQuery('search', event.target.value)}
                  />
                </div>

                {(term === '' || filteredResults.length > 0) && (
                  <Combobox.Options
                    static
                    className='max-h-96 scroll-py-2 divide-y divide-gray-100 overflow-y-auto'
                  >
                    <li className='p-2'>
                      {term === '' && (
                        <h2 className='mb-2 mt-4 px-3 text-xs font-semibold text-gray-500'>
                          Recently changed
                        </h2>
                      )}
                      <ul className='text-sm text-gray-700 dark:text-gray-300'>
                        {(term === '' ? recent : filteredResults).map(
                          (project) => (
                            <Combobox.Option
                              key={project.id}
                              value={project}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active &&
                                    'bg-gray-200 dark:bg-amber-800/20' /*text-white */
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <DocumentTextIcon
                                    className={classNames(
                                      'h-6 w-6 flex-none ',
                                      'text-gray-400' /*active ? 'text-white' : 'text-gray-400'*/
                                    )}
                                    aria-hidden='true'
                                  />
                                  <span className='ml-3 flex-auto truncate'>
                                    {project.name}
                                  </span>
                                  {active && (
                                    <span className='ml-3 flex-none text-gray-400'>
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          )
                        )}
                      </ul>
                    </li>
                    {/* {term === '' && (
                      <li className="p-2">
                        <h2 className="sr-only">Quick actions</h2>
                        <ul className="text-sm text-gray-700">
                          {quickActions.map((action) => (
                            <Combobox.Option
                              key={action.shortcut}
                              value={action}
                              className={({ active }) => classNames('flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-indigo-600 text-white')}
                            >
                              {({ active }) => (
                                <>
                                  <action.icon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                  <span className="ml-3 flex-auto truncate">{action.name}</span>
                                  <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">{action.shortcut}</kbd>
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )} */}
                  </Combobox.Options>
                )}

                {term !== '' && filteredResults.length === 0 && (
                  <div className='px-6 py-14 text-center sm:px-14'>
                    <FolderIcon
                      className='mx-auto h-6 w-6 text-gray-400'
                      aria-hidden='true'
                    />
                    <p className='mt-4 text-sm text-gray-900 dark:text-gray-300'>
                      We couldn&apos;t find any projects with that term. Please
                      try again.
                    </p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { QueryClient, useQueryClient } from '@tanstack/react-query'
import {
  type Note,
  NoteType,
  isFolder,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { useAuthenticatedUser } from '../providers/UserProvider'
import {
  CacheData,
  cachedNotesContext,
  getCachedData,
  getCurrentCacheVersion,
  updateCache,
} from '../providers/CachedNotesProvider'
import { useNotesExtension } from './useNotesExtension'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  noteQueryKey,
} from '../utils/queryKeyFactory'
import * as supabase from '../lib/supabase/noteOperations'
import * as cloudkit from '../lib/cloudkit/noteOperations'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'
import { AuthType, AuthenticatedUser } from '../utils/User'

export type CreateOptions = {
  recordName: string
  noteType: NoteType
  parent?: string
  filename?: string // without extension
  isDir?: boolean
  content?: string
  title?: string
}

function prepareDraft(
  options: CreateOptions,
  extension: string,
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser
): Note {
  const {
    recordName,
    noteType,
    parent,
    filename = 'Untitled',
    // eslint-disable-next-line unicorn/prevent-abbreviations
    isDir = false,
    content = '# ',
    title = filename,
  } = options

  const correctedParent =
    parent === 'notes' || parent === 'teamspaces' ? undefined : parent

  const baseDraft = {
    recordName,
    parent: correctedParent,
    noteType,
    isFolder: Boolean(isDir),
    filename, // Keep filename without extension
    title,
    content: isDir ? '' : content,
  }

  const processedDraft =
    user.authType === AuthType.CLOUDKIT ||
    (user.authType === AuthType.CLOUDKIT_SUPABASE && !isTeamspaceNote(noteType))
      ? handleCloudKitDraft(baseDraft, extension, cachedNotesQueryClient, user)
      : baseDraft

  // Assign filename with extension after processing
  const finalFilename = processedDraft.isFolder
    ? processedDraft.filename
    : `${processedDraft.filename}.${extension}`

  return {
    ...processedDraft,
    filename: finalFilename,
  }
}

function handleCloudKitDraft(
  draft: Note,
  extension: string,
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser
): Note {
  if (!draft.recordName) {
    throw new Error('Record name is required')
  }
  // Convert just in case we had some old data
  const privateNotes = getCachedData(
    cachedNotesQueryClient,
    cacheKeys.privateProjectNotes(user.userId)
  )

  const filenameWithHierarchy = updateFilenameWithParent(
    privateNotes,
    draft.filename,
    extension,
    draft.parent
  )

  // Generate a unique base filename (without extension)
  const uniqueFilename = generateFilename(
    privateNotes,
    filenameWithHierarchy,
    draft.recordName,
    0
  )

  return {
    ...draft,
    filename: uniqueFilename, // Keep filename without extension
  }
}

function updateFilenameWithParent(
  privateNotes: Map<string, Note>,
  filename: string,
  extension: string,
  parent?: string
): string {
  const parentFilename = parent ? privateNotes.get(parent)?.filename : undefined

  if (parentFilename) {
    // edge case: sometimes the parent filename contains the filename, so we need to remove it
    const lastSlashIndex = parentFilename.lastIndexOf('/')
    const folderPath = parentFilename.endsWith(`.${extension}`)
      ? lastSlashIndex === -1
        ? parentFilename
        : parentFilename.slice(0, lastSlashIndex)
      : parentFilename

    return `${folderPath}/${filename}`
  }

  return filename
}

function generateFilename(
  privateNotes: Map<string, Note>,
  filenameWithHierarchy: string,
  recordName: string,
  number: number
): string {
  // Construct the filename with the current number
  const newFilename =
    number > 0
      ? `${filenameWithHierarchy} ${number.toString()}`
      : filenameWithHierarchy

  // Check if this filename exists (will check later with extension)
  if (filenameExists(privateNotes, newFilename, recordName)) {
    // If it does, increment the number and try again
    return generateFilename(
      privateNotes,
      filenameWithHierarchy,
      recordName,
      number + 1
    )
  }

  return newFilename
}

function filenameExists(
  privateNotes: Map<string, Note>,
  filename: string,
  recordName: string
): boolean {
  return [...privateNotes.values()].some((note) => {
    // Remove extension from note's filename for comparison
    const noteFilename = note.isFolder
      ? note.filename
      : note.filename.slice(0, note.filename.lastIndexOf('.'))

    return noteFilename === filename && note.recordName !== recordName
  })
}

export function useCreateNote(
  successCallback: (note: Note) => void,
  errorCallback?: (error: Error) => void
) {
  const user = useAuthenticatedUser()
  const { data: extension } = useNotesExtension()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  return useSafeMutation<Note, Error, CreateOptions, CacheData>({
    mutationFn: (options: CreateOptions) => {
      if (!extension) {
        throw new Error('Extension not found')
      }

      const draft = prepareDraft(
        options,
        extension,
        cachedNotesQueryClient,
        user
      )

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          return cloudkit.createNote(draft)
        }
        case AuthType.SUPABASE: {
          return supabase.createNote(user.userId, draft)
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          return isTeamspaceNote(options.noteType)
            ? supabase.createNote(user.teamUserId, draft)
            : cloudkit.createNote(draft)
        }
      }
    },
    onMutate: (options) => {
      // console.debug(
      //   '[useCreateNote] onMutate',
      //   recordName,
      //   noteType,
      //   parent,
      //   filename,
      //   isDir,
      //   title
      // )
      if (!extension) {
        throw new Error('Extension not found')
      }
      const cacheKey = cacheKeyFromNoteType(options.noteType, user)
      const previousNotes = getCachedData(cachedNotesQueryClient, cacheKey)
      void cachedNotesQueryClient.cancelQueries(cacheKey)

      const newNote = prepareDraft(
        options,
        extension,
        cachedNotesQueryClient,
        user
      )

      updateCache(cachedNotesQueryClient, [newNote], cacheKey)

      if (!isFolder(newNote)) {
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
        // Only jump right away to the new note if it's a Supabase note
        // CloudKit doesn't have a recordChangeTag yet and an attempt to save the note will fail / create another unwanted note
        if (
          user.authType === AuthType.SUPABASE ||
          (user.authType === AuthType.CLOUDKIT_SUPABASE &&
            isTeamspaceNote(newNote.noteType))
        ) {
          successCallback(newNote)
        }
      }

      // return a context object with the snapshotted value
      return {
        map: previousNotes,
        version: getCurrentCacheVersion(cachedNotesQueryClient, cacheKey),
      }
    },
    onError: (error, { noteType }, context?: CacheData) => {
      if (context) {
        const cacheKey = cacheKeyFromNoteType(noteType, user)
        cachedNotesQueryClient.setQueryData(cacheKey, context)
      }
      if (errorCallback) {
        errorCallback(error)
      }
    },
    onSuccess: (newNote: Note) => {
      trackEvent(
        newNote.noteType === NoteType.TEAM_SPACE
          ? 'WEB - Teamspace Created'
          : 'WEB - Note Created',
        {
          recordName: newNote.recordName,
          noteType: NoteType[newNote.noteType],
          parent: newNote.parent,
          filename: newNote.filename,
          content: newNote.content,
          title: newNote.title,
        }
      )

      if (!isFolder(newNote)) {
        // set the new note in the cache
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
      }

      const cacheKey = cacheKeyFromNoteType(newNote.noteType, user)
      updateCache(cachedNotesQueryClient, [newNote], cacheKey)

      // We always need to return the callback
      successCallback(newNote)
    },
  })
}

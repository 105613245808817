import { useQueryClient, QueryClient } from '@tanstack/react-query'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType, AuthenticatedUser } from '../utils/User'
import { type Note, type NoteType, isPrivateNote } from '../utils/syncUtils'
import {
  cachedNotesContext,
  getCachedData,
  updateCache,
} from '../providers/CachedNotesProvider'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  projectNoteQueryKey,
} from '../utils/queryKeyFactory'
import { type SidebarEntry } from '../modules/sidebar/SidebarBuilder'
import * as supabase from '../lib/supabase/noteOperations'
import * as cloudkit from '../lib/cloudkit/noteOperations'
import { useSafeMutation } from './useSafeMutation'

type SaveNoteTitleOptions = {
  recordName: string
  noteType: NoteType
  title: string
  children?: SidebarEntry[]
}

export default function useSaveNoteTitle() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  return useSafeMutation({
    mutationFn: ({
      recordName,
      noteType,
      title,
      children,
    }: SaveNoteTitleOptions) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] saving', title)

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          return saveCloudKitNotes(
            cachedNotesQueryClient,
            user,
            recordName,
            title,
            children
          )
        }
        case AuthType.SUPABASE: {
          return supabase.saveNoteTitle(user.userId, recordName, title)
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          return isPrivateNote(noteType)
            ? saveCloudKitNotes(
                cachedNotesQueryClient,
                user,
                recordName,
                title,
                children
              )
            : supabase.saveNoteTitle(user.userId, recordName, title)
        }
      }
    },
    onMutate: ({
      recordName,
      noteType,
      title,
      children,
    }: SaveNoteTitleOptions) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] onMutate', title)

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          return saveCloudKitNotesOptimisticUpdate(
            cachedNotesQueryClient,
            user.userId,
            recordName,
            title,
            children
          )
        }
        case AuthType.SUPABASE: {
          return saveSupabaseNotesOptimisticUpdate(
            cachedNotesQueryClient,
            user,
            recordName,
            title,
            noteType
          )
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          return isPrivateNote(noteType)
            ? saveCloudKitNotesOptimisticUpdate(
                cachedNotesQueryClient,
                user.userId,
                recordName,
                title,
                children
              )
            : saveSupabaseNotesOptimisticUpdate(
                cachedNotesQueryClient,
                user,
                recordName,
                title,
                noteType
              )
        }
      }
    },
    onError: (_error, { noteType }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      // and invalidate the queries. Especially for CloudKit, we need to do this, because the recordChangeTag might have changed
      const cacheKey = cacheKeyFromNoteType(noteType, user)
      cachedNotesQueryClient.setQueryData(cacheKey, context)
      void cachedNotesQueryClient.invalidateQueries(cacheKey)
    },
    onSuccess: (updatedNotes, { noteType }) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] onSuccess', updatedNotes)

      const cacheKey = cacheKeyFromNoteType(noteType, user)
      updateCache(cachedNotesQueryClient, [...updatedNotes.values()], cacheKey)

      // update the recordChangeTag
      for (const [, note] of updatedNotes) {
        if (!note.recordName) continue
        queryClient.setQueryData<Note>(
          projectNoteQueryKey({
            recordName: note.recordName,
            noteType,
          }),
          (oldData) => {
            return oldData
              ? { ...oldData, recordChangeTag: note.recordChangeTag }
              : undefined
          }
        )
      }
    },
  })
}

function saveCloudKitNotes(
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser,
  recordName: string,
  title: string,
  children?: SidebarEntry[]
) {
  const privateNotes = getCachedData(
    cachedNotesQueryClient,
    cacheKeys.privateProjectNotes(user.userId)
  )

  const changedNotes = compileChangedNotes(
    privateNotes,
    recordName,
    title,
    children
  )
  if (!changedNotes) throw new Error('Couldn’t find the note')

  return cloudkit.saveNoteMeta([...changedNotes.values()])
}

function saveCloudKitNotesOptimisticUpdate(
  cachedNotesQueryClient: QueryClient,
  userId: string,
  recordName: string,
  title: string,
  children?: SidebarEntry[]
) {
  // cancel any outgoing refetches (so they don't overwrite our optimistic update)
  void cachedNotesQueryClient.cancelQueries(
    cacheKeys.privateProjectNotes(userId)
  )
  // snapshot the previous value
  const previousNotes = getCachedData(
    cachedNotesQueryClient,
    cacheKeys.privateProjectNotes(userId)
  )

  // optimistically update to the new value
  const changedNotes = compileChangedNotes(
    previousNotes,
    recordName,
    title,
    children
  )
  if (changedNotes) {
    updateCache(
      cachedNotesQueryClient,
      [...changedNotes.values()],
      cacheKeys.privateProjectNotes(userId)
    )
  }
  return previousNotes
}

function saveSupabaseNotesOptimisticUpdate(
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser,
  recordName: string,
  title: string,
  noteType: NoteType
) {
  const cacheKey = cacheKeyFromNoteType(noteType, user)
  const previousNotes = getCachedData(cachedNotesQueryClient, cacheKey)

  // cancel any outgoing refetches (so they don't overwrite our optimistic update)
  void cachedNotesQueryClient.cancelQueries(cacheKey)

  const notes = getCachedData(cachedNotesQueryClient, cacheKey)
  const newNote = notes.get(recordName)

  if (newNote) {
    // Important: we need to pass a new object instead of newNote.title = title, otherwise react won't rerender
    updateCache(cachedNotesQueryClient, [{ ...newNote, title }], cacheKey)
  }
  return previousNotes
}

function compileChangedNotes(
  privateNotes: Map<string, Note>,
  recordName: string,
  title: string,
  children?: SidebarEntry[]
): Map<string, Note> | undefined {
  const note = privateNotes.get(recordName)
  if (!note?.isFolder) return undefined

  const [oldPath, newPath] = updateFolderTitle(note, title)
  // update the title and filename of the note
  const changedNotes = new Map<string, Note>([
    [recordName, { ...note, title, filename: newPath }],
  ])
  // update the filename of all children
  return new Map([
    ...changedNotes,
    ...sidebarEntriesToNotes(privateNotes, oldPath, newPath, children),
  ])
}

function updateFolderTitle(
  note: Note,
  title: string
): [oldName: string, newName: string] {
  const components = note.filename.split('/')
  components.pop()

  // Remove special characters not allowed in folders
  components.push(title.replace(/[/\\:*?"<>|]/g, ''))
  const filename = components.join('/')

  return [note.filename, filename]
}

function sidebarEntriesToNotes(
  privateNotes: Map<string, Note>,
  oldPath: string,
  newPath: string,
  children?: SidebarEntry[]
): Map<string, Note> {
  if (!children) return new Map<string, Note>()

  let changedNotes = new Map<string, Note>()

  children.forEach((child) => {
    const note = privateNotes.get(child.recordName)
    if (!note) return

    changedNotes.set(note.recordName, {
      ...note,
      filename: note.filename.replace(oldPath, newPath),
    })
    const newNotes = sidebarEntriesToNotes(
      privateNotes,
      oldPath,
      newPath,
      child.children
    )
    changedNotes = new Map([...changedNotes, ...newNotes])
  })
  return changedNotes
}

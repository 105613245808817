import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, LinkIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { MenuButton, Menu, MenuItem, MenuDivider } from '@szhsin/react-menu'
import { useListTeamspaceMembers } from '../../hooks/useListTeamspaceMembers'
import { useInviteMember } from '../../hooks/useInviteMember'
import { useRemoveMember } from '../../hooks/useRemoveMember'
import { useAcceptInvitation } from '../../hooks/useAcceptInvitation'
import useUpdateTeamspaceUserRole from '../../hooks/useUpdateTeamspaceUserRole'
import { showTeamspaceSignIn, useUserState } from '../../providers/UserProvider'

function onClose() {
  // Nothing to do here, we control the open and close state by checkign if editingFolder is null
}

export function ManageMembersDialog({
  teamspaceID,
  closeManageTeamspace,
}: {
  teamspaceID: { id: string; title: string } | undefined
  closeManageTeamspace: () => void
}) {
  const [memberEmail, setMemberEmail] = useState('')
  const { data: members } = useListTeamspaceMembers(teamspaceID?.id)
  const inviteMember = useInviteMember()
  const removeMember = useRemoveMember()
  const updateMemberRole = useUpdateTeamspaceUserRole()
  const [icon, setIcon] = useState(
    <LinkIcon className='mr-0.5 h-4 w-4' aria-hidden='true' />
  )

  const buttonReference = useRef<HTMLButtonElement>(null)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Stop event from propagating, or CMD+A will select the text of the editor behind it
    event.stopPropagation()

    if (event.key === 'Enter') {
      buttonReference.current?.click()
    }
  }

  async function handleCopyLinkClick() {
    if (teamspaceID?.id) {
      setIcon(<CheckIcon className='mr-0.5 h-4 w-4' aria-hidden='true' />)
      await navigator.clipboard.writeText(
        `${window.location.origin}/join?id=${teamspaceID.id}`
      )
      setIcon(<LinkIcon className='mr-0.5 h-4 w-4' aria-hidden='true' />)
    } else {
      // TODO: what should happen if teamspaceID is undefined? Can this be avoided altogether?
    }
  }

  return (
    <Transition.Root show={Boolean(teamspaceID)} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all focus:ring-0 sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-[#1f1f1f]'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400'
                    onClick={closeManageTeamspace}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='w-full text-center sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900 dark:text-gray-200'
                    >
                      Share{' '}
                      <span className='underline'>{teamspaceID?.title}</span>
                    </Dialog.Title>

                    <div className='mt-5 w-full sm:mt-4 sm:flex'>
                      <input
                        type='text'
                        name='title'
                        id='title'
                        className='mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 dark:text-gray-200 dark:ring-gray-400'
                        placeholder='name@example.com'
                        onChange={(event) => {
                          setMemberEmail(event.target.value)
                        }}
                        value={memberEmail}
                        onKeyDown={handleKeyDown}
                      />
                      <button
                        ref={buttonReference}
                        type='button'
                        className='mt-2 inline-flex w-full justify-center rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 sm:ml-3 sm:w-auto'
                        onClick={() => {
                          inviteMember.mutate({
                            teamspaceID: teamspaceID?.id,
                            email: memberEmail,
                          })
                          // Set the input field empty
                          setMemberEmail('')
                        }}
                      >
                        Share
                      </button>
                    </div>
                  </div>
                </div>

                {/* User List */}

                <div className=''>
                  <div
                    className=' flow-root'
                    style={{
                      maxHeight: '600px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <div className='overflow-x-auto'>
                      <div className='inline-block min-w-full py-2 align-middle'>
                        <table className='mt-4 min-w-full table-auto rounded-none border-none'>
                          <tbody>
                            {members?.map((person) => (
                              <tr key={person.email} className='h-8'>
                                <td className='border-none p-0 pl-0.5 text-sm text-gray-700 dark:text-gray-200 py-1'>
                                  <div className='flex items-center'>
                                    <div className='w-6 h-6 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center mr-2'>
                                      <span className='text-gray-600 dark:text-gray-300 text-sm font-medium'>
                                        {person.email[0].toUpperCase()}
                                      </span>
                                    </div>
                                    {person.email}
                                  </div>
                                </td>
                                <td className='border-none text-sm text-gray-500 p-0 pr-0.5 dark:text-gray-200 text-right py-1'>
                                  {person.role === 'owner' ? (
                                    <span className='text-gray-700 dark:text-gray-200'>
                                      {person.role}
                                    </span>
                                  ) : (
                                    <Menu
                                      menuButton={
                                        <MenuButton
                                          className={`relative text-left align-middle m-0 p-0 -mr-1.5`}
                                        >
                                          <span className='rounded-md hover:bg-gray-100 p-1 pl-2 dark:hover:bg-gray-100/10'>
                                            {person.role}
                                            <i
                                              className={`fa-regular far fa-chevron-down ml-1 h-1 w-4 text-xs`}
                                              aria-hidden='true'
                                            />
                                          </span>
                                        </MenuButton>
                                      }
                                    >
                                      <MenuItem
                                        key='makeGuest'
                                        onClick={() => {
                                          updateMemberRole.mutate({
                                            teamspaceID: teamspaceID?.id,
                                            email: person.email,
                                            role: 'guest',
                                          })
                                        }}
                                      >
                                        guest
                                      </MenuItem>
                                      <MenuItem
                                        key='makeMember'
                                        onClick={() => {
                                          updateMemberRole.mutate({
                                            teamspaceID: teamspaceID?.id,
                                            email: person.email,
                                            role: 'member',
                                          })
                                        }}
                                      >
                                        member
                                      </MenuItem>
                                      <MenuItem
                                        key='makeAdmin'
                                        onClick={() => {
                                          updateMemberRole.mutate({
                                            teamspaceID: teamspaceID?.id,
                                            email: person.email,
                                            role: 'admin',
                                          })
                                        }}
                                      >
                                        admin
                                      </MenuItem>

                                      <MenuDivider />

                                      <MenuItem
                                        key='removeMember'
                                        onClick={() => {
                                          removeMember.mutate({
                                            teamspaceID: teamspaceID?.id,
                                            email: person.email,
                                          })
                                        }}
                                      >
                                        Remove
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='border-t border-gray-200 dark:border-gray-700 mt-12 pt-6'>
                  <div className='sm:flex sm:items-center'>
                    <div className='flex w-full items-start justify-between'>
                      <button
                        type='button'
                        className='m-0 -mt-0.5 inline-flex items-center p-0 text-sm font-semibold text-amber-500 hover:text-amber-400'
                        onClick={handleCopyLinkClick}
                      >
                        {icon}
                        Copy invite link
                      </button>

                      <span className='text-sm text-gray-500 dark:text-gray-400'>
                        Only people added above can use this link
                      </span>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function extractIdFromPath() {
  // Get the current pathname
  const pathname = window.location.pathname + window.location.search

  // Define the pattern to match the structure 'join&id=UUID'
  const pattern = /join\?id=([\da-f-]+)/

  // Match the pattern to the pathname
  const match = pathname.match(pattern)

  // If there's a match and it has at least two elements (the full match and one capturing group)
  if (match?.[1]) {
    // Return the ID (which should be in the second element of the match array)
    return match[1]
  }

  // If no match is found, return null
  return null
}

export function AcceptInvitationDialog() {
  const user = useUserState()
  const { acceptInvitation, isLoading, error } = useAcceptInvitation()
  const [teamspaceID, onManageTeamspace] = useState(null)

  useEffect(() => {
    if (!user) {
      return
    } // If no user is logged in, no need to show this

    const id = extractIdFromPath()
    if (!user.supabaseUserId && id) {
      // show the teamspace sign in dialog after everything has been loaded after a second, otherwise it won't show up
      setTimeout(() => {
        showTeamspaceSignIn() // The user has to log in before we can accept an invite
      }, 1000)
      return
    }
    onManageTeamspace(id)
  }, [user])

  return (
    <Transition.Root show={!!teamspaceID} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-[#1f1f1f]'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                  <button
                    type='button'
                    className='focus-ring-0 text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400'
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <UserGroupIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> 
                      <i className="far fa-screen-users h-4 w-6 text-center text-green-600" aria-hidden="true"></i>
                    </div> */}
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900 dark:text-gray-200'
                    >
                      You have been invited to a teamspace
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500 dark:text-gray-300'>
                        In teamspaces you can share regular (project) notes with
                        your team and stay updated with shared calendar notes.
                      </p>
                    </div>
                    <div className='mt-3'>
                      {error && (
                        <p className='text-sm text-red-500 dark:text-red-300'>
                          {error.message}
                        </p>
                      )}
                    </div>
                    <div className='mt-2'></div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    disabled={isLoading}
                    type='button'
                    className='relative inline-flex w-full justify-center rounded-md bg-amber-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 disabled:bg-amber-500/30 sm:ml-3 sm:w-auto disabled:dark:bg-amber-600/30'
                    onClick={() => {
                      acceptInvitation(teamspaceID)
                    }}
                  >
                    Join Teamspace
                    <div
                      className='inline-loader absolute'
                      style={{
                        left: 'calc(50% - 7.5px)',
                        top: 'calc(50% - 7.5px)',
                        display: isLoading ? 'inline' : 'none',
                      }}
                    ></div>
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto dark:bg-transparent dark:text-gray-200 dark:ring-gray-400 dark:hover:bg-gray-700'
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

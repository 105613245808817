import { InputRule, mergeAttributes } from '@tiptap/core'
import { createTipTapBlock } from '../../../../api/block'
import { handleEnter } from '../ListItemKeyboardShortcuts'
import styles from '../../../Block.module.css'
import { FoldMarkerHandleClickPlugin } from '../FoldMarkerHandleClickPlugin'

export const BulletListItemBlockContent = createTipTapBlock<'bulletListItem'>({
  name: 'bulletListItem',
  content: 'inline*',

  // This is needed to detect when the user types "-", so it gets converted into a bullet item.
  addInputRules() {
    const bulletItemCharacters: string[] =
      this.options.editor.options.bulletItemCharacters || []

    return bulletItemCharacters.map((character: string) => {
      return new InputRule({
        find: new RegExp(`^[${character}]\\s$`),
        handler: ({ state, chain, range }) => {
          chain()
            .BNUpdateBlock(state.selection.from, {
              type: 'bulletListItem',
              props: {
                flagged: 0,
                folded: false,
              },
            })
            // Removes the "-" character used to set the list.
            .deleteRange({ from: range.from, to: range.to })
        },
      })
    })
  },

  addAttributes() {
    return {
      flagged: {
        default: 0,
        parseHTML: (element) => element.dataset.flagged,
        renderHTML: (attributes) => ({
          'data-flagged': attributes.flagged as number,
        }),
      },
      folded: {
        default: false,
        parseHTML: (element) => element.dataset.folded,
        renderHTML: (attributes) => ({
          'data-folded': attributes.folded as boolean,
        }),
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => handleEnter(this.editor),
    }
  },

  parseHTML() {
    return [
      {
        tag: 'li',
        getAttrs: (element) => {
          if (typeof element === 'string') {
            return false
          }

          const parent = element.parentElement

          // eslint-disable-next-line unicorn/no-null -- parentElement has the type HTMLElement | null
          if (parent == null) {
            return false
          }

          if (parent.tagName === 'UL') {
            return {}
          }

          return false
        },
        node: this.name,
      },
      // Case for BlockNote list structure.
      {
        tag: 'span',
        getAttrs: (element) => {
          if (typeof element === 'string') {
            return false
          }

          const parent = element.parentElement

          // eslint-disable-next-line unicorn/no-null -- parentElement has the type HTMLElement | null
          if (parent == null) {
            return false
          }

          if (parent.dataset.contentType === 'bulletListItem') {
            return {}
          }

          return false
        },
        priority: 300,
        node: this.name,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const spanInlineContent = ['span', { class: styles.inlineContent }, 0]
    const spanFoldMarker = node.attrs.folded
      ? ['span', { class: styles.foldMarker }, '…']
      : undefined
    const children = [spanInlineContent, spanFoldMarker].filter(Boolean)
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: styles.blockContent,
        'data-content-type': this.name,
      }),
      ...children,
    ]
  },

  addProseMirrorPlugins() {
    return [FoldMarkerHandleClickPlugin()]
  },
})

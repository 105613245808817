import { useQueryClient } from '@tanstack/react-query'
import {
  type Note,
  NoteType,
  isPrivateNote,
  toPrivate,
} from '../utils/syncUtils'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType } from '../utils/User'
import {
  CacheData,
  cachedNotesContext,
  getCurrentCacheVersion,
} from '../providers/CachedNotesProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { type SidebarEntry } from '../modules/sidebar/SidebarBuilder'
import * as supabase from '../lib/supabase/noteOperations'
import * as cloudkit from '../lib/cloudkit/noteOperations'
import { useSafeMutation } from './useSafeMutation'
import { trackEvent } from '../lib/analytics'
import { updateCache, removeFromCache } from '../providers/CachedNotesProvider'

type MoveNoteOption = {
  recordName: string
  noteType: NoteType
  parent?: { recordName: string; noteType: NoteType }
  children: SidebarEntry[]
  extension: string
  privateProjectNotes: Map<string, Note>
  teamProjectNotes: Map<string, Note>
}

// TODO: This is a bit of a mess, but it works. Refactor this to be more readable
export default function useMoveNote() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  return useSafeMutation<
    Map<string, Note>,
    Error,
    MoveNoteOption,
    {
      previousPrivateProjectNotes: Map<string, Note>
      previousTeamProjectNotes: Map<string, Note>
    }
  >({
    mutationFn: async ({
      recordName,
      parent,
      noteType,
      children,
      privateProjectNotes,
      teamProjectNotes,
      extension,
    }: MoveNoteOption) => {
      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          const changedNotes = compileChangedNotesForCloudKit(
            privateProjectNotes,
            recordName,
            children,
            parent?.recordName
          )
          if (changedNotes.length > 0) {
            return await cloudkit.saveNoteMeta(changedNotes)
          }
          console.error('Couldnt find the note')
          trackEvent('web.useMoveNote.error', {
            recordName,
            parent,
          })
          throw new Error('Couldnt find the note')
        }
        case AuthType.SUPABASE: {
          return supabase.moveNote(
            user.userId,
            recordName,
            parent ? noteType : toPrivate(noteType), // Needs to be updated from teamspace note to private note
            parent?.recordName,
            parent?.noteType
          )
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          if (isPrivateNote(noteType)) {
            if (
              parent?.recordName === undefined ||
              isPrivateNote(parent.noteType)
            ) {
              const changedNotes = compileChangedNotesForCloudKit(
                privateProjectNotes,
                recordName,
                children,
                parent?.recordName
              )
              if (changedNotes.length > 0) {
                return await cloudkit.saveNoteMeta(changedNotes)
              }
              console.error('Couldnt find the note')
              trackEvent('web.useMoveNote.error', {
                recordName,
                parent,
              })
              throw new Error('Couldnt find the note')
            } else {
              if (children.length > 0) {
                const [changedNotes, order] = compilePrivateNotesToTeamspace(
                  privateProjectNotes,
                  recordName,
                  parent?.recordName,
                  children
                )
                for (const recordName of order) {
                  const draftNote = changedNotes.get(recordName)
                  if (draftNote) {
                    await supabase.createNote(user.teamUserId, draftNote)
                  }
                }
                await cloudkit.deleteNotes(order)
                return changedNotes
              }
              const note = privateProjectNotes.get(recordName)
              if (note) {
                const newNote: Note = {
                  ...note,
                  parent: parent.recordName,
                  noteType: NoteType.TEAM_SPACE_NOTE,
                }
                await supabase.createNote(user.teamUserId, newNote)
                await cloudkit.deleteNotes([recordName])
                return new Map([[recordName, newNote]])
              }
              throw new Error('Couldnt find the note')
            }
          } else {
            if (
              parent?.recordName === undefined ||
              isPrivateNote(parent.noteType)
            ) {
              const changedNotes = compileTeamNotesToPrivate({
                privateNotes: privateProjectNotes,
                teamNotes: teamProjectNotes,
                extension,
                recordName,
                parentRecordName: parent?.recordName,
                children,
              })
              const newNotes = new Map<string, Note>()
              for (const note of changedNotes) {
                if (note.recordName === undefined) continue
                newNotes.set(note.recordName, await cloudkit.createNote(note))
                await supabase.deleteNote(note.recordName)
              }
              return newNotes
            } else {
              return supabase.moveNote(
                user.teamUserId,
                recordName,
                noteType,
                parent.recordName,
                parent.noteType
              )
            }
          }
        }
      }
    },
    onMutate: ({
      recordName,
      parent,
      noteType,
      children,
      privateProjectNotes,
      teamProjectNotes,
      extension,
    }) => {
      const previousPrivateProjectNotes = privateProjectNotes
      const previousTeamProjectNotes = teamProjectNotes

      void cachedNotesQueryClient.cancelQueries(cacheKeys.notes)
      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          const changedNotes: Note[] = compileChangedNotesForCloudKit(
            previousPrivateProjectNotes,
            recordName,
            children,
            parent?.recordName
          )
          updateCache(
            cachedNotesQueryClient,
            changedNotes,
            cacheKeys.privateProjectNotes(user.userId)
          )
          break
        }
        case AuthType.SUPABASE: {
          if (isPrivateNote(noteType)) {
            if (parent === undefined || isPrivateNote(parent.noteType)) {
              // Case 1: moved a private note to a private folder or root
              const note = previousPrivateProjectNotes.get(recordName)
              if (note) {
                const updatedNote = {
                  ...note,
                  parent: parent?.recordName,
                }
                updateCache(
                  cachedNotesQueryClient,
                  [updatedNote],
                  cacheKeys.privateProjectNotes(user.userId)
                )
              }
            } else {
              // Case 2: moved a private note to a teamspace folder
              const note = previousPrivateProjectNotes.get(recordName)
              if (note) {
                const updatedNote = {
                  ...note,
                  parent: parent.recordName,
                  noteType: NoteType.TEAM_SPACE_NOTE,
                }
                updateCache(
                  cachedNotesQueryClient,
                  [updatedNote],
                  cacheKeys.teamProjectNotes(user.teamUserId)
                )
                removeFromCache(
                  cachedNotesQueryClient,
                  [note],
                  cacheKeys.privateProjectNotes(user.userId)
                )
              }
            }
          } else {
            if (parent === undefined || isPrivateNote(parent.noteType)) {
              // Case 3: moved a teamspace note to a private folder or root
              const note = previousTeamProjectNotes.get(recordName)
              if (note) {
                const updatedNote = {
                  ...note,
                  parent: parent?.recordName,
                  noteType: NoteType.PROJECT_NOTE,
                }
                updateCache(
                  cachedNotesQueryClient,
                  [updatedNote],
                  cacheKeys.privateProjectNotes(user.userId)
                )
                removeFromCache(
                  cachedNotesQueryClient,
                  [note],
                  cacheKeys.teamProjectNotes(user.teamUserId)
                )
              }
            } else {
              // Case 4: moved a teamspace note to a teamspace folder
              const note = previousTeamProjectNotes.get(recordName)
              if (note) {
                const updatedNote = {
                  ...note,
                  parent: parent.recordName,
                }
                updateCache(
                  cachedNotesQueryClient,
                  [updatedNote],
                  cacheKeys.teamProjectNotes(user.teamUserId)
                )
              }
            }
          }
          break
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          if (isPrivateNote(noteType)) {
            if (parent === undefined || isPrivateNote(parent.noteType)) {
              // Case 1: moved a private note to a private folder or root - CloudKit only
              const changedNotes: Note[] = compileChangedNotesForCloudKit(
                previousPrivateProjectNotes,
                recordName,
                children,
                parent?.recordName
              )
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.privateProjectNotes(user.userId)
              )
            } else {
              // Case 2: moved a private note to a teamspace folder - CloudKit to Supabase
              if (children.length > 0) {
                const [changedNotes] = compilePrivateNotesToTeamspace(
                  previousPrivateProjectNotes,
                  recordName,
                  parent.recordName,
                  children
                )
                updateCache(
                  cachedNotesQueryClient,
                  [...changedNotes.values()],
                  cacheKeys.teamProjectNotes(user.teamUserId)
                )
                removeFromCache(
                  cachedNotesQueryClient,
                  [...changedNotes.values()],
                  cacheKeys.privateProjectNotes(user.userId)
                )
              } else {
                const note = previousPrivateProjectNotes.get(recordName)
                if (note) {
                  const updatedNote = {
                    ...note,
                    parent: parent.recordName,
                    noteType: NoteType.TEAM_SPACE_NOTE,
                  }
                  updateCache(
                    cachedNotesQueryClient,
                    [updatedNote],
                    cacheKeys.teamProjectNotes(user.teamUserId)
                  )
                  removeFromCache(
                    cachedNotesQueryClient,
                    [note],
                    cacheKeys.privateProjectNotes(user.userId)
                  )
                }
              }
            }
          } else {
            if (parent === undefined || isPrivateNote(parent.noteType)) {
              // Case 3: moved a teamspace note to a private folder or root
              const changedNotes = compileTeamNotesToPrivate({
                privateNotes: previousPrivateProjectNotes,
                teamNotes: previousTeamProjectNotes,
                extension,
                recordName,
                parentRecordName: parent?.recordName,
                children,
              })
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.privateProjectNotes(user.userId)
              )
              removeFromCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.teamProjectNotes(user.teamUserId)
              )
            } else {
              // Case 4: moved a teamspace note to a teamspace folder
              const note = previousTeamProjectNotes.get(recordName)

              if (note) {
                const updatedNote = {
                  ...note,
                  parent: parent.recordName,
                }
                updateCache(
                  cachedNotesQueryClient,
                  [updatedNote],
                  cacheKeys.teamProjectNotes(user.teamUserId)
                )
              }
            }
          }
          break
        }
      }

      return { previousPrivateProjectNotes, previousTeamProjectNotes }
    },
    onError: (_error, _variables, context) => {
      if (!context) {
        return
      }

      const cacheKey = cacheKeys.privateProjectNotes(user.userId)
      cachedNotesQueryClient.setQueryData<CacheData>(cacheKey, {
        map: context.previousPrivateProjectNotes,
        version: getCurrentCacheVersion(cachedNotesQueryClient, cacheKey) + 1,
      })

      if (
        user.authType === AuthType.SUPABASE ||
        user.authType === AuthType.CLOUDKIT_SUPABASE
      ) {
        const cacheKey = cacheKeys.teamProjectNotes(user.teamUserId)
        cachedNotesQueryClient.setQueryData<CacheData>(cacheKey, {
          map: context.previousTeamProjectNotes,
          version: getCurrentCacheVersion(cachedNotesQueryClient, cacheKey) + 1,
        })
      }
    },
    onSuccess: (updatedNotes: Map<string, Note>, { noteType, parent }) => {
      const changedNotes = [...updatedNotes.values()]

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          updateCache(
            cachedNotesQueryClient,
            changedNotes,
            cacheKeys.privateProjectNotes(user.userId)
          )
          break
        }
        case AuthType.CLOUDKIT_SUPABASE:
        case AuthType.SUPABASE: {
          if (isPrivateNote(noteType)) {
            if (
              parent?.recordName === undefined ||
              isPrivateNote(parent.noteType)
            ) {
              // Case: moved a private note to root or private folder
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.privateProjectNotes(user.userId)
              )
            } else {
              // Case: moved a private note to teamspace
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.teamProjectNotes(user.teamUserId)
              )
              removeFromCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.privateProjectNotes(user.userId)
              )
            }
          } else {
            if (
              parent?.recordName === undefined ||
              isPrivateNote(parent.noteType)
            ) {
              // Case: moved a teamspace note to private notes
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.privateProjectNotes(user.userId)
              )
              removeFromCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.teamProjectNotes(user.teamUserId)
              )
            } else {
              // Case: moved a teamspace note within teamspace

              // If we don't also update the queryClient cache, it will use the previously cached parent if we edit the note directly
              // after moving it (1. select the note, 2. move it, 3. edit it. It will return to the previous folder, if we don't cache here)
              for (const changedNote of changedNotes) {
                queryClient.setQueryData(
                  noteQueryKey({
                    recordName: changedNote.recordName,
                    noteType: changedNote.noteType,
                    filename: changedNote.filename,
                    parent: changedNote.parent,
                  }),
                  changedNote
                )
              }
              updateCache(
                cachedNotesQueryClient,
                changedNotes,
                cacheKeys.teamProjectNotes(user.teamUserId)
              )
            }
          }
          break
        }
      }
    },
  })
}

function compileChangedNotesForCloudKit(
  notes: Map<string, Note>,
  recordName: string,
  children: SidebarEntry[],
  parentRecordName?: string
): Note[] {
  const note = notes.get(recordName)
  if (!note) return []

  const newParentNote = parentRecordName
    ? notes.get(parentRecordName)
    : undefined
  const filename = note.filename.split('/').pop() ?? note.filename
  const changedNotes: Note[] = []
  if (Boolean(newParentNote) && newParentNote?.isFolder) {
    // The new parent is a folder
    const newPath = `${newParentNote.filename}/${filename}`
    changedNotes.push({ ...note, filename: newPath })
    if (note.isFolder) {
      // recursively traverse the children and add to the recordsToSave
      changedNotes.push(
        ...sidebarEntriesToNotes(notes, note.filename, newPath, children)
      )
    }
  } else {
    // The new parent is root or invalid
    changedNotes.push({ ...note, filename })
    if (note.isFolder) {
      // recursively traverse the children and add to the recordsToSave
      changedNotes.push(
        ...sidebarEntriesToNotes(notes, note.filename, filename, children)
      )
    }
  }
  return changedNotes
}

function sidebarEntriesToNotes(
  notes: Map<string, Note>,
  oldPath: string,
  newPath: string,
  sidebarEntries: SidebarEntry[] = []
): Note[] {
  const resultNotes: Note[] = []
  for (const entry of sidebarEntries) {
    const changedNote: Note = notes.get(entry.recordName)
    if (!changedNote) return

    // Prevent mutating the original object
    const filename = changedNote.filename
    resultNotes.push({
      ...changedNote,
      filename: filename.replace(oldPath, newPath),
    })

    if (changedNote.isFolder && entry.children) {
      resultNotes.push(
        ...sidebarEntriesToNotes(notes, oldPath, newPath, entry.children)
      )
    }
  }
  return resultNotes
}

function compilePrivateNotesToTeamspace(
  notes: Map<string, Note>,
  recordName: string,
  parentRecordName: string,
  children: SidebarEntry[]
): [Map<string, Note>, string[]] {
  const note: Note = notes.get(recordName)
  if (!note) return [new Map<string, Note>(), []]

  let changedNotes = new Map<string, Note>([
    [
      note.recordName,
      {
        ...note,
        parent: parentRecordName,
        noteType: NoteType.TEAM_SPACE_NOTE,
        filename: note.filename.split('/').pop(),
      },
    ],
  ])
  const order: string[] = [note.recordName]
  if (note.isFolder && children.length > 0) {
    // recursively traverse the children and add to the changed notes
    children.forEach((entry: SidebarEntry) => {
      const [changedChildrenNotes, childrenOrder] =
        compilePrivateNotesToTeamspace(
          notes,
          entry.recordName,
          note.recordName,
          entry.children
        )
      changedNotes = new Map([...changedChildrenNotes, ...changedNotes])
      order.push(...childrenOrder)
    })
  }
  return [changedNotes, order]
}

function compileTeamNotesToPrivate({
  privateNotes,
  teamNotes,
  extension,
  recordName,
  parentRecordName,
  children,
  parentPath,
}: {
  privateNotes: Map<string, Note>
  teamNotes: Map<string, Note>
  extension: string
  recordName: string
  parentRecordName?: string
  children: SidebarEntry[]
  parentPath?: string
}): Note[] {
  const note = teamNotes.get(recordName)
  if (!note) return []

  const changedNotes: Note[] = []
  let filename = note.title ?? note.filename
  if (parentRecordName) {
    // Case: moving to a folder
    if (parentPath) {
      filename = note.isFolder
        ? `${parentPath}/${filename}`
        : `${parentPath}/${filename}.${extension}`
    } else {
      const parentNote = privateNotes.get(parentRecordName)
      if (parentNote) {
        filename = note.isFolder
          ? `${parentNote.filename}/${note.title}`
          : `${parentNote.filename}/${note.title}.${extension}`
      } else {
        trackEvent('web.useMoveNote.error', {
          message: 'Cannot find parent note',
          recordName,
          parentRecordName,
        })
      }
    }
    const newNote: Note = {
      ...note,
      filename,
      noteType: NoteType.PROJECT_NOTE,
    }
    changedNotes.push(newNote)
    if (note.isFolder && children.length > 0) {
      // recursively traverse the children and add to the changed notes
      children.forEach((entry: SidebarEntry) => {
        const result: Note[] = compileTeamNotesToPrivate({
          privateNotes,
          teamNotes,
          extension,
          recordName: entry.recordName,
          parentRecordName: note.recordName,
          children: entry.children,
          parentPath: filename,
        })
        changedNotes.push(...result)
      })
    }
  } else {
    // Case: moving to root
    const newNote: Note = {
      ...note,
      filename: note.isFolder ? filename : `${filename}.${extension}`,
      noteType: NoteType.PROJECT_NOTE,
    }
    changedNotes.push(newNote)
    if (note.isFolder && children.length > 0) {
      // recursively traverse the children and add to the changed notes
      children.forEach((entry: SidebarEntry) => {
        const result: Note[] = compileTeamNotesToPrivate({
          privateNotes,
          teamNotes,
          extension,
          recordName: entry.recordName,
          parentRecordName: note.recordName,
          children: entry.children,
          parentPath: filename,
        })
        changedNotes.push(...result)
      })
    }
  }
  return changedNotes
}

import { Dispatch, DragEvent, SetStateAction } from 'react'
import { CalendarEvent } from '../../CalendarEvent'
import { updateContentNodesAndBlock } from './updateContent'
import { getRowNumberAndDate } from './event'
import { PlaceholderEvent } from '../types'
import { throttleSetTemporaryEvent } from './throttleSetTemporaryEvent'
import { TEMP_EVENT_ID } from '../data'
import { cleanUpDraggedEvent } from './event'

export function createEventOnDrop(
  event: DragEvent<HTMLDivElement>,
  currentEvents: CalendarEvent[],
  calendarEventDrag: CalendarEvent | null,
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>,
  setPlaceholderEvent: Dispatch<SetStateAction<PlaceholderEvent | null>>
) {
  throttleSetTemporaryEvent.cancel()

  if (calendarEventDrag) {
    const temporaryEvent = currentEvents.find(({ id }) => id === TEMP_EVENT_ID)
    if (temporaryEvent) {
      const newStartDate = temporaryEvent.startDate
      const newEndDate = temporaryEvent.endDate

      updateContentNodesAndBlock(
        calendarEventDrag.id,
        calendarEventDrag,
        newStartDate,
        newEndDate
      )
      cleanUpDraggedEvent(setCalendarEventDrag)
    }
  } else {
    const { eventDateRoundedTo15Minutes } = getRowNumberAndDate(event)
    const data = event.dataTransfer?.getData('text/plain')
    const htmlData = event.dataTransfer?.getData('text/html')
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlData ?? '', 'text/html')
    const element = doc.body.firstChild as HTMLElement
    if (element) {
      const blockID = element.getAttribute('data-id')
      if (blockID && data) {
        updateContentNodesAndBlock(
          blockID,
          null,
          eventDateRoundedTo15Minutes,
          eventDateRoundedTo15Minutes.add(30, 'minute')
        )
      }
    }

    setPlaceholderEvent(null)
  }
  const calendarEventList = document.getElementById('calendar-events-list')
  calendarEventList?.style.removeProperty('pointer-events')
}

import { useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { teamKeys } from '../utils/queryKeyFactory'
import { updateTeamspaceUserRole } from '../lib/supabase/invitation'
import { useSafeMutation } from './useSafeMutation'

export default function useUpdateTeamspaceUserRole() {
  const user = useUserState()

  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({
      teamspaceID,
      email,
      role,
    }: {
      teamspaceID: string
      email: string
      role: string
    }) => {
      if (
        !teamspaceID ||
        !email ||
        !role ||
        !['admin', 'member', 'guest'].includes(role)
      ) {
        return Promise.resolve(undefined)
      }

      // eslint-disable-next-line no-console
      console.debug(
        '[useSetTeamspaceUserRole] setting user role for teamspace',
        email,
        teamspaceID,
        role
      )

      if (user.supabaseUserId) {
        return updateTeamspaceUserRole(teamspaceID, email, role)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID }) => {
      // eslint-disable-next-line no-console
      console.log('[useSetTeamspaceUserRole] onSuccess')

      // Invalidate the query so it reloads
      queryClient.invalidateQueries(teamKeys.members(teamspaceID))
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(`[useSetTeamspaceUserRole] onError ${error}`)
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}

import { createContext, useContext, useEffect, useState } from 'react'

const DarkModeContext = createContext<boolean>(false)

const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    setIsDarkMode(mediaQuery.matches)

    const handler = () => { setIsDarkMode(mediaQuery.matches) }
    mediaQuery.addEventListener('change', handler)

    return () => { mediaQuery.removeEventListener('change', handler) }
  }, [])

  return (
    <DarkModeContext.Provider value={isDarkMode}>
      {children}
    </DarkModeContext.Provider>
  )
}

function useDarkMode() {
  return useContext(DarkModeContext)
}

export { DarkModeProvider, useDarkMode }

import dayjs from 'dayjs'

export const is12hrFormat = () =>
  dayjs().localeData().longDateFormat('LT').includes('A')

export function getHourWidth() {
  const day = dayjs().startOf('day')
  const testString = day.format(is12hrFormat() ? 'h A' : 'HH:mm')

  return testString.length * 9 + 10 // adjust the width based locale of the browser and time format
}

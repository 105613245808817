import { Badge, Menu } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";

export type DragHandleMenuItemProps = PolymorphicComponentProps<"button"> & {
  closeMenu: () => void;
  icon?: React.ReactNode;
  shortcut?: string;
};

export const DragHandleMenuItem = (props: DragHandleMenuItemProps) => {
  const { closeMenu, onClick, ...propsToPassThrough } = props;
  return (
    <Menu.Item
      icon={props.icon}
      {...propsToPassThrough}
      onClick={(event) => {
        closeMenu();
        onClick?.(event);
      }}
      rightSection={
        props.shortcut && <Badge size={"xs"}>{props.shortcut}</Badge>
      }>
      {props.children}
    </Menu.Item>
  );
};

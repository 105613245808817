import { useRef, useEffect, useState } from 'react'

type DebouncedValue<T> = {
  key: string;
  value: T;
};

// This debounce uses keys with multiple timeouts. So we can run a debounce timeout for multiple
// notes at the same time. This fixes the issue when you change one note, then switch quickly to
// another and change this as well. Without keys, it will clear the old timeout, so the first
// note will never get saved.
// This scenario usually occurs, when you cut content from one note to the other.

// eslint-disable-next-line no-unused-vars
export function useDebouncedState<T>(initialValue: T, delay: number): [T, (key: string, value: T) => void] {
  const [debouncedValue, setDebouncedValue] = useState<DebouncedValue<T>>({
    key: '',
    value: initialValue,
  })
  const [value, setValue] = useState(initialValue)

  // Maintain a reference to the timeouts
  const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({})

  useEffect(() => {
    const currentKey = debouncedValue.key

    // If a timeout already exists for this key, clear it
    if (timeoutsRef.current[currentKey]) {
      clearTimeout(timeoutsRef.current[currentKey])
    }

    // Set a new timeout for this key
    timeoutsRef.current[currentKey] = setTimeout(() => {
      setValue(debouncedValue.value)
      delete timeoutsRef.current[currentKey]
    }, delay)

    return
  }, [debouncedValue, delay])

  const setDebouncedValueWithKey = (key: string, value: T) => {
    setDebouncedValue({ key, value })
  }

  return [value, setDebouncedValueWithKey]
}

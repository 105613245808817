import { Plugin, PluginKey } from "prosemirror-state";
import { Transaction } from "@tiptap/pm/state";

// ProseMirror Plugin which automatically sets the prop 'visible' of following nodes to false when a heading is folded and vice versa.
// All the nodes between the heading and the next heading on the same level or until the end of the note will be hidden.
const PLUGIN_KEY = new PluginKey(`folded-headings`);
export const FoldedHeadingsPlugin = () => {
  return new Plugin({
    key: PLUGIN_KEY,
    appendTransaction: (_transactions, _oldState, newState) => {
      function setNodeAttribute(
        node: any,
        pos: number,
        name: string,
        value: any
      ) {
        if ((node.attrs[name] ?? true) !== value) {
          tr.setNodeAttribute(pos, name, value);
        }
      }

      const tr: Transaction = newState.tr;
      tr.setMeta("foldedHeadings", true);

      let visible: boolean = true;
      let foldLevel: number = 0; // 0 means no heading is folded

      // Traverses each node the doc using DFS, so blocks which are on the same nesting level will be traversed in the
      // same order they appear.
      newState.doc.descendants((node, pos) => {
        if (node.type.name === "heading") {
          // settings for this heading
          if (node.attrs["level"] <= foldLevel) {
            // if heading is at the same level or higher, we need to show it
            visible = true;

            // Only change it if needed, otherwise animations stop working when indenting for example
            setNodeAttribute(node, pos, "visible", visible);
          }

          // settings for next blocks
          if (node.attrs["folded"] === true) {
            if (node.attrs["level"] > foldLevel) {
              setNodeAttribute(node, pos, "visible", visible);
            }

            // heading is folded, so we need to hide the next blocks and set the foldLevel
            visible = false;
            // set level of the folded heading, if level is not set or the level is higher
            if (foldLevel === 0 || node.attrs["level"] < foldLevel) {
              foldLevel = node.attrs["level"];
            }
          } else {
            // heading is not folded, we only reset if the heading is at the same or higher level as the folded heading
            if (node.attrs["level"] <= foldLevel) {
              visible = true;
              foldLevel = 0;
            } else {
              // if the heading is at a higher level than the folded heading, we need to hide the next blocks
              setNodeAttribute(node, pos, "visible", visible);
            }
          }
        } else if (node.type.spec.group === "blockContent") {
          setNodeAttribute(node, pos, "visible", visible);
        }
      });

      return tr.docChanged ? tr : null;
    },
  });
};

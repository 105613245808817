import { Mark } from "@tiptap/core";
import { EditorState, Plugin, Transaction } from "@tiptap/pm/state";

export interface BoldOptions {
  HTMLAttributes: Record<string, any>;
}

export const flaggedRegex = /^\s*(!{1,})\s+.*$/;

function validateBounds(lineStart: number, lineEnd: number, docSize: number) {
  return !(lineStart >= docSize || lineEnd > docSize);
}

function getLineBoundaries(state: EditorState) {
  const lineStart = state.doc.resolve(state.selection.$from.pos).start(-1);
  const lineEnd = state.doc.resolve(state.selection.$from.pos).end(-1);
  return { lineStart, lineEnd };
}

function checkLineChanged(oldState: EditorState, newState: EditorState) {
  const oldLineText =
    oldState.selection.$anchor.node(-1).content.firstChild?.content.firstChild
      ?.text ?? "";
  const newLineText =
    newState.selection.$anchor.node(-1).content.firstChild?.content.firstChild
      ?.text ?? "";

  const oldNode = oldState.selection.$anchor.node(-1).content.firstChild;
  const nodeAttrs =
    newState.selection.$anchor.node(-1).content.firstChild?.attrs;

  return !(
    oldLineText === newLineText &&
    JSON.stringify(nodeAttrs) === JSON.stringify(oldNode?.attrs)
  );
}

function updateNode(
  node: any | null,
  tr: Transaction,
  lineStart: number
): Transaction {
  const attr = node?.attrs;
  const flagLevel = attr?.flagged ?? 0;

  if (!node || !attr) {
    return tr;
  }

  // Search for flags in the text
  const match = flaggedRegex.exec(node?.content.firstChild?.text ?? "");

  // If we found flags, set the flagged attributed taken from the number of "!" at the beginning
  if (match) {
    let newFlagLevel = match[1].length;

    // If hte task is checked, scheduled or canceled, don't apply a flagged attribute
    if (attr?.checked || attr?.scheduled || attr?.cancelled) {
      newFlagLevel = 0;
    }

    // 3 is the maximum level
    newFlagLevel = Math.min(MAX_FLAG_LEVEL, newFlagLevel);

    // Apply the flagged attribute only if it's different than the current
    if (flagLevel !== newFlagLevel) {
      tr = tr.setNodeMarkup(lineStart, node.type, {
        ...attr,
        flagged: newFlagLevel,
      });
    }
  } else if (flagLevel > 0) {
    // We didn't find a "!" in the text, so set it to 0 if it isn't already
    tr = tr.setNodeMarkup(lineStart, node.type, {
      ...attr,
      flagged: 0,
    });
  }

  return tr;
}

const MAX_FLAG_LEVEL = 3;

export const Flagged = Mark.create({
  name: "flagged",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {},

        appendTransaction: (_transactions, oldState, newState) => {
          const { lineStart, lineEnd } = getLineBoundaries(newState);

          // Only if the node text or attributes changed continue
          if (
            !validateBounds(lineStart, lineEnd, oldState.doc.content.size) ||
            !checkLineChanged(oldState, newState)
          ) {
            return null;
          }

          const node = newState.selection.$anchor.node(-1).content.firstChild;

          var tr = newState.tr;
          tr = updateNode(node, tr, lineStart);

          // Get the old node and the new node, in case the user has hit enter or similar, then we need to update the previous node and the current node
          const pos = oldState.selection.$anchor.pos;
          const docSize = newState.doc.content.size;
          if (pos > 0 && pos < docSize) {
            const oldNode = newState.doc.nodeAt(
              oldState.selection.$anchor.pos - 1
            );

            if (oldNode !== node?.content.firstChild) {
              const oldLineStart = getLineBoundaries(oldState).lineStart;
              tr = updateNode(oldNode, tr, oldLineStart);
            }
          }

          return tr.docChanged ? tr : null;
        },
      }),
    ];
  },
});

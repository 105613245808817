import { useState } from 'react'
import {
  type Stripe,
  type StripeElements,
  type StripeCardElement,
} from '@stripe/stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import { type User } from '../utils/User'
import {
  type PaymentsRequestParams,
  type Address,
  type BackendResponse,
  stripeRequest,
} from '../lib/stripe'

// Define the types for the parameters of usePayment
type UsePaymentParams = {
  stripe: Stripe | null
  elements: StripeElements | null
}

type PaymentResult = {
  success: boolean
  xCallbackUrl?: string
}

// paymentMethodId,
// user,
// email: email ?? user?.email,
// address,
// duration,
// isSmallTeam,
// revenueCatUserId,
// stripeCustomerId: stripeCustomerId ?? '',

export function usePayment({ stripe, elements }: UsePaymentParams) {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean | null>(null)

  const startSubscription = async (
    payload: PaymentsRequestParams
  ): Promise<BackendResponse> => {
    try {
      setIsLoading(true)
      const response = await stripeRequest<PaymentsRequestParams>(
        '/payments.php',
        payload,
        false
      )
      return response
    } catch (requestError) {
      setError(String(requestError))
      return { error: String(requestError) }
    } finally {
      setIsLoading(false)
    }
  }

  const resubscribe = async (
    user: User,
    duration: string,
    isSmallTeam: boolean,
    revenueCatUserId: string,
    stripeCustomerId = ''
  ): Promise<PaymentResult> => {
    setError(null)
    const data: BackendResponse = await startSubscription({
      paymentMethodId: '',
      user,
      email: user?.email,
      address: null,
      duration,
      isSmallTeam,
      revenueCatUserId,
      stripeCustomerId,
    })
    if (!data.error) {
      // Handle successful payment
      return {
        success: true,
        xCallbackUrl: data['x-callback'] || undefined,
      }
    }
    setError(data.error)
    return { success: false }
  }

  const sendPayment = async (
    user: User,
    email: string,
    address: Address,
    duration: string,
    isSmallTeam: boolean,
    revenueCatUserId: string
  ): Promise<PaymentResult> => {
    setError(null)
    setIsLoading(true)

    if (!stripe || !elements) {
      setError(
        'Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.'
      )
      setIsLoading(false)
      return { success: false }
    }

    const card: StripeCardElement | null = elements.getElement(CardElement)
    if (!card) {
      setError('Error: Card Element not found')
      setIsLoading(false)
      return { success: false }
    }

    const { error: stripeError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card,
      })

    if (stripeError) {
      setError(stripeError.message ?? 'An unknown error occurred')
      setIsLoading(false)
      return { success: false }
    }

    const data: BackendResponse = await startSubscription({
      paymentMethodId: paymentMethod.id,
      user,
      email,
      address,
      duration,
      isSmallTeam,
      revenueCatUserId,
    })
    if (!data.error) {
      // Handle successful payment
      setIsLoading(false)
      return {
        success: true,
        xCallbackUrl: data['x-callback'] || undefined,
      }
    }
    setError(data.error)
    setIsLoading(false)
    return { success: false }
  }

  return { sendPayment, error, setError, isLoading, resubscribe }
}

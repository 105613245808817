import { BlockNoteEditor, BlockSchema } from "@packages/blocknote-core";
import { DragHandleMenuItem } from "../DragHandleMenuItem";
import { DragHandleMenuProps } from "../DragHandleMenu";
import { ReactNode } from "react";
import iconsData from "../../../FormattingToolbar/components/FontIcons";

export const FlaggingButton = <BSchema extends BlockSchema>(props: {
  itemProps: DragHandleMenuProps<BSchema> & { children: ReactNode };
  editor: BlockNoteEditor<BSchema>;
}) => {
  const handleFlagButtonClick = () => {
    // Set the cursor into the block, otherwise the plugin won't react and set the background color
    props.editor?.setTextCursorPosition(props.itemProps.block, "start");

    const block = props.itemProps.block;
    const existingContent = block.content;

    // Count exclamation marks at the beginning of the text
    console.log(existingContent[0]);

    let currentFlags = "";
    var firstBlock = existingContent[0];

    // Check if the first block is a text block
    if (firstBlock?.type === "text") {
      const flagRegex = /^(!+)\s/;

      // Get the existing exclamation marks at the beginning of the first block
      const flagMatch = firstBlock.text.match(flagRegex);
      currentFlags = flagMatch ? flagMatch[1] : "";

      // Remove if from the existing content, so we can add it back edited
      existingContent.shift();

      // Replace the currentFlags of the text with currentFlags + "!". If there are three "!" already, replace with empty string
      const newFlags = currentFlags.length < 3 ? currentFlags + "! " : "";
      firstBlock.text = newFlags + firstBlock.text.replace(flagRegex, "");

      // Add the updated first block back to the beginning of the content
      existingContent.unshift(firstBlock);
    } else {
      // The first block is not a text block (it could be a link), so add one
      existingContent.unshift({
        attrs: {},
        styles: {},
        text: "! ", // First exclamation mark
        type: "text",
      });
    }

    props.editor?.updateBlock(block, { ...block, content: existingContent });
  };

  return (
    <DragHandleMenuItem
      icon={iconsData.flagged()}
      onClick={handleFlagButtonClick}
      closeMenu={props.itemProps.closeMenu}
      children={props.itemProps.children}
      shortcut="* ! task"
    />
  );
};

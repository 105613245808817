import { mergeAttributes } from "@tiptap/core";
import { createTipTapBlock } from "../../../api/block";

export interface TableRowOptions {
  HTMLAttributes: Record<string, any>;
}

export const TableRowBlockContent = createTipTapBlock<"tableRowBlockItem">({
  name: "tableRowBlockItem",
  content: "(tableCellBlockItem* | tableHeaderCellBlockItem*)",
  tableRole: "row",

  // We need to set isChildBlock for the table so that the nodeToBlock and blockToNode conversions work.
  // This is a workaround, beacuse every block normally gets wrapped with a blockContainer and blockGroup,
  // which is not working great with table rows and cells.
  addAttributes() {
    return {
      isParentBlock: {
        default: true,
      },
    };
  },

  parseHTML() {
    return [{ tag: "tr" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tr",
      mergeAttributes(HTMLAttributes, {
        "data-content-type": this.name,
      }),
      0,
    ];
  },
});

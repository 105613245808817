import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorBoundary from './components/ErrorBoundary'
import { UserProvider } from './providers/UserProvider'
import { SignIn } from './modules/user/SignInOut'
import { SelectedDateProvider } from './providers/SelectedDateProvider'
import { Layout } from './modules/Layout'
import CachedNotesProvider from './providers/CachedNotesProvider'
import { DarkModeProvider } from './providers/DarkModeProvider'
import { HtmlHeader } from './modules/HtmlHeader'
import SelectedRecordNameProvider from './providers/SelectedRecordNameProvider'
import { init as initAnalytics } from './lib/analytics'
import { NotificationProvider } from './providers/NotificationProvider'
import AccessTokenProvider from './providers/AccessTokenProvider'
import { GoogleMeetSummary } from './modules/GoogleMeetSummary'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReadOnlyEditor from './modules/editor/ReadOnlyEditor'
import { NetworkProvider } from './providers/NetworkProvider'
import { BackendManager } from './modules/BackendManager'

initAnalytics()

const queryClient = new QueryClient()

const rootElement = document.querySelector('#root')

function MainApp() {
  return (
    <ErrorBoundary>
      <NotificationProvider>
        <NetworkProvider>
          <SignIn />
          <QueryClientProvider client={queryClient}>
            <CachedNotesProvider>
              <AccessTokenProvider>
                <UserProvider>
                  <BackendManager />
                  <HelmetProvider>
                    <DarkModeProvider>
                      <HtmlHeader />
                      <SelectedDateProvider>
                        <SelectedRecordNameProvider>
                          <Layout />
                          <GoogleMeetSummary />
                        </SelectedRecordNameProvider>
                      </SelectedDateProvider>
                    </DarkModeProvider>
                  </HelmetProvider>
                </UserProvider>
              </AccessTokenProvider>
            </CachedNotesProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </NetworkProvider>
      </NotificationProvider>
    </ErrorBoundary>
  )
}

function NotePreview() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <DarkModeProvider>
          <HtmlHeader />
          <ReadOnlyEditor />
        </DarkModeProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
}

if (rootElement) {
  createRoot(rootElement).render(
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path='/' element={<MainApp />} />
          <Route path='/join' element={<MainApp />} />
          <Route path='/join/:id' element={<MainApp />} />
          <Route path='/google-meet' element={<NotePreview />} />
        </Routes>
      </Router>
    </React.StrictMode>
  )
} else {
  document.body.innerHTML =
    '<div style="color: red; text-align: center; margin-top: 20px;">Error: Root element not found</div>'
}

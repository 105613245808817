class AttachmentURLCache {
  cache: Map<string, string>;

  constructor() {
    this.cache = new Map();
  }

  set(key: string, value: string) {
    this.cache.set(key, value);
  }

  get(key: string) {
    return this.cache.get(key);
  }

  has(key: string) {
    return this.cache.has(key);
  }
}

// Create a single instance of this cache to use throughout your app.
const attachmentUrlCache = new AttachmentURLCache();
export default attachmentUrlCache;

import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { type UseQueryResult } from '@tanstack/react-query'
import { type CalendarEvent } from '../modules/calendar/CalendarEvent'
import { calculateEventPosition } from '../modules/calendar/timeline/utils/event'
import { VEvent } from '../hooks/AppleCalDAV'

dayjs.extend(utc)
dayjs.extend(timezone)

export function flatMapCalendarEvents(
  queriesResults: UseQueryResult<VEvent[]>[],
  selectedDay: Dayjs
) {
  return queriesResults.flatMap((queryResult) => {
    const vevents = queryResult.data
    return vevents
      ? vevents.map((vevent: VEvent): CalendarEvent => {
          return convertDAVObjectToCalendarEvent(vevent, selectedDay)
        })
      : []
  })
}

export function convertDAVObjectToCalendarEvent(
  vevent: VEvent,
  selectedDay: Dayjs
): CalendarEvent {
  // vevent.start example '20240803T130000Z'
  const startDate = vevent.allday
    ? dayjs(vevent.start, 'YYYYMMDD')
    : dayjs.utc(vevent.start, 'YYYYMMDDTHHmmssZ').local()
  const endDate = vevent.allday
    ? dayjs(vevent.end, 'YYYYMMDD')
    : dayjs.utc(vevent.end, 'YYYYMMDDTHHmmssZ').local()
  return {
    contentType: 'event',
    // add random string to force re-render when changing selectedDay
    id: Math.random().toString(36).slice(2, 6),
    description: vevent.description,
    startDate,
    endDate,
    allDay: vevent.allday,
    content: vevent.summary,
    position: calculateEventPosition(startDate, endDate, selectedDay),
    color: vevent.calendarColor,
    calendarName: vevent.calendarName,
  } as CalendarEvent
}

import { useEffect } from 'react'

const CurrentTimeLine = ({ currentLineRef, currentLinePosition, onScrollToLine }) => {
  useEffect(() => {
    onScrollToLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLineRef.current])

  return (
    <div
      className="current-time-line absolute left-0 w-full h-0.5 bg-red-700"
      ref={currentLineRef}
      style={{
        top: `calc(${currentLinePosition}% + 7px)`,
      }}
    />
  )
}

export default CurrentTimeLine

import * as chrono from 'chrono-node'
import dayjs from 'dayjs'
export const dateCompletionChronoParser = chrono.casual.clone()

dateCompletionChronoParser.parsers.push({
  pattern: () => {
    return /(\W|^)(now|tod.*|tonight|last\s*night|(?:tom.*|tmr|yes.*)\s*|tom.*|tmr|yes.*)(?=\W|$)/gi
  },
  extract: (context, match) => {
    const refMoment = dayjs(context.refDate)
    let startMoment = refMoment // Clone the reference date using Day.js
    const lowerText = match[0].toLowerCase().trim()

    const component = context.createParsingComponents()

    if (lowerText === 'tonight') {
      // Normally means this coming midnight
      component.imply('hour', 22)
      component.imply('meridiem', 1)
    } else if (/^tom.*(?=\W|$)/.test(lowerText)) {
      // "Tomorrow" - adjust startMoment to the next day if it's after 1 AM
      if (refMoment.hour() > 1) {
        startMoment = startMoment.add(1, 'day')
      }
    } else if (/^yes.*(?=\W|$)/.test(lowerText)) {
      startMoment = startMoment.subtract(1, 'day')
    } else if (/last\s*night/.test(lowerText)) {
      // Adjust for "last night"
      component.imply('hour', 0)
      if (refMoment.hour() > 6) {
        startMoment = startMoment.subtract(1, 'day')
      }
    } else if (lowerText === 'now') {
      // For "now", use the exact time from refMoment
      component.imply('hour', refMoment.hour())
      component.imply('minute', refMoment.minute())
      component.imply('second', refMoment.second())
      // Note: Day.js does handle milliseconds, but it might not be necessary for your context
    }

    // Assign the calculated date to context.start
    component.assign('day', startMoment.date())
    component.assign('month', startMoment.month() + 1) // Day.js months are 0-indexed, similar to JavaScript Date
    component.assign('year', startMoment.year())

    component.addTags(['day'])
    return component
  },
})

dateCompletionChronoParser.parsers.push({
  pattern: () => {
    return /([12][0-9]{3})?(?:[\s,-]*)(week|woche|semaine|semana|wk|kw|cw)(?:[\s,-]*)([0-5]?\d)?(?:[\s,-]*)?([12][0-9]{3})?/i
  },
  extract: (context, match) => {
    const refMoment = dayjs(context.refDate)
    let year: number, week: number

    // Check for year and week in the match groups
    if (match[1]) {
      year = parseInt(match[1], 10)
    } else if (match[4]) {
      year = parseInt(match[4], 10)
    } else {
      year = refMoment.year() // Default to current year if not specified
    }

    if (match[3]) {
      week = parseInt(match[3], 10)
    } else {
      week = refMoment.week() // Default to current week if not specified
    }

    // Create a dayjs date representing the first day of the specified week
    const startMoment = dayjs().year(year).week(week).startOf('week')

    const component = context.createParsingComponents()
    component.assign('day', startMoment.date())
    component.assign('month', startMoment.month() + 1) // Day.js months are 0-indexed
    component.assign('year', startMoment.year())

    component.addTags(['week'])
    return component
  },
})

import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { fetchUserDefault } from '../lib/cloudkit/userDefaults'

/**
 * Custom hook that fetches the user default value from the CloudKit client
 *
 * @param key1 - The first key used to fetch the user default value.
 * @param key2 - Optional second key used to fetch the user default value if the first key is not found or the loading fails.
 * @returns The result of the query, which is a string or undefined.
 */
export function useFetchUserDefault(
  key1: string,
  key2?: string
): UseQueryResult<string | undefined> {
  const user = useUserState()

  return useQuery({
    enabled: !!user?.cloudKitUserId,
    queryKey: ['userDefault', key1, key2],
    queryFn: async () => {
      const value1 = await fetchUserDefault(key1)
      if (value1) {
        return value1
      }
      return key2 ? fetchUserDefault(key2) : undefined
    },
  })
}

import { useEffect } from 'react'
import { useEnsureZones } from '../hooks/useEnsureZones'
import { useLoginCheck } from '../hooks/useLoginCheck'
import { useBackendNotifications } from '../hooks/useBackendNotifications'
import { supabaseClient } from '../lib/SupabaseClient'
import { container } from '../lib/CloudKitClient'

const handleDisconnect = () => {
  supabaseClient.realtime.disconnect()
  container?.unregisterForNotifications()
}

export function BackendManager() {
  useLoginCheck()
  useBackendNotifications()
  const { ensureZonesExist } = useEnsureZones()

  useEffect(() => {
    ensureZonesExist()
  }, [ensureZonesExist])

  return (
    <>
      {/* <button
        onClick={handleDisconnect}
        className='fixed bottom-4 right-24 z-10 rounded-md bg-red-500 p-2 text-white'
      >
        Disconnect subscriptions
      </button> */}
    </>
  )
}

/* eslint-disable no-console */
import CloudKit from 'tsl-apple-cloudkit'
import { database } from '../CloudKitClient'
import {
  Note,
  NoteType,
  SourceDatabase,
  isTeamspaceNote,
} from '../../utils/syncUtils'
import { loadNoteFrom, loadNotesFrom } from './noteUtils'
import { getNoteTypeFilter } from '../getNoteTypeFilter'
import { NotesZone } from '../../hooks/useEnsureZones'

const cloudKit = window.CloudKit

// NOTE: Instead of querying title (which is encrypted and super slow) and meta, we can query content and get the tag, backlink and title data from there directly.
// For this we should do only a query to update the cache from a specific modifiedDate instead of updating all of it all the time.
export const noteFields = [
  'recordName',
  'recordChangeTag',
  'filename',
  'noteType',
  'isDir',
  'content',
  'fileModifiedAt',
  'attachments',
]

export async function fetchFileExtension(): Promise<string> {
  // eslint-disable-next-line no-console
  console.log('[CloudKit] fetch file extension')

  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: ['filename'],
    resultsLimit: 20,
  }

  const query: CloudKit.Query = {
    recordType: 'Note',
    filterBy: [
      {
        fieldName: 'noteType',
        comparator: cloudKit.QueryFilterComparator.IN,
        fieldValue: {
          value: [NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE],
        },
      },
      {
        fieldName: 'isDir',
        comparator: cloudKit.QueryFilterComparator.EQUALS,
        fieldValue: {
          value: 0,
        },
      },
    ],
    sortBy: [
      {
        fieldName: 'fileModifiedAt',
        ascending: false,
      },
    ],
  }

  const response = await performQuery(query, fetchOptions, false)
  const records = response.records

  // Map records to fielnames, extracting record.fields?.filename?.value
  const filenames = records.map(
    (record) => record.fields?.filename?.value as string
  )

  // If filenames is empty, return txt, otherwise check which extension is most common
  if (filenames.length === 0) {
    return 'txt'
  }

  // Count the number of occurences of each extension
  const counts: Record<string, number> = {}
  for (const filename of filenames) {
    // Skip filenames without dots or with slashes
    if (!filename.includes('.') || filename.includes('/')) {
      continue
    }
    const extension = filename.split('.').pop() ?? 'txt'
    counts[extension] = (counts[extension] || 0) + 1
  }

  // Sort the extensions by the number of occurences
  const sorted = Object.keys(counts).sort((a, b) => counts[b] - counts[a])
  const extension = sorted[0] ?? 'txt' // Default to txt if no valid extensions found

  console.log('[CloudKit] loaded extension =', extension)

  // Return the most common extension
  return extension
}

export async function testFetch() {
  console.log('[CloudKit] test fetching')

  const query: CloudKit.Query = {
    recordType: 'Note',
  }

  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: ['filename'],
    resultsLimit: 0,
  }

  await performQuery(query, fetchOptions, false)
  console.log('[CloudKit] test fetch done')
  return 'fetched'
}

export async function fetchNoteByRecordName(recordName: string) {
  console.log('[CloudKit] fetching by recordName (recordName=)', recordName)

  const query: CloudKit.Query = {
    recordType: 'Note',
    filterBy: [
      {
        systemFieldName: 'recordName',
        comparator: cloudKit.QueryFilterComparator.EQUALS,
        // comparator: CloudKit.QueryFilterComparator.IN,
        fieldValue: {
          // value: [recordNames],
          value: { recordName: recordName },
        },
      },
    ],
  }

  const desiredKeys = noteFields
  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: desiredKeys,
    resultsLimit: 1,
  }

  const response = await performQuery(query, fetchOptions)
  console.log('[CloudKit] fetched by recordName', response.records[0])
  return await loadNoteFrom(response.records[0])
}

export async function fetchNoteByFilename(
  filename: string,
  shouldExist?: boolean
) {
  console.log('[CloudKit] fetching by filename', filename)

  const query: CloudKit.Query = {
    recordType: 'Note',
    filterBy: [
      {
        fieldName: 'filename',
        comparator: cloudKit.QueryFilterComparator.EQUALS,
        fieldValue: {
          value: filename,
        },
      },
      {
        fieldName: 'noteType',
        comparator: cloudKit.QueryFilterComparator.IN,
        fieldValue: {
          value: [NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE],
        },
      },
    ],
  }

  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: noteFields,
    resultsLimit: 1,
  }

  const response = await performQuery(query, fetchOptions, false)

  const records = response.records
  const numberOfRecords = records.length

  if (numberOfRecords === 0) {
    if (shouldExist) {
      throw new Error('Could not fetch note')
    } else {
      const initialNote: Note = {
        content: '',
        noteType: NoteType.CALENDAR_NOTE,
        filename: filename,
        source: SourceDatabase.CLOUDKIT,
      }
      return initialNote
    }
  }

  const loadedNote = await loadNoteFrom(response.records[0])

  console.log('[CloudKit] fetched by filename', loadedNote.recordChangeTag)
  return loadedNote
}

export async function fetchNoteStubs(
  noteType: NoteType,
  continuationMarker: string | undefined = undefined,
  existingResults = new Map<string, Note>()
): Promise<Map<string, Note>> {
  console.log('[CloudKit] fetching note stubs, loaded =', existingResults.size)

  if (isTeamspaceNote(noteType)) {
    throw new Error('Teamspace notes are not supported on CloudKit')
  }

  const noteTypesFilter = getNoteTypeFilter(noteType)

  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: ['filename', 'fileModifiedAt', 'isDir', 'noteType'],
    continuationMarker,
  }

  const query: CloudKit.Query = {
    recordType: 'Note',
    filterBy: [
      {
        fieldName: 'noteType',
        comparator: cloudKit.QueryFilterComparator.IN,
        fieldValue: {
          value: noteTypesFilter,
        },
      },
    ],
  }

  const response = await performQuery(query, fetchOptions, false)
  const records = response.records

  const promises = records.map(async (record) => {
    return [record.recordName, await loadNoteFrom(record, false)] as [
      string,
      Note,
    ]
  })

  const notesTuples = await Promise.all(promises)
  const results = new Map<string, Note>([...existingResults, ...notesTuples])

  if (response.continuationMarker) {
    return fetchNoteStubs(noteType, response.continuationMarker, results)
  }

  return results
}

export async function fetchNotes({
  noteType,
  lastModifiedDate,
  // eslint-disable-next-line unicorn/no-null -- CloudKit requires null
  continuationMarker = null,
  existingResults = new Map<string, Note>(),
}: {
  noteType: NoteType
  lastModifiedDate?: Date
  continuationMarker?: string | null
  existingResults?: Map<string, Note>
}): Promise<Map<string, Note>> {
  console.log(
    '[CloudKit] fetching all notes (' + noteType.toString() + '), loaded = ',
    existingResults.size,
    'lastModifiedDate =',
    lastModifiedDate
  )

  if (isTeamspaceNote(noteType)) {
    throw new Error('Teamspace notes are not supported on CloudKit')
  }

  const noteTypesFilter = getNoteTypeFilter(noteType)

  const query: CloudKit.Query = {
    recordType: 'Note',
    filterBy: [
      {
        fieldName: 'noteType',
        comparator: cloudKit.QueryFilterComparator.IN,
        fieldValue: {
          value: noteTypesFilter,
        },
      },
      ...(lastModifiedDate
        ? [
            {
              fieldName: 'fileModifiedAt',
              comparator: cloudKit.QueryFilterComparator.GREATER_THAN,
              fieldValue: {
                value: lastModifiedDate.getTime(),
              },
            },
          ]
        : []),
    ],
    sortBy: [
      {
        fieldName: 'filename',
        ascending: true,
      },
    ],
  }

  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: noteFields,
    continuationMarker: continuationMarker,
  }

  // Set throwNoResult to false to allow empty results
  const response = await performQuery(query, fetchOptions, false)
  const records = response.records

  if (records.length === 0) {
    return new Map<string, Note>()
  }

  const notes = new Map<string, Note>([
    ...existingResults,
    ...(await loadNotesFrom(
      records.filter(
        (record) => !record.fields?.filename?.value?.startsWith('@')
      )
    )),
  ])

  // Keep fetching if there are more results to load
  if (response.moreComing && response.continuationMarker) {
    return await fetchNotes({
      noteType,
      lastModifiedDate,
      continuationMarker: response.continuationMarker,
      existingResults: notes,
    })
  } else {
    console.log('Fetched all', noteType, 'notes', notes)
    return notes
  }
}

export async function performQuery(
  query: CloudKit.Query,
  fetchOptions: CloudKit.RecordFetchOptions,
  throwNoResult = true,
  zone = NotesZone
): Promise<CloudKit.QueryResponse> {
  if (!database) {
    throw new Error('Database is undefined')
  }

  fetchOptions.zoneID = zone
  const response = await database.performQuery(query, fetchOptions)

  if (response.hasErrors) {
    throw response.errors[0]
  }

  if (response.records.length === 0 && throwNoResult) {
    throw new Error('Note not found')
  }

  return response
}
/* eslint-enable no-console */

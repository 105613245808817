import { Editor } from '@tiptap/core'
import { getBlockInfoFromPos } from '../../../helpers/getBlockInfoFromPos'
import { TextSelection } from 'prosemirror-state'
import { clickableMarkAutoCompleteInstanceIdentifier } from '../../../api/createClickableMark'

export const handleEnter = (editor: Editor) => {
  const blockInfo = getBlockInfoFromPos(
    editor.state.doc,
    editor.state.selection.from
  )

  if (!blockInfo) {
    return false
  }

  const { node, contentType, contentNode } = blockInfo

  const selectionEmpty =
    editor.state.selection.anchor === editor.state.selection.head

  const isFolded = contentNode.attrs.folded === true

  if (isFolded) {
    // Insert a new list item below the folded node
    editor
      .chain()
      .focus(undefined, { scrollIntoView: false })
      .command(({ tr }) => {
        const pos = tr.doc.resolve(editor.state.selection.from).after(2)
        const newListItem = contentNode.type.createAndFill()
        if (newListItem) {
          tr.insert(pos, newListItem)
          tr.setSelection(TextSelection.create(tr.doc, pos + 2))
        }
        return true
      })
      .run()
    return true
  }

  if (contentType.name === 'codefence') {
    editor.commands.insertContent('\n')
    return true
  }

  if (!contentType.name.endsWith('ListItem') || !selectionEmpty) {
    return false
  }

  // Don't execute this if we are typing up a hashtag
  if (
    document.querySelector(
      '[' + clickableMarkAutoCompleteInstanceIdentifier + '="true"]'
    )
  ) {
    return false
  }

  return editor.commands.first(({ state, chain, commands }) => [
    () =>
      // Changes list item block to a text block if the content is empty.
      commands.command(() => {
        if (node.textContent.length === 0) {
          return commands.BNUpdateBlock(state.selection.from, {
            type: 'paragraph',
            props: {},
          })
        }

        return false
      }),

    () =>
      // Splits the current block, moving content inside that's after the cursor to a new block of the same type
      // below. With some exceptions: if its a list with children and you split at the at the end of the parent to
      // create a child at position 0, it creates an indented child with the same style as the child below it.

      // Instead of:
      // - parent | <- hit enter
      //   * child 1
      //   * child 2

      // You get:
      // - parent
      // - |
      //   * child 1
      //   * child 2

      // We want:
      // - parent
      //   * |
      //   * child 1
      //   * child 2

      commands.command(() => {
        if (node.textContent.length > 0) {
          chain()
            .deleteSelection() // Delete the selection if any
            .BNSplitBlock(state.selection.from, true) // Split the block, means it creates a new block below

            // The children below the new block are all now children of this new block, we want to move them back to the
            // original block. We do this by selecting the other children and lifting them.
            .command(({ chain }) => {
              if (node.childCount < 2) {
                return false
              }

              // Get the new block
              const addedNode = node.child(1)

              // Get the position of the other children (which are now children of the new block)
              const startPos = state.selection.from
              const endPos = startPos + addedNode.nodeSize

              // First indent the new block, so it becomes a child of the parent we splitted it from
              chain()
                .sinkListItem('blockContainer')

                // Select all the other children, so we can unindent them from the new block
                .setTextSelection({
                  from: startPos + 8,
                  to: endPos + 2,
                })

                // Lift, aka unindent the other children
                .liftListItem('blockContainer')

                // Reset the cursor to the new block
                .setNodeSelection(startPos + 2)
                .run()

              // Optionally change the type of the new block, so it's the same as the other children
              if (addedNode.childCount > 1) {
                const type = addedNode.child(1).firstChild?.type.name
                if (type) {
                  chain().BNUpdateBlock(startPos + 4, {
                    type: type,
                    props: {},
                  })
                }
              }

              return true
            })
            .run()

          return true
        }

        return false
      }),
  ])
}

export const handleSelectAboveBelow = (
  editor: Editor,
  direction: 'above' | 'below',
  contentType: string
) => {
  const { selection } = editor.state

  const blockInfo = getBlockInfoFromPos(
    editor.state.doc,
    editor.state.selection.from
  )

  if (blockInfo?.contentType.name !== contentType) {
    return false
  }

  const $position = direction === 'above' ? selection.$from : selection.$to
  let targetPos = null

  if (direction === 'above') {
    // For above, we need to get the node before the current one
    if ($position.depth > 0) {
      targetPos = $position.before($position.depth - 1)
    }
  } else {
    // For below, we need to get the node after the current one
    targetPos = $position.after($position.depth - 1)
  }

  // If no node is found, return false
  if (targetPos == null) {
    console.log('target pos is nul')
    return false
  }

  // Create a new selection around the node
  const nodeSelection = TextSelection.create(editor.state.doc, targetPos)

  // Apply the new selection
  editor.view.dispatch(editor.state.tr.setSelection(nodeSelection))

  return true
}

export const handleAttribute = (
  editor: Editor,
  attribute: 'checked' | 'cancelled' | 'scheduled'
) => {
  const { from, to } = editor.state.selection

  // First, find if any node in the range has attribute = false
  let anyUnset = false
  editor.state.doc.nodesBetween(from, to, (node) => {
    if (node.attrs[attribute] !== undefined && !node.attrs[attribute]) {
      anyUnset = true
    }
  })

  // Then, set all nodes' attribute based on anyUnset
  editor.state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.attrs[attribute] !== undefined) {
      const newAttributes = {
        ...node.attrs,
        [attribute]: anyUnset, // if any node had attribute = false, all nodes will have it set to true
      }

      if (attribute !== 'cancelled') {
        newAttributes.cancelled = false
      }

      if (attribute !== 'scheduled') {
        newAttributes.scheduled = false
      }

      if (attribute !== 'checked') {
        newAttributes.checked = false
      }

      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeMarkup(pos, undefined, newAttributes)
          return true
        })
        .run()
    }
  })

  return true
}

export const handleMove = (editor: Editor, direction: 'up' | 'down') => {
  const { $anchor, $head } = editor.state.selection

  // Look for items with this as the parent node
  const parentNode = $head.node(-2)

  const nodes: any[] = []
  editor.state.doc.nodesBetween(
    $anchor.pos,
    $head.pos,
    (node, _pos, parent) => {
      if (parent !== null && parent.eq(parentNode)) {
        nodes.push(node)
        return false
      }
      return
    }
  )

  // Save first and last nodes for setting the cursor later
  const startNode = editor.state.selection.$from.node(-1)
  const endNode = editor.state.selection.$to.node(-1)

  // Save the cursor offset within the taskNode before moving
  const cursorOffsetStart = editor.state.selection.$from.parentOffset
  const cursorOffsetEnd = editor.state.selection.$to.parentOffset

  // Get task or check node of the text node and its index
  const nodeIndexStart = editor.state.selection.$from.index(-2)
  const nodeIndexEnd = editor.state.selection.$to.index(-2)

  // Determine the target index based on the direction
  const targetIndex = direction === 'up' ? nodeIndexStart - 1 : nodeIndexEnd + 2

  // Ensure movement is possible (not out of bounds)
  const listNode = editor.state.selection.$from.node(-2)
  const isWithinBounds =
    direction === 'up'
      ? nodeIndexStart > 0
      : nodeIndexEnd < listNode.childCount - 1

  if (nodes.length > 0 && isWithinBounds) {
    // Start a transaction
    let tr = editor.state.tr

    // Delete the range
    const deleteAction = () => {
      tr = tr.deleteRange(
        editor.state.selection.$from.posAtIndex(nodeIndexStart, -2),
        editor.state.selection.$to.posAtIndex(nodeIndexEnd + 1, -2)
      )
    }

    const insertAction = () => {
      // Insert the fragment at the target index
      // Loop through all nodes in nodes
      for (let i = nodes.length - 1; i >= 0; i--) {
        const node = nodes[i]

        // Insert each node at the target index
        tr = tr.insert(
          editor.state.selection.$from.posAtIndex(targetIndex, -2),
          node
        )
      }
    }

    if (direction === 'up') {
      deleteAction()
      insertAction()
    } else {
      insertAction()
      deleteAction()
    }

    // Apply the transaction
    editor.view.dispatch(tr)

    // Fix the cursor selection or it's always at the end of the node, which is not good if the node has children
    // First find the first and last node in the list to fetch the start and end pos
    let startPos = -1
    let endPos = -1
    editor.state.doc.descendants((node, pos) => {
      if (node.attrs.id === startNode.attrs.id) {
        startPos = pos
      }

      if (node.attrs.id === endNode.attrs.id) {
        endPos = pos
      }
    })

    // Set the cursor selection to the pos
    if (startPos > -1 && endPos > -1) {
      editor
        .chain()
        .setTextSelection({
          from: startPos + cursorOffsetStart + 2,
          to: endPos + cursorOffsetEnd + 2,
        })
        .run()
    }
  }

  return true
}

import { QueryClient, QueryKey } from '@tanstack/react-query'
import {
  PersistQueryClientProvider,
  type PersistedClient,
  type Persister,
} from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'
import { createContext } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Note } from '../utils/syncUtils'

// Define a type for the cache data
export type CacheData = {
  map: Map<string, Note>
  version: number
}

export const cachedNotesContext = createContext<QueryClient | undefined>(
  undefined
)

const cacheTime = 1000 * 60 * 60 * 24 * 7 // 1 week
const cachedNotesQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime,
      staleTime: cacheTime,
    },
  },
})

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 * @see https://tanstack.com/query/v4/docs/react/plugins/persistQueryClient#building-a-persister
 */
function createIDBPersister(idbValidKey: IDBValidKey = 'notes') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    },
  } as Persister
}

const idbPersister = createIDBPersister()

export const updateCache = (
  queryClient: QueryClient,
  changedNotes: Note[],
  queryKey: QueryKey
) => {
  queryClient.setQueryData<CacheData>(queryKey, (oldData) => {
    if (!oldData) return { map: new Map(), version: 0 }

    const map = oldData instanceof Map ? oldData : oldData.map
    for (const note of changedNotes) {
      if (note.recordName) {
        map.set(note.recordName, note)
      }
    }

    // Handle version increment based on oldData type
    const currentVersion = oldData instanceof Map ? 0 : oldData.version || 0
    return { map, version: currentVersion + 1 }
  })
}

export const removeFromCacheByRecordNames = (
  queryClient: QueryClient,
  recordNames: string[],
  queryKey: QueryKey
) => {
  queryClient.setQueryData<CacheData>(queryKey, (oldData) => {
    if (!oldData) return { map: new Map(), version: 0 }

    const map = oldData instanceof Map ? oldData : oldData.map
    for (const recordName of recordNames) {
      map.delete(recordName)
    }

    // Handle version increment based on oldData type
    const currentVersion = oldData instanceof Map ? 0 : oldData.version || 0
    return { map: map, version: currentVersion + 1 }
  })
}

export const removeFromCache = (
  queryClient: QueryClient,
  changedNotes: Note[],
  queryKey: QueryKey
) => {
  const recordNames = changedNotes
    .map((note) => note.recordName)
    .filter((name): name is string => !!name)
  removeFromCacheByRecordNames(queryClient, recordNames, queryKey)
}

export const getCurrentCacheVersion = (
  queryClient: QueryClient,
  queryKey: QueryKey
) => {
  const data = queryClient.getQueryData<CacheData>(queryKey)
  if (!data) return 0
  return data instanceof Map ? 0 : data.version || 0
}

export const getCachedData = (queryClient: QueryClient, queryKey: QueryKey) => {
  const data = queryClient.getQueryData<CacheData>(queryKey)
  if (!data) return new Map<string, Note>()
  return 'map' in data ? data.map : data
}

export default function CachedNotesProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <PersistQueryClientProvider
      context={cachedNotesContext}
      client={cachedNotesQueryClient}
      persistOptions={{ persister: idbPersister, maxAge: cacheTime }}
    >
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        context={cachedNotesContext}
        position='bottom-right'
      />
    </PersistQueryClientProvider>
  )
}

import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from "@tiptap/core";

export const doneTagInputRegex =
  /@done\((([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))( ((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]( ?[aApP][mM])?))?\)$/;
export const doneTagPasteRegex =
  /@done\((([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))( ((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]( ?[aApP][mM])?))?\)/g;

export const DoneTag = Mark.create({
  name: "doneTag",

  parseHTML() {
    return [
      {
        tag: "span[data-done]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-done": "",
      }),
      0,
    ];
  },

  addInputRules() {
    return [markInputRule({ find: doneTagInputRegex, type: this.type })];
  },

  addPasteRules() {
    return [markPasteRule({ find: doneTagPasteRegex, type: this.type })];
  },
});

import { Extension } from "@tiptap/core";

export const VisibleExtension = Extension.create({
  name: "blockVisible",

  addGlobalAttributes() {
    return [
      {
        types: [
          "heading",
          "paragraph",
          "quoteListItem",
          "bulletListItem",
          "numberedListItem",
          "checkListItem",
          "taskListItem",
          "blockQuote",
          "codefence",
          "tableBlockItem",
          "separator",
          // add all the block types you want to be able to hide
        ],
        attributes: {
          visible: {
            default: true,
            parseHTML: (element) =>
              element.hasAttribute("data-visible")
                ? element.getAttribute("data-visible")
                : true,
            renderHTML: (attributes) =>
              attributes.visible === false && {
                "data-visible": attributes.visible,
              },
          },
        },
      },
    ];
  },
});

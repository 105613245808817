import { InputRule, mergeAttributes } from "@tiptap/core";
import { createTipTapBlock } from "../../../api/block";
import { handleSelectAboveBelow } from "../ListItemBlockContent/ListItemKeyboardShortcuts";
import { Node } from "prosemirror-model";
import { Schema } from "@tiptap/pm/model";

function createTable(
  schema: Schema,
  rowsCount: number,
  colsCount: number
): Node {
  const cells: Node[] = [];
  const headerCells: Node[] = [];

  // Normal cells
  for (let index = 0; index < colsCount; index += 1) {
    const cell = schema.nodes.tableCellBlockItem.createAndFill();

    if (cell) {
      cells.push(cell);
    }
  }

  // Header cells
  for (let index = 0; index < colsCount; index += 1) {
    const cell = schema.nodes.tableHeaderCellBlockItem.createAndFill();

    if (cell) {
      headerCells.push(cell);
    }
  }

  const rows: Node[] = [];
  rows.push(schema.nodes.tableRowBlockItem.createChecked(null, headerCells));
  for (let index = 1; index < rowsCount; index += 1) {
    rows.push(schema.nodes.tableRowBlockItem.createChecked(null, cells));
  }

  return schema.nodes.tableBlockItem.createChecked(null, rows);
}

export const TableBlockContent = createTipTapBlock<"tableBlockItem">({
  name: "tableBlockItem",
  content: "tableRowBlockItem+",
  indentable: false,

  // We need to set isParentBlock for the table so that the nodeToBlock and blockToNode conversions work.
  addAttributes() {
    return {
      isParentBlock: {
        default: true,
      },
    };
  },

  addInputRules() {
    return [
      // Creates a table when typing "===".
      new InputRule({
        find: /^(={3,})\s$/,
        handler: ({ state, match, range }) => {
          const table = createTable(
            this.editor.schema,
            match[1].length,
            match[1].length
          );

          // Add this node to the documen
          const tr = state.tr;
          tr.deleteRange(range.from, range.to).insert(range.from - 1, table);

          const newState = state.apply(tr);

          // Update the editor with the new state.
          this.editor.view.updateState(newState);
        },
      }),
    ];
  },

  // TODO: Implement shortcuts to jump between rows using tab and shift-tab.
  addKeyboardShortcuts() {
    return {
      ArrowUp: () => handleSelectAboveBelow(this.editor, "above", this.name),
      ArrowDown: () => handleSelectAboveBelow(this.editor, "below", this.name),
      //   Tab: () => {
      //     if (this.editor.commands.goToNextCell()) {
      //       return true
      //     }

      //     if (!this.editor.can().addRowAfter()) {
      //       return false
      //     }

      //     return this.editor.chain().addRowAfter().goToNextCell().run()
      //   },
      //   'Shift-Tab': () => this.editor.commands.goToPreviousCell(),
      //   Backspace: deleteTableWhenAllCellsSelected,
      //   'Mod-Backspace': deleteTableWhenAllCellsSelected,
      //   Delete: deleteTableWhenAllCellsSelected,
      //   'Mod-Delete': deleteTableWhenAllCellsSelected,
    };
  },

  parseHTML() {
    return [
      {
        tag: "table",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(HTMLAttributes, {
        "data-content-type": this.name,
      }),
      ["table", ["tbody", 0]],
    ];
  },
});

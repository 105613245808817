import { Note, NoteType, SourceDatabase } from '../../utils/syncUtils'
import { supabaseClient } from '../SupabaseClient'
import { getNoteTypeFilter } from '../getNoteTypeFilter'
import { loadNoteFrom, loadNotesFrom } from './noteUtils'
import { SupabaseNote } from './types'

export async function fetchNoteById(currentUserId: string, id: string) {
  const { data, error } = await supabaseClient
    .from('notes')
    .select('*')
    .eq('id', id)
    .limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }

  if (data.length > 0) {
    return await loadNoteFrom(currentUserId, data[0])
  } else {
    throw new Error('Note not found')
  }
}

export async function fetchNoteByFilename(
  currentUserId: string,
  filename: string,
  noteType: NoteType = NoteType.CALENDAR_NOTE,
  parent: string | null = null
  /*fetchEncryptedContent = false*/
) {
  // NOTE: To fetch decrypted content, use the `decrypted_notes` table instead of `notes`
  let query = supabaseClient
    // Note: use the view `readable_notes`, because it catches the empty string decryption issue by checking before decrypting.
    // If we update the RLS policies of the notes table, we need to update the view `readable_notes` where condition.
    // .from(fetchEncryptedContent ? 'notes' : 'readable_notes') //'decrypted_notes')
    .from('notes')
    .select('*')
    .eq('note_type', noteType)
    .eq('filename', filename)

  if (noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE && parent != null) {
    query = query.eq('parent', parent)
  }

  const { data, error } = await query.limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error

    // If we get a code 22000, the note has empty content and this couldn't be decrypted properly, so load all the metadata again, but set content to empty
    // if (error.code !== '22000') {
    //   throw error;
    // } else {
    //   console.log("the content couldn't be decrypted, load the original and set content to nothing", data);

    //   // To fix this, we need to load the note from the notes table, which has the encrypted content, but it's anyways probably empty, therefore the error
    //   const note = await this.fetchNoteByFilename(currentUserId, filename, noteType, parent, true);
    //   note.content = '';
    //   return note;
    // }
  }

  if (data && data.length > 0) {
    return await loadNoteFrom(currentUserId, data[0])
  }

  const emptyNote: Note = {
    content: '',
    noteType: noteType,
    filename: filename,
    parent: parent,
    isEmpty: true,
    source: SourceDatabase.SUPABASE,
  }

  return emptyNote
}

// This is just fetching the ids fast, so that we know if the user has access to a specific teamspace or not after he was removed
export async function listMyTeamspaceNotes(): Promise<string[]> {
  const pageSize = 1000
  let pageIndex = 0
  let hasMoreData = true
  let allNoteIds: string[] = []

  while (hasMoreData) {
    const start = pageIndex * pageSize
    const end = start + pageSize - 1

    const { data, error } = await supabaseClient
      .from('notes')
      .select('id')
      .in('note_type', [
        NoteType.TEAM_SPACE,
        NoteType.TEAM_SPACE_NOTE,
        NoteType.TEAM_SPACE_CALENDAR_NOTE,
      ])
      .range(start, end)

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }

    if (!data || data.length < pageSize) {
      hasMoreData = false // No more data to fetch
    }

    allNoteIds = allNoteIds.concat(data.map((note) => note.id))
    pageIndex++ // Increment page index for the next iteration
  }

  return allNoteIds
}

export async function fetchNotes({
  currentUserId,
  noteType,
  lastModifiedDate,
  shouldLoadStubs = false,
}: {
  currentUserId: string
  noteType: NoteType
  lastModifiedDate?: Date
  shouldLoadStubs: boolean
}): Promise<Map<string, Note>> {
  const pageSize = 1000
  let pageIndex = 0
  let hasMoreData = true
  const noteTypeFilter = getNoteTypeFilter(noteType)

  // const startTime = performance.now()
  // console.log('[Supabase] FETCHING NOTES STARTED')

  let notes: SupabaseNote[] = []
  while (hasMoreData) {
    const start = pageIndex * pageSize
    const end = start + pageSize - 1

    let query = supabaseClient
      .from('notes')
      .select(
        shouldLoadStubs
          ? 'id, note_type, filename, is_dir, modified_at, change_tag, parent, title'
          : '*'
      )
      .in('note_type', noteTypeFilter)
      .range(start, end)

    if (!shouldLoadStubs && lastModifiedDate) {
      query = query.gt('modified_at', lastModifiedDate.toISOString())
    }

    const { data, error } = await query

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw error
    }

    if (!data || data.length < pageSize) {
      hasMoreData = false
    }

    notes = notes.concat(data)
    pageIndex++

    // eslint-disable-next-line no-console
    console.log(
      '[Supabase] fetched notes, loaded =',
      notes.length,
      'shouldLoadStubs?',
      shouldLoadStubs,
      'lastModifiedDate',
      lastModifiedDate
    )
  }

  return await loadNotesFrom(currentUserId, notes)
}

import { mergeAttributes } from "@tiptap/core";
import { createTipTapBlock } from "../../../api/block";

export interface TableCellOptions {
  HTMLAttributes: Record<string, any>;
}

export const TableHeaderCellBlockContent =
  createTipTapBlock<"tableHeaderCellBlockItem">({
    name: "tableHeaderCellBlockItem",
    content: "inline*",

    // We need to set isChildBlock for the table so that the nodeToBlock and blockToNode conversions work.
    // This is a workaround, beacuse every block normally gets wrapped with a blockContainer and blockGroup,
    // which is not working great with table rows and cells.
    addAttributes() {
      return {
        isChildBlock: {
          default: true,
        },
      };
    },

    parseHTML() {
      return [{ tag: "th" }];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        "th",
        mergeAttributes(HTMLAttributes, {
          "data-content-type": this.name,
        }),
        0,
      ];
    },
  });

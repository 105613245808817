import { useSafeQuery } from './useSafeQuery'
import {
  type Note,
  filenameToKey,
  isCalendarNote,
  NoteType,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { CacheData, cachedNotesContext } from '../providers/CachedNotesProvider'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { fetchNotes } from '../lib/fetchNotes'
import { cacheKeyFromNoteType } from '../utils/queryKeyFactory'
import { UseQueryResult } from '@tanstack/react-query'

/**
 * Helper function to group notes by title
 * We use a stable function reference to memoize the result and avoid unnecessary re-runs on every render
 * @see https://tanstack.com/query/latest/docs/framework/react/guides/render-optimizations#memoization
 */
function byTitle(data: CacheData) {
  const notes = new Map<string, Note[]>()

  // Convert Map to CacheData if needed
  const cacheData: CacheData =
    data instanceof Map ? { map: data, version: 0 } : data

  for (const note of cacheData.map.values()) {
    const title = isCalendarNote(note.noteType)
      ? filenameToKey(note.filename)
      : note.title
    if (!title) continue

    // exclude notes that starts with an @
    if (!title.startsWith('@')) {
      const noteArray = notes.get(title)
      if (noteArray) {
        notes.set(title, [...noteArray, note])
      } else {
        notes.set(title, [note])
      }
    }
  }
  return notes
}

// Overload signatures
function useNotes(
  noteType: NoteType,
  waitCondition?: boolean
): UseQueryResult<CacheData>
function useNotes(
  noteType: NoteType,
  waitCondition: boolean,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  select: (data: CacheData) => Map<string, Note[]>
): UseQueryResult<Map<string, Note[]>>

// Implementation
function useNotes(
  noteType: NoteType,
  waitCondition = true,
  select?: (data: CacheData) => Map<string, Note[]>
): UseQueryResult<CacheData | Map<string, Note[]>> {
  const user = useAuthenticatedUser()
  const isTeam = isTeamspaceNote(noteType)
  const isEnabled = isTeam
    ? 'teamUserId' in user && Boolean(user.teamUserId)
    : true

  return useSafeQuery({
    enabled: isEnabled && waitCondition,
    context: cachedNotesContext,
    queryKey: cacheKeyFromNoteType(noteType, user),
    queryFn: () => fetchNotes(user, noteType),
    select,
  })
}

// Wrap the results for compatibility reasons. We switched from caching notes inside a Map<string, Note>
// to CacheData (which is also a Map<string, Note>, but with an additional version variable, so we don't
// need to copy the whole Map for each update, just increment version to trigger the reactivity and re-render).
// But if the user still has data stored as Map, we convert it here to a CacheData.
function wrapNotesResult(
  result: UseQueryResult<CacheData> | UseQueryResult<Map<string, Note>>
): UseQueryResult<CacheData> {
  if (result.data instanceof Map) {
    return {
      ...result,
      data: {
        map: result.data,
        version: 0,
      },
    } as UseQueryResult<CacheData>
  }
  return result as UseQueryResult<CacheData>
}

export function usePrivateProjectNotes() {
  return wrapNotesResult(useNotes(NoteType.PROJECT_NOTE))
}

export function usePrivateProjectNotesByTitle() {
  return useNotes(NoteType.PROJECT_NOTE, true, byTitle)
}

export function usePrivateCalendarNotes() {
  return wrapNotesResult(useNotes(NoteType.CALENDAR_NOTE))
}

export function useTeamProjectNotes() {
  return wrapNotesResult(useNotes(NoteType.TEAM_SPACE_NOTE))
}

export function useTeamProjectNotesByTitle() {
  return useNotes(NoteType.TEAM_SPACE_NOTE, true, byTitle)
}

export function useTeamCalendarNotes() {
  const { isSuccess, fetchStatus } = useTeamProjectNotes()
  return wrapNotesResult(
    useNotes(
      NoteType.TEAM_SPACE_CALENDAR_NOTE,
      isSuccess && fetchStatus === 'idle'
    )
  )
}

import { Dayjs } from 'dayjs'
import useNote from '../../hooks/useNote'
import {
  SelectedDate,
  selectedDateToDay,
  useSelectedDate,
} from '../../providers/SelectedDateProvider'
import { useSelectedRecordName } from '../../providers/SelectedRecordNameProvider'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import {
  NoteType,
  isCalendarNote,
  isTeamspaceNote,
} from '../../utils/syncUtils'
import { findSidebarEntry } from '../sidebar/MainSidebar'
import { SidebarEntry } from '../sidebar/SidebarBuilder'

function generateCalendarNoteTitleAndDetail(
  date: Dayjs, // Replace 'any' with the appropriate type for 'date'
  selectedDate: SelectedDate
): { title: string; detail: string } {
  let title = ''
  let detail = ''

  switch (selectedDate.active) {
    case 'week': {
      title = `Week ${selectedDate.week.toString()}, ${selectedDate.year.toString()}`
      const startOfWeek = date.startOf('week')
      const endOfWeek = date.endOf('week')
      detail = startOfWeek.format('MMM D') + ' - ' + endOfWeek.format('MMM D')
      break
    }
    case 'month': {
      const isCurrentYear = date.year() === new Date().getFullYear()
      title = date.format(isCurrentYear ? 'MMMM' : 'MMMM YYYY')
      break
    }
    case 'quarter': {
      title = `Q${date.quarter().toString()}, ${date.year().toString()}`
      const startOfQuarter = date.startOf('quarter')
      detail = [0, 1, 2]
        .map((m) => startOfQuarter.add(m, 'month').format('MMM'))
        .join(' - ')
      break
    }
    case 'year': {
      title = date.format('YYYY')
      break
    }
    default: {
      const format = 'ddd, D MMM'
      title = date.calendar(undefined, {
        lastDay: `[Yesterday], ${format}`,
        sameDay: `[Today], ${format}`,
        nextDay: `[Tomorrow], ${format}`,
        nextWeek: format,
        lastWeek: format,
        sameElse: format,
      })
    }
  }

  return { title, detail }
}

function parsePageTitle(
  selectedDate: SelectedDate,
  isLoading: boolean,
  isError: boolean,
  noteTitle = '',
  noteType: NoteType = NoteType.CALENDAR_NOTE,
  filename: string | null | undefined,
  sidebarEntries: SidebarEntry[],
  recordName: string,
  error?: unknown
): { title: string; folder?: string; detail?: string } {
  const date = selectedDateToDay(selectedDate)
  let title = 'NotePlan for Web'
  let detail = ''
  let folder: string | undefined = undefined

  if (isLoading) {
    return {
      title: 'Loading ...',
      folder: noteType === NoteType.PROJECT_NOTE ? 'Loading ...' : undefined,
    }
  }

  if (isError) {
    const message: string | undefined =
      error && typeof error === 'object' && 'message' in error
        ? (error.message as string)
        : error?.toString()
    return { title: message ?? 'Error' }
  }

  if (noteTitle && noteTitle.length > 0) {
    title = noteTitle
  }

  if (isCalendarNote(noteType)) {
    const result = generateCalendarNoteTitleAndDetail(date, selectedDate)
    title = result.title
    detail = result.detail
  } else {
    const pathComponents = filename?.split('/')
    folder =
      pathComponents && pathComponents.length > 1
        ? // Case CloudKit
          // Take all components except the last
          pathComponents.slice(0, -1).join('/')
        : // Case Supabase
          breadcrumbPath(sidebarEntries, recordName)

    if (folder.length === 0) {
      folder = 'Notes'
    }
  }

  return { title, folder, detail }
}

// traverse the sidebar entries to compile the breadcrumb path
function breadcrumbPath(
  sidebarEntries: SidebarEntry[],
  recordName: string
): string {
  const path: string[] = []
  const { parent } = findSidebarEntry(sidebarEntries, recordName)
  if (parent && !['notes', 'teamspaces'].includes(parent.recordName)) {
    path.push(breadcrumbPath(sidebarEntries, parent.recordName), parent.title)
  }
  return path.filter((p) => p.length > 0).join('/')
}

function determineIcon(
  selectedDate: SelectedDate,
  folder: string | undefined,
  noteType: NoteType | undefined
): string {
  if (folder) {
    return 'fa-light fa-folder'
  }
  if (noteType && isTeamspaceNote(noteType) && !isCalendarNote(noteType)) {
    return 'far fa-screen-users text-green-500'
  }

  switch (selectedDate.active) {
    case 'week': {
      return 'fa-light fa-calendar-week'
    }
    case 'month': {
      return 'fal fa-calendar-range'
    }
    case 'quarter': {
      return 'fal fa-calendar-days'
    }
    case 'year': {
      return 'fal fa-calendar-days'
    }
    default: {
      return 'fa-light fa-calendar-star'
    }
  }
}

function determineIconColor(selectedDate: SelectedDate): string {
  switch (selectedDate.active) {
    case 'week': {
      return 'text-fuchsia-600 dark:text-fuchsia-500'
    }
    case 'month': {
      return 'text-red-700 dark:text-red-600'
    }
    case 'quarter': {
      return 'text-orange-700 dark:text-orange-600'
    }
    case 'year': {
      return 'text-yellow-600 dark:text-yellow-500'
    }
    default: {
      return 'text-orange-600 dark:text-orange-400'
    }
  }
}

type Props = {
  onToday: (_isWeek: boolean) => void
}

export const NoteTitle = ({ onToday }: Props) => {
  const selectedDate = useSelectedDate()
  const selectedRecordName = useSelectedRecordName()
  const { noteKey, sidebarEntries, handleRevealNote } = useSidebarProvider()
  const { isLoading, isError, error, data: note } = useNote(noteKey)

  const { title, folder, detail } = parsePageTitle(
    selectedDate,
    isLoading,
    isError,
    note?.title,
    note?.noteType ?? NoteType.CALENDAR_NOTE,
    note?.filename,
    sidebarEntries,
    selectedRecordName,
    error
  )

  const icon = determineIcon(selectedDate, folder, note?.noteType)
  const iconColor = determineIconColor(selectedDate)

  return (
    <button
      className='note-title p-0'
      type='button'
      data-tooltip-id='my-tooltip'
      data-tooltip-content={folder ? 'Show note in sidebar' : 'Open today (⌃T)'}
      onClick={() => {
        folder ? handleRevealNote(selectedRecordName) : onToday(false)
      }}
    >
      <i
        className={`${icon} ${folder ? 'text-blue-500' : iconColor} mr-1.5`}
      ></i>
      <h1 className='text-lg font-bold'>{folder ?? title}</h1>
      <h2
        className={
          'text-xs ml-2 text-gray-400 mb-0 align-bottom' + (detail ?? ' hidden')
        }
      >
        {detail}
      </h2>
    </button>
  )
}

import { MantineThemeOverride } from "@mantine/core";

type ColorScheme = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

export const blockNoteColorScheme: ColorScheme = [
  "#FFFFFF",
  "#EFEFEF",
  "#CFCFCF",
  "#AFAFAF",
  "#7F7F7F",
  "#3F3F3F",
  "#1F1F1F",
  "#161616",
  "#0F0F0F",
  "#000000",
];

export const getBlockNoteTheme = (
  useDarkTheme: boolean = false
): MantineThemeOverride => {
  const boxShadow = `0px 6px 8px ${
    useDarkTheme ? "#00000030" : "#00000020"
  }, 0px 0px 0px ${
    useDarkTheme ? blockNoteColorScheme[6] : blockNoteColorScheme[1]
  }`;

  const border = `0.8px solid ${useDarkTheme ? "#494949" : "#E5E5E5"}`;

  const sideMenu = useDarkTheme
    ? blockNoteColorScheme[4]
    : blockNoteColorScheme[2];

  // const primaryBackground = useDarkTheme
  //   ? blockNoteColorScheme[6]
  //   : blockNoteColorScheme[0];
  // const secondaryBackground = useDarkTheme
  //   ? blockNoteColorScheme[7]
  //   : blockNoteColorScheme[1];

  const primaryText = useDarkTheme
    ? blockNoteColorScheme[2]
    : blockNoteColorScheme[5];
  // const secondaryText = useDarkTheme
  //   ? blockNoteColorScheme[4]
  //   : blockNoteColorScheme[4];

  const hoveredBackground = useDarkTheme
    ? blockNoteColorScheme[7]
    : blockNoteColorScheme[1];
  // const hoveredText = useDarkTheme
  //   ? blockNoteColorScheme[2]
  //   : blockNoteColorScheme[5];

  // const selectedBackground = useDarkTheme
  //   ? blockNoteColorScheme[8]
  //   : blockNoteColorScheme[5];
  // const selectedText = useDarkTheme
  //   ? blockNoteColorScheme[2]
  //   : blockNoteColorScheme[0];

  const disabledBackground = useDarkTheme
    ? blockNoteColorScheme[7]
    : blockNoteColorScheme[1];
  const disabledText = useDarkTheme
    ? blockNoteColorScheme[5]
    : blockNoteColorScheme[3];

  //TODO: Implement NotePlan themes for the approach used above for BlockNote

  const slashMenuitemColor = useDarkTheme ? "#3F3F3F" : "#E7E7E5";
  const backgroundSlashMenuColor = useDarkTheme ? "#332F33" : "#F5F5F5";
  const slashMenuBorderColor = useDarkTheme ? " #2C2C2C" : "#E2E2E2";
  // const slashMenuItemTextColor = useDarkTheme ? "#F2F2F2" : "#3D3D3D";

  const primaryBackground = useDarkTheme ? "#383838" : "#ffffff";
  const toolbarBackground = useDarkTheme ? "#494949" : "#eee";

  const toolbarColor = useDarkTheme ? "#D0D0D0" : "#000102";
  // const slashMenuIconColor = useDarkTheme ? "#ffffff" : "#3D3D3D";

  const slashMenuLabelTextColor = "#858689";

  const menuItemTextFont = `"-apple-system", "system-ui", "BlinkMacSystemFont",
  "Helvetica Neue", Arial, "Liberation Sans", sans-serif`;

  return {
    activeStyles: {
      // Removes button press effect.
      transform: "none",
    },
    colorScheme: useDarkTheme ? "dark" : "light",
    colors: {
      scheme: blockNoteColorScheme,
      dark: blockNoteColorScheme,
      textColors: [
        // primaryText,
        blockNoteColorScheme[6],
        "#9b9a97",
        "#64473a",
        "#e03e3e",
        "#d9730d",
        "#dfab01",
        "#4d6461",
        "#0b6e99",
        "#6940a5",
        "#ad1a72",
      ],
      backgroundColors: [
        // primaryBackground,
        blockNoteColorScheme[0],
        "#ebeced",
        "#e9e5e3",
        "#fbe4e4",
        "#f6e9d9",
        "#fbf3db",
        "#ddedea",
        "#ddebf1",
        "#eae4f2",
        "#f4dfeb",
      ],
    },
    components: {
      // Block Side Menu items
      ActionIcon: {
        styles: () => ({
          root: {
            color: sideMenu,
          },
        }),
      },
      // Slash Menu, Formatting Toolbar dropdown, color picker dropdown
      Menu: {
        styles: () => ({
          dropdown: {
            fontFamily: menuItemTextFont,
            backgroundColor: backgroundSlashMenuColor,
            border: border,
            borderRadius: "6px",
            boxShadow: boxShadow,
            color: primaryText,
            padding: "2px",

            ".mantine-Menu-itemIcon": {
              width: 14,
              marginBottom: 3,
              opacity: 0.5,
            },

            ".mantine-Text-root": {
              fontFamily: menuItemTextFont,
            },

            // Badge (markdown hint)
            ".mantine-Menu-itemRightSection": {
              marginLeft: "auto",
              ".mantine-Badge-root": {
                backgroundColor: "transparent",
                color: slashMenuLabelTextColor,
                font: menuItemTextFont,
                fontSize: 11,
                fontWeight: 400,
                textTransform: "none",
                marginBottom: 3,
                marginRight: -10,
                fontFamily: menuItemTextFont,
              },
            },

            ".mantine-Menu-item": {
              // backgroundColor: slashMenuitemColor,

              height: 30,
              padding: 10,
              width: "97.5%",
              display: "flex",
              border: "none",
              borderRadius: 6,
              marginLeft: 4,
              color: toolbarColor,
              backgroundColor: "transparent",
              alignItems: "flex-end",
              cursor: "default",
              fontWeight: 400,
              paddingBottom: 4,
              fontSize: 14,
              letterSpacing: "0.4px",
              ".mantine-Text-root": {
                fontFamily: menuItemTextFont,
              },
            },
            ".mantine-Menu-item[data-hovered]": {
              backgroundColor: slashMenuitemColor,
              border: "none",
              color: toolbarColor,
              borderRadius: "6px",
            },
          },
        }),
      },
      DragHandleMenu: {
        styles: () => ({
          root: {
            ".mantine-Menu-item": {
              fontSize: "14px",
              height: "30px",
              fontFamily: menuItemTextFont,
              width: "99%",
              display: "flex",
              background: "transparent",

              ".mantine-Menu-itemIcon": {
                marginBottom: 3,
              },

              // Badge (markdown hint)
              ".mantine-Menu-itemRightSection": {
                marginLeft: "auto",
                ".mantine-Badge-root": {
                  backgroundColor: "transparent",
                  color: slashMenuLabelTextColor,
                  font: menuItemTextFont,
                  fontSize: 11,
                  fontWeight: 400,
                  textTransform: "none",
                  marginBottom: 3,
                  marginRight: -10,
                  fontFamily: menuItemTextFont,
                },
              },
            },
          },
        }),
      },
      EditHyperlinkMenu: {
        styles: () => ({
          root: {
            fontFamily: menuItemTextFont,
            backgroundColor: primaryBackground,
            border: border,
            borderRadius: "6px",
            boxShadow: boxShadow,
            color: primaryText,
            gap: "0px",
            minWidth: "145px",
            padding: "2px",

            // Row
            ".mantine-Group-root": {
              flexWrap: "nowrap",
              gap: "8px",
              paddingInline: "6px",
              // Row icon
              ".mantine-Container-root": {
                color: primaryText,
                display: "flex",
                justifyContent: "center",
                padding: 0,
                width: "fit-content",
              },
              // Row input field
              ".mantine-TextInput-root": {
                width: "300px",
                ".mantine-TextInput-wrapper": {
                  ".mantine-TextInput-input": {
                    border: "none",
                    color: primaryText,
                    fontSize: "14px",
                    padding: 0,
                    fontFamily: menuItemTextFont,
                    backgroundColor: primaryBackground,
                  },
                },
              },
            },
          },
        }),
      },
      Toolbar: {
        styles: () => ({
          root: {
            backgroundColor: primaryBackground,
            boxShadow: boxShadow,
            border: border,
            borderRadius: "6px",
            flexWrap: "nowrap",
            gap: "2px",
            padding: "2px 2px 2px 2px",
            width: "fit-content",
            // Button (including dropdown target)
            ".mantine-UnstyledButton-root": {
              backgroundColor: primaryBackground,
              border: "none",
              borderRadius: "4px",
              color: toolbarColor,
              fontWeight: 600,
            },
            // Hovered button
            ".mantine-UnstyledButton-root:hover": {
              backgroundColor: toolbarBackground,
              border: "none",
            },
            // Selected button
            ".mantine-UnstyledButton-root[data-selected]": {
              backgroundColor: toolbarBackground,
              border: "none",
            },
            // Disabled button
            ".mantine-UnstyledButton-root[data-disabled]": {
              backgroundColor: disabledBackground,
              border: "none",
              color: disabledText,
            },
            // Dropdown
            ".mantine-Menu-dropdown": {
              // Dropdown item
              ".mantine-Menu-item": {
                fontSize: "12px",
                height: "30px",
                ".mantine-Menu-itemRightSection": {
                  paddingLeft: "5px",
                },
              },
              ".mantine-Menu-item:hover": {
                backgroundColor: hoveredBackground,
              },
            },
            ".mantine-EditHyperlinkMenu-root *": {
              fontFamily: menuItemTextFont,
            },
            ".mantine-Input-input": {
              background: "transparent",
            },
          },
        }),
      },
      Tooltip: {
        styles: () => ({
          root: {
            backgroundColor: "black",
            border: border,
            borderRadius: "6px",
            boxShadow: boxShadow,
            color: "white",
            padding: "4px 10px",
            textAlign: "center",
            fontFamily: menuItemTextFont,
            "div ~ div": {
              color: "white",
            },
            div: {
              fontFamily: menuItemTextFont,
            },
          },
        }),
      },
      SlashMenu: {
        styles: () => ({
          root: {
            position: "relative",
            background: backgroundSlashMenuColor,
            padding: "2px 0",
            border: `1px solid ${slashMenuBorderColor}`,
            outline: "none",
            maxHeight: 360,
            minWidth: 300,
            width: 450,
            overflow: "hidden",

            ".mantine-Menu-item": {
              // Icon
              height: 30,
              padding: 0,
              width: "97.5%",
              display: "flex",
              border: "none",
              borderRadius: 6,
              marginLeft: 4,
              color: toolbarColor,
              backgroundColor: "transparent",
              alignItems: "flex-end",
              cursor: "default",
              fontWeight: 400,
              paddingBottom: 4,
              fontSize: 14,
              letterSpacing: "0.4px",
              ".mantine-Menu-itemIcon": {
                backgroundColor: "transparent",
                borderRadius: "4px",
                color: toolbarColor,
                width: 40,
                boxSizing: "content-box",
                fontSize: 14,
                marginRight: 2,
                marginBottom: 0,
              },
              ".mantine-Text-root": {
                fontFamily: menuItemTextFont,
              },
              // Text
              ".mantine-Menu-itemLabel": {
                ".mantine-Stack-root": {
                  gap: "0",
                },
              },
              // Badge (markdown hint)
              ".mantine-Menu-itemRightSection": {
                marginLeft: "auto",
                textTransform: "lowercase",
                ".mantine-Badge-root": {
                  backgroundColor: "transparent",
                  color: slashMenuLabelTextColor,
                  font: menuItemTextFont,
                  fontSize: 11,
                  fontWeight: 400,
                  textTransform: "none",
                  marginBottom: 3,
                  marginRight: 2,
                  fontFamily: menuItemTextFont,
                },
              },
            },
          },
        }),
      },
    },
    fontFamily: "Inter",
    other: {
      colors: [
        "default",
        "gray",
        "brown",
        "red",
        "orange",
        "yellow",
        "green",
        "blue",
        "purple",
        "pink",
      ],
    },
    primaryColor: "scheme",
  };
};

import CloudKit from 'tsl-apple-cloudkit'
import {
  Note,
  NoteType,
  SourceDatabase,
  readNoteTitleFromContent,
  readTagsFromContent,
} from '../../utils/syncUtils'

export async function loadNotesFrom(
  records: CloudKit.RecordReceived[]
): Promise<Map<string, Note>> {
  const promises = records.map(async (record) => {
    return [record.recordName, await loadNoteFrom(record, true)] as [
      string,
      Note,
    ]
  })

  const notesTuples = await Promise.all(promises)
  return new Map<string, Note>(notesTuples)
}

export async function loadNoteFrom(
  record: CloudKit.RecordReceived,
  loadAttachments = true
): Promise<Note> {
  // The meta field is a special value that can contain meta values such as the title of the not
  // EDIT: We don't read them from the meta field anymore, but process them from the content directly, this would make it easy to get the complete data right away
  // Otherwise we would have to save the meta data also on native and existing users would have to re-index everything, so it's easier to process it directly here.
  // const meta = readMetaField(record.fields?.meta?.value);

  let date: Date | undefined
  if (record.fields?.fileModifiedAt?.value) {
    date = new Date(record.fields?.fileModifiedAt?.value)
  }

  const isFolder = record.fields?.isDir?.value === 1
  const filename = record.fields?.filename?.value
  let title = record.fields?.title?.value

  // If it's a folder we need to use the filename as the title, since we are not using the title field in the native version and filename == title for folders, there's no content to read the title from
  if (isFolder) {
    const path = filename.split('/')
    title = path[path.length > 0 ? path.length - 1 : 0]
  }

  const note: Note = {
    content: record.fields?.content ? record.fields?.content?.value : '',
    noteType: record.fields?.noteType?.value ?? NoteType.ASSET_CALENDAR_NOTE,
    filename: filename,
    recordName: record.recordName,
    recordChangeTag: record.recordChangeTag,
    fileModifiedAt: date,
    isFolder: isFolder,
    source: SourceDatabase.CLOUDKIT,
    title: title,
    tags: [],
  }

  // If there are attachments and the content is empty, take out the first attachment and fetch it as the content.
  // This means the user has "encryption" of notes activated and this saves the content as an asset instead of text.
  // CloudKit automatically encrypts assets.
  if (
    record.fields?.attachments &&
    record.fields?.attachments?.value &&
    loadAttachments
  ) {
    const attachments = record.fields.attachments.value

    // Either it's specified as an asset or the there is something in the assets but no content
    if (
      attachments.length > 0 &&
      (note.content.length === 0 ||
        note.noteType === NoteType.ASSET_CALENDAR_NOTE ||
        note.noteType === NoteType.ASSET_PROJECT_NOTE)
    ) {
      // Download attachment
      try {
        const attachmentResponse = await fetch(attachments[0].downloadURL)
        const blob = await attachmentResponse.blob()

        // Turn blob into a string
        note.content = await new Promise<string>((resolve) => {
          const reader = new FileReader()

          reader.onloadend = () => {
            // This usually means the blob was empty
            if (reader.result === 'Bad Request\nFile not found') {
              resolve('')
            } else {
              resolve(reader.result as string)
            }
          }
          reader.readAsText(blob)
        })
      } catch (err) {
        console.error('Error fetching attachment:', err)
      }

      // Remove attachment from list
      note.encrypted = true
      attachments.shift()
    }

    note.attachments = JSON.stringify(
      attachments.map(({ downloadURL }: { downloadURL: string }) => downloadURL)
    )
  }

  // If the title is empty, get it from the content, if any content is available
  if (!isFolder) {
    if (note.content.length > 0) {
      note.title = readNoteTitleFromContent(note, false)
    }

    // Set the meta field if needed
    note.tags = readTagsFromContent(note)
  }

  return note
}

/* eslint-disable no-console */
import { useCallback, useEffect } from 'react'
import { useAuthenticatedUser } from '../providers/UserProvider'
import {
  cachedNotesContext,
  getCachedData,
  removeFromCacheByRecordNames,
  updateCache,
} from '../providers/CachedNotesProvider'
import { QueryClient, QueryKey, useQueryClient } from '@tanstack/react-query'
import { AuthType } from '../utils/User'
import { useIsOnline } from '../providers/NetworkProvider'
import { fetchNotes } from '../lib/fetchNotes'
import { cacheKeyFromNoteType, noteQueryKey } from '../utils/queryKeyFactory'
import { Note, NoteType, isTeamspaceNote } from '../utils/syncUtils'
import { useSupabaseSubscription } from './useSupabaseSubscription'
import { useCloudKitSubscription } from './useCloudKitSubscription'

function getLastModifiedDate(notesMap: Map<string, Note> | undefined) {
  if (!notesMap || notesMap.size === 0) return

  let latest = new Date(0)
  for (const note of notesMap.values()) {
    const modifiedAt = note.fileModifiedAt
      ? new Date(note.fileModifiedAt)
      : new Date(0)
    if (modifiedAt > latest) {
      latest = modifiedAt
    }
  }
  return latest
}

function isNewerNote(existingNote: Note, newNote: Note): boolean {
  const existingModifiedAt = existingNote.fileModifiedAt
    ? new Date(existingNote.fileModifiedAt)
    : new Date(0)
  const newModifiedAt = newNote.fileModifiedAt
    ? new Date(newNote.fileModifiedAt)
    : new Date(0)
  return newModifiedAt > existingModifiedAt
}

function mergeNotes(
  existingNotes: Map<string, Note> | undefined,
  newNotes: Map<string, Note>
) {
  const mergedNotes = new Map(existingNotes)

  for (const [key, newNote] of newNotes) {
    const existingNote = mergedNotes.get(key)
    if (existingNote) {
      if (isNewerNote(existingNote, newNote)) {
        mergedNotes.set(key, newNote)
      }
    } else {
      mergedNotes.set(key, newNote)
    }
  }

  return mergedNotes
}

function removeMissingNotes(
  cacheKey: QueryKey,
  allStubs: Map<string, Note>,
  cachedNotesQueryClient: QueryClient
) {
  const stubIds = new Set(allStubs.keys())
  const oldNotes = getCachedData(cachedNotesQueryClient, cacheKey)
  const notesToRemove: string[] = []

  for (const id of oldNotes.keys()) {
    if (!stubIds.has(id)) {
      notesToRemove.push(id)
    }
  }

  if (notesToRemove.length > 0) {
    console.debug('[useBackendNotifications] Removed notes:', notesToRemove)
    removeFromCacheByRecordNames(
      cachedNotesQueryClient,
      notesToRemove,
      cacheKey
    )
  } else {
    console.debug('[useBackendNotifications] No notes removed')
  }
}

export function useBackendNotifications() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })
  const isOnline = useIsOnline()

  const isSupabaseRegistered = useSupabaseSubscription()
  const isCloudKitRegistered = useCloudKitSubscription()
  const notificationsAreOff =
    (user.authType === AuthType.CLOUDKIT && isCloudKitRegistered === false) ||
    (user.authType === AuthType.SUPABASE && isSupabaseRegistered === false) ||
    (user.authType === AuthType.CLOUDKIT_SUPABASE &&
      (isCloudKitRegistered === false || isSupabaseRegistered === false))

  const fetchChangedNotes = useCallback(async () => {
    const noteTypes = [
      NoteType.PROJECT_NOTE,
      NoteType.TEAM_SPACE_NOTE,
      NoteType.CALENDAR_NOTE,
      NoteType.TEAM_SPACE_CALENDAR_NOTE,
    ]

    console.debug('[useBackendNotifications] Start background fetch')

    const fetchPromises = noteTypes.map(async (noteType) => {
      // Skip teamspace notes for CloudKit-only users since CloudKit doesn't support them
      if (user.authType === AuthType.CLOUDKIT && isTeamspaceNote(noteType)) {
        console.debug(
          '[useBackendNotifications] Skipping noteType',
          noteType,
          'for authType',
          user.authType
        )
        return
      }

      try {
        const cacheKey = cacheKeyFromNoteType(noteType, user)
        const lastModifiedDate = getLastModifiedDate(
          getCachedData(cachedNotesQueryClient, cacheKey)
        )
        if (!lastModifiedDate) return

        console.debug(
          '[useBackendNotifications] Fetching notes',
          noteType,
          lastModifiedDate
        )

        const newNotes = await fetchNotes(user, noteType, lastModifiedDate)
        console.debug('[useBackendNotifications] new notes', cacheKey, newNotes)

        // Update the cache with the new notes
        const oldNotes = getCachedData(cachedNotesQueryClient, cacheKey)
        updateCache(
          cachedNotesQueryClient,
          [...mergeNotes(oldNotes, newNotes.map).values()],
          cacheKey
        )

        // Update the opened notes
        for (const note of newNotes.map.values()) {
          const existingNote = queryClient.getQueryData<Note>(
            noteQueryKey(note)
          )
          if (existingNote && isNewerNote(existingNote, note)) {
            void queryClient.invalidateQueries({ queryKey: noteQueryKey(note) })
          }
        }

        // Load all the IDs only (faster than loading content and assets) so we can remove the notes that were deleted while we didn't receive notifications
        if (
          ![
            NoteType.CALENDAR_NOTE,
            NoteType.ASSET_CALENDAR_NOTE,
            NoteType.TEAM_SPACE_CALENDAR_NOTE,
          ].includes(noteType)
        ) {
          const allStubs = await fetchNotes(user, noteType, undefined, true)
          console.debug(
            '[useBackendNotifications] note stubs',
            cacheKey,
            allStubs
          )

          removeMissingNotes(cacheKey, allStubs.map, cachedNotesQueryClient)
        }
      } catch (error) {
        console.error('[useBackendNotifications] Error fetching notes:', error)
        return
      }
    })

    await Promise.all(fetchPromises)
  }, [user, cachedNotesQueryClient, queryClient])

  useEffect(() => {
    console.log(
      '[useBackendNotifications] isOnline',
      isOnline,
      'notificationsAreOff',
      notificationsAreOff
    )

    if (isOnline && notificationsAreOff) {
      console.debug(
        'back online again or notifications are off -> fetching changed notes'
      )
      void fetchChangedNotes()
    }
    // Edit: Removed fetchChangedNotes from the dependencies, because the callback changes often, like due to changes in teh query client or user and triggers this unecessarily, at least 3x when reloading.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, notificationsAreOff /*, fetchChangedNotes*/])
}
/* eslint-enable no-console */

import { stripeRequest } from '../lib/stripe'

export function useSubscribeEmailOctopus() {
  const subscribeUser = async (
    userId: string
  ): Promise<{
    error?: string
  }> => {
    try {
      const response = await stripeRequest<object>('/emailoctopus.php', {
        supabaseUserId: userId,
      })
      return response
    } catch (error) {
      return { error: String(error) }
    }
  }

  return { subscribeUser }
}

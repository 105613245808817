import { createClickableMark } from "../api/createClickableMark";
import { MantineThemeOverride } from "@mantine/core";

export const DateLink = (
  onDateLinkClicked?: (event: MouseEvent) => void,
  onLoadDateLinks?: (
    prefix: string,
    keyword: string
  ) => Array<
    | string
    | { display: string; paste: string; icon?: string; shortcut?: string }
  >,
  theme?: MantineThemeOverride
) =>
  createClickableMark({
    name: "datelink",
    suggestionChar: ">",
    suggestionPrefix: ">",
    shouldMatchQuery: false,
    allowSpaces: true,
    regex:
      /[>@](today|(([0-9]{4})(-((0[1-9]|1[0-2])(-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))?|Q[1-4]|W0[1-9]|W[1-4]\d|W5[0-3]))?))/g,
    attrsMap: {
      datelink: 0,
      href: 1,
    },
    onMarkClicked: onDateLinkClicked,
    onLoadSuggestionItems: onLoadDateLinks,
    instructions:
      "Type something like '5th Feb', 'week 2', or choose to schedule:",
    theme: theme,
  });

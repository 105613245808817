import {
  Note,
  NoteType,
  getFilenameFromNoteTitle,
  isPrivateNote,
  isTeamspaceNote,
  readNoteTitleFromContent,
} from '../../utils/syncUtils'
import { v4 as uuidv4 } from 'uuid'
import { addTag, removeTag } from './notificationTracker'
import { supabaseClient } from '../SupabaseClient'
import {
  deleteAttachmentFolder,
  loadNoteFrom,
  updateAttachments,
} from './noteUtils'

export async function createNote(currentUserId: string, draft: Note) {
  // Prepare the create
  const newNote = {
    user_id: currentUserId,
    id: draft.recordName,
    filename: draft.filename,
    note_type: draft.noteType ?? NoteType.PROJECT_NOTE,
    // EDIT: We don't encrypt on the database anymore, planned is later a full E2EE but locally
    //   content_encrypted: draft.content?.length > 0 ? draft.content : null,
    content: draft.content?.length > 0 ? draft.content : null,
    title: draft.title?.length > 0 ? draft.title : null,
    // EDIT: We don't encrypt on the database anymore, planned is later a full E2EE but locally
    //   title_encrypted: draft.title?.length > 0 ? draft.title : null,
    parent: draft.parent,
    is_dir: draft.isFolder,
    change_tag: uuidv4(),
  }

  // eslint-disable-next-line no-console
  console.log('[Supabase] createNote', newNote)

  // Ignore the next notification
  addTag(newNote.change_tag)

  const { error: insertError } = await supabaseClient
    .from('notes')
    .insert(newNote)
  if (insertError) {
    removeTag(newNote.id) // Database query failed, so remove it again from the skip list
    throw insertError
  }

  const { data, error: selectError } = await supabaseClient
    .from('notes')
    .select('*')
    .eq('id', draft.recordName)
    .limit(1)
  if (selectError) {
    removeTag(newNote.change_tag) // Database query failed, so remove it again from the skip list
    throw selectError
  }
  // eslint-disable-next-line no-console
  console.log('[Supabase] createNote result', data)

  // Return the created note
  if (data && data.length > 0) {
    return await loadNoteFrom(currentUserId, data[0])
  }

  throw new Error('Could not create note')
}

export async function saveNote(
  currentUserId: string,
  note: Note,
  content: string,
  attachments: string[],
  modifiedAt: Date = new Date()
) {
  // We need to upload the attachments, if they don't already exist, then take the path and save it in an array in the row.
  // Also this deletes old attachments that were removed.
  const savedAttachments = await updateAttachments(
    note.recordName,
    note.uploadedAttachments,
    attachments
  )
  note.content = content // Update the content here, so we get the up-to-date title from the note
  const title = readNoteTitleFromContent(note, false) ?? null

  // Prepare the update
  const updatedNote = {
    filename: getFilenameFromNoteTitle(note),
    note_type: note.noteType ?? NoteType.CALENDAR_NOTE,
    //   title_encrypted: title,
    title: title,
    content: content.length == 0 ? null : content,
    //   content_encrypted: content.length == 0 ? null : content,
    attachments: savedAttachments,
    change_tag: uuidv4(),
    parent: note.parent,
    modified_at: (modifiedAt ?? new Date()).toISOString(),
  }

  // eslint-disable-next-line no-console
  console.debug('[Supabase] saveNote', updatedNote)
  let result = null

  // Ignore the next notification
  addTag(updatedNote.change_tag)

  // NOTE: Data encryption doesn't work when we use upsert, so we need to use update and insert instead
  if (note.recordChangeTag) {
    // eslint-disable-next-line no-console
    console.debug('[Supabase] saveNote, update, because change tag was found')
    // If we have a recordChangeTag, the note already exists
    // update the note and select the updated note in the 'decrypted_notes' table
    result = await supabaseClient
      .from('notes')
      .update(updatedNote)
      .eq('id', note.recordName)
  } else {
    // Set a record name, otherwise, we can't re-query the note to return it
    if (!note.recordName) {
      note.recordName = uuidv4()
    }

    updatedNote['user_id'] = currentUserId
    updatedNote['id'] = note.recordName // set only here otherwise update will create new records
    updatedNote.content = '' // No need to upload any content yet, if it has an image, it won't be displayed otherwise
    result = await supabaseClient.from('notes').insert(updatedNote)

    // If there was no error, save it now again. If we save it the first time, we can't first upload the attachments and then save, we have to save the note first
    if (!result.error) {
      note.recordChangeTag = updatedNote.change_tag
      return await saveNote(currentUserId, note, content, attachments)
    }
  }

  if (result.error) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw result.error
  }

  const { data, error: selectError } = await supabaseClient
    .from('notes')
    .select('*')
    .eq('id', note.recordName)
    .limit(1)
  if (selectError) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw selectError
  }

  // Return the saved note
  if (data && data.length > 0) {
    // eslint-disable-next-line no-console
    console.debug('[Supabase], saved note:', data[0])
    const uploaded = await loadNoteFrom(currentUserId, data[0])
    return uploaded
  }

  throw new Error('Could not save note')
}

export async function saveNoteTitle(
  currentUserId: string,
  id: string,
  title: string
) {
  // Prepare the update
  // eslint-disable-next-line unicorn/no-null -- supabase uses null for no title
  const newTitle = title.length === 0 ? null : title
  // TODO rename also the filename
  const updatedNote = {
    //   title_encrypted: newTitle,
    title: newTitle,
    modified_at: new Date().toISOString(),
    change_tag: uuidv4(),
  }

  // eslint-disable-next-line no-console
  console.debug('[Supabase] saveNoteTitle', updatedNote)

  // Ignore the next notification
  addTag(updatedNote.change_tag)

  const { error: updateError } = await supabaseClient
    .from('notes')
    .update(updatedNote)
    .eq('id', id)
  if (updateError) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw updateError
  }
  const { data, error: selectError } = await supabaseClient
    .from('notes')
    .select('*')
    .eq('id', id)
    .limit(1)
  if (selectError) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw selectError
  }
  if (data.length > 0) {
    const note = await loadNoteFrom(currentUserId, data[0])
    if (note.recordName) {
      return new Map<string, Note>([[note.recordName, note]])
    } else {
      throw new Error('Note recordName is undefined')
    }
  }
  throw new Error('No data found')
}

export async function moveNote(
  currentUserId: string,
  id: string,
  noteType: NoteType,
  parentId?: string,
  parentNoteType?: NoteType
) {
  const updatedNote = {
    // eslint-disable-next-line unicorn/no-null -- supabase uses null for no parent
    parent: parentId ?? null,
    modified_at: new Date().toISOString(),
    change_tag: uuidv4(),
    note_type: noteType,
  }

  if (
    isPrivateNote(noteType) &&
    parentNoteType &&
    isTeamspaceNote(parentNoteType)
  ) {
    updatedNote['note_type'] = NoteType.TEAM_SPACE_NOTE
  }

  if (
    isTeamspaceNote(noteType) &&
    parentNoteType &&
    isPrivateNote(parentNoteType)
  ) {
    updatedNote['note_type'] = NoteType.PROJECT_NOTE
  }

  // eslint-disable-next-line no-console
  console.debug('[Supabase] moveNote', updatedNote)

  addTag(updatedNote.change_tag)
  const { error: updateError } = await supabaseClient
    .from('notes')
    .update(updatedNote)
    .eq('id', id)

  if (updateError) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw updateError
  }
  const { data, error: selectError } = await supabaseClient
    .from('notes')
    .select('*')
    .eq('id', id)
    .limit(1)
  if (selectError) {
    removeTag(updatedNote.change_tag) // Database query failed, so remove it again from the skip list
    throw selectError
  }
  if (data.length > 0) {
    const note = await loadNoteFrom(currentUserId, data[0])
    if (note.recordName) {
      return new Map<string, Note>([[note.recordName, note]])
    } else {
      throw new Error('Note recordName is undefined')
    }
  }
  throw new Error('This should not happen')
}

export async function deleteNote(id: string) {
  // eslint-disable-next-line no-console
  console.debug('Deleting - id: ', id)
  await deleteAttachmentFolder(id)

  addTag(id)
  const { error } = await supabaseClient.from('notes').delete().eq('id', id)

  if (error) {
    removeTag(id)
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error.message)
  }

  return true
}

export async function hasPrivateNotes(currentUserId: string): Promise<boolean> {
  const { data, error } = await supabaseClient
    .from('notes')
    .select('id')
    // .in('note_type', [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE]) // Doesn't matter what note
    .eq('user_id', currentUserId)
    .limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching private notes:', error)
    throw error
  }

  // eslint-disable-next-line no-console
  console.debug('Has private notes:', data.length > 0)
  return data.length > 0
}

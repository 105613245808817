import { useMutation, useQueryClient } from '@tanstack/react-query'
import CloudKit from 'tsl-apple-cloudkit'
import { database } from '../lib/CloudKitClient'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { useSafeQuery } from './useSafeQuery'
import { AuthType } from '../utils/User'
import { privateKeys } from '../utils/queryKeyFactory'

export const NotesZone: CloudKit.ZoneID = { zoneName: 'Notes' }
export const SettingsZone: CloudKit.ZoneID = { zoneName: 'Settings' }

async function fetchAllRecordZones() {
  if (!database) {
    return []
  }
  const response = await database.fetchAllRecordZones()
  if (response.hasErrors) {
    throw new Error('Error fetching all record zones')
  }
  return response.zones
}

async function createZone(zoneID: CloudKit.ZoneID) {
  if (!database) {
    throw new Error('Database is not initialized')
  }
  const response = await database.saveRecordZones([zoneID.zoneName])
  if (response.hasErrors) {
    throw new Error('Error creating zone')
  }
  return response.zones[0]
}

export function useEnsureZones() {
  const queryClient = useQueryClient()
  const { authType } = useAuthenticatedUser()

  const { data: zones } = useSafeQuery({
    queryKey: privateKeys.zones,
    queryFn: fetchAllRecordZones,
    enabled:
      authType === AuthType.CLOUDKIT || authType === AuthType.CLOUDKIT_SUPABASE,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: 2,
  })

  const mutation = useMutation(createZone, {
    onSuccess: () => {
      void queryClient.invalidateQueries(privateKeys.zones)
    },
  })

  const ensureZonesExist = () => {
    if (
      (authType === AuthType.CLOUDKIT ||
        authType === AuthType.CLOUDKIT_SUPABASE) &&
      zones
    ) {
      const missingZones = [NotesZone, SettingsZone].filter(
        (zone) => !zones.some((z) => z.zoneID.zoneName === zone.zoneName)
      )
      for (const zone of missingZones) mutation.mutate(zone)
    }
  }

  return { ensureZonesExist }
}

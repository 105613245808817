import { useRef } from 'react'
import { HourList } from './HourList'

function HorizontalLines() {
  const containerOffset = useRef(null)

  return (
    <div className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-gray-700" style={{ gridTemplateRows: 'repeat(96, 1rem)' }}>
      <div ref={containerOffset} className="row-end-1 h-7"></div>
      <HourList />
      <div />
    </div>
  )
}

export default HorizontalLines

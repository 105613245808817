import { createClickableMark } from "../api/createClickableMark";
import { MantineThemeOverride } from "@mantine/core";

export const Hashtag = (
  onHashTagClicked?: (event: MouseEvent) => void,
  onLoadHashtags?: (
    prefix: string,
    keyword: string
  ) => Array<string | { display: string; paste: string }>,
  theme?: MantineThemeOverride
) =>
  createClickableMark({
    name: "hashtag",
    suggestionChar: "#",
    suggestionPrefix: "#",
    regex:
      /(^|\s|["'([{])(?![#][\d\p{P}]+($|\s))([#]([^\p{P}\s]|[-_/])+?\(.*?\)|[#]([^\p{P}\s]|[-_/])+)/gu,
    attrsMap: {
      hashtag: 3,
      href: 3,
    },
    onMarkClicked: onHashTagClicked,
    onLoadSuggestionItems: onLoadHashtags,
    theme: theme,
  });

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import { v4 as uuid } from 'uuid'
import { useDroppable, useDraggable } from '@dnd-kit/core'
import {
  NoteType,
  SourceDatabase,
  isTeamspaceNote,
} from '../../utils/syncUtils'
import { useDeleteNote } from '../../hooks/useDeleteNote'
import useSaveNoteTitle from '../../hooks/useSaveNoteTitle'
import { useLeaveTeamspace } from '../../hooks/useLeaveTeamspace'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { useSelectedRecordName } from '../../providers/SelectedRecordNameProvider'
import { type SidebarEntry } from './SidebarBuilder'

const LOCAL_STORAGE_PREFIX = 'SIDEBAR_ITEM_OPEN_STATE_'

const getLocalStorageKey = (itemName: string) => {
  return `${LOCAL_STORAGE_PREFIX}${itemName}`
}

function setItemStateToLocalStorage(itemName: string, state: boolean) {
  if (state) {
    localStorage.setItem(getLocalStorageKey(itemName), JSON.stringify(state))
  } else {
    localStorage.removeItem(getLocalStorageKey(itemName))
  }
}

function getItemStateFromLocalStorage(itemName: string): boolean | undefined {
  const savedState = localStorage.getItem(getLocalStorageKey(itemName))
  return savedState == null ? undefined : (JSON.parse(savedState) as boolean)
}

function SkeletonLoader() {
  const lineLengths = [80, 60]
  return (
    <li className='ml-5'>
      <div className='skeleton my-3 h-3 rounded-md' style={{ width: `60%` }} />
      {lineLengths.map((length, index) => {
        return (
          <div
            key={index}
            className='skeleton my-3 ml-4 h-3 rounded-md'
            style={{ width: `${length}%` }}
          />
        )
      })}
      <div
        className='skeleton mb-3 mt-6 h-3 rounded-md'
        style={{ width: `70%` }}
      />
      <div
        className='skeleton my-3 ml-4 h-3 rounded-md'
        style={{ width: `50%` }}
      />
      <div
        className='skeleton my-3 ml-4 h-3 rounded-md'
        style={{ width: `40%` }}
      />
    </li>
  )
}

function traverse(entries: SidebarEntry[] | undefined): string[] {
  if (!entries) return [] as string[]

  let recordNames: string[] = []

  for (const entry of entries) {
    recordNames.push(entry.recordName)

    if (entry.children) {
      recordNames = [...recordNames, ...traverse(entry.children)]
    }
  }

  return recordNames
}

type Props = {
  item: SidebarEntry
  onManageTeamspace: (_id: string, _title: string) => void
  collapsed?: boolean
}

export function SidebarItem({ item, onManageTeamspace, collapsed }: Props) {
  const selectedRecordName = useSelectedRecordName()
  const { handleSelectRecordName, createNote, breadcrumb, setBreadcrumb } =
    useSidebarProvider()

  // #region open state
  const [isOpen, setIsOpen] = useState<boolean>(getInitialState())

  // Fetch the saved state from localStorage for the current item or fall back to its default
  function getInitialState(): boolean {
    if (collapsed) {
      return false
    }

    const savedState = getItemStateFromLocalStorage(item.recordName)

    if (savedState === undefined) {
      return Boolean(item.header)
    }
    return savedState
  }

  useEffect(() => {
    setItemStateToLocalStorage(item.recordName, isOpen)
  }, [isOpen, item.recordName])

  const hasRightChevron = item.header || item.noteType === NoteType.TEAM_SPACE
  // #endregion

  // #region teamspace
  const { handleExportSupabaseTeamspace } = useSidebarProvider()
  const leaveTeamspace = useLeaveTeamspace()
  // #endregion

  // #region editing
  const [editedTitle, setEditedTitle] = useState<string | undefined>(undefined)
  const saveNoteTitle = useSaveNoteTitle()
  function saveTitle() {
    saveNoteTitle.mutate({
      recordName: item.recordName,
      noteType: item.noteType,
      title: editedTitle ?? '',
      children: item.children,
    })
    setEditedTitle(undefined)
  }
  const deleteNote = useDeleteNote()
  // #endregion

  // #region drag and drop
  const { isOver, setNodeRef } = useDroppable({
    id: item.recordName,
    disabled: item.disabled || (item.header && item.recordName !== 'notes'),
    data: {
      noteType: item.noteType,
      filename: item.filename,
      source: item.source,
    },
  })
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
  } = useDraggable({
    id: item.recordName,
    disabled:
      item.disabled ||
      item.header ||
      Boolean(item.menu) ||
      ![
        NoteType.ASSET_PROJECT_NOTE,
        NoteType.PROJECT_NOTE,
        NoteType.TEAM_SPACE_NOTE,
      ].includes(item.noteType),
    data: {
      noteType: item.noteType,
      children: item.children,
      parentRecordName: item.parent,
      parentPath: item.filename?.split('/').slice(0, -1).join('/'),
      source: item.source,
    },
  })
  // Prevent scrolling for pointer events on touch devices
  const touchAction = { touchAction: 'none' }

  // #endregion

  // #region reveal
  useEffect(() => {
    // Don't use item.filename here (in case of supabase), because the filename for Supabase folders is always "Untitled" and thus if you create a new note
    // it will be named "Untitled" as well initially.
    const isSupabase = item.source === SourceDatabase.SUPABASE
    const key = isSupabase ? item.title : item.filename

    if (
      breadcrumb.includes(item.recordName) ||
      (breadcrumb &&
        breadcrumb.length > 0 &&
        (isSupabase ? breadcrumb[0] === key : breadcrumb[0].startsWith(key)))
    ) {
      setIsOpen(true)
      // delay 200ms until the children are mounted
      setTimeout(() => {
        // Remove both, the recordName and the title from the breadcrumb, because for the note we get the title entry in the breadcrumps
        // Otherwise we have an inifinite loop
        const removeKey = isSupabase ? key : breadcrumb[0]
        setBreadcrumb(
          breadcrumb.filter(
            (crumb) => crumb !== item.recordName && crumb !== removeKey
          )
        )
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumb, setIsOpen])
  // #endregion

  return (
    <li
      id={item.recordName}
      className={classNames(
        { hidden: item.isHidden },
        { 'opacity-50': item.disabled }
      )}
      ref={setDraggableNodeRef}
      style={touchAction}
      {...listeners}
      {...attributes}
    >
      {!item.children ? (
        <a
          onClick={(e) => {
            if (item.action?.()) {
              return
            }

            if (item.disabled) {
              return
            }
            if (
              item.selectable !== null &&
              item.selectable !== undefined &&
              !item.selectable
            ) {
              return
            }
            e.preventDefault()
            handleSelectRecordName(item.recordName)
          }}
          className={classNames(
            'cursor-default group',
            // 'block rounded-md py-1 text-sm font-regular text-gray-700 dark:text-gray-200 ',
            // item.recordName.startsWith('spacer') && 'h-2.5',
            // !item.disabled && 'hover:bg-stone-200 dark:hover:bg-stone-200/[0.1]',
            item.recordName.startsWith('spacer') ? 'h-2.5' : '',
            item.disabled
              ? ''
              : 'hover:bg-stone-200 dark:hover:bg-stone-200/[0.1]',
            'block rounded-md pl-2 ml-2 text-sm font-regular text-gray-700 dark:text-gray-200 ',

            item.recordName == selectedRecordName
              ? 'bg-stone-200 dark:bg-stone-200/[0.1]'
              : '',
            // item.menu ? 'pl-0' : 'pl-1.5'
            item.menu ? 'py-0.5' : 'py-1 my-0.5',
            !item.menu ? 'pl-6' : '',
            item.action ? 'pl-[0.95em]' : ''
          )}
        >
          {item.menu ?? (
            <div className='flex items-center'>
              <span className='truncate'>
                {/* Add an icon */}
                <i
                  className={classNames(
                    'text-[0.9rem] mr-2 text-center w-4',
                    item.color,
                    item.icon
                  )}
                />
                {item.title}
              </span>
              {item.shortcut ? (
                <span className='ml-auto mr-2 text-xs font-semibold text-gray-400'>
                  {item.shortcut}
                </span>
              ) : undefined}

              {!item.hideContext && !item.header && !item.disabled && (
                <Menu
                  align='end'
                  boundingBoxPadding='25px 0px 0px 50px'
                  menuButton={
                    <MenuButton
                      className='m-0 ml-auto hidden p-0 group-hover:block'
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <i
                        className='fa-regular fas fa-ellipsis-vertical mx-3 text-sm'
                        aria-hidden='true'
                      />
                    </MenuButton>
                  }
                >
                  <MenuItem
                    key='delete'
                    onClick={(e) => {
                      e.syntheticEvent.stopPropagation()
                      if (
                        window.confirm(
                          'Are you sure you wish to delete this note?'
                        )
                      ) {
                        deleteNote.mutate({
                          recordName: item.recordName,
                          noteType: item.noteType,
                        })
                      }
                    }}
                  >
                    <i
                      className='far fa-trash mr-2 w-4 text-center'
                      aria-hidden='true'
                    />
                    Delete
                  </MenuItem>
                </Menu>
              )}
            </div>
          )}
        </a>
      ) : (
        <div ref={setNodeRef}>
          <div
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className={classNames(
              'truncate cursor-default group',
              'hover:bg-stone-200 dark:hover:bg-stone-200/[0.1]',
              // 'flex items-center text-left',
              'flex items-center text-left pl-2 ml-2',
              item.header
                ? 'rounded-[0.4em] py-0.5 text-xs font-bold text-gray-400 dark:text-gray-400'
                : 'rounded-md py-1 my-0.5 font-regular text-sm text-gray-700 dark:text-gray-100',
              // item.recordName == selectedRecordName && 'bg-stone-200 dark:bg-stone-200/[0.1]',
              // isOver && 'bg-blue-200 dark:bg-blue-200/[0.1]',
              // !hasRightChevron && item.children.length > 0 && '-ml-2'
              'w-[calc(100%-0.5rem)]',
              {
                'bg-stone-200 dark:bg-stone-200/[0.1]':
                  item.recordName == selectedRecordName,
                'bg-blue-200 dark:bg-blue-200/[0.1]': isOver,
              }
            )}
          >
            {/* The chevron on the left (regular folders) */}
            {/* {!hasRightChevron && item.children.length > 0 && (
                  <ChevronRightIcon
                    className={classNames(open ? 'rotate-90 text-gray-500 dark:text-gray-200' : 'text-gray-500 dark:text-gray-200', 'h-4 w-4 shrink-0 cursor-pointer')}
                    aria-hidden="true"
                  />
                )} */}
            {!hasRightChevron && item.children.length > 0 ? (
              <ChevronRightIcon
                className={classNames(
                  isOpen
                    ? 'rotate-90 text-gray-500 dark:text-gray-200'
                    : 'text-gray-500 dark:text-gray-200',
                  'h-4 w-4 shrink-0 cursor-pointer'
                )}
                aria-hidden='true'
              />
            ) : (
              <span
                className={classNames(
                  item.children.length > 0 || item.header ? 'ml-1' : 'ml-4'
                )}
              />
            )}

            {/* Add an icon */}
            {/* {!item.header && <i className={classNames('text-[0.9rem] mr-2 text-center w-4 cursor-default', item.color, item.icon)} {...listeners} {...attributes}></i>} */}
            {item.header ? (
              <span />
            ) : (
              <i
                className={classNames(
                  'text-[0.9rem] mr-2 text-center w-4 cursor-default',
                  item.color,
                  item.icon
                )}
                {...listeners}
                {...attributes}
              />
            )}

            {editedTitle ? (
              <input
                autoFocus
                type='text'
                className='font-regular m-0 mr-3 w-full rounded-none bg-gray-100 px-1 py-0.5 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-200'
                value={editedTitle}
                onChange={(e) => {
                  setEditedTitle(e.target.value)
                }}
                onFocus={(e) => {
                  e.target.select()
                }}
                onBlur={() => {
                  saveTitle()
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    saveTitle()
                  }
                  // prevent the parent from opening / closing
                  if (e.key === ' ') {
                    e.stopPropagation()
                  }
                  if (e.key === 'Escape') {
                    setEditedTitle(undefined)
                  }
                }}
              />
            ) : (
              <span
                className='w-full truncate'
                onClick={
                  item.action
                    ? (e) => {
                        if (item.action) {
                          item.action()
                        }
                        e.stopPropagation()
                      }
                    : undefined
                }
              >
                {item.title}
                {item.isLocked && (
                  <i
                    className='far fa-lock ml-2 w-4 text-center text-gray-400 dark:text-gray-500'
                    aria-hidden='true'
                  />
                )}
              </span>
            )}

            {/* The chevron on the right (headers and teamspaces) */}
            {hasRightChevron ? (
              <ChevronRightIcon
                className={classNames(
                  isOpen
                    ? 'rotate-90 text-gray-500 dark:text-gray-200'
                    : 'text-gray-500 dark:text-gray-200',
                  'h-4 w-4 shrink-0'
                )}
                aria-hidden='true'
              />
            ) : null}

            {!item.hideContext && !item.header && !editedTitle && (
              <Menu
                align='end'
                boundingBoxPadding='25px 0px 0px 50px'
                menuButton={
                  <MenuButton
                    className='m-0 ml-auto hidden p-0 group-hover:block'
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <i
                      className='fa-regular fas fa-ellipsis-vertical mx-3 text-sm'
                      aria-hidden='true'
                    />
                  </MenuButton>
                }
              >
                <MenuItem
                  key='new-folder'
                  onClick={(e) => {
                    // If we stop propagating events here, it doesn't open the folder
                    if (isOpen) {
                      e.syntheticEvent.stopPropagation()
                    } else {
                      setIsOpen(true)
                    }

                    createNote({
                      recordName: uuid(),
                      noteType: isTeamspaceNote(
                        item.noteType ?? NoteType.PROJECT_NOTE
                      )
                        ? NoteType.TEAM_SPACE_NOTE
                        : NoteType.PROJECT_NOTE,
                      parent: item.recordName,
                      isDir: true,
                    })
                  }}
                >
                  <i
                    className='far fa-folder mr-2 w-4 text-center'
                    aria-hidden='true'
                  />
                  New Folder
                </MenuItem>
                <MenuItem
                  key='new-note'
                  onClick={(e) => {
                    // If we stop propagating events here, it doesn't open the folder
                    if (isOpen) {
                      e.syntheticEvent.stopPropagation()
                    } else {
                      setIsOpen(true)
                    }
                    createNote({
                      recordName: uuid(),
                      noteType: item.childNoteType ?? item.noteType,
                      parent: item.recordName,
                    })
                  }}
                >
                  <i
                    className='far fa-file-lines mr-2 w-4 text-center'
                    aria-hidden='true'
                  />
                  New Note
                </MenuItem>

                {item.noteType !== NoteType.TEAM_SPACE ||
                item.hasAdminRights ? (
                  <>
                    <MenuItem
                      key='rename'
                      // Disable 'Rename' when it's a teamspace and the user is not an owner and not an admin
                      onClick={(e) => {
                        e.syntheticEvent.stopPropagation()
                        setEditedTitle(item.title)
                      }}
                    >
                      <i
                        className='far fa-input-text mr-2 w-4 text-center'
                        aria-hidden='true'
                      />
                      Rename
                    </MenuItem>
                    <MenuItem
                      key='delete'
                      // Disable 'Delete' when it's a teamspace and the user is not an owner and not an admin
                      onClick={(e) => {
                        e.syntheticEvent.stopPropagation()
                        // traverse the tree and get all descendence ids of this item
                        const descendenceIds: string[] = traverse(item.children)
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this folder and it's content?"
                          )
                        ) {
                          deleteNote.mutate({
                            recordName: item.recordName,
                            noteType: item.noteType,
                            descendenceIds,
                          })
                        }
                      }}
                    >
                      <i
                        className='far fa-trash mr-2 w-4 text-center'
                        aria-hidden='true'
                      />
                      Delete
                    </MenuItem>
                  </>
                ) : undefined}

                {item.noteType === NoteType.TEAM_SPACE && (
                  <>
                    {item.hasAdminRights ? (
                      <>
                        <MenuItem
                          key='export'
                          // Disable 'Manage Members' when it's a teamspace and the user is not an owner and not an admin
                          onClick={(event) => {
                            event.syntheticEvent.stopPropagation()
                            handleExportSupabaseTeamspace(item.recordName)
                          }}
                        >
                          <i
                            className='far fa-file-export mr-2 w-4 text-center'
                            aria-hidden='true'
                          />
                          Export
                        </MenuItem>

                        <MenuItem
                          key='add-member'
                          // Disable 'Manage Members' when it's a teamspace and the user is not an owner and not an admin
                          onClick={(event) => {
                            event.syntheticEvent.stopPropagation()
                            onManageTeamspace(item.recordName, item.title)
                          }}
                        >
                          <i
                            className='far fa-user-plus mr-2 w-4 text-center'
                            aria-hidden='true'
                          />
                          Manage Members
                        </MenuItem>
                      </>
                    ) : undefined}

                    {!item.isOwner && (
                      <MenuItem
                        key='leave-team'
                        // Disable 'Manage Members' when it's a teamspace and the user is not an owner and not an admin
                        onClick={(event) => {
                          event.syntheticEvent.stopPropagation()
                          leaveTeamspace.mutate({
                            teamspaceID: item.recordName,
                          })
                        }}
                      >
                        <i
                          className='far fa-hand-wave mr-2 w-4 text-center'
                          aria-hidden='true'
                        />
                        Leave Teamspace
                      </MenuItem>
                    )}
                  </>
                )}
              </Menu>
            )}
          </div>

          {/* The sub-items of this item */}
          {/* https://stackoverflow.com/questions/50303162/compare-the-performance-between-hiding-react-components-with-css-and-state-manag */}
          {/* CSS solution will be faster for toggling between show and hide.
              However, if isOpen is initially false, then the non-CSS way avoids mounting Component initially, so initial 
              rendering will be faster.
              Furthermore, when isOpen is false, the parent component will be more responsive to other events since it has 
              one less component to render/re-render (when parent re-renders, Component gets re-rendered too even if its props didn't change, 
              unless it's declared as pure or implemented shouldComponentUpdate). */}
          {isOpen ? (
            <ul
              className={classNames(
                item.header || item.noteType == NoteType.TEAM_SPACE
                  ? 'mt-0 px-0'
                  : 'mt-0 pl-3'
              )}
            >
              {item.isLoading ? (
                <SkeletonLoader />
              ) : (
                item.children.map((subItem) => (
                  <SidebarItem
                    key={subItem.recordName}
                    item={subItem}
                    onManageTeamspace={onManageTeamspace}
                  />
                ))
              )}
            </ul>
          ) : undefined}
        </div>
      )}
    </li>
  )
}

import { useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { teamKeys } from '../utils/queryKeyFactory'
import { removeTeamspaceMember } from '../lib/supabase/invitation'
import { trackEvent } from '../lib/analytics'
import { type TeamspaceMember } from './useListTeamspaceMembers'
import { useSafeMutation } from './useSafeMutation'

export function useRemoveMember() {
  const user = useUserState()
  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({
      teamspaceID,
      email,
    }: {
      teamspaceID: string
      email: string
    }) => {
      if (!teamspaceID || !email) {
        return Promise.resolve(undefined)
      }

      // console.debug(
      //   '[useRemoveMember] removing invitation or member from teamspace',
      //   email,
      //   teamspaceID
      // )

      if (user?.supabaseUserId) {
        return removeTeamspaceMember(teamspaceID, email)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID, email }) => {
      if (data && teamspaceID && email) {
        trackEvent('WEB - Team Member Removed', {
          teamspaceID,
          email,
        })

        const teamspaceMembers = queryClient.getQueryData<[TeamspaceMember]>(
          teamKeys.members(teamspaceID)
        )
        queryClient.setQueryData(
          teamKeys.members(teamspaceID),
          teamspaceMembers?.filter((m) => m.email !== email)
        )
      }
    },
    onError: (error) => {
      trackEvent('WEB - Team Member Removed Failed', {
        error,
      })
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}

import { createContext } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { AccessTokenResponse } from '../hooks/GoogleCalendar'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const accessTokenContext = createContext<QueryClient | undefined>(
  undefined
)

export const accessTokenMaxAge = 1000 * 60 * 59 // 59 minutes
const accessTokenQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: accessTokenMaxAge,
      staleTime: accessTokenMaxAge,
      // disable too much refetching
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 3,
    },
  },
})

// https://tanstack.com/query/v4/docs/react/plugins/createSyncStoragePersister
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'accessToken',
})

export default function AccessTokenProvider({
  children,
}: {
  children: React.ReactNode
}) {
  function validateToken() {
    const accessTokens =
      accessTokenQueryClient.getQueriesData<AccessTokenResponse>({
        type: 'all',
      })
    for (const [queryKey, accessToken] of accessTokens) {
      // in case we got an old token, initiate a refetch
      if (accessToken && accessToken.expires < Date.now()) {
        void accessTokenQueryClient.invalidateQueries([queryKey])
      }
    }
  }

  return (
    <PersistQueryClientProvider
      context={accessTokenContext}
      client={accessTokenQueryClient}
      persistOptions={{
        persister: localStoragePersister,
        maxAge: accessTokenMaxAge,
      }}
      onSuccess={validateToken}
    >
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} context={accessTokenContext} position="bottom-right" /> */}
    </PersistQueryClientProvider>
  )
}

import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'
import { useUserDispatch } from '../providers/UserProvider'

export function useSafeQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
  const setUser = useUserDispatch()
  const result = useQuery({ ...options })
  const { isError, error } = result

  // handle CloudKit auth errors
  if (isError && typeof error === 'object' && 'ckErrorCode' in error && error.ckErrorCode === 'AUTHENTICATION_REQUIRED') {
    setUser(null)
    window.location.href = '/' // Reload the page right away
  }
  // handle Supabase auth errors
  if (isError && typeof error === 'object' && 'code' in error && error.code === '42501') {
    setUser((prevUser) => {
      if (prevUser?.cloudKitUserId) {
        return { cloudKitUserId: prevUser.cloudKitUserId }
      }
      return null
    })
  }

  return result
}

/* eslint-disable no-console */
import { useState, useEffect } from 'react'
import { container } from '../lib/CloudKitClient'
import { AuthType } from '../utils/User'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { registerForNotifications } from '../lib/cloudkit/notification'
import { useQueryClient } from '@tanstack/react-query'
import { cachedNotesContext } from '../providers/CachedNotesProvider'

export function useCloudKitSubscription() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  const [isRegistered, setIsRegistered] = useState<boolean | undefined>(
    undefined
  )

  useEffect(() => {
    if (
      user.authType !== AuthType.CLOUDKIT &&
      user.authType !== AuthType.CLOUDKIT_SUPABASE
    ) {
      setIsRegistered(undefined)
      return
    }

    const checkSubscriptionStatus = () => {
      // if (!container) {
      //   return
      // }
      // console.debug(
      //   'Checking CloudKit subscription status',
      //   new Date().toLocaleTimeString(),
      //   container.isRegisteredForNotifications
      // )
      setIsRegistered(container.isRegisteredForNotifications)
    }

    const intervalId = setInterval(checkSubscriptionStatus, 60_000) // Check every minute
    window.addEventListener('focus', checkSubscriptionStatus)
    checkSubscriptionStatus()

    return () => {
      clearInterval(intervalId)
      window.removeEventListener('focus', checkSubscriptionStatus)
    }
  }, [user.authType])

  useEffect(() => {
    if (
      user.authType !== AuthType.CLOUDKIT &&
      user.authType !== AuthType.CLOUDKIT_SUPABASE
    ) {
      return
    }

    if (isRegistered === false) {
      console.debug('cloudkit subscription is off -> subscribing')
      void registerForNotifications(
        user,
        queryClient,
        cachedNotesQueryClient,
        setIsRegistered
      )
    }
    if (isRegistered) {
      console.debug('cloudkit subscription is on')
    }
  }, [isRegistered, user, queryClient, cachedNotesQueryClient])

  return isRegistered
}
/* eslint-enable no-console */

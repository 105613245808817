import { ReactNode } from "react";
import { BlockSchema } from "@packages/blocknote-core";

import { DragHandleMenuProps } from "../DragHandleMenu";
import { DragHandleMenuItem } from "../DragHandleMenuItem";
import { AiOutlinePlus } from "react-icons/ai";

export const InsertBlockButton = <BSchema extends BlockSchema>(
  props: DragHandleMenuProps<BSchema> & { children: ReactNode }
) => {
  return (
    <DragHandleMenuItem
      icon={<AiOutlinePlus />}
      closeMenu={props.closeMenu}
      onClick={() => props.addBlock(true)}>
      {props.children}
    </DragHandleMenuItem>
  );
};

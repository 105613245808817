import { useQueryClient, QueryClient } from '@tanstack/react-query'
import {
  type Note,
  type NoteType,
  getSupabaseFileExtension,
  isCalendarNote,
  isTeamspaceNote,
  isPrivateNote,
} from '../utils/syncUtils'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType, AuthenticatedUser } from '../utils/User'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  noteQueryKey,
} from '../utils/queryKeyFactory'
import {
  CacheData,
  cachedNotesContext,
  getCachedData,
} from '../providers/CachedNotesProvider'
import { useNotesExtension } from './useNotesExtension'
import * as supabase from '../lib/supabase/fetch'
import * as cloudkit from '../lib/cloudkit/fetch'
import { useSafeQuery } from './useSafeQuery'

export type useNoteConfig = {
  noteType: NoteType
  filename: string
  recordName?: string
  parent?: string
}

function findNote(
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser,
  config?: useNoteConfig
) {
  if (!config) {
    return
  }
  const cacheKey = cacheKeyFromNoteType(config.noteType, user)
  const notes = getCachedData(cachedNotesQueryClient, cacheKey)

  if (!config.recordName) {
    return
  }

  // For non-calendar notes, use direct map lookup by recordName
  if (!isCalendarNote(config.noteType)) {
    return notes.get(config.recordName)
  }

  // For calendar notes, use recordName first if available
  const note = notes.get(config.recordName)
  if (note) {
    return note
  }

  // Fallback to filename lookup only if needed
  if (isTeamspaceNote(config.noteType)) {
    return [...notes.values()].find(
      (note) =>
        note.filename === config.filename && note.parent === config.parent
    )
  }
  return [...notes.values()].find((note) => note.filename === config.filename)
}

// We don't get a notification when access to a teamspace was removed, so actively check this here, when something suspicious happens.
// Two cases are possible: We query for an ID but get an "Note not found" error we get an empty calendar note back (happens more commonly, but the teamspace check is as 'cheap' as possible)
async function verifyTeamspaceAccess(
  cachedNotesQueryClient: QueryClient,
  teamUserId: string
) {
  const teamspaceNotes = await supabase.listMyTeamspaceNotes()

  // Update the cache
  cachedNotesQueryClient.setQueryData<CacheData>(
    cacheKeys.teamProjectNotes(teamUserId),
    (oldData) => {
      const newMap = new Map<string, Note>()
      const data = oldData instanceof Map ? oldData : oldData?.map

      if (!data || !oldData) {
        return { map: newMap, version: oldData?.version ?? 0 } // Ensure returning a CacheData object
      }

      for (const recordName of teamspaceNotes) {
        const note = data.get(recordName) as Note | undefined
        if (note) {
          newMap.set(recordName, note)
        }
      }

      const currentVersion = oldData instanceof Map ? 0 : oldData.version || 0
      return { map: newMap, version: currentVersion + 1 }
    }
  )
}

async function fetchNote(
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser,
  config?: useNoteConfig
) {
  if (!config) {
    throw new Error('No config defined')
  }
  const cachedNote = findNote(cachedNotesQueryClient, user, config)
  const cachedNoteModificationDate = cachedNote?.fileModifiedAt

  let fetchedNote
  switch (user.authType) {
    case AuthType.CLOUDKIT: {
      fetchedNote = await fetchNoteFromCloudKit(queryClient, config)
      break
    }
    case AuthType.SUPABASE: {
      fetchedNote = await fetchNoteFromSupabase(
        cachedNotesQueryClient,
        config,
        user.userId
      )
      break
    }
    case AuthType.CLOUDKIT_SUPABASE: {
      fetchedNote = isPrivateNote(config.noteType)
        ? await fetchNoteFromCloudKit(queryClient, config)
        : await fetchNoteFromSupabase(
            cachedNotesQueryClient,
            config,
            user.teamUserId
          )
      break
    }
  }

  // Compare the modification dates of the cached note and the fetched note. It can happen that we are editing a note locally and it's not yet
  // uploaded to the cloud. If we fetch now (like when switching to another note and back), we get the old note (from the outdated cloud).
  // So instead, also fetch again from the cache and check which one is newer.
  if (cachedNoteModificationDate) {
    const fetchedNoteModificationDate = fetchedNote.fileModifiedAt

    if (
      fetchedNoteModificationDate &&
      fetchedNoteModificationDate > cachedNoteModificationDate
    ) {
      return fetchedNote
    }
  }

  // Only create a new object if attachments actually changed
  if (cachedNote && cachedNote.attachments === fetchedNote.attachments) {
    return cachedNote
  }

  // Create shallow copy with updated attachments only if needed. If we just update the attachments field, ReactJS will not trigger a re-render.
  return {
    ...(cachedNote ?? fetchedNote),
    attachments: fetchedNote.attachments,
  }
}

async function fetchNoteFromCloudKit(
  queryClient: QueryClient,
  config: useNoteConfig
) {
  if (isCalendarNote(config.noteType)) {
    // eslint-disable-next-line no-console
    console.debug('[useNote] query calendar note', config.filename)
    const queryKey = getQueryKey(config)
    if (!queryKey) {
      throw new Error('No query key defined')
    }
    const calendarNote = queryClient.getQueryData<Note>(queryKey)
    if (calendarNote?.recordName) {
      return cloudkit.fetchNoteByFilename(config.filename, true)
    }
    return cloudkit.fetchNoteByFilename(config.filename, false)
  }
  if (!config.recordName) {
    throw new Error('No record name defined')
  }
  // eslint-disable-next-line no-console
  console.debug('[useNote] query project note', config.recordName)
  return cloudkit.fetchNoteByRecordName(config.recordName)
}

async function fetchNoteFromSupabase(
  cachedNotesQueryClient: QueryClient,
  config: useNoteConfig,
  userId: string
) {
  // eslint-disable-next-line no-console
  console.debug('[useNote] query supabase note', config)
  let note
  if (isCalendarNote(config.noteType)) {
    // This can be a private or a teamspace calendar note (depends on the type and parent, we need the parent so we know which teamspace)
    note = await supabase.fetchNoteByFilename(
      userId,
      config.filename,
      config.noteType,
      config.parent
    )
  } else if (config.recordName) {
    note = await supabase.fetchNoteById(userId, config.recordName)
  }
  // eslint-disable-next-line no-console
  console.log('fetched note', note)

  if (!note) {
    throw new Error('Note not found')
  }

  // An empty teamspace note can mean we lost access, but doesn't have to, check now:
  if (note.isEmpty && isTeamspaceNote(config.noteType)) {
    void verifyTeamspaceAccess(cachedNotesQueryClient, userId)
  }

  return note
}

function getQueryKey(config?: useNoteConfig) {
  return config && config.recordName !== 'search'
    ? noteQueryKey(config)
    : undefined
}

export default function useNote(config?: useNoteConfig) {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  // if filename doesn't contain an extension, we add the extension according if it is a private or team note
  const { data: privateExtension } = useNotesExtension()
  const extension = config
    ? isTeamspaceNote(config.noteType)
      ? getSupabaseFileExtension()
      : privateExtension
    : undefined

  if (
    extension &&
    config?.filename &&
    (config.filename.split('.').length === 1 || !config.filename.split('.')[1])
  ) {
    config.filename = `${config.filename}.${extension}`
  }

  const queryKey = getQueryKey(config)

  const queryResult = useSafeQuery({
    enabled: Boolean(extension) && Boolean(config),
    retry: 2,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: 'always',
    // TODO use [user.authType, config] as queryKey
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    staleTime: 0, // set this to a minimal value, so we can utilize initialData and prevent an immidiate refetch. UPDATE: Set it to 0 otherwise we don't load the updated note. It will still fetch the note from the cache first, but update it immidiately.
    initialData: () => findNote(cachedNotesQueryClient, user, config),
    queryFn: () => fetchNote(queryClient, cachedNotesQueryClient, user, config),
  })

  const { isError, error } = queryResult

  // If the note wasn't found, and it's a teamspace note, it's very likely that we lost access to the teamspace, check this and update the cache.
  if (
    config &&
    isTeamspaceNote(config.noteType) &&
    (user.authType === AuthType.SUPABASE ||
      user.authType === AuthType.CLOUDKIT_SUPABASE) &&
    isError &&
    (error as Error).message === 'Note not found'
  ) {
    void verifyTeamspaceAccess(cachedNotesQueryClient, user.teamUserId)
  }

  return queryResult
}

import { createContext, useContext, useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import {
  useSelectedDate,
  useSelectedDateDispatch,
} from './SelectedDateProvider'

export const SelectedRecordNameContext = createContext<string | undefined>(
  undefined
)
export const SelectedRecordNameDispatchContext =
  createContext<React.Dispatch<string>>(undefined)

export function useSelectedRecordName() {
  const context = useContext(SelectedRecordNameContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedRecordName must be used within a SelectedRecordNameProvider'
    )
  }
  return context
}

export function useSelectedRecordNameDispatch() {
  const context = useContext(SelectedRecordNameDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedRecordNameDispatch must be used within a SelectedRecordNameProvider'
    )
  }
  return context
}

export default function SelectedRecordNameProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [selectedRecordName, setSelectedRecordName] = useLocalStorage(
    'selectedRecordName',
    'daily'
  )
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()

  useEffect(() => {
    // On mount set the selectedDate if it is undefined and selectedRecordName is a date
    if (selectedDate === undefined && selectedRecordName === 'daily') {
      selectedDateDispatch({ type: 'today' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set the selectedRecordName when changing selectedDate, so that it opens the right calendar note
  // This gets triggered when you switch to any date or week in the calendar or in the sidebar
  useEffect(() => {
    // If it's a teamspace calendar note, we need to append the parent id (the id of the teamspace record)
    // in the form of 'daily_<parent-id>', 'weekly_<parent-id>', 'monthly_<parent-id>', etc
    function getNewRecordName(recordName: string, timeframe: string) {
      const parts = recordName.split('_')
      const parent = parts.length > 1 ? parts[1] : undefined
      return parent ? `${timeframe}_${parent}` : timeframe
    }

    const recordName = selectedRecordName ?? 'daily'
    const active = selectedDate.active
    const prefix =
      active === 'week'
        ? 'weekly'
        : active === 'month'
          ? 'monthly'
          : active === 'quarter'
            ? 'quarterly'
            : active === 'year'
              ? 'yearly'
              : 'daily'

    if (!recordName.startsWith(prefix)) {
      setSelectedRecordName(getNewRecordName(recordName, prefix))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  // keyboard shortcuts
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.metaKey && event.shiftKey) {
        if (event.key === 'f') {
          setSelectedRecordName('search')
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [setSelectedRecordName])

  return (
    <SelectedRecordNameContext.Provider value={selectedRecordName}>
      <SelectedRecordNameDispatchContext.Provider value={setSelectedRecordName}>
        {children}
      </SelectedRecordNameDispatchContext.Provider>
    </SelectedRecordNameContext.Provider>
  )
}

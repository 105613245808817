import { Resizable } from 're-resizable'
import classNames from 'classnames'
import {
  type DragEvent,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { type Dayjs } from 'dayjs'
import { handleResizeStop } from '../utils/resize'
import {
  addAlphaToColor,
  darkenColor,
  desaturateColor,
} from '../../../../utils/color'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import { type CalendarEvent } from '../../CalendarEvent'
import { PlaceholderEvent } from '../types'
import { trackError } from '../../../../lib/analytics'

// Extend the Resizable component to allow for draggable events
declare module 're-resizable' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ResizableProps {
    draggable?: boolean
    onDragEnter?: (dragEvent: DragEvent) => void
    onDragStart?: (dragEvent: DragEvent) => void
  }
}

export type EventContentProps = {
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
  event: CalendarEvent | PlaceholderEvent
  eventHeight: number
  segmentHeight: number
  offsetPxTop?: number
  className?: string
  style?: React.CSSProperties
  selectedDay?: Dayjs
}

function isEvent(
  event: CalendarEvent | PlaceholderEvent
): event is CalendarEvent {
  return 'contentType' in event
}

function isCalendarEvent(
  event: CalendarEvent | PlaceholderEvent
): event is CalendarEvent {
  return isEvent(event) && event.contentType === 'event'
}

function isContentEvent(
  event: CalendarEvent | PlaceholderEvent
): event is CalendarEvent {
  return isEvent(event) && event.contentType !== 'event'
}

function getIconName(event: CalendarEvent | PlaceholderEvent): string {
  if (isEvent(event)) {
    switch (event.contentType) {
      case 'taskListItem': {
        return 'fa-regular fa-circle'
      }
      case 'bulletListItem': {
        return 'fa-solid fa-circle-small'
      }
      default: {
        return 'fa-regular fa-square'
      }
    }
  }
  return 'fa-regular fa-square'
}

function getColor(
  event: CalendarEvent | PlaceholderEvent,
  isDarkMode: boolean
): string | undefined {
  if (isEvent(event) && event.color) {
    return isDarkMode ? darkenColor(event.color, 50) : event.color
  }
  return undefined
}

export function EventContent({
  setCalendarEventDrag,
  event,
  eventHeight,
  segmentHeight,
  offsetPxTop = 0,
  className = '',
  style = {},
  selectedDay,
}: EventContentProps) {
  const isDarkMode = useDarkMode()
  const [deltaHeight, setDeltaHeight] = useState<number | undefined>(undefined)
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)

  const isShortEvent = eventHeight < segmentHeight * 2
  const eventDate = event.startDate
  const bounds = document.querySelector('#calendar-event-bounds')
  if (!bounds) {
    trackError('EventContent', 'Bounds element not found')
    return
  }

  const iconName = getIconName(event)
  const position =
    isEvent(event) && event.contentType === 'bulletListItem'
      ? `text-[0.5rem] left-[0.6em] ${isShortEvent ? 'top-[0.45em]' : 'top-[0.45em]'}`
      : `left-[0.2em] ${isShortEvent ? 'top-[0.2em]' : 'top-[0.1em]'}`

  const icon = isContentEvent(event) && (
    <i
      className={`absolute ${position} ${iconName}${
        event.checked ? '-check' : ''
      }`}
    />
  )

  const colorClassName =
    isCalendarEvent(event) && !event.color ? 'blue' : 'orange'
  const color = getColor(event, isDarkMode)

  return (
    <Resizable
      className={classNames(
        'relative h-full flex-grow user-none',
        { 'opacity-50': isEvent(event) && event.id === 'temporary' },
        { 'opacity-[0.46]': isEvent(event) && event.checked }
      )}
      style={{
        ...style,
        transform: deltaHeight
          ? `translateY(${(-deltaHeight + offsetPxTop).toString()}px)`
          : style.transform ?? '',
      }}
      onResize={(_mouseEvent, direction, _reference, delta) => {
        if (isContentEvent(event)) {
          direction === 'top' && setDeltaHeight(delta.height)
        }
      }}
      onResizeStop={(mouseEvent, direction, _reference, delta) => {
        if (isContentEvent(event)) {
          mouseEvent.preventDefault()
          handleResizeStop(event, segmentHeight, delta, direction)
          setDeltaHeight(0)
        }
      }}
      onResizeStart={(mouseEvent, direction, reference) => {
        if (isContentEvent(event)) {
          mouseEvent.preventDefault()
          setMaxHeight(
            eventHeight -
              2 +
              (direction === 'top'
                ? Math.abs(
                    bounds.getBoundingClientRect().top -
                      reference.getBoundingClientRect().top
                  )
                : Math.abs(
                    bounds.getBoundingClientRect().bottom -
                      reference.getBoundingClientRect().bottom
                  ))
          )
        }
      }}
      size={{ height: eventHeight - 2, width: 'auto' }}
      enable={
        isContentEvent(event)
          ? {
              top: true,
              right: false,
              bottom: true,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }
          : false
      }
      grid={[1, segmentHeight]}
      maxHeight={maxHeight ?? 0 - 2}
      minHeight={segmentHeight}
      draggable={isContentEvent(event)}
      onDragEnter={(_dragEvent: DragEvent) => {
        const calendarEventList = document.querySelector(
          '#calendar-events-list'
        )
        if (calendarEventList instanceof HTMLElement) {
          calendarEventList.style.setProperty('pointer-events', 'none')
        }
      }}
      onDragStart={(dragEvent: DragEvent) => {
        if (isContentEvent(event)) {
          dragEvent.dataTransfer.setData(
            'text/plain',
            JSON.stringify({ ...event, type: 'timeblock' })
          )
          const target = dragEvent.target as HTMLElement

          const clientRect = target.getBoundingClientRect()
          const scaledElement = target.cloneNode(true) as HTMLElement

          scaledElement.id = 'dragged-event-image'
          scaledElement.style.width = String(clientRect.width * 0.6)
          scaledElement.style.height = String(clientRect.height * 0.6)

          scaledElement.style.transform = 'translateX(-5000px)'
          document.body.append(scaledElement)

          dragEvent.dataTransfer.setDragImage(scaledElement, 0, 0)
          setCalendarEventDrag(event)
        }
      }}
    >
      <div
        id={`event-content-${isEvent(event) ? event.id : 'placeholder'}`}
        className={`truncate event-content-background ${colorClassName} ${className} opacity-90`}
        style={{
          backgroundColor: addAlphaToColor(color, 0.1962),
          borderColor: addAlphaToColor(color, 0.924),
        }}
      >
        <p
          className={classNames(
            'time text-opacity-95 text-[0.65rem] ml-px -mb-0.5',
            { hidden: isShortEvent }
          )}
          style={{
            color:
              color &&
              darkenColor(desaturateColor(color, 30), isDarkMode ? -30 : 60),
          }}
        >
          {isEvent(event) &&
            (event.contentType !== 'event' ||
              eventDate.isSame(selectedDay, 'day')) && (
              <>
                <time dateTime={eventDate.format('YYYY-MM-DDTHH:mm')}>
                  {eventDate.format('LT')}
                </time>
                {event.contentType === 'event' && (
                  <>
                    <i className='fa-light fa-xs fa-alarm-clock mx-1' />
                    {event.calendarName}
                  </>
                )}
              </>
            )}
        </p>
        <p className='label relative ml-px truncate text-xs font-semibold text-opacity-95'>
          {icon}
          <span
            className={`ml-${isCalendarEvent(event) ? '0' : '5'}`}
            style={{
              color:
                color &&
                darkenColor(desaturateColor(color, 30), isDarkMode ? -30 : 60),
            }}
          >
            {isEvent(event) ? event.content : ''}
          </span>
        </p>
      </div>
    </Resizable>
  )
}

import CloudKit from 'tsl-apple-cloudkit'
import { database } from '../CloudKitClient'
import { SettingsZone } from '../../hooks/useEnsureZones'

// See https://github.com/typescriptlibs/tsl-apple-cloudkit/issues/28#issuecomment-2199131604
const cloudKit = window.CloudKit

export async function setUserDefault(
  key: string,
  value: string
): Promise<void> {
  if (!database) {
    throw new Error('Database is undefined')
  }

  // Fetch the existing UserVariable record
  const fetchResponse = await database.performQuery(
    {
      recordType: 'UserVariable',
      filterBy: [
        {
          systemFieldName: 'recordName',
          comparator: cloudKit.QueryFilterComparator.EQUALS,
          fieldValue: { value: { recordName: key } },
        },
      ],
    },
    { zoneID: SettingsZone }
  )

  if (fetchResponse.hasErrors) {
    throw fetchResponse.errors[0]
  }

  // Check if the fetched record's value is different from the new value
  const existingRecord = fetchResponse.records[0]
  if (existingRecord && existingRecord.fields.stringValue.value === value) {
    console.log('[CloudKit] No change for user default', key)
    return
  } else {
    console.log(
      '[CloudKit] Could not find user default or values are different, so save the new value',
      key
    )
  }

  // Save the UserVariable record if the value has changed
  const recordToCreate: CloudKit.RecordToCreate = {
    recordType: 'UserVariable',
    recordName: key,
    fields: {
      stringValue: { value: value },
    },
  }

  const recordToSave: CloudKit.RecordToSave = {
    ...recordToCreate,
    recordChangeTag: existingRecord?.recordChangeTag || '',
  }

  const saveResponse = await database.saveRecords(
    existingRecord ? [recordToSave] : [recordToCreate],
    { zoneID: SettingsZone }
  )

  if (saveResponse.hasErrors) {
    console.log(saveResponse.errors[0])
    throw saveResponse.errors[0]
  }

  console.log('[CloudKit] Saved user default', key, value)
}

export async function fetchUserDefault(key: string): Promise<string> {
  if (!database) {
    throw new Error('Database is undefined')
  }

  // Fetch a UserVariable record from the default zone
  const response = await database.performQuery({
    recordType: 'UserVariable',
    filterBy: [
      {
        systemFieldName: 'recordName',
        comparator: cloudKit.QueryFilterComparator.EQUALS,
        fieldValue: {
          value: { recordName: key },
        },
      },
    ],
  })

  if (response.hasErrors) {
    throw response.errors[0]
  }

  const records = response.records
  const numberOfRecords = records.length

  if (numberOfRecords === 0) {
    return ''
  }

  const record = records[0]
  const value = record.fields?.stringValue.value as string

  console.log('[CloudKit] fetched user default', record, value)

  return value
}

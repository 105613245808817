import { Helmet } from 'react-helmet-async'
import { useDarkMode } from '../providers/DarkModeProvider'
import { useUserState } from '../providers/UserProvider'

export function HtmlHeader() {
  const user = useUserState()
  return (
    <Helmet>
      <title>{user ? 'NotePlan for Web' : 'NotePlan Login'}</title>
      <meta name='robots' content='noindex, nofollow' />
      <link rel='canonical' href={window.location.href} />
      <link
        rel='stylesheet'
        href={`/${useDarkMode() ? 'atom-one-dark' : 'xcode'}.css`}
      />
    </Helmet>
  )
}

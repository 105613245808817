import {
  Menu,
  MenuItem,
  MenuButton,
  MenuHeader,
  MenuDivider,
} from '@szhsin/react-menu'
import { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import {
  useSignIn,
  useSignOut,
  useAccessToken,
} from '../../hooks/GoogleCalendar'
import { SignInAppleDialog } from './SignInAppleDialog'
import { useNotification } from '../../providers/NotificationProvider'
import {
  useAppleCalDAVSignout,
  useAppleCalendars,
} from '../../hooks/AppleCalDAV'
import { useGoogleCalendars } from '../../hooks/GoogleCalendar'
import { useLocalStorage } from 'usehooks-ts'

export function CalendarMenu() {
  const errorIcon = 'fa-triangle-exclamation text-red-500'
  const { data: accessToken } = useAccessToken()
  const { data: googleCalendars = [] } = useGoogleCalendars()
  const [hiddenCalendars, setHiddenCalendars] = useLocalStorage<string[]>(
    'hiddenCalendars',
    []
  )
  const signin = useSignIn()
  const googleSignin = useGoogleLogin({
    // important config for google identity service to work with our backend
    flow: 'auth-code',
    redirect_uri: 'postmessage', // https://stackoverflow.com/a/18990247
    ux_mode: 'popup',
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
    onSuccess: ({ code }) => {
      signin.mutate(
        { code },
        {
          onSuccess: () => {
            showNotification({
              title: 'Google Calendar',
              message: 'Sign in successfully',
            })
          },
        }
      )
    }, // sign in was successful, let's store the refresh token and retrieve the access token
    onError: ({ error_description }) => {
      showNotification({
        title: 'Google Calendar',
        message: error_description ?? '',
        icon: errorIcon,
      })
      handleGoogleSignout()
    },
  })
  const googleSignout = useSignOut()

  function handleGoogleSignin() {
    googleSignin()
  }

  function handleGoogleSignout() {
    googleSignout.mutate(undefined, {
      onSuccess: () => {
        showNotification({
          title: 'Google Calendar',
          message: 'Sign out successfully',
        })
      },
    })
  }

  const [showSignInAppleDialog, setShowSignInAppleDialog] =
    useState<boolean>(false)
  const { data: appleCalendars = [] } = useAppleCalendars()
  const hasAppleCalendars =
    Array.isArray(appleCalendars) && appleCalendars.length > 0
  const signout = useAppleCalDAVSignout()
  const { showNotification } = useNotification()

  function handleAppleSignout() {
    signout.mutate(undefined, {
      onSuccess: () => {
        showNotification({
          title: 'Apple Calendar',
          message: 'Successfully signed out from Apple Calendars',
        })
      },
      onError: (registerError) => {
        showNotification({
          title: 'Apple Calendar',
          message: registerError.response?.data.error ?? 'An error occurred',
          icon: errorIcon,
        })
      },
    })
  }

  function handleCalendarToggle(calendarId: string) {
    setHiddenCalendars((previous) => {
      if (previous.includes(calendarId)) {
        return previous.filter((id) => id !== calendarId)
      }
      return [...previous, calendarId]
    })
  }

  const isGoogleSignedIn = !!accessToken?.token
  const isAppleSignedIn = hasAppleCalendars
  const menuIcon =
    isGoogleSignedIn || isAppleSignedIn
      ? 'fa-regular fa-calendar-check'
      : 'fa-regular fa-calendar-plus'

  return (
    <>
      <SignInAppleDialog
        open={showSignInAppleDialog}
        setOpen={setShowSignInAppleDialog}
      />
      <Menu
        menuButton={
          <MenuButton className='p-1 hover:bg-gray-500/25'>
            <i className={`${menuIcon} h-6 w-6 text-sm`} aria-hidden='true' />
          </MenuButton>
        }
      >
        <MenuHeader>Manage Calendars</MenuHeader>
        {signin.isError && <MenuItem disabled>{String(signin.error)}</MenuItem>}
        {isGoogleSignedIn ? (
          <>
            <MenuItem disabled>Google</MenuItem>
            {googleCalendars.map((calendar) => (
              <MenuItem
                key={calendar.id}
                type='checkbox'
                checked={!hiddenCalendars.includes(calendar.id)}
                onClick={() => {
                  handleCalendarToggle(calendar.id)
                }}
              >
                <div className='flex items-center gap-2'>
                  <div
                    className='relative flex h-4 w-4 items-center justify-center'
                    style={{ backgroundColor: calendar.backgroundColor }}
                  >
                    {!hiddenCalendars.includes(calendar.id) && (
                      <i className='fa-solid fa-check text-sm text-white' />
                    )}
                  </div>
                  <span>{calendar.summary}</span>
                </div>
              </MenuItem>
            ))}
            <MenuItem onClick={handleGoogleSignout}>
              <i
                className='fa-regular fa-calendar-minus h-6 w-6 text-sm'
                aria-hidden='true'
              />
              Sign out
            </MenuItem>
            <MenuDivider className='' />
          </>
        ) : (
          <MenuItem onClick={handleGoogleSignin}>
            <i
              className='fa-regular fa-calendar-plus h-6 w-6 text-sm'
              aria-hidden='true'
            />
            Sign in with Google
          </MenuItem>
        )}
        {isAppleSignedIn ? (
          <MenuItem onClick={handleAppleSignout}>
            <i
              className='fa-regular fa-calendar-minus h-6 w-6 text-sm'
              aria-hidden='true'
            />
            Sign out of Apple
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setShowSignInAppleDialog(true)
            }}
          >
            <i
              className='fa-regular fa-calendar-plus h-6 w-6 text-sm'
              aria-hidden='true'
            />
            Sign in with Apple
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

// type SettingsMenuProps = { timelineDays: number; onSetTimelineDays: (_days: number) => void };
// const SettingsMenu = ({ timelineDays, onSetTimelineDays }: SettingsMenuProps) => {
//   return (
//     <Menu
//       className=""
//       menuButton={
//         <MenuButton className="p-1 hover:bg-gray-500/25">
//           <i className="fa-regular fa-gear h-5 w-5 text-sm" aria-hidden="true" />
//         </MenuButton>
//       }
//     >
//       <MenuRadioGroup value={timelineDays} onRadioChange={(e) => onSetTimelineDays(e.value)}>
//         <MenuHeader>Timeline</MenuHeader>
//         <MenuItem type="radio" value={1}>
//           1 day
//         </MenuItem>
//         <MenuItem type="radio" value={2}>
//           2 days
//         </MenuItem>
//         <MenuItem type="radio" value={3}>
//           3 days
//         </MenuItem>
//         <MenuItem type="radio" value={4}>
//           4 days
//         </MenuItem>
//         <MenuItem type="radio" value={5}>
//           5 days
//         </MenuItem>
//         <MenuItem type="radio" value={6}>
//           6 days
//         </MenuItem>
//         <MenuItem type="radio" value={7}>
//           7 days
//         </MenuItem>
//       </MenuRadioGroup>
//     </Menu>
//   );

import dayjs, { type Dayjs, extend } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

extend(isSameOrBefore)

export function eachDayOfMonth(month: number, year: number): Dayjs[] {
  const currentDate = dayjs().year(year).month(month)
  let startDate = currentDate.startOf('month').startOf('week')
  const endDate = currentDate.endOf('month').endOf('week')

  const days = []

  while (startDate.isSameOrBefore(endDate)) {
    days.push(startDate.clone())
    startDate = startDate.add(1, 'day')
  }

  return days
}

export function eachWeekOfMonth(
  month: number,
  year: number
): { week: number; year: number }[] {
  const currentDate = dayjs().year(year).month(month)
  let startDate = currentDate.startOf('month').startOf('week')
  const endDate = currentDate.endOf('month').endOf('week')

  const weeks = []

  while (startDate.isSameOrBefore(endDate)) {
    weeks.push({ week: startDate.week(), year: startDate.year() })
    startDate = startDate.add(7, 'day')
  }

  return weeks
}

import { createContext, useContext, useEffect, useState } from 'react'
import { useScript } from 'usehooks-ts'
import { useAccessToken } from '../hooks/GoogleCalendar'

export const defaultContext = createContext<boolean>(false)

export const useGapiIsReady = () => {
  const isReady = useContext(defaultContext)

  return isReady
}

type GapiProviderProps = {
  gapiConfig: {
    apiKey: string
    discoveryDocs: string[]
  }
  children?: React.ReactNode
}

export function GapiProvider({ gapiConfig, children }: GapiProviderProps) {
  const Context = defaultContext
  const status = useScript('https://apis.google.com/js/api.js', {
    removeOnUnmount: false,
  })
  const [isReady, setIsReady] = useState<boolean>(false)
  const { data: accessToken } = useAccessToken()

  useEffect(() => {
    if (status === 'ready' && accessToken) {
      gapi.load('client', () => {
        void gapi.client.init(gapiConfig).then(() => {
          // eslint-disable-next-line no-console
          console.log('gapi client loaded')
          gapi.client.setToken({ access_token: accessToken?.token })
          // console.log(gapi.client.getToken());
          setIsReady(true)
        })
      })
    }
  }, [status, gapiConfig, accessToken])

  return <Context.Provider value={isReady}>{children}</Context.Provider>
}

import { Note, isCalendarNote } from './syncUtils'
import { LinkMarkSuggestionItem } from '@packages/blocknote-core'

export const noteList = (notes: Note[]): LinkMarkSuggestionItem[] => {
  return notes
    .filter((note) => !isCalendarNote(note.noteType) && note.title)
    .sort((a, b) => {
      if (a.fileModifiedAt < b.fileModifiedAt) {
        return 1
      }
      if (a.fileModifiedAt > b.fileModifiedAt) {
        return -1
      }
      return 0
    })
    .flatMap((note) => ({
      icon: 'file-text',
      display: note.title,
      paste: note.title,
    }))
}

import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import {
  useAppleCalDAVSignin,
  type SigninRequestParams,
} from '../../hooks/AppleCalDAV'
import { useNotification } from '../../providers/NotificationProvider'

export function SignInAppleDialog({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (_open: boolean) => void
}) {
  const [error, setError] = useState<string | undefined>(undefined)
  const signin = useAppleCalDAVSignin()
  const { showNotification } = useNotification()

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setError(undefined)
    const formData = new FormData(event.target as HTMLFormElement)
    const registerParams: SigninRequestParams = {
      username: formData.get('email') as string,
      password: formData.get('password') as string,
    }
    signin.mutate(registerParams, {
      onSuccess: () => {
        setOpen(false)
        showNotification({
          title: 'Sign in with Apple',
          message: 'Successfully signed in with Apple',
        })
      },
      onError: (registerError) => {
        setError(registerError.response?.data.error)
      },
    })
  }

  return (
    <Transition.Root show={open}>
      <Dialog
        className='relative z-10'
        onClose={() => {
          setOpen(false)
        }}
      >
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all focus:ring-0 sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-[#1f1f1f]'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-5 sm:block'>
                  <button
                    type='button'
                    className='rounded-md text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400'
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    <span className='sr-only'>Close</span>
                    <i className='fa fa-xmark' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='w-full text-center sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-semibold leading-6 text-gray-900 dark:text-gray-200'
                    >
                      Sign in with Apple ID
                    </Dialog.Title>
                    <h4 className='mt-4 text-base font-normal leading-6 text-gray-900 dark:text-gray-200'>
                      How to generate an app-specific password
                    </h4>
                    <ol className='ml-4 mt-2 list-inside list-outside list-decimal space-y-2 text-base font-normal leading-6 text-gray-900 dark:text-gray-200'>
                      <li>
                        Sign in to{' '}
                        <a
                          href='https://appleid.apple.com/account/home'
                          target='_blank'
                          rel='noreferrer'
                          className='text-blue-500 underline hover:text-blue-600'
                        >
                          appleid.apple.com
                        </a>
                        .
                      </li>
                      <li>
                        Navigate to{' '}
                        <a
                          href='https://appleid.apple.com/account/manage/section/security'
                          target='_blank'
                          rel='noreferrer'
                          className='text-blue-500 underline hover:text-blue-600'
                        >
                          Sign-In and Security
                        </a>{' '}
                        ➔ &quot;App-Specific Passwords&quot;.
                      </li>
                      <li>
                        Generate an app-specific password and use it below.
                      </li>
                    </ol>

                    <div className='mt-10'>
                      <form className='space-y-3' onSubmit={handleSubmit}>
                        <div>
                          <label
                            htmlFor='email'
                            className='block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200'
                          >
                            Apple ID
                          </label>
                          <div className='mt-2'>
                            <input
                              id='email'
                              name='email'
                              type='email'
                              required
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 dark:bg-gray-900 dark:text-gray-200'
                            />
                          </div>
                        </div>

                        <div>
                          <div className='flex items-center justify-between'>
                            <label
                              htmlFor='password'
                              className='block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200'
                            >
                              App-specific password
                            </label>
                          </div>
                          <div className='mt-2'>
                            <input
                              id='password'
                              name='password'
                              type='password'
                              required
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 dark:bg-gray-900 dark:text-gray-200'
                            />
                          </div>
                        </div>

                        {typeof error === 'string' && (
                          <p className='my-2 text-red-500'>{error}</p>
                        )}

                        <div>
                          <button
                            type='submit'
                            className='flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600'
                            disabled={signin.isLoading}
                          >
                            {signin.isLoading ? 'Loading...' : 'Sign in'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useSafeQuery } from './useSafeQuery'
import { getSupabaseFileExtension } from '../utils/syncUtils'
import { cacheKeys } from '../utils/queryKeyFactory'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType } from '../utils/User'
import { cachedNotesContext } from '../providers/CachedNotesProvider'
import { fetchFileExtension } from '../lib/cloudkit/fetch'

export function useNotesExtension() {
  const user = useAuthenticatedUser()

  return useSafeQuery({
    context: cachedNotesContext,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    queryKey: cacheKeys.extension(user.authType),
    queryFn: () => {
      // console.debug('[useNotesExtension] user.authType', user.authType)
      switch (user.authType) {
        case AuthType.CLOUDKIT_SUPABASE:
        case AuthType.CLOUDKIT: {
          return fetchFileExtension()
        }
        case AuthType.SUPABASE: {
          return getSupabaseFileExtension()
        }
      }
    },
  })
}

/* eslint-disable no-console */
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'
import CloudKit from 'tsl-apple-cloudkit'
import { useQueryClient } from '@tanstack/react-query'
import { type AuthChangeEvent, type Session } from '@supabase/supabase-js'
import { useLocalStorage } from 'usehooks-ts'
import * as supabaseAuth from '../lib/supabase/auth'
import * as cloudkitAuth from '../lib/cloudkit/auth'
import { trackError, trackEvent } from '../lib/analytics'
import { accessTokenContext } from './AccessTokenProvider'
import { cachedNotesContext } from './CachedNotesProvider'
import { useSignOutWithUserId } from '../hooks/GoogleCalendar'
import { useAppleCalDAVSignoutWithUserId } from '../hooks/AppleCalDAV'
import { cacheKeys } from '../utils/queryKeyFactory'
import { User, AuthenticatedUser, AuthType } from '../utils/User'
import { container } from '../lib/CloudKitClient'

function isAuthenticated(
  user: User | undefined
): AuthenticatedUser | undefined {
  if (!user) return

  const { cloudKitUserId, supabaseUserId, email, confirmed_at } = user

  if (cloudKitUserId && supabaseUserId) {
    return {
      authType: AuthType.CLOUDKIT_SUPABASE,
      userId: cloudKitUserId,
      teamUserId: supabaseUserId,
      email: email,
      confirmed_at: confirmed_at,
    }
  } else if (cloudKitUserId) {
    return {
      authType: AuthType.CLOUDKIT,
      userId: cloudKitUserId,
    }
  } else if (supabaseUserId) {
    return {
      authType: AuthType.SUPABASE,
      userId: supabaseUserId,
      teamUserId: supabaseUserId,
      email: email,
      confirmed_at: confirmed_at,
    }
  } else {
    return
  }
}

// #region Context
const UserStateContext = createContext<User | undefined>(undefined)
const UserDispatchContext = createContext<
  Dispatch<SetStateAction<User>> | undefined
>(undefined)

export function useUserState() {
  return useContext(UserStateContext)
}

export const useUserDispatch = () => {
  return useContext(UserDispatchContext)
}

const AuthenticatedUserContext = createContext<AuthenticatedUser | undefined>(
  undefined
)

export function useAuthenticatedUser() {
  const context = useContext(AuthenticatedUserContext)
  if (!context) {
    throw new Error('useAuthenticatedUser must be used within UserProvider')
  }
  return context
}
// #endregion

// #region UI functions
function reload() {
  window.location.href = '/'
}

function hideSignInForm() {
  document.querySelector('#sign-in-container')?.classList.add('hidden')
  document.querySelector('#authContainer')?.classList.add('hidden')
}

function showSignInForm() {
  document.querySelector('#sign-in-container')?.classList.remove('hidden')
  document.querySelector('#sign-out-container')?.classList.add('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document.querySelector('#cloudkit-sign-in')?.classList.remove('hidden')

  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
  document
    .querySelector('#apple-sign-out-button-container')
    ?.classList.add('hidden')
  document.querySelector('#supabase-signout')?.classList.add('hidden')
}

export function showTeamspaceSignIn(visible = true) {
  if (visible) {
    document
      .querySelector('#teamspace-sign-in-container')
      ?.classList.remove('hidden')
    document.querySelector('#authContainer')?.classList.remove('hidden')
  } else {
    document
      .querySelector('#teamspace-sign-in-container')
      ?.classList.add('hidden')
    document.querySelector('#authContainer')?.classList.add('hidden')
  }
}

export function showTeamspaceSignOut() {
  document.querySelector('#supabase-signout')?.classList.remove('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document.querySelector('#sign-out-container')?.classList.remove('hidden')
  document
    .querySelector('#apple-sign-out-button-container')
    ?.classList.add('hidden')

  // Hide the teampspace login form
  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
}

export function showSignOut(
  supabaseSignedIn: boolean,
  cloudKitSignedIn: boolean
) {
  if (supabaseSignedIn && !cloudKitSignedIn) {
    // If cloudkit is signed in, show only cloudkit logout, because it's preferred
    document.querySelector('#supabase-signout')?.classList.remove('hidden')
    document
      .querySelector('#apple-sign-out-button-container')
      ?.classList.add('hidden')
  } else {
    document.querySelector('#supabase-signout')?.classList.add('hidden')
    document
      .querySelector('#apple-sign-out-button-container')
      ?.classList.remove('hidden')
  }
  document.querySelector('#sign-out-container')?.classList.remove('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
}
// #endregion

/*
Login scenarios:
1. Just CloudKit
2. Just Supabase
3. First, CloudKit, then Supabase Teamspaces (sidebar has to be reloaded)
4. CloudKit + Teamspace (Supabase) - user logs out (on purpose) 
  = logout supabase and reset the sidebar
   
5. CloudKit + Teamspace (Supabase) - 24h auto logout
  = keep Supabase login, when logging in again with CloudKit 
    5a. Except it's a different user that logged in, then logout supabase and reset the sidebar
*/

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useLocalStorage<User>('user', null)
  const authenticatedUser = isAuthenticated(user)
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })
  const accessTokenQueryClient = useQueryClient({ context: accessTokenContext })
  const signOutGoogle = useSignOutWithUserId()
  const signOutApple = useAppleCalDAVSignoutWithUserId()

  function signOutCalendars() {
    if (authenticatedUser?.userId) {
      signOutGoogle.mutate(authenticatedUser.userId)
      signOutApple.mutate(authenticatedUser.userId)
    }
  }

  // We need to store the user in a ref, because the user state is not updated immediately.
  // With this the functions below can access the current user state.
  const userReference = useRef(user)
  useEffect(() => {
    userReference.current = user
  }, [user])

  // Logging for debug reasons
  // console.log('extension', cachedNotesQueryClient?.getQueryData(cacheKeys.extension));

  function signInWithCloudKit(id: string) {
    setUser((previousUser) => ({ ...previousUser, cloudKitUserId: id }))
  }

  function signInWithSupabase(id: string, email: string, confirmed_at: Date) {
    setUser((previousUser) => ({
      ...previousUser,
      supabaseUserId: id,
      email,
      confirmed_at,
    }))
  }

  function signOutCloudKit() {
    setUser(null)
    reload()
  }

  function signOutSupabase() {
    setUser((previousUser) => {
      if (previousUser?.cloudKitUserId) {
        return { cloudKitUserId: previousUser.cloudKitUserId }
      }
      return null
    })
  }

  async function setupCloudKit(userID: string) {
    console.log('setupCloudKit, userID:', userID)

    // If the user logged in with CloudKit and Supabase for teamspaces, he will get signed out automatically out of CloudKit every 24h.
    // We want to keep Supabase signed in in this case unless a different CloudKit user logs in. Then sign out the supabase user.
    if (
      userReference.current?.supabaseUserId &&
      userReference.current.cloudKitUserId &&
      userReference.current.cloudKitUserId !== userID
    ) {
      // Log out supabase here if the stored user ID is different or null
      await supabaseAuth.signOut()
    }

    trackEvent('WEB - CloudKit Login', {
      cloudKitUserId: userID,
    })

    signInWithCloudKit(userID)

    // If we are logged into a teamspace, handle that here. Otherwise, if we are logged out of CloudKit, but logged into Supabase, the user will be null
    await handleSupabaseSessionUpdate(await supabaseAuth.session())

    cloudkitAuth.onSignedOut(async () => {
      queryClient.clear()
      cachedNotesQueryClient.clear()
      accessTokenQueryClient.clear()

      // If we are signed into a teamspace (supabase), we need to logout that too
      if (userReference.current?.supabaseUserId) {
        await supabaseAuth.signOut() // Use await here, otherwise, it will logout, but still show a 'you are logged into teamspace' warning after the logout
      }

      signOutCalendars()
      signOutCloudKit()
    })
  }

  function handleSupabaseAuthEvent(
    event: AuthChangeEvent,
    session: Session | null
  ) {
    if (event === 'USER_UPDATED') {
      return
    }

    if (event === 'SIGNED_OUT') {
      queryClient.clear()
      cachedNotesQueryClient.clear()
      accessTokenQueryClient.clear()

      signOutSupabase()

      // If we are not logged into CloudKit log out Google as well
      if (!userReference.current?.cloudKitUserId) {
        signOutCalendars()
      }
      return
    }

    console.log('auth state changed:', event, userReference.current)
    void handleSupabaseSessionUpdate(session)
  }

  async function handleSupabaseSessionUpdate(session: Session | null) {
    console.log('handleSupabaseSession', session)
    if (session?.user && session.user.aud === 'authenticated') {
      // If the user is signed into CloudKit, set a meta data value in supabase, so we know this is meant as a teamspace login when CloudKit gets logged out

      // Fetch the meta data of the user
      let meta
      try {
        meta = await supabaseAuth.getUserMetaData()
      } catch (error) {
        trackError(
          'handleSupabaseSessionUpdate',
          'Failed to get user meta data',
          {
            error,
          }
        )
      }

      // Update the meta data if needed
      if (meta?.cloudKitUserId !== userReference.current?.cloudKitUserId) {
        // Case 1: If the cloudKitUserId is empty but the meta data is not, we need to show the login form
        // Case 2: If the cloudKitUserId is different from the meta data (none is empty) we need to logout the supabase user

        // Case 1
        if (!userReference.current?.cloudKitUserId && meta?.cloudKitUserId) {
          console.log(
            'CloudKit is logged out, but teamspace was logged in, show the login form'
          )
          signOutCalendars()
          await supabaseAuth.signOut()
          setUser(null)
          showSignInForm()
          return
        }

        // Case 2
        if (
          userReference.current?.cloudKitUserId &&
          meta?.cloudKitUserId &&
          userReference.current.cloudKitUserId !== meta.cloudKitUserId
        ) {
          console.log(
            'CloudKit ID is different from the last login, logout teamspace'
          )
          signOutCalendars()
          await supabaseAuth.signOut()
          return
        }

        // Update the meta data, it means we are logged in with CloudKit and a teamspace
        await supabaseAuth.setUserMetaData({
          ...meta,
          cloudKitUserId: userReference.current?.cloudKitUserId,
        })
        console.log('invalidated team notes')
      }

      const confirmed_at = new Date(session.user.email_confirmed_at)
      signInWithSupabase(session.user.id, session.user.email, confirmed_at)
      trackEvent('WEB - Supabase Login', {
        supabaseUserId: session.user.id,
        email: session.user.email,
        confirmed_at,
      })

      // because of the long stale time, we need to explicitly load the team notes
      void cachedNotesQueryClient.invalidateQueries({
        queryKey: cacheKeys.teamNotes(),
      })
      showTeamspaceSignIn(false)
    }
  }

  useEffect(() => {
    if (!container) {
      supabaseAuth.setUpAuth(handleSupabaseAuthEvent)
      return
    }

    cloudkitAuth.setUpAuth(
      async (userIdentity: CloudKit.UserIdentity | null) => {
        if (userIdentity) {
          // The user is authenticated
          await setupCloudKit(userIdentity.userRecordName)
        } else {
          // Not authenticated
          cloudkitAuth.onSignedIn((userIdentity: CloudKit.UserIdentity) => {
            void setupCloudKit(userIdentity.userRecordName)
          })
        }
        supabaseAuth.setUpAuth(handleSupabaseAuthEvent)
      },
      async (error: CloudKit.CKError | undefined) => {
        trackEvent('WEB - CloudKit Login Error', {
          error,
        })

        // Logout supabase (teamspaces) as well, if we ran into a cloudkit error
        if (userReference.current?.supabaseUserId) {
          await supabaseAuth.signOut()
        }
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only run this once on mount
  }, [])

  useEffect(() => {
    if (user == null) {
      showSignInForm()
      return
    }

    hideSignInForm()
  }, [user])

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        {authenticatedUser && (
          <AuthenticatedUserContext.Provider value={authenticatedUser}>
            {children}
          </AuthenticatedUserContext.Provider>
        )}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

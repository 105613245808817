import { Block, PartialBlock } from "../../extensions/Blocks/api/blockTypes";
import { DefaultBlockSchema } from "../..";
import { convertBlocksToMarkdown } from "./blocksToMarkdownConversions";
import { convertMarkdownToBlocks } from "./markdownToBlocksConversions";

// fallback polyfill for window.matchMedia
window.matchMedia =
  window.matchMedia ||
  function () {
    return {
      matches: false,
      addListener: function () {},
      removeListener: function () {},
      addEventListener: function () {},
      removeEventListener: function () {},
    };
  };

export function markdownToBlocks(
  note: string,
  attachments: string,
  bulletItemCharacters: string[],
  taskItemCharacters: string[]
): PartialBlock<DefaultBlockSchema>[] {
  return convertMarkdownToBlocks(
    note,
    attachments,
    bulletItemCharacters,
    taskItemCharacters
  );
}

export function blocksToMarkdown(
  blocks: Block<DefaultBlockSchema>[],
  bulletItemCharacters: string[],
  taskItemCharacters: string[],
  isLegacyAttachment?: boolean
): string {
  return convertBlocksToMarkdown(
    blocks,
    bulletItemCharacters,
    taskItemCharacters,
    isLegacyAttachment
  );
}

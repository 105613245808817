import {
  QueryClient,
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { cachedNotesContext } from '../providers/CachedNotesProvider'
import { cacheKeys } from '../utils/queryKeyFactory'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { Dayjs } from 'dayjs'
import { determineBaseURL } from '../utils/backend'

const backendClient = axios.create({
  baseURL: determineBaseURL(window.location.hostname, 'apple'),
  headers: {
    'Content-Type': 'application/json',
  },
})

export type SigninRequestParams = {
  username: string
  password: string
}

type Response = { success: boolean }
type ErrorResponse = { error: string }

export function useAppleCalDAVSignin() {
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })
  const user = useAuthenticatedUser()

  return useMutation<Response, AxiosError<ErrorResponse>, SigninRequestParams>({
    context: cachedNotesContext,
    mutationFn: async (parameters: SigninRequestParams) => {
      const response = await backendClient.post<Response>('signin.php', {
        ...parameters,
        userid: user.userId,
      })
      return response.data
    },
    onSuccess: (_data: Response) => {
      void cachedNotesQueryClient.invalidateQueries({
        queryKey: cacheKeys.appleCalendars(user.userId),
      })
    },
  })
}

export function useAppleCalDAVSignout() {
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })
  const user = useAuthenticatedUser()

  return useMutation<Response, AxiosError<ErrorResponse>>({
    context: cachedNotesContext,
    mutationFn: async () => {
      const response = await backendClient.post<Response>('signout.php', {
        userid: user.userId,
      })
      return response.data
    },
    onSuccess: (_data: Response) => {
      cachedNotesQueryClient.removeQueries({
        queryKey: cacheKeys.appleCalendars(user.userId),
      })
    },
  })
}

export function useAppleCalDAVSignoutWithUserId() {
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })

  return useMutation<Response, AxiosError<ErrorResponse>, string>({
    context: cachedNotesContext,
    mutationFn: async (userId: string) => {
      const response = await backendClient.post<Response>('signout.php', {
        userid: userId,
      })
      return response.data
    },
    onSuccess: (_data: Response, userId: string) => {
      cachedNotesQueryClient.removeQueries({
        queryKey: cacheKeys.appleCalendars(userId),
      })
    },
  })
}

async function handleAppleApiError<T>(
  queryClient: QueryClient,
  queryKey: QueryKey | undefined,
  request: () => Promise<T>
) {
  try {
    return await request()
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        // cache "User not found" aka not signed in to prevent further requests
        return [] as T
      }
      const httpCodes = [400, 405]
      if (error.response?.status && httpCodes.includes(error.response.status)) {
        queryClient.removeQueries(queryKey)
      }
    }
    throw error
  }
}

export type VCalendar = {
  url: string
  displayName: string
  calendarColor: string
}

export function useAppleCalendars() {
  const user = useAuthenticatedUser()
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })

  return useQuery({
    context: cachedNotesContext,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    queryKey: cacheKeys.appleCalendars(user.userId),
    queryFn: () =>
      handleAppleApiError(
        cachedNotesQueryClient,
        cacheKeys.appleCalendars(user.userId),
        async () => {
          const response = await backendClient.get<VCalendar[]>(
            'calendars.php',
            {
              params: { userid: user.userId },
            }
          )
          return response.data
        }
      ),
  })
}

export type VEvent = {
  summary: string
  description: string
  location: string
  start: string
  end: string
  allday: boolean
  calendarName: string
  calendarColor: string
}

async function fetchEvents(
  cachedNotesQueryClient: QueryClient,
  userid: string,
  calendar: VCalendar,
  month: string
) {
  return handleAppleApiError(
    cachedNotesQueryClient,
    cacheKeys.appleEvents(userid, calendar.url, month),
    async () => {
      const response = await backendClient.get<VEvent[]>('events.php', {
        params: { userid, calendarurl: calendar.url, month },
      })
      if (Array.isArray(response.data)) {
        return response.data.map((vevent: VEvent) => ({
          ...vevent,
          calendarName: calendar.displayName,
          calendarColor: calendar.calendarColor,
        })) as VEvent[]
      }

      throw new Error('Invalid response data')
    }
  )
}

/**
 * Custom hook to fetch Apple Calendar events.
 *
 * @param urls - Array of iCalendar URLs.
 * @param date - Date as Dayjs object.
 * @returns Array of query results for each iCalendar URL.
 */
export function useAppleEvents(date: Dayjs) {
  const user = useAuthenticatedUser()
  const { data: appleCalendars = [] } = useAppleCalendars()
  const clonedDate: Dayjs = date.clone()
  const month: string = clonedDate.format('YYYY-MM')
  const cachedNotesQueryClient = useQueryClient({ context: cachedNotesContext })

  return useQueries({
    context: cachedNotesContext,
    queries: Array.isArray(appleCalendars)
      ? appleCalendars.map((calendar) => ({
          queryKey: cacheKeys.appleEvents(user.userId, calendar.url, month),
          queryFn: () =>
            fetchEvents(cachedNotesQueryClient, user.userId, calendar, month),
          refetchInterval: 5 * 60 * 1000, // Poll every 5 minutes
          refetchIntervalInBackground: false,
          refetchOnWindowFocus: false,
          retry: false, // disable retrying to save function calls
        }))
      : [],
  })
}

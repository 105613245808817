import { Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'

export type NotificationType = {
  title: string
  message: string
  icon?: string
  autoHide?: boolean
  timeout?: number
}

export function Notification({
  notification,
  onClose,
}: {
  notification?: NotificationType
  onClose: () => void
}) {
  useEffect(() => {
    if (notification && notification.autoHide !== false) {
      const timeout = notification.timeout ?? 3000
      const timer = setTimeout(onClose, timeout)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [notification, onClose])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live='assertive'
        className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
      >
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={Boolean(notification)}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg dark:bg-stone-700 bg-orange-50 shadow-xl ring-1 ring-black ring-opacity-5'>
              <div className='p-4'>
                <div className='flex items-start'>
                  <div className='flex-shrink-0'>
                    <i
                      className={`fa ${notification?.icon ?? 'fa-check-circle text-green-400'}`}
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='text-sm font-medium text-gray-900 dark:text-gray-100'>
                      {notification?.title}
                    </p>
                    <p className='mt-1 text-sm text-gray-500 dark:text-gray-200'>
                      {notification?.message}
                    </p>
                  </div>
                  <div className='ml-4 flex flex-shrink-0'>
                    <button
                      type='button'
                      className='inline-flex rounded-md dark:bg-stone-700 bg-orange-50 text-gray-400 dark:text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={onClose}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

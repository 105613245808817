import { ReactNode } from "react";
import { BlockSchema } from "@packages/blocknote-core";

import { DragHandleMenuProps } from "../DragHandleMenu";
import { DragHandleMenuItem } from "../DragHandleMenuItem";
import { MdCheck, MdOutlineCancel } from "react-icons/md";

export const CompleteBlockButton = <BSchema extends BlockSchema>(
  props: DragHandleMenuProps<BSchema> & { children: ReactNode }
) => {
  return (
    <DragHandleMenuItem
      icon={<MdCheck />}
      closeMenu={props.closeMenu}
      onClick={() =>
        props.editor.updateBlock(props.block, {
          props: {
            ...props.block.props,
            checked: props.block.props?.checked ? false : true,
            cancelled: false,
          },
        })
      }
      shortcut="⌘D">
      {props.children}
    </DragHandleMenuItem>
  );
};

export const CancelBlockButton = <BSchema extends BlockSchema>(
  props: DragHandleMenuProps<BSchema> & { children: ReactNode }
) => {
  return (
    <DragHandleMenuItem
      icon={<MdOutlineCancel />}
      closeMenu={props.closeMenu}
      onClick={() =>
        props.editor.updateBlock(props.block, {
          props: {
            ...props.block.props,
            checked: false,
            cancelled: props.block.props?.cancelled ? false : true,
          },
        })
      }
      shortcut="⌘S">
      {props.children}
    </DragHandleMenuItem>
  );
};

import { SuggestionProps } from "@tiptap/suggestion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import {
  Menu,
  Badge,
  MantineProvider,
  MantineThemeOverride,
  Stack,
  Text,
} from "@mantine/core";
import { LinkMarkSuggestionItem } from "./createClickableMark";

export interface SuggestionRef {
  onKeyDown: (props: any) => boolean;
}

interface ThemedSuggestionProps extends SuggestionProps {
  theme?: MantineThemeOverride;
  instructions?: string;
}
const SuggestionItem = ({
  item,
  index,
  selectItem,
  selectedIndex,
}: {
  item: string | LinkMarkSuggestionItem;
  index: number;
  selectItem: (index: number) => void;
  selectedIndex: number;
}) => {
  const itemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selectedIndex === index) {
      itemRef.current?.setAttribute("data-hovered", "true");
    } else {
      itemRef.current?.removeAttribute("data-hovered");
    }
  }, [selectedIndex, index]);

  const displayText = typeof item === "string" ? item : item.display;
  const icon = typeof item === "string" ? null : item.icon;
  const shortcut = typeof item === "string" ? null : item.shortcut;

  return (
    <Menu.Item
      icon={icon && <i className={`fa-${icon} fa-regular`}></i>}
      rightSection={shortcut && <Badge size={"xs"}>{shortcut}</Badge>}
      className="truncate"
      key={index}
      closeMenuOnClick={false}
      onClick={() => selectItem(index)}
      ref={itemRef}>
      <Stack>
        <Text title={displayText}>{displayText}</Text>
      </Stack>
    </Menu.Item>
  );
};

export default forwardRef<SuggestionRef, ThemedSuggestionProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = props.items[index];

      if (typeof item === "object" && item !== null) {
        props.command(item.paste);
      } else if (typeof item === "string") {
        props.command(item);
      }
    };

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter" && props.items.length > 0) {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <MantineProvider theme={props.theme}>
        <Menu
          /** Hacky fix to get the desired menu behaviour. The trigger="hover"
           * attribute allows focus to remain on the editor, allowing for suggestion
           * filtering. The closeDelay=10000000 attribute allows the menu to stay open
           * practically indefinitely, as normally hovering off it would cause it to
           * close due to trigger="hover".
           */
          defaultOpened={true}
          trigger={"hover"}
          closeDelay={10000000}>
          <Menu.Dropdown
            style={{
              maxHeight: "300px",
              maxWidth: "600px",
              minWidth: "400px",
              overflowY: "auto",
            }}>
            {props.query.length === 0 && props.instructions && (
              <Text className="pl-3 py-1 text-gray-400" size="xs">
                {props.instructions}
              </Text>
            )}

            {props.items.length > 0 &&
              props.items.map((item, index) => (
                <SuggestionItem
                  key={index}
                  item={item}
                  index={index}
                  selectItem={selectItem}
                  selectedIndex={selectedIndex}
                />
              ))}
          </Menu.Dropdown>
        </Menu>
      </MantineProvider>
    );
  }
);

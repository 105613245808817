import { DragHandleMenu, DragHandleMenuProps } from "./DragHandleMenu";
import { RemoveBlockButton } from "./DefaultButtons/RemoveBlockButton";
import { BlockSchema } from "@packages/blocknote-core";
import {
  CancelBlockButton,
  CompleteBlockButton,
} from "./DefaultButtons/TaskStateBlockButtons";
import { FlaggingButton } from "../../BlockSideMenu/components/DefaultButtons/FlaggingButton";
import { InsertBlockButton } from "./DefaultButtons/InsertBlockButton";
import { ScheduleButton } from "./DefaultButtons/SchdeduleButton";

export const DefaultDragHandleMenu = <BSchema extends BlockSchema>(
  props: DragHandleMenuProps<BSchema>
) => {
  const baseListType = ["checkListItem", "taskListItem"];
  const taskLikeType = ["bulletListItem", ...baseListType];
  return (
    <DragHandleMenu>
      <InsertBlockButton {...props}>Insert</InsertBlockButton>
      {baseListType.some((type) => type === props.block.type) ? (
        <>
          <CompleteBlockButton {...props}>Complete</CompleteBlockButton>
          <ScheduleButton
            itemProps={{ ...props, children: "Schedule" }}
            editor={props.editor}
          />
          <CancelBlockButton {...props}>Cancel</CancelBlockButton>
        </>
      ) : null}
      {taskLikeType.some((type) => type === props.block.type) ? (
        <>
          <FlaggingButton
            itemProps={{ ...props, children: "Flag Important" }}
            editor={props.editor}
          />
        </>
      ) : null}
      <RemoveBlockButton {...props}>Delete</RemoveBlockButton>
    </DragHandleMenu>
  );
};

import { type CalendarEvent } from '../../CalendarEvent'

export function getGroupedEvents(events: CalendarEvent[]): CalendarEvent[][] {
  const filteredEvents = events.filter((e: CalendarEvent | null | undefined) =>
    Boolean(e)
  )
  const temporaryEvent = filteredEvents.find((e) => e.id === 'temporary')
  const oldSort = [...filteredEvents.filter((e) => e.id !== 'temporary')]
  events.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf())

  const result: CalendarEvent[][] = []
  let currentGroup: CalendarEvent[] = []
  let currentGroupIDs: string[] = []

  if (temporaryEvent) {
    result.push([temporaryEvent])
  }

  for (const currentEvent of events) {
    let overlapFound = false
    for (const previousEvent of currentGroup) {
      if (
        currentEvent.startDate.isBefore(previousEvent.endDate) &&
        currentEvent.endDate.isAfter(previousEvent.startDate)
      ) {
        overlapFound = true
        break
      }
    }

    if (overlapFound) {
      currentGroup.push(currentEvent)
      currentGroupIDs.push(currentEvent.id)
    } else {
      const currentGroupIDsCopy = [...currentGroupIDs]
      result.push([
        ...oldSort.filter((e) => currentGroupIDsCopy.includes(e.id)),
      ])
      currentGroup = [currentEvent]
      currentGroupIDs = [currentEvent.id]
    }
  }

  if (currentGroup.length > 0) {
    result.push([...oldSort.filter((e) => currentGroupIDs.includes(e.id))])
  }

  return result
}

import { createContext, useContext, useState } from 'react'
import { Block, DefaultBlockSchema } from '@packages/blocknote-core'

// This lets us share the content of the editor with WeekView (timeblocking) to calculate the timeblocks.
// Have added this so that we don't trigger a re-render upon every content change of the editor.
export const EditorContentContext = createContext<Block<DefaultBlockSchema>[] | null>(null)
export const EditorContentDispatchContext = createContext<React.Dispatch<
  React.SetStateAction<Block<DefaultBlockSchema>[]>
> | null>(null)

export const useEditorContent = () => {
  const context = useContext(EditorContentContext)
  if (context === undefined) {
    throw new Error('useEditorContent must be used within a EditorContentProvider')
  }
  return context
}

export const useEditorContentDispatch = () => {
  const context = useContext(EditorContentDispatchContext)
  if (context === undefined) {
    throw new Error('useEditorContentDispatch must be used within a EditorContentProvider')
  }
  return context
}

export const EditorContentProvider = ({ children }): JSX.Element => {
  const [editorContent, setEditorContent] = useState<Block<DefaultBlockSchema>[]>([])

  return (
    <EditorContentContext.Provider value={editorContent}>
      <EditorContentDispatchContext.Provider value={setEditorContent}>
        {children}
      </EditorContentDispatchContext.Provider>
    </EditorContentContext.Provider>
  )
}

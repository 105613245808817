import { QueryClient } from '@tanstack/react-query'
import { hasTag, removeTag } from './notificationTracker'
import { SupabaseNote } from './types'
import {
  REALTIME_SUBSCRIBE_STATES,
  RealtimePostgresChangesPayload,
} from '@supabase/supabase-js'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  noteQueryKey,
  privateKeys,
  teamKeys,
} from '../../utils/queryKeyFactory'
import { Note } from '../../utils/syncUtils'
import { supabaseClient } from '../SupabaseClient'
import { loadNoteFrom } from './noteUtils'
import { AuthenticatedUser } from '../../utils/User'
import {
  removeFromCacheByRecordNames,
  updateCache,
} from '../../providers/CachedNotesProvider'

export const notesChannel = 'notes'

export function subscribeToChannel(
  user: AuthenticatedUser,
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient,
  setIsRegistered: (isRegistered: boolean) => void
) {
  supabaseClient
    .channel(notesChannel)
    .on<SupabaseNote>(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'notes' },
      (payload) => {
        handleChange(payload, user, queryClient, cachedNotesQueryClient).catch(
          (error: unknown) => {
            console.error('Error handling change:', error)
            setIsRegistered(false)
          }
        )
      }
    )
    .subscribe((status) => {
      // console.debug('Supabase connection status:', status)
      if (status === REALTIME_SUBSCRIBE_STATES.SUBSCRIBED) {
        setIsRegistered(true)
      } else {
        setIsRegistered(false)
      }
    })
}

async function handleChange(
  payload: RealtimePostgresChangesPayload<SupabaseNote>,
  user: AuthenticatedUser,
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient
) {
  const id =
    'change_tag' in payload.new
      ? payload.new.change_tag
      : 'id' in payload.old
        ? payload.old.id
        : undefined
  if (id && hasTag(id)) {
    removeTag(id)
    return
  }

  const currentUserId = 'teamUserId' in user ? user.teamUserId : user.userId

  // eslint-disable-next-line no-console
  console.debug('note changed', payload)
  switch (payload.eventType) {
    case 'INSERT':
    case 'UPDATE': {
      const updatedNote = await loadNoteFrom(currentUserId, payload.new)
      queryClient.setQueryData<Note>(
        noteQueryKey(updatedNote),
        () => updatedNote
      )

      const cacheKey = cacheKeyFromNoteType(updatedNote.noteType, user)
      updateCache(cachedNotesQueryClient, [updatedNote], cacheKey)
      break
    }
    case 'DELETE': {
      for (const [queryKey, note] of queryClient.getQueriesData<Note>(
        teamKeys.all
      )) {
        if (
          payload.old.id !== undefined &&
          payload.old.id === note?.recordName
        ) {
          queryClient.removeQueries(queryKey)
        }
      }
      for (const [queryKey, note] of queryClient.getQueriesData<Note>(
        privateKeys.notes
      )) {
        if (
          payload.old.id !== undefined &&
          payload.old.id === note?.recordName
        ) {
          queryClient.removeQueries(queryKey)
        }
      }

      if (payload.old.id) {
        removeFromCacheByRecordNames(
          cachedNotesQueryClient,
          [payload.old.id],
          cacheKeys.teamNotes(currentUserId)
        )
        removeFromCacheByRecordNames(
          cachedNotesQueryClient,
          [payload.old.id],
          cacheKeys.privateNotes(currentUserId)
        )
      }

      break
    }
  }
}

import { useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { teamKeys } from '../utils/queryKeyFactory'
import { inviteTeamspaceMember } from '../lib/supabase/invitation'
import { trackEvent } from '../lib/analytics'
import { type TeamspaceMember } from './useListTeamspaceMembers'
import { useSafeMutation } from './useSafeMutation'

export function useInviteMember(
  successCallback?: (_data: TeamspaceMember | undefined) => void
) {
  const user = useUserState()
  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({
      teamspaceID,
      email,
    }: {
      teamspaceID: string
      email: string
    }) => {
      if (!teamspaceID || !email || email.length < 3) {
        return Promise.resolve(undefined)
      }

      // Verify if the email is valid
      const emailRegex = /\S+@\S+\.\S+/
      if (!emailRegex.test(email)) {
        return Promise.resolve(undefined)
      }

      trackEvent('WEB - Team Member Invite', {
        email,
        teamspaceID,
      })

      if (user?.supabaseUserId) {
        return inviteTeamspaceMember(teamspaceID, email)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID, email }) => {
      if (data && teamspaceID && email) {
        trackEvent('WEB - Team Member Invited', {
          email,
          teamspaceID,
        })

        const teamspaceMembers =
          queryClient.getQueryData<[TeamspaceMember]>(
            teamKeys.members(teamspaceID)
          ) ?? []
        queryClient.setQueryData(teamKeys.members(teamspaceID), [
          ...teamspaceMembers,
          { email: email.toLowerCase(), role: 'pending' },
        ])
      }
      successCallback?.(data)
    },
    onError: (error) => {
      trackEvent('WEB - Team Member Invite Failed', {
        error,
      })
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}

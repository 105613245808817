import { Plugin, PluginKey } from "prosemirror-state";

// Add options to the plugin
export interface BreakMarkOnSpacePluginOptions {
  // The mark to break on space
  markName: string;
}

export const createBreakMarkPlugin = (
  options: BreakMarkOnSpacePluginOptions
) => {
  const { markName } = options;

  return new Plugin({
    key: new PluginKey("breakMarkOnSpace-" + markName),
    props: {
      handleKeyDown(view, event) {
        const { state, dispatch } = view;
        const { $anchor, from } = state.selection;

        if (
          !$anchor ||
          event.key.length !== 1 ||
          !/[\x20-\x7E]/.test(event.key)
        ) {
          return false;
        }

        // Check for key modifier
        if (event.metaKey || event.ctrlKey || event.altKey) {
          return false;
        }

        const markType = state.schema.marks[markName];
        if (!markType) {
          return false;
        }

        // Check if the cursor is within or right after a range with the mark
        let isWithinMark = false;
        state.doc.nodesBetween(from, from, (node) => {
          if (markType.isInSet(node.marks)) {
            isWithinMark = true;
            return false; // Stop traversal
          }

          return true;
        });

        if (isWithinMark) {
          return false;
        }

        const isActive = markType.isInSet($anchor.marks());

        if (!isActive) {
          return false;
        }

        // Remove the specific mark and insert the character
        if (dispatch) {
          dispatch(state.tr.removeStoredMark(markType).insertText(event.key));
        }

        // Check if the text has cahnged
        const { $anchor: newAnchor } = view.state.selection;
        if ($anchor.pos === newAnchor.pos) {
          return false;
        }

        return true;
      },
    },
  });
};

import { testFetch } from '../lib/cloudkit/fetch'
import { useUserState } from '../providers/UserProvider'
import { privateKeys } from '../utils/queryKeyFactory'
import { useSafeQuery } from './useSafeQuery'

export function useLoginCheck() {
  const user = useUserState()

  const queryResult = useSafeQuery({
    enabled: !!user?.cloudKitUserId,
    retry: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryKey: privateKeys.logincheck(user?.cloudKitUserId),
    queryFn: async () => {
      if (user?.cloudKitUserId) {
        return testFetch()
      }
      throw new Error('Cloudkit user not logged in')
    },
  })

  return queryResult
}

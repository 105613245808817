import { BlockNoteEditor, BlockSchema } from "@packages/blocknote-core";
import { DragHandleMenuItem } from "../DragHandleMenuItem";
import { DragHandleMenuProps } from "../DragHandleMenu";
import { ReactNode } from "react";
import iconsData from "../../../FormattingToolbar/components/FontIcons";

export const ScheduleButton = <BSchema extends BlockSchema>(props: {
  itemProps: DragHandleMenuProps<BSchema> & { children: ReactNode };
  editor: BlockNoteEditor<BSchema>;
}) => {
  const handleScheduleButtonClick = () => {
    // Set the cursor into the block, otherwise the plugin won't react and set the background color
    props.editor?._tiptapEditor.commands.focus();
    props.editor?.setTextCursorPosition(props.itemProps.block, "end");

    const block = props.itemProps.block;
    const existingContent = block.content;

    // Check if the last block ends with a space, then don't add an extra space
    const lastContentItem = existingContent[existingContent.length - 1];
    const textToAdd =
      lastContentItem &&
      "text" in lastContentItem &&
      lastContentItem.text.endsWith(" ")
        ? ">"
        : " >";

    existingContent.push({
      attrs: {},
      styles: {},
      text: textToAdd,
      type: "text",
    });

    props.editor?.updateBlock(block, { ...block, content: existingContent });
    props.editor?._tiptapEditor.commands.focus();
    props.editor?.setTextCursorPosition(block, "end");
  };

  return (
    <DragHandleMenuItem
      icon={iconsData.schedule()}
      onClick={handleScheduleButtonClick}
      closeMenu={props.itemProps.closeMenu}
      children={props.itemProps.children}
      shortcut=">date"
    />
  );
};

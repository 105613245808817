import { type Dayjs } from 'dayjs'
import { type CalendarEvent } from '../../CalendarEvent'
import { calculateOffsetAndHeight } from '../utils/event'
import { EventContent } from './EventContent'
import { Dispatch, SetStateAction } from 'react'

export function MultipleEvents({
  events,
  segmentHeight,
  setCalendarEventDrag,
  selectedDay,
}: {
  events: CalendarEvent[]
  segmentHeight: number
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
  selectedDay: Dayjs
}) {
  const lastEvent = events.at(-1)
  if (!lastEvent) {
    return
  }

  const minute = lastEvent.startDate.startOf('minute').toISOString()
  const referenceDate = lastEvent.startDate.subtract(
    lastEvent.startDate.minute() % 15,
    'minute'
  )
  return (
    <li
      id={`event-group-${minute}`}
      className='col-start-1 mt-px flex space-x-0.5'
      style={{ gridRow: lastEvent.position }}
    >
      {events.map((event) => {
        const offsetPxTop =
          (event.startDate.diff(referenceDate, 'minute') * segmentHeight) / 15
        const { eventHeight } = calculateOffsetAndHeight(
          event,
          segmentHeight,
          selectedDay
        )
        return (
          <EventContent
            setCalendarEventDrag={setCalendarEventDrag}
            event={event}
            offsetPxTop={offsetPxTop}
            eventHeight={eventHeight}
            segmentHeight={segmentHeight}
            className='group inset-1 flex-grow basis-1/4'
            style={{ transform: `translateY(${String(offsetPxTop)}px)` }}
            key={`event-${event.id}`}
          />
        )
      })}
    </li>
  )
}

import { NoteType } from '../utils/syncUtils'

export function getNoteTypeFilter(noteType: NoteType): NoteType[] {
  const noteTypeMap: Partial<Record<NoteType, NoteType[]>> = {
    [NoteType.PROJECT_NOTE]: [
      NoteType.PROJECT_NOTE,
      NoteType.ASSET_PROJECT_NOTE,
    ],
    [NoteType.CALENDAR_NOTE]: [
      NoteType.CALENDAR_NOTE,
      NoteType.ASSET_CALENDAR_NOTE,
    ],
    [NoteType.TEAM_SPACE_NOTE]: [NoteType.TEAM_SPACE_NOTE, NoteType.TEAM_SPACE],
    [NoteType.TEAM_SPACE_CALENDAR_NOTE]: [NoteType.TEAM_SPACE_CALENDAR_NOTE],
  }

  const noteTypeFilter = noteTypeMap[noteType]
  if (!noteTypeFilter) {
    throw new Error(`Unsupported note type: ${noteType.toString()}`)
  }

  return noteTypeFilter
}

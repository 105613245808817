import mixpanel, { type Dict } from 'mixpanel-browser'

let INITIALIZED = false

export function init() {
  if (!INITIALIZED && process.env.MIXPANEL_TOKEN) {
    mixpanel.init(process.env.MIXPANEL_TOKEN, {
      debug: Boolean(import.meta.env.DEV),
      track_pageview: false,
      persistence: 'localStorage',
      ignore_dnt: true,
    })
    INITIALIZED = true
  }
}

export function trackEvent(eventName: string, eventProps?: Dict) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console -- show analytics events only in dev
    console.info(
      '%c[Analytics] %c%s',
      'color:green',
      'color:orange',
      eventName,
      eventProps
    )
  }
  if (INITIALIZED) {
    mixpanel.track(eventName, eventProps)
  }
}

export function trackError(
  functionName: string,
  message: string,
  eventProps?: Dict
) {
  // eslint-disable-next-line no-console -- always show errors
  console.error(
    '%c[Analytics] %c%s',
    'color:red',
    'color:red',
    message,
    eventProps
  )
  if (INITIALIZED) {
    mixpanel.track(`web.${functionName}.error`, {
      ...eventProps,
      message,
    })
  }
}

export function identify(userId?: string) {
  if (userId) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console -- show analytics events only in dev
      console.info('[Analytics] Identify', userId)
    }
    if (INITIALIZED) {
      mixpanel.identify(userId)
    }
  }
}

export function peopleSet(people: Dict) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console -- show analytics events only in dev
    console.info('[Analytics] People Set', people)
  }
  if (INITIALIZED) {
    mixpanel.people.set(people)
  }
}

import { useState, useEffect, useRef } from 'react'
import { useIsMutating, useIsFetching } from '@tanstack/react-query'

function ChildComponent({ cloudState }) {
  return (
    <div className="absolute contents">
      {cloudState === 'default' && <i className="fa-solid fa-cloud fa-md"></i>}
      {cloudState === 'loading' && (
        <div className="relative pr-2 w-8">
          <i className="fa-regular fa-spinner-third fa-spin fa-xl absolute right-0.5"></i>
          <i className="fa-solid fa-cloud fa-xs absolute right-1.5"></i>
        </div>
      )}
      {cloudState === 'done-loading' && <i className="w-8 fa-solid fa-cloud-check fa-md"></i>}
    </div>
  )
}

function CloudLoading({ needsUpload }) {
  const [cloudState, setCloudState] = useState('default')
  const isMutating = useIsMutating()
  const isFetching = useIsFetching()
  const didStartFetching = useRef<boolean>(false)

  useEffect(() => {
    // Once the fetching started, we show the loading dialog, block other states that come in
    // till the loading is done
    if (didStartFetching.current) {
      return
    }

    if (isMutating || isFetching) {
      didStartFetching.current = true
      setCloudState('loading')
    } else if (needsUpload) {
      // NeedsUpload is true when the user has made changes to the note.
      // It can be set to false when the user reverses the change so an upload is unecessary
      setCloudState('default')
    } else {
      setCloudState('done-loading')
    }
  }, [isMutating, isFetching, needsUpload, cloudState])

  useEffect(() => {
    if (cloudState === 'loading') {
      setTimeout(() => {
        didStartFetching.current = false
        setCloudState('done-loading')
      }, 1000)
    }
  }, [cloudState])
  return (
    <div className="flex items-center mr-2 w-10">
      {cloudState === 'default' ? (
        <div className="">
          {isMutating !== null ? (
            <i className="bg-gray-300 text-gray-900 w-4 h-4 rounded-full flex justify-center items-center text-xs font-variant-numeric transform -rotate-12 font-normal">{1}</i>
          ) : null}
        </div>
      ) : null}

      <button className=" bg-transparent ">
        <ChildComponent cloudState={cloudState} />
      </button>
    </div>
  )
}

export default CloudLoading

import { useQueryClient } from '@tanstack/react-query'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType } from '../utils/User'
import {
  CacheData,
  cachedNotesContext,
  getCachedData,
  getCurrentCacheVersion,
  removeFromCacheByRecordNames,
} from '../providers/CachedNotesProvider'
import { type NoteType, isPrivateNote } from '../utils/syncUtils'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  projectNoteQueryKey,
} from '../utils/queryKeyFactory'
import * as supabase from '../lib/supabase/noteOperations'
import * as cloudkit from '../lib/cloudkit/noteOperations'
import { useSafeMutation } from './useSafeMutation'
import { trackEvent } from '../lib/analytics'

type DeleteOptions = {
  recordName: string
  noteType: NoteType
  descendenceIds?: string[]
}

export function useDeleteNote() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  return useSafeMutation({
    mutationFn: async ({
      recordName,
      noteType,
      descendenceIds,
    }: DeleteOptions) => {
      // console.debug('[useDeleteNote] deleting', recordName)

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          const ids = [recordName, ...(descendenceIds ?? [])].reverse()
          return cloudkit.deleteNotes(ids)
        }
        case AuthType.SUPABASE: {
          return supabase.deleteNote(recordName)
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          if (isPrivateNote(noteType)) {
            const ids = [recordName, ...(descendenceIds ?? [])].reverse()
            return cloudkit.deleteNotes(ids)
          }
          return supabase.deleteNote(recordName)
        }
      }
    },
    onMutate: ({ recordName, noteType, descendenceIds }: DeleteOptions) => {
      // console.debug(
      //   '[useDeleteNote] onMutate',
      //   recordName,
      //   noteType,
      //   descendenceIds
      // )

      const cacheKey = cacheKeyFromNoteType(noteType, user)
      const previousNotes = getCachedData(cachedNotesQueryClient, cacheKey)
      void cachedNotesQueryClient.cancelQueries(cacheKey)

      // Optimistically remove the note
      removeFromCacheByRecordNames(
        cachedNotesQueryClient,
        [recordName, ...(descendenceIds ?? [])],
        cacheKey
      )

      // TODO remove also calendar notes
      queryClient.removeQueries(projectNoteQueryKey({ recordName, noteType }))

      // return a context object with the snapshotted value
      return {
        map: previousNotes,
        version: getCurrentCacheVersion(cachedNotesQueryClient, cacheKey),
      }
    },
    onError: (error, variables, context) => {
      console.error('[useDeleteNote] onError', error, variables, context)
      trackEvent('web.useDeleteNote.error', {
        error,
        recordName: variables.recordName,
        noteType: variables.noteType,
        descendenceIds: variables.descendenceIds,
      })
      const cacheKey = cacheKeyFromNoteType(variables.noteType, user)
      cachedNotesQueryClient.setQueryData<CacheData>(cacheKey, context)
    },
    onSuccess: (
      _data,
      { recordName, noteType, descendenceIds }: DeleteOptions
    ) => {
      trackEvent('WEB - Note Deleted', {
        recordName,
        noteType,
        descendenceIds,
      })
    },
  })
}

import CloudKit from 'tsl-apple-cloudkit'
import { container } from '../CloudKitClient'
import { trackEvent } from '../analytics'

export function setUpAuth(
  onSuccess: (result: CloudKit.UserIdentity | null) => unknown,
  onError: (reason?: CloudKit.CKError) => unknown
) {
  if (!container) {
    // onError(new CloudKit.CKError())
    return
  }

  container
    .setUpAuth()
    .then(onSuccess)
    .catch((error: unknown) => onError(error as CloudKit.CKError))
}

export function onSignedIn(
  onSuccess: (result: CloudKit.UserIdentity) => unknown
) {
  if (!container) {
    return
  }
  container
    .whenUserSignsIn()
    .then(onSuccess)
    .catch((error: unknown) => {
      console.error('Error during sign-in:', error)
      trackEvent('web.CloudKitClient.onSignedIn', {
        error,
      })
    })
}

export function onSignedOut(onSuccess: () => unknown) {
  if (!container) {
    return
  }
  container
    .whenUserSignsOut()
    .then(onSuccess)
    .catch((error: unknown) => {
      console.error('Error during sign-out:', error)
      trackEvent('web.CloudKitClient.onSignedOut', {
        error,
      })
    })
}

import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { teamKeys } from '../utils/queryKeyFactory'
import { fetchMembers } from '../lib/supabase/invitation'

export type TeamspaceMember = {
  email: string
  role: string
}

export function useListTeamspaceMembers(
  teamspaceID: string | null
): UseQueryResult<TeamspaceMember[] | undefined | null, Error> {
  const user = useUserState()

  const queryResult = useQuery<TeamspaceMember[] | undefined | null, Error>({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: teamKeys.members(teamspaceID),
    queryFn: async () => {
      if (teamspaceID && user.supabaseUserId) {
        return fetchMembers(teamspaceID)
      }
      return []
    },
  })

  //   const { isError, error } = queryResult;

  return queryResult
}

import { Container, Database } from 'tsl-apple-cloudkit'

const apiToken = process.env.CLOUDKIT_API_TOKEN
if (!apiToken) {
  throw new Error('CLOUDKIT_API_TOKEN is not set')
}

// Initialize variables for container and database
let cloudKitClient
let container: Container | undefined
let database: Database | undefined

// See https://github.com/typescriptlibs/tsl-apple-cloudkit/issues/28#issuecomment-2199131604
// There can be situations where CloudKit is blocked and so window.CloudKit is undefined.
// In this case the app crashes, if we don't check if window.CloudKit is undefined and users
// also can't use the email login as workaround. It can be blocked by the browser or network security.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (typeof window !== 'undefined' && window.CloudKit) {
  cloudKitClient = window.CloudKit.configure({
    containers: [
      {
        containerIdentifier: 'iCloud.co.noteplan.NotePlan',
        apiTokenAuth: {
          apiToken,
          persist: true,
          signInButton: {
            id: 'apple-sign-in-button',
            theme: 'medium',
          },
          signOutButton: {
            id: 'apple-sign-out-button',
            theme: 'medium',
          },
        },
        environment: 'production',
      },
    ],
  })

  container = cloudKitClient.getDefaultContainer()
  database = container.privateCloudDatabase
} else {
  container = undefined
  database = undefined
}

export { container, database }

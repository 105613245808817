import { Editor, Mark } from "@tiptap/core";
import { InlineParser } from "./inlineParser";
import { Plugin } from "prosemirror-state";
import Suggestion from "@tiptap/suggestion";
import { PluginKey } from "@tiptap/pm/state";

import tippy, { Instance, Props } from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import SuggestionList, { SuggestionRef } from "./SuggestionList";
import { MantineThemeOverride } from "@mantine/core";
import Fuse from "fuse.js";

export const clickableMarkAutoCompleteInstanceIdentifier =
  "clickable-mark-auto-complete";

export interface LinkOptions {
  HTMLAttributes: Record<string, any>;
}

export interface LinkMarkSuggestionItem {
  display: string;
  paste: string;
  icon?: string;
  shortcut?: string;
}

interface LinkMarkOptions {
  name: string;
  suggestionChar?: string;
  suggestionPrefix?: string;
  suggestionSuffix?: string;
  allowSpaces?: boolean;
  shouldMatchQuery?: boolean;
  dataAttrOverride?: string;
  regex: RegExp;
  attrsMap: { [attr: string]: number };
  onMarkClicked?: (event: MouseEvent) => void;
  onLoadSuggestionItems?: (
    prefix: string,
    keyword: string
  ) => Array<string | LinkMarkSuggestionItem>;

  instructions?: string;
  theme?: MantineThemeOverride;
}

export const createClickableMark = ({
  name,
  suggestionChar,
  suggestionPrefix,
  suggestionSuffix,
  allowSpaces,
  shouldMatchQuery,
  dataAttrOverride,
  regex,
  attrsMap,
  onMarkClicked,
  onLoadSuggestionItems,
  instructions,
  theme,
}: LinkMarkOptions) =>
  Mark.create<LinkOptions>({
    name,

    parseHTML() {
      return [
        {
          tag: "a",
          getAttrs: (element: HTMLElement | string) => {
            if (typeof element === "string") {
              return false;
            }
            return false;
          },
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        "a",
        {
          ...HTMLAttributes,
          [dataAttrOverride ?? "data-" + name]: this.name,
        },
        0,
      ];
    },

    addAttributes() {
      return {
        [name]: {
          default: false,
          parseHTML: (element) =>
            element.getAttribute(dataAttrOverride ?? "data-" + name),
          renderHTML: () => ({
            [dataAttrOverride ?? "data-" + name]: true,
          }),
        },
      };
    },

    addProseMirrorPlugins() {
      const plugins = [
        new InlineParser({
          markType: this.type,
          regex,
          attrsMap: attrsMap,
        }).plugin,

        new Plugin({
          props: {
            handleClickOn: (_view, _pos, _node, _nodePos, event, direct) => {
              if (!direct || !onMarkClicked) {
                return false;
              } // Only handle direct clicks, not bubbled events

              const isClickableMark = (
                event.target as HTMLElement
              ).hasAttribute(dataAttrOverride ?? "data-" + name);

              if (isClickableMark) {
                event.preventDefault(); // Prevent default behavior
                event.stopPropagation(); // Stop the event from bubbling up
                // Handle the click event for your custom mark
                onMarkClicked(event);
                return true; // Indicate that this plugin handled the click
              }
              return false; // Indicate that this plugin did not handle the click
            },
          },
        }),
      ];

      if (suggestionChar) {
        const suggestionPlugin = buildSuggestionPlugin(
          this.editor,
          name,
          onLoadSuggestionItems,
          suggestionChar,
          suggestionPrefix,
          suggestionSuffix,
          allowSpaces,
          shouldMatchQuery,
          instructions,
          theme
        );
        plugins.push(suggestionPlugin);
      }

      return plugins;
    },
  });

const buildSuggestionPlugin = (
  editor: Editor,
  name: string,
  onLoadSuggestionItems:
    | ((
        prefix: string,
        keyword: string
      ) => Array<string | LinkMarkSuggestionItem>)
    | undefined,
  suggestionChar: string,
  suggestionPrefix?: string,
  suggestionSuffix?: string,
  allowSpaces?: boolean,
  shouldMatchQuery = true,
  instructions?: string,
  theme?: MantineThemeOverride
): Plugin => {
  return Suggestion({
    editor: editor,
    char: suggestionChar,
    allowSpaces: allowSpaces,
    pluginKey: new PluginKey(name + "-suggestion"),

    items: ({ query }) => {
      if (onLoadSuggestionItems) {
        const suggestions = onLoadSuggestionItems(suggestionChar, query);
        if (query.length === 0 || !shouldMatchQuery) {
          return suggestions.slice(0, 300);
        } else {
          const items = suggestions.map((suggestion) =>
            typeof suggestion === "string"
              ? { display: suggestion, paste: suggestion }
              : suggestion
          );
          const fuse = new Fuse(items, {
            keys: ["display", "paste"],
            threshold: 0.5,
            findAllMatches: true,
            shouldSort: true,
            isCaseSensitive: false,
          });

          const results = fuse
            .search(query, { limit: 100 })
            .map((result) => result.item);

          if (results.length === 1 && results[0].display === query) {
            return [];
          } else {
            return results;
          }
        }
      } else {
        return [];
      }
    },

    render: () => {
      let component: ReactRenderer;
      let popup: Instance<Props>;

      return {
        onStart: (props) => {
          const existing = document.querySelector(
            "[" + clickableMarkAutoCompleteInstanceIdentifier + '="true"]'
          );
          if (existing) {
            return;
          } // Don't create duplicates, this gets called various times

          component = new ReactRenderer(SuggestionList, {
            props: {
              ...props,
              theme: theme,
              instructions: instructions,
            },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy(editor.view.dom, {
            onCreate(instance) {
              instance.popper
                .querySelector(".tippy-box")
                ?.setAttribute(
                  clickableMarkAutoCompleteInstanceIdentifier,
                  "true"
                );
            },
            getReferenceClientRect: () =>
              (props.clientRect && props.clientRect()) || new DOMRect(),
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          if (!component) {
            return;
          }

          component.updateProps(props);
          if (!props.clientRect) {
            return;
          }

          if (props.items.length === 0) {
            popup.hide();
          } else if (!popup.state.isVisible) {
            popup.show();
          }

          popup.setProps({
            getReferenceClientRect: () =>
              (props.clientRect && props.clientRect()) || new DOMRect(),
          });
        },

        onKeyDown(props) {
          if (!component || !component.ref || !tippy) {
            return false;
          }

          if (props.event.key === "Escape") {
            popup.hide();
            return true;
          }

          return (component.ref as SuggestionRef).onKeyDown(props) || false;
        },

        onExit() {
          if (popup?.destroy) {
            popup.destroy();
          }

          if (component) {
            component.destroy();
          }
        },
      };
    },
    command: ({ editor, range, props }) => {
      // somehow the parsing doesn't get triggered, we need to set the mark manually
      editor
        .chain()
        .focus()
        .insertContentAt(range, suggestionPrefix ?? "")
        .setMark(name, { href: props })
        .insertContent(props)
        .unsetMark(name)
        .insertContent(suggestionSuffix ?? " ")
        .run();
    },
  });
};

import { useQueryClient } from '@tanstack/react-query'
import {
  type Note,
  type Change,
  NoteType,
  isPrivateNote,
} from '../utils/syncUtils'
import { cacheKeyFromNoteType, noteQueryKey } from '../utils/queryKeyFactory'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AuthType } from '../utils/User'
import {
  cachedNotesContext,
  getCachedData,
  updateCache,
} from '../providers/CachedNotesProvider'
import * as supabase from '../lib/supabase/noteOperations'
import * as cloudkit from '../lib/cloudkit/noteOperations'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'

export function useSaveNote(successCallback?: (data: Note) => void) {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  // TODO should always return a note. if undefined, throw an error
  return useSafeMutation<Note, Error, Change>({
    mutationFn: (change: Change) => {
      const queryKey = noteQueryKey(change)
      // always get the latest version of the RecordChangeTag
      // TODO use cachedNotesQueryClient
      let note = queryClient.getQueryData<Note>(queryKey)

      // Used by starter note creator, because we don't create the note by opening the calendar date
      if (change.forceCreate) {
        note = {
          recordName: change.recordName,
          noteType: change.noteType,
          parent: change.parent, // Assign ID of parent in case it's supabase, ignored by CloudKit
          content: change.content,
          filename: change.filename,
        }
      }

      if (!note) {
        console.error('[useSaveNote] Note not found', change)
        trackEvent('web.useSaveNote.error', {
          message: 'Note not found',
          change,
        })
        throw new Error('Note not found')
      }

      // console.debug('[useSaveNote] saving', note, change, user)

      switch (user.authType) {
        case AuthType.CLOUDKIT: {
          return cloudkit.saveNote(
            note,
            change.content,
            change.attachments,
            change.modificationDate
          )
        }
        case AuthType.SUPABASE: {
          return supabase.saveNote(
            user.teamUserId,
            note,
            change.content,
            change.attachments
          )
        }
        case AuthType.CLOUDKIT_SUPABASE: {
          return isPrivateNote(change.noteType)
            ? cloudkit.saveNote(
                note,
                change.content,
                change.attachments,
                change.modificationDate
              )
            : supabase.saveNote(
                user.teamUserId,
                note,
                change.content,
                change.attachments
              )
        }
      }
    },

    onMutate: (change) => {
      if (!change.recordName) return

      const queryKey = noteQueryKey(change)
      const note = queryClient.getQueryData<Note>(queryKey)

      // Optimistically update the note in cache
      if (note) {
        note.content = change.content
        note.fileModifiedAt = change.modificationDate
        queryClient.setQueryData(noteQueryKey(change), note)
      }

      const cacheKey = cacheKeyFromNoteType(change.noteType, user)
      const previousNotes = getCachedData(cachedNotesQueryClient, cacheKey)

      // Optimistically update the note in the general cache, that's where we will fetch it when opening the note.
      const previousNote = previousNotes.get(change.recordName)
      if (previousNote) {
        const updatedNote = {
          ...previousNote,
          content: change.content,
          modificationDate: change.modificationDate,
        }
        updateCache(cachedNotesQueryClient, [updatedNote], cacheKey)
      }
    },

    onSuccess: (note, change) => {
      trackEvent('WEB - Note Updated', {
        recordName: change.recordName,
        noteType: NoteType[change.noteType],
        parent: change.parent,
        filename: change.filename,
      })

      queryClient.setQueryData(noteQueryKey(change), note)
      const cacheKey = cacheKeyFromNoteType(note.noteType, user)
      updateCache(cachedNotesQueryClient, [note], cacheKey)

      successCallback?.(note)
    },
    onError: (error, change) => {
      trackEvent('WEB - Note Updated Failed', {
        error,
      })
      if (error.message.includes('oplock error')) {
        trackEvent('WEB - Note Updated Failed - Oplock Error', {
          error,
        })

        // eslint-disable-next-line no-console
        console.log('[useSaveNote] Conflict detected, refetch note.')

        // Refetch the note upon error, so the next time it works. This would be merged once we receive it in TipTapEditor
        void queryClient.invalidateQueries({
          queryKey: noteQueryKey(change),
          refetchType: 'all',
        })
      }
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}

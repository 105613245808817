import { createClickableMark } from "../api/createClickableMark";
import { MantineThemeOverride } from "@mantine/core";

export const WikiLink = (
  onWikiLinkClicked?: (event: MouseEvent) => void,
  onLoadWikiLinks?: (
    prefix: string,
    keyword: string
  ) => Array<string | { display: string; paste: string }>,
  theme?: MantineThemeOverride
) =>
  createClickableMark({
    name: "wikilink",
    suggestionChar: "[[",
    suggestionPrefix: "[[",
    suggestionSuffix: "]]",
    allowSpaces: true,
    regex: /(\[{2})(.*?)(\]{2})/g,
    attrsMap: {
      wikilink: 2,
      href: 2,
    },
    onMarkClicked: onWikiLinkClicked,
    onLoadSuggestionItems: onLoadWikiLinks,
    theme: theme,
  });

/* eslint-disable no-console */
import CloudKit from 'tsl-apple-cloudkit'
import { Note } from '../../utils/syncUtils'
import { container, database } from '../CloudKitClient'
import { QueryClient } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import {
  cacheKeyFromNoteType,
  cacheKeys,
  noteQueryKey,
  privateKeys,
} from '../../utils/queryKeyFactory'
import { fetchNoteByRecordName } from './fetch'
import { trackEvent } from '../analytics'
import { AuthenticatedUser } from '../../utils/User'
import {
  removeFromCacheByRecordNames,
  updateCache,
} from '../../providers/CachedNotesProvider'

// This is used to skip notifications, because we get a lot of notifications when we save a lot of notes, especially when we update the titles
// And each notification causes a fetch of the same note and a sidebar update. This is not going well, if thousands of notes are updated at once.
// TODO add expiration to the tags, so they don't stay in the array forever and cause memory issues
const skipNotificationChangeTags: string[] = []
const cachedNotifications = new Map<string, CloudKit.QueryNotification>()
let isRegisteringForNotifications = false

function shouldBlockNotificationTag(tag: string): boolean {
  const index = skipNotificationChangeTags.indexOf(tag)
  if (index !== -1) {
    skipNotificationChangeTags.splice(index, 1)
    // console.debug('[CloudKit] Notification blocked')
    return true
  }
  return false
}

function getTag(updatedNote: Note): string | undefined {
  if (!updatedNote.recordChangeTag || !updatedNote.recordName) return
  return updatedNote.recordName + '_' + updatedNote.recordChangeTag
}

function shouldBlockNotification(updatedNote: Note): boolean {
  const tag = getTag(updatedNote)
  if (!tag) return false
  return shouldBlockNotificationTag(tag)
}

export function blockNextNotificationTag(tag: string) {
  skipNotificationChangeTags.push(tag)
}

export function blockNextNotification(updatedNote: Note) {
  const tag = getTag(updatedNote)
  if (!tag) return
  blockNextNotificationTag(tag)
}

export async function registerForNotifications(
  user: AuthenticatedUser,
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient,
  setIsRegistered: (isRegistered: boolean) => void
) {
  if (isRegisteringForNotifications) {
    // console.debug('[CloudKit] already registering for notifications')
    return
  }
  isRegisteringForNotifications = true

  const handleNotification = (notification: CloudKit.Notification) => {
    // console.log('We got a new notification', notification.notificationID)
    if (notification.isQueryNotification) {
      cachedNotifications.set(
        notification.notificationID,
        notification as CloudKit.QueryNotification
      )
      processNotifications()
    }
  }

  const processNotifications = debounce(() => {
    for (const [id, notification] of cachedNotifications.entries()) {
      void processNotification(
        notification,
        queryClient,
        cachedNotesQueryClient,
        user
      )
      cachedNotifications.delete(id)
    }
  }, 300)

  try {
    if (!container) {
      setIsRegistered(false)
      return
    }

    container.removeNotificationListener(handleNotification)
    container.unregisterForNotifications()
    await container.registerForNotifications()
    container.addNotificationListener(handleNotification)
    // subscribing to changes have be done after registering for notifications
    await subscribeToNoteChanges()
    setIsRegistered(true)
  } catch (error) {
    console.error(error)
    setIsRegistered(false)
  }
  isRegisteringForNotifications = false
}

export async function processNotification(
  notification: CloudKit.QueryNotification,
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient,
  user: AuthenticatedUser
) {
  let updatedNote: Note | undefined
  const isDeletion =
    notification.queryNotificationReason ===
    'QUERY_NOTIFICATION_REASON_RECORD_DELETED'

  // Load the full note if it's not a deletion
  if (!isDeletion) {
    try {
      updatedNote = await fetchNoteByRecordName(notification.recordName)
    } catch {
      console.error(
        '[CloudKit] No updated note found for notification',
        notification
      )
      trackEvent('web.processNotification.error', {
        message: 'No updated note found for notification',
        notification,
      })
      return
    }
  }

  // don't process local deletion
  // because we don't have the change tag, we just use the record name
  if (shouldBlockNotificationTag(notification.recordName)) {
    return
  }

  // don't process local creation and updates
  if (!isDeletion && updatedNote && shouldBlockNotification(updatedNote)) return

  console.debug(
    '[CloudKit] Processing notification',
    notification.queryNotificationReason,
    notification.recordName
  )

  switch (notification.queryNotificationReason) {
    case 'QUERY_NOTIFICATION_REASON_RECORD_CREATED':
    case 'QUERY_NOTIFICATION_REASON_RECORD_UPDATED': {
      if (!updatedNote) return
      const queryKey = noteQueryKey(updatedNote)
      queryClient.setQueryData<Note>(queryKey, () => updatedNote)

      const cacheKey = cacheKeyFromNoteType(updatedNote.noteType, user)
      updateCache(cachedNotesQueryClient, [updatedNote], cacheKey)
      break
    }

    case 'QUERY_NOTIFICATION_REASON_RECORD_DELETED': {
      const data = queryClient.getQueriesData<Note>(privateKeys.notes)
      for (const [queryKey, note] of data) {
        if (notification.recordName === note?.recordName) {
          queryClient.removeQueries(queryKey)
        }
      }

      removeFromCacheByRecordNames(
        cachedNotesQueryClient,
        [notification.recordName],
        cacheKeys.privateNotes(user.userId)
      )
      break
    }
  }
}

const subscription: CloudKit.Subscription = {
  subscriptionID: 'note-changes',
  subscriptionType: 'query',
  zoneID: {
    zoneName: '_zoneWide',
  },
  firesOn: ['create', 'update', 'delete'],
  zoneWide: true,
  query: {
    recordType: 'Note',
    filterBy: [],
  },
}

async function subscribeToNoteChanges() {
  if (!database) {
    return
  }
  const deleteResponse = await database.deleteSubscriptions([subscription])
  if (deleteResponse.hasErrors) {
    console.error(deleteResponse.errors[0])
  }

  const saveResponse = await database.saveSubscriptions([subscription])

  if (saveResponse.hasErrors) {
    console.error(saveResponse.errors[0])
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw saveResponse.errors[0]
  }
}
/* eslint-enable no-console */

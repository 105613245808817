import {
  Block,
  BlockNoteEditor,
  BlockSchema,
  PartialBlock,
} from "@packages/blocknote-core";
import { ActionIcon, Group, Menu } from "@mantine/core";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  AiOutlineCaretDown,
  AiOutlineCaretRight,
  AiOutlinePlus,
} from "react-icons/ai";
import { MdDragIndicator } from "react-icons/md";
import { DefaultDragHandleMenu } from "./DefaultDragHandleMenu";
import { DragHandleMenuProps } from "./DragHandleMenu";
import { TooltipContent } from "../../SharedComponents/Tooltip/components/TooltipContent";
import Tippy from "@tippyjs/react";

export type BlockSideMenuProps<BSchema extends BlockSchema> = {
  editor: BlockNoteEditor<BSchema>;
  block: Block<BSchema>;
  dragHandleMenu?: FC<DragHandleMenuProps<BSchema>>;
  addBlock: (shouldAddNewLine: boolean) => void;
  blockDragStart: (event: DragEvent) => void;
  blockDragEnd: () => void;
  freezeMenu: () => void;
  unfreezeMenu: () => void;
};

export const BlockSideMenu = <BSchema extends BlockSchema>(
  props: BlockSideMenuProps<BSchema>
) => {
  const [dragHandleMenuOpened, setDragHandleMenuOpened] =
    useState<boolean>(false);
  const [blockId, setBlockId] = useState<string>(props.block.id);
  const prevBlockId = useRef<string>(props.block.id);
  const [folded, setFolded] = useState<boolean>(props.block.props.folded);
  const foldableTypesWithChildren = [
    "bulletListItem",
    "numberedListItem",
    "checkListItem",
    "taskListItem",
  ];
  const canFold =
    props.block.type === "heading" ||
    (foldableTypesWithChildren.includes(props.block.type.toString()) &&
      props.block.children.length > 0);

  const dragHandleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const dragHandle = dragHandleRef.current;
    if (dragHandle instanceof HTMLDivElement) {
      dragHandle.addEventListener("dragstart", props.blockDragStart);
      dragHandle.addEventListener("dragend", props.blockDragEnd);

      return () => {
        dragHandle.removeEventListener("dragstart", props.blockDragStart);
        dragHandle.removeEventListener("dragend", props.blockDragEnd);
      };
    }

    return;
  }, [props.blockDragEnd, props.blockDragStart]);

  const toggleFold = useCallback(() => {
    if (canFold) {
      props.editor.updateBlock(blockId, {
        props: {
          folded: !folded,
        },
      } as PartialBlock<BSchema>);
      setFolded(!folded);
    }
  }, [canFold, blockId, folded, props.editor]);

  useEffect(() => {
    setBlockId(props.block.id);
    setFolded(props.block.props.folded);
  }, [props.block.id, props.block.props.folded]);

  useEffect(() => {
    if (prevBlockId.current !== props.block.id) {
      closeMenu();
    }
    prevBlockId.current = props.block.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.block.id]);

  const closeMenu = () => {
    setDragHandleMenuOpened(false);
    props.unfreezeMenu();
  };

  const DragHandleMenu = props.dragHandleMenu || DefaultDragHandleMenu;

  return (
    <Group spacing={0}>
      {canFold ? (
        folded ? (
          <ActionIcon size={24} data-test={"dragHandleFold"}>
            {<AiOutlineCaretRight size={24} onClick={() => toggleFold()} />}
          </ActionIcon>
        ) : (
          <ActionIcon size={24} data-test={"dragHandleFold"}>
            {<AiOutlineCaretDown size={24} onClick={() => toggleFold()} />}
          </ActionIcon>
        )
      ) : (
        <ActionIcon size={24} data-test={"dragHandleAdd"}>
          {
            <AiOutlinePlus
              size={24}
              onClick={() => {
                props.addBlock(false);
              }}
            />
          }
        </ActionIcon>
      )}
      <Menu opened={dragHandleMenuOpened} width={220} position={"bottom-start"}>
        <Menu.Target>
          <div draggable="true" ref={dragHandleRef}>
            <Tippy
              content={
                <TooltipContent
                  showMainTooltip={true}
                  mainTooltip="Drag to move"
                  secondaryTooltip="Click for menu"
                />
              }
              placement="bottom"
              delay={[500, 0]}
              duration={0}
              trigger={"mouseenter"}>
              <ActionIcon
                onClick={() => {
                  setDragHandleMenuOpened(true);
                  props.freezeMenu();
                }}
                size={24}
                data-test={"dragHandle"}>
                {<MdDragIndicator size={24} />}
              </ActionIcon>
            </Tippy>
          </div>
        </Menu.Target>
        <DragHandleMenu
          editor={props.editor}
          block={props.block}
          closeMenu={closeMenu}
          addBlock={props.addBlock}
        />
      </Menu>
    </Group>
  );
};

import { type Dayjs } from 'dayjs'
import { type Block, type BlockSchema } from '@packages/blocknote-core'
import { convertTo24HourFormat } from '../../utils/time'
import { calculateEventPosition } from './timeline/utils/event'

export type CalendarEvent = {
  id: string
  contentType: string
  content: string
  startDate: Dayjs
  endDate: Dayjs
  position: string
  checked?: boolean
  cancelled?: boolean
  startDateMatch?: string
  endDateMatch?: string
  fullDate?: string
  allDay?: boolean
  description?: string
  color?: string
  calendarName?: string
  calendarId?: string
}

type ProcessedResult = {
  startDateMatch: string
  endDateMatch: string
  fullDate: string
  startDate: Dayjs
  endDate: Dayjs
  position: string
}

export function processEventsInBlock(
  block: Block<BlockSchema>,
  selectedDay: Dayjs
) {
  const validTypes = [
    'taskListItem',
    'checkListItem',
    'bulletListItem',
    'heading',
  ]
  let events: CalendarEvent[] = []

  // Canceled tasks or checklists are not counted as timeblocks and shouldn't be displayed
  if (validTypes.includes(block.type) && !block.props.cancelled) {
    let timeBlock: ProcessedResult | undefined
    let eventContent = ''

    for (const inlineContent of block.content) {
      if (inlineContent.type !== 'text') continue

      const match =
        inlineContent.styles.timeString &&
        /\d{1,2}\s*:\s*\d{2}\s*(?:AM|PM)?\s*-\s*\d{1,2}\s*:\s*\d{2}\s*(?:AM|PM)?/.exec(
          inlineContent.text
        )

      if (match) {
        const fullTime = match[0].split('-')
        const startTime: string = fullTime[0]
        const endTime: string = fullTime[1]
        const is24hours = !/AM|PM/.test(startTime)
        const start24Hour = is24hours
          ? startTime
          : convertTo24HourFormat(startTime)
        const end24Hour = is24hours
          ? endTime
          : convertTo24HourFormat(endTime.trim())
        const startDateTime = selectedDay
          .set('hour', Number.parseInt(start24Hour.split(':')[0], 10))
          .set('minute', Number.parseInt(start24Hour.split(':')[1], 10))
          .set('second', 0)
          .set('millisecond', 0)
        let endDateTime = selectedDay
          .set('hour', Number.parseInt(end24Hour.split(':')[0], 10))
          .set('minute', Number.parseInt(end24Hour.split(':')[1], 10))
          .set('second', 0)
          .set('millisecond', 0)
        if (startDateTime > endDateTime) {
          endDateTime = startDateTime.add(15, 'minute')
        }

        timeBlock = {
          startDateMatch: startTime,
          endDateMatch: endTime,
          fullDate: inlineContent.text,
          startDate: startDateTime,
          endDate: endDateTime,
          position: calculateEventPosition(
            startDateTime,
            endDateTime,
            selectedDay
          ),
        }
      } else {
        eventContent += `${inlineContent.text} `
      }
    }

    if (timeBlock && eventContent.trim().length > 0) {
      events.push({
        ...timeBlock,
        content: eventContent.trim(),
        id: block.id,
        contentType: block.type,
        checked: block.props.checked as boolean,
        cancelled: block.props.cancelled as boolean,
      })
    }
  }

  for (const child of block.children) {
    events = [...events, ...processEventsInBlock(child, selectedDay)]
  }

  return events
}

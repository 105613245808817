import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CheckIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Toggle from '../../components/Toggle'
import { useUserState } from '../../providers/UserProvider'
import { trackEvent } from '../../lib/analytics'
import { CheckoutForm } from './StripeCheckout'

// Production key
const stripePromise = loadStripe(
  'pk_live_51LNe8pD2RtXCdIzHTNtlWBfkkoIDVCmQmK8LFITYn08VOor8rYQSelXXZU7OJDAuSQo1mbAtkKq6OvSufIMZrwjH00rt6blgq2'
)

// const stripePromise = loadStripe(
//   'pk_test_51LNe8pD2RtXCdIzHFRRBjpon510YaSFdWn406RT08wBQTkdvF1N6u4j1EbTAAbOf6Hm3rb5A4l2Oco3YUgYOwZAn00ALUoj0r2'
// ) // Test key

function reloadPage() {
  window.location.reload()
}

export function CheckoutDialog({
  open,
  setOpen,
  isGuest,
  stripeCustomerId,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  isGuest: boolean
  stripeCustomerId?: string
}) {
  const [isAnnualToggled, setIsAnnualToggled] = useState(true)
  const [isSmallTeamToggled, setIsSmallTeamToggled] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [xCallbackUrl, setXCallbackUrl] = useState<string | null>(null)
  const user = useUserState()
  const isSupabase = !user?.cloudKitUserId && Boolean(user?.supabaseUserId)

  const handleAnnualToggle = (newState: boolean) => {
    setIsAnnualToggled(newState)
    // Additional actions when the toggle state changes
  }

  const handleSmallTeamToggle = (newState: boolean) => {
    // If we are not logged into supabase, we can't subscribe to the small team plan
    if (newState && !user?.supabaseUserId) {
      // eslint-disable-next-line no-alert -- quick fix for now
      alert(
        'Please log into the teamspaces to subscribe to the team plan (Settings > Login to Teamspaces).'
      )
      return
    }

    setIsSmallTeamToggled(newState)
    setIsAnnualToggled(false) // Only monthly plan for small teams available for now
  }

  const handleCloudKitRedirect = () => {
    if (xCallbackUrl) {
      window.location.href = xCallbackUrl
    }
  }

  useEffect(() => {
    if (user && open) {
      trackEvent('WEB - Checkout Dialog Opened')
    }
  }, [open, user])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen} static>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${
                  isSuccessful ? 'sm:max-w-xl' : 'sm:max-w-4xl'
                } sm:p-6`}
              >
                <button
                  type='button'
                  onClick={() => {
                    setOpen(false)
                  }}
                  className='absolute right-4 top-4 bg-transparent'
                >
                  <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                </button>
                {isSuccessful ? (
                  <>
                    <div className='text-center'>
                      <CheckIcon
                        className='mx-auto h-12 w-12 text-green-500'
                        aria-hidden='true'
                      />
                      <h3 className='mt-2 text-lg font-medium leading-6 text-gray-900'>
                        Successfully Subscribed
                      </h3>
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                          Thank you for subscribing to NotePlan!<br></br>Please
                          activate your subscription by clicking below.
                        </p>
                        <button
                          onClick={reloadPage}
                          className='inline-flex items-center rounded-md bg-amber-600 px-4 py-2 mt-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2'
                        >
                          Continue
                        </button>
                      </div>
                      {/* {user?.cloudKitUserId && xCallbackUrl && ( */}
                      {xCallbackUrl && (
                        <div className='mt-10 flex items-center justify-between gap-3 border-t pt-4'>
                          <div>Using native app?</div>
                          <button
                            onClick={handleCloudKitRedirect}
                            className='inline-flex items-center rounded-md border border-gray-200 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
                          >
                            Activate Subscription in App →
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='mb-6 pt-12 text-center'>
                      <h2 className='text-3xl font-semibold text-gray-900'>
                        {isGuest
                          ? `Don't miss out on NotePlan's best features!`
                          : 'Unlock continued access to NotePlan'}
                      </h2>
                      <p className='text-gray-600'>
                        {isGuest
                          ? 'Upgrade now for filters, private calendar notes, project notes & complete control.'
                          : 'Continue using NotePlan without interruption by subscribing today.'}
                      </p>
                    </div>
                    <div className='mb-3 flex w-full justify-center'>
                      <div>
                        <Toggle
                          isChecked={isAnnualToggled}
                          isEnabled={!isSmallTeamToggled} // For now only monthly team plan
                          onToggle={handleAnnualToggle}
                          label='Annual Plan'
                          description='Save 10% off'
                        />
                      </div>
                      {!isGuest && (
                        <div className='ml-4'>
                          <Toggle
                            isChecked={isSmallTeamToggled}
                            isEnabled
                            onToggle={handleSmallTeamToggle}
                            label='Small Team'
                            description='up to 5 Members'
                          />
                        </div>
                      )}
                    </div>
                    <div className='grid grid-cols-2 gap-1'>
                      <div>
                        <div className='w-full bg-white p-4 pt-0 sm:p-8'>
                          {/* <h5 className="mb-4 text-xl font-medium text-gray-500 no-underline">Standard plan</h5> */}
                          <div className='flex items-baseline text-gray-900'>
                            <span className='text-3xl font-semibold'>$</span>
                            <span className='text-5xl font-extrabold tracking-tight'>
                              {isAnnualToggled
                                ? isSmallTeamToggled
                                  ? 199
                                  : 99
                                : isSmallTeamToggled
                                  ? 19.99
                                  : 9.99}
                            </span>
                            <span className='ms-1 text-xl font-normal text-gray-500'>
                              {isAnnualToggled ? '/year' : '/month'}
                            </span>
                          </div>
                          <ul className='my-7 space-y-5'>
                            {isSupabase ||
                              (isSmallTeamToggled && (
                                <li className='flex items-start'>
                                  <i className='fas fa-info-circle h-4 w-4 flex-shrink-0 rounded-full pt-0.5 text-blue-600' />
                                  <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                    <span>
                                      Beta version available for iOS and macOS
                                    </span>
                                    <p className='mt-1 text-sm opacity-80'>
                                      Join our TestFlight beta to sync with
                                      native apps.{' '}
                                      <a
                                        href='https://testflight.apple.com/join/O10uVN0K'
                                        className='text-blue-600 hover:underline'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        Get TestFlight access here
                                      </a>
                                    </p>
                                  </span>
                                </li>
                              ))}
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Notes, tasks and calendar all in one place{' '}
                              </span>
                            </li>
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Flexible daily & weekly planning views
                              </span>
                            </li>
                            {!isSupabase && (
                              <li className='flex items-start'>
                                <i className='fas fa-check-circle pt-0.5 text-green-600' />

                                <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                  Support all devices: Web, Mac, iPad, iPhone
                                </span>
                              </li>
                            )}
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Integrated with Google Calendar
                              </span>
                            </li>
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Backlinking and bi-directional links for
                                interconnected notes
                              </span>
                            </li>
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Tags, mentions, and filter for easy organization
                              </span>
                            </li>
                            <li className='flex items-start'>
                              <i className='fas fa-check-circle pt-0.5 text-green-600' />
                              <span className='ms-3 text-base font-normal leading-tight text-gray-500'>
                                Markdown support for efficient note-taking
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='border-l dark:border-gray-200'>
                        <div className='h-full overflow-auto'>
                          <div>
                            <Elements stripe={stripePromise}>
                              <CheckoutForm
                                duration={isAnnualToggled ? 'y' : 'm'}
                                isSmallteam={isSmallTeamToggled}
                                setIsSuccessful={setIsSuccessful}
                                setXCallbackUrl={setXCallbackUrl}
                                stripeCustomerId={stripeCustomerId}
                              />
                            </Elements>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

let changeTags: string[] = []

export const addTag = (tag: string) => {
  changeTags.push(tag)
}

export const removeTag = (tag: string) => {
  changeTags = changeTags.filter((t) => t !== tag)
}

export const hasTag = (tag: string) => {
  return changeTags.includes(tag)
}

import dayjs, { type Dayjs } from 'dayjs'
import { type Dispatch, type SetStateAction } from 'react'
import { type CalendarEvent } from '../../CalendarEvent'
import { type PlaceholderEvent } from '../types'

function adjustEventDates(
  eventStartDate: Dayjs,
  eventEndDate: Dayjs,
  selectedDay: Dayjs
) {
  const clonedDate = selectedDay.clone()
  const startOfDay = clonedDate.startOf('day')
  const endOfDay = clonedDate.endOf('day')

  let startDate = eventStartDate.isBefore(startOfDay)
    ? startOfDay
    : eventStartDate.clone()
  let endDate = eventEndDate.isAfter(endOfDay) ? endOfDay : eventEndDate.clone()

  if (startDate.isBefore(startOfDay) && endDate.isAfter(endOfDay)) {
    startDate = startOfDay
    endDate = endOfDay
  }

  return { startDate, endDate }
}

export function calculateOffsetAndHeight(
  event: CalendarEvent | PlaceholderEvent,
  segmentHeight: number,
  selectedDay: Dayjs
) {
  const remainingMinutesTop = event.startDate.minute() % 15
  const offsetPxTop = (remainingMinutesTop * segmentHeight) / 15
  const { startDate, endDate } = adjustEventDates(
    event.startDate,
    event.endDate,
    selectedDay
  )
  const eventHeight = Math.max(
    (endDate.diff(startDate, 'minute') * segmentHeight + offsetPxTop) / 15,
    segmentHeight / 2 + 2
  )

  return { eventHeight, offsetPxTop }
}

export function calculateEventPosition(
  startDateTime: Dayjs,
  endDateTime: Dayjs,
  selectedDay: Dayjs
): string {
  const { startDate, endDate } = adjustEventDates(
    startDateTime,
    endDateTime,
    selectedDay
  )
  const row =
    Math.floor(
      startDate.diff(startDate.clone().startOf('day'), 'minute') / 15
    ) + 2
  const span = Math.ceil(endDate.clone().diff(startDate, 'minute') / 15)

  return `${row.toString()} / span ${span.toString()}`
}

export function cleanUpDraggedEvent(
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
) {
  setCalendarEventDrag(null)

  const elementToDelete = document.getElementById('dragged-event-image')
  if (elementToDelete) {
    elementToDelete.parentNode?.removeChild(elementToDelete)
  }
}

function getDropPosition(event) {
  const rect = event.target.getBoundingClientRect()
  const offsetX = event.clientX - rect.left
  const offsetY = event.clientY - rect.top
  return { x: offsetX, y: offsetY }
}

export function getRowNumber(eventDate) {
  const startOfDay = eventDate.startOf('day')
  const minutesSinceMidnight = eventDate.diff(startOfDay, 'minute')
  return Math.floor(minutesSinceMidnight / 15) + 2
}

export function getRowNumberAndDate(event) {
  const position = getDropPosition(event)
  const boundingClientRect = event.target.getBoundingClientRect()
  const segmentHeight = boundingClientRect.height / 97
  const percentOfTimeline =
    (position.y - segmentHeight) / boundingClientRect.height
  const hour = Math.floor(percentOfTimeline * 24.5)
  const minute = Math.floor((percentOfTimeline * 24.5 - hour) * 60)
  const weekDate = dayjs(event.target.getAttribute('data-date'), 'YYYY-MM-DD')

  let eventDate = weekDate.hour(hour).minute(minute)
  // Add 15min
  eventDate = eventDate.add(-25, 'minute')

  const lastHour = weekDate.hour(23).minute(30)
  if (eventDate.isAfter(lastHour)) {
    eventDate = lastHour
  }
  const rowNumber = getRowNumber(eventDate)

  const eventDateRoundedTo15Minutes = eventDate.minute(
    eventDate.minute() - (eventDate.minute() % 15)
  )

  return { rowNumber, eventDateRoundedTo15Minutes }
}
export const getSpan = (newStartDate, newEndDate) =>
  Math.ceil(newEndDate.diff(newStartDate, 'minute') / 15)

import { useUserState } from '../providers/UserProvider'
import { acceptInvitation as supabaseAcceptInvitation } from '../lib/supabase/invitation'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'

export function useAcceptInvitation(): {
  acceptInvitation: (_teamspaceID: string) => void
  isLoading: boolean
  error: Error | null
} {
  const user = useUserState()

  const mutation = useSafeMutation<boolean, Error, { teamspaceID: string }>({
    mutationFn: async ({ teamspaceID }) => {
      if (teamspaceID && user?.supabaseUserId) {
        const result = await supabaseAcceptInvitation(teamspaceID)
        trackEvent('WEB - Team Member Invitation Accepted', {
          teamspaceID,
          supabaseUserId: user.supabaseUserId,
        })
        window.location.href = '/' // When we are successful, reload the page so we get rid of the pathname with the teamspace ID
        return result
      }
      throw new Error('Invalid teamspace ID or user ID')
    },
  })

  const acceptInvitation = (teamspaceID: string) => {
    mutation.mutate({ teamspaceID })
  }

  return {
    acceptInvitation,
    error: mutation.error,
    isLoading: mutation.isLoading,
  }
}

import { useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { type LinkMarkSuggestionItem } from '@packages/blocknote-core'
import { useCreateNote } from '../../hooks/useCreateNote'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import {
  type SelectedDate,
  dateToWeek,
  selectedDateToKey,
  useSelectedDate,
} from '../../providers/SelectedDateProvider'
import { useNoteReferences, ReferenceType } from '../../hooks/useNoteReferences'
import useNote, { type useNoteConfig } from '../../hooks/useNote'
import {
  type Note,
  NoteType,
  filenameToKey,
  isCalendarNote,
  isTeamspaceNote,
} from '../../utils/syncUtils'
import { tagList } from '../../utils/tagList'
import { noteList } from '../../utils/noteList'
import { dateLinkSuggestions } from '../../utils/dateLinkSuggestionParser'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { useSelectedRecordName } from '../../providers/SelectedRecordNameProvider'
import {
  usePrivateProjectNotesByTitle,
  useTeamProjectNotesByTitle,
} from '../../hooks/useNotesFactory'
import { TipTapEditor } from './TipTapEditor'
import { WeekNoteReference } from './WeekNoteReference'
import { NoteReference } from './NoteReference'
import { NoteHeader } from './NoteHeader'
import { filterMap } from '../../utils/commons'
import { FromNote } from '../../providers/ReferencesProvider'

type Props = {
  showCalendar: boolean
  toggleCalendar: () => void
  onCommandBarOpen: (visible: boolean, search: string) => void
  isSubscribed: boolean
  isGuest: boolean
  isTrialLoading: boolean
  isTrialRunning?: string
  setDialogOpen: (value: boolean) => void
}

export default function NoteEditor({
  showCalendar,
  toggleCalendar,
  onCommandBarOpen,
  isSubscribed,
  isGuest,
  isTrialRunning,
  isTrialLoading,
  setDialogOpen,
}: Props) {
  const selectedRecordName = useSelectedRecordName()
  const {
    noteKey,
    handleSelectRecordName,
    handleRevealNote,
    shouldForceUpdateEditor,
    notes: notesMap,
  } = useSidebarProvider()
  const { isLoading, data: note } = useNote(noteKey)
  const selectedDate = useSelectedDate()
  const [needsUpload, setNeedsUpload] = useState(false)
  const selectedDay: SelectedDate = useMemo(
    () => ({
      active: 'day',
      date: selectedDate.date,
      ...dateToWeek(selectedDate.date),
    }),
    [selectedDate]
  )
  const weekNoteKey: useNoteConfig | undefined = useMemo(() => {
    if (
      noteKey &&
      isCalendarNote(noteKey.noteType) &&
      selectedDate.active === 'day'
    ) {
      // because selectedDate can store a different day and week, we need always the selected day for the key
      const key = selectedDateToKey({ ...selectedDay, active: 'week' })
      return { ...noteKey, recordName: 'weekly', filename: key, key }
    }
    return
  }, [noteKey, selectedDate.active, selectedDay])
  const { isLoading: isLoadingWeek, data: weekNote } = useNote(weekNoteKey)

  // # region references
  const noteReferences = useNoteReferences(ReferenceType.ALL)
  const filteredNoteReferences = useMemo(() => {
    if (!note) return new Map<string, FromNote>()
    const noteID = isCalendarNote(note.noteType)
      ? filenameToKey(note.filename)
      : note.recordName ?? selectedRecordName

    const incomingReferences =
      noteReferences.get(noteID)?.incoming ?? new Map<string, FromNote>()

    return filterMap(
      incomingReferences,
      (fromNote) => fromNote.blocks.length > 0
    )
  }, [noteReferences, note, selectedRecordName])
  // # endregion

  // # region mark
  const { data: privateNotesByTitle } = usePrivateProjectNotesByTitle()
  const { data: teamNotesByTitle } = useTeamProjectNotesByTitle()
  const createNoteMutation = useCreateNote((note: Note) => {
    handleRevealNote(note.recordName)
  })
  const selectedDateDispatch = useSelectedDateDispatch()

  // When a user clicks on a mark such as a hashtag, wikilink, datelink...
  const handleMarkClicked = useCallback(
    (event: MouseEvent) => {
      const element = event.target as HTMLElement

      if (Object.hasOwn(element.dataset, 'hashtag')) {
        onCommandBarOpen(true, `tag: ${element.innerText}`)
      } else if (Object.hasOwn(element.dataset, 'wikilink')) {
        const wikiLink = element.innerText
        const notes = isTeamspaceNote(noteKey.noteType)
          ? teamNotesByTitle?.get(wikiLink)
          : privateNotesByTitle?.get(wikiLink)
        const altNotes = isTeamspaceNote(noteKey.noteType)
          ? privateNotesByTitle?.get(wikiLink)
          : teamNotesByTitle?.get(wikiLink)
        // attempt to open the 'closest' note first
        const note =
          (notes && notes.length > 0 && notes[0]) ||
          (altNotes && altNotes.length > 0 && altNotes[0]) ||
          undefined

        if (note?.recordName) {
          handleSelectRecordName(note.recordName)
        } else {
          const recordName = uuid()
          createNoteMutation.mutate({
            content: `# ${wikiLink}`,
            filename: wikiLink,
            parent: noteKey.parent,
            recordName,
            noteType: isTeamspaceNote(noteKey.noteType)
              ? NoteType.TEAM_SPACE_NOTE
              : NoteType.PROJECT_NOTE,
            isDir: false,
          })
          handleSelectRecordName(recordName)
        }
      } else if (Object.hasOwn(element.dataset, 'datelink')) {
        const dateLink = element.innerText
        // if (dateLink === '@today') {
        //   selectedDateDispatch({ type: 'today', forceDay: true })
        // } else if (dateLink === '@tomorrow') {
        //   selectedDateDispatch({ type: 'add', amount: 1, unit: 'day' })
        // } else if (dateLink === '@yesterday') {
        //   selectedDateDispatch({ type: 'subtract', amount: 1, unit: 'day' })
        // } else {
        const weekRegex = />(\d{4})-W(\d{1,2})/
        const matches = weekRegex.exec(dateLink)

        if (matches) {
          const year = Number.parseInt(matches[1])
          const week = Number.parseInt(matches[2])

          selectedDateDispatch({ type: 'setWeek', year, week })
        } else {
          const cleanedDateLink = dateLink.replace(/[@><]/g, '')
          const date = dayjs(cleanedDateLink)

          if (date.isValid()) {
            selectedDateDispatch({ type: 'setDay', date })
          }
        }
        // }
      }
    },
    [
      onCommandBarOpen,
      noteKey?.noteType,
      noteKey?.parent,
      teamNotesByTitle,
      privateNotesByTitle,
      handleSelectRecordName,
      createNoteMutation,
      selectedDateDispatch,
    ]
  )

  const handleLoadSuggestions = useCallback(
    (prefix: string, keyword: string): (string | LinkMarkSuggestionItem)[] => {
      // Implemented logic to load hashtags
      const notes = [...notesMap.values()].filter(
        (note) => !note.isFolder && !note.filename.startsWith('@')
      )

      if (['@', '#'].includes(prefix)) {
        return tagList(notes, prefix)
      }

      // WikiLinks auto-completion
      if (prefix === '[[') {
        return noteList(notes)
      }

      if (prefix === '>') {
        return dateLinkSuggestions(keyword)
      }

      return []
    },
    [notesMap]
  )
  // # endregion

  const isValidUserStatus =
    isTrialLoading || isGuest || isSubscribed || isTrialRunning === 'true'
  const isTodaySelected =
    selectedDate.date.isSame(new Date(), 'day') &&
    selectedRecordName === 'daily'

  const isEditable = Boolean(isValidUserStatus || isTodaySelected)

  return (
    <div className='note-body flex flex-col'>
      <NoteHeader
        showCalendar={showCalendar}
        toggleCalendar={toggleCalendar}
        needsUpload={needsUpload}
        noteType={noteKey?.noteType}
      />
      <div className='editor-container-wrapper'>
        {Boolean(weekNoteKey) && (
          <WeekNoteReference
            isLoading={isLoadingWeek}
            weekNote={weekNote}
            selectedDate={selectedDay}
            jumpToWeek={({ week, year }) => {
              selectedDateDispatch({ type: 'setWeek', week, year })
            }}
          />
        )}
        {filteredNoteReferences.size > 0 && (
          <NoteReference
            references={filteredNoteReferences}
            // onSelectNote={handleRevealNote}
            onSelectNote={handleSelectRecordName}
          />
        )}
        {(Boolean(weekNoteKey) || filteredNoteReferences.size > 0) && (
          <div
            className='mx-auto mt-4 w-full max-w-3xl'
            style={{ paddingInline: '54px' }}
          >
            <hr className='my-1 w-full opacity-25' />
          </div>
        )}
        <TipTapEditor
          shouldForceUpdateEditor={shouldForceUpdateEditor}
          isLoading={isLoading}
          note={note}
          setNeedsUpload={setNeedsUpload}
          onMarkClicked={handleMarkClicked}
          onLoadSuggestions={handleLoadSuggestions}
          isEditable={isEditable}
          handleClick={() => {
            if (!isEditable) {
              setDialogOpen(true)
            }
          }}
        />
      </div>
    </div>
  )
}

import {
  QueryClient,
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { AxiosError } from 'axios'
import {
  accessTokenContext,
  accessTokenMaxAge,
} from '../providers/AccessTokenProvider'
import axios from 'axios'
import { determineBaseURL } from '../utils/backend'
import { googleLogout } from '@react-oauth/google'
import dayjs, { type Dayjs } from 'dayjs'
import { type CalendarEvent } from '../modules/calendar/CalendarEvent'
import { calculateEventPosition } from '../modules/calendar/timeline/utils/event'
import { useGapiIsReady } from '../providers/GapiProvider'

export const backendClient = axios.create({
  baseURL: determineBaseURL(window.location.hostname, 'google'),
  headers: {
    'Content-Type': 'application/json',
  },
})

export type AccessTokenResponse = {
  token: string
  expires: number
}

/**
 * Query for using the access token.
 * It will be refetched automatically if it expires.
 */
export function useAccessToken() {
  const user = useAuthenticatedUser()

  return useQuery<AccessTokenResponse, AxiosError>({
    context: accessTokenContext,
    refetchInterval: accessTokenMaxAge,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    queryKey: [user.userId],
    queryFn: async () => {
      const response = await backendClient.post<AccessTokenResponse>(
        'auth.php',
        {
          userid: user.userId,
        }
      )
      return response.data
    },
  })
}

type SignInRequestParams = {
  code: string
}

/**
 * Mutation to forward the authentication code to the backend
 * and retrieve the access token in return.
 */
export function useSignIn() {
  const queryClient = useQueryClient({ context: accessTokenContext })
  const user = useAuthenticatedUser()

  return useMutation({
    context: accessTokenContext,
    retry: 3, // retry in case our backend is not reachable
    mutationFn: async ({ code }: SignInRequestParams) => {
      const response = await backendClient.post<AccessTokenResponse>(
        'signin.php',
        {
          code,
          userid: user.userId,
        }
      )
      return response.data
    },
    onMutate: () => {
      // Cancel any outgoing refetches
      void queryClient.cancelQueries([user.userId])
    },
    onError: () => {
      queryClient.setQueryData([user.userId], undefined)
    },
    onSuccess: (data: AccessTokenResponse) => {
      queryClient.setQueryData([user.userId], data)
    },
  })
}

type SignOutResponse = {
  success: boolean
}

function signOutMutation(userId: string) {
  return backendClient.post<SignOutResponse>('signout.php', { userid: userId })
}

export function useSignOut() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient({ context: accessTokenContext })

  return useMutation({
    context: accessTokenContext,
    mutationFn: async () => {
      const response = await signOutMutation(user.userId)
      return response.data
    },
    onSuccess: () => {
      queryClient.clear()
      googleLogout()
    },
  })
}

export function useSignOutWithUserId() {
  const queryClient = useQueryClient({ context: accessTokenContext })

  return useMutation({
    context: accessTokenContext,
    mutationFn: async (userId: string) => {
      const response = await signOutMutation(userId)
      return response.data
    },
    onSuccess: () => {
      queryClient.clear()
      googleLogout()
    },
  })
}

async function handleGoogleApiError<T>(
  accessTokenQueryClient: QueryClient,
  queryKey: QueryKey,
  request: () => gapi.client.HttpRequest<T>
): Promise<T> {
  try {
    const response = await request()
    return response.result
  } catch (error) {
    const googleError = error as gapi.client.HttpRequestRejected
    if (googleError.status === 401 || googleError.status === 403) {
      accessTokenQueryClient.removeQueries({ queryKey })
    }
    throw error
  }
}

export function useGoogleCalendars() {
  const gapiIsReady = useGapiIsReady()
  const { data: accessToken, isStale: accessTokenIsStale } = useAccessToken()
  const accessTokenQueryClient = useQueryClient({ context: accessTokenContext })

  return useQuery({
    context: accessTokenContext,
    enabled: Boolean(accessToken) && !accessTokenIsStale && gapiIsReady,
    staleTime: 0,
    refetchInterval: 5000 * 60, // every 5 minute
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: 1, // once is enough
    queryKey: ['googleCalendars'],
    queryFn: async () => {
      return handleGoogleApiError(
        accessTokenQueryClient,
        ['googleCalendars'],
        () => gapi.client.calendar.calendarList.list()
      ).then((result) => result.items)
    },
  })
}

function gapiEventsToCalendarEvents(
  selectedDay: Dayjs,
  data: gapi.client.calendar.Event[],
  calendar: gapi.client.calendar.CalendarListEntry
): CalendarEvent[] {
  return data.map((event) => {
    const start = event.start.dateTime ?? event.start.date
    const end = event.end.dateTime ?? event.end.date
    return {
      id: event.id,
      content: event.summary,
      position: calculateEventPosition(dayjs(start), dayjs(end), selectedDay),
      startDate: dayjs(start),
      endDate: dayjs(end),
      contentType: 'event',
      allDay: Boolean(event.start.date),
      description: event.description,
      color: calendar.backgroundColor,
      calendarId: calendar.id,
      calendarName: calendar.summary,
    }
  })
}

export function useGoogleCalendarEvents(selectedDay: dayjs.Dayjs) {
  const clonedDay = selectedDay.clone()
  const key: string = clonedDay.format('YYYYMMDD')
  const gapiIsReady = useGapiIsReady()
  const { data: accessToken, isStale: accessTokenIsStale } = useAccessToken()
  const { data: googleCalendars = [] } = useGoogleCalendars()
  const accessTokenQueryClient = useQueryClient({ context: accessTokenContext })

  return useQueries({
    context: accessTokenContext,
    queries: googleCalendars.map((calendar) => ({
      enabled: Boolean(accessToken) && !accessTokenIsStale && gapiIsReady,
      staleTime: 0, // make this immediately stale so the following refetches will trigger
      refetchInterval: 5000 * 60, // every 5 minute
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: 1, // once is enough
      queryKey: ['googleCalendarEvents', calendar.id, key],
      queryFn: async () => {
        const request = {
          calendarId: calendar.id,
          // select only events from selectedDay
          timeMin: clonedDay.startOf('day').toISOString(),
          timeMax: clonedDay.endOf('day').toISOString(),
          showDeleted: false,
          singleEvents: true,
          orderBy: 'startTime' as gapi.client.calendar.EventsOrder,
        }
        return handleGoogleApiError<gapi.client.calendar.Events>(
          accessTokenQueryClient,
          ['googleCalendarEvents', calendar.id, key],
          () => gapi.client.calendar.events.list(request)
        ).then((result) => result.items)
      },
      select: (data: gapi.client.calendar.Event[]) =>
        gapiEventsToCalendarEvents(clonedDay, data, calendar),
    })),
  })
}

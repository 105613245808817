import { type Dayjs } from 'dayjs'
import { type UseQueryResult } from '@tanstack/react-query'
import { type CalendarEvent } from '../../CalendarEvent'
import { useGoogleCalendarEvents } from '../../../../hooks/GoogleCalendar'
import { flatMapCalendarEvents } from '../../../../utils/calendar'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import {
  addAlphaToColor,
  darkenColor,
  desaturateColor,
} from '../../../../utils/color'
import { VEvent, useAppleEvents } from '../../../../hooks/AppleCalDAV'
import { useMemo } from 'react'
import classNames from 'classnames'
import { useLocalStorage } from 'usehooks-ts'

export function AllDayEvents({
  selectedDay,
  labelWidth,
}: {
  selectedDay: Dayjs
  labelWidth: number
}) {
  const isDarkMode = useDarkMode()
  const [hiddenCalendars] = useLocalStorage<string[]>('hiddenCalendars', [])
  const googleResults = useGoogleCalendarEvents(selectedDay)
  const googleEvents = useMemo(() => {
    return googleResults
      .flatMap((result) => result.data ?? [])
      .filter(
        (event) =>
          !!event.allDay && !hiddenCalendars.includes(event.calendarId ?? '')
      )
  }, [googleResults, hiddenCalendars])
  const appleResults = useAppleEvents(selectedDay)
  const appleEvents = useMemo(() => {
    return flatMapCalendarEvents(
      appleResults as UseQueryResult<VEvent[]>[],
      selectedDay
    )
      .filter((event) => event.allDay)
      .filter((event) => {
        return (
          event.startDate.isSame(selectedDay, 'day') ||
          event.endDate.isSame(selectedDay, 'day') ||
          (event.startDate.isBefore(selectedDay, 'day') &&
            event.endDate.isAfter(selectedDay, 'day'))
        )
      })
  }, [appleResults, selectedDay])

  const allDayEvents: CalendarEvent[] = useMemo(() => {
    return [...googleEvents, ...appleEvents]
  }, [googleEvents, appleEvents])

  return (
    <div className='flex border-b-2'>
      <div
        className='flex flex-shrink-0 text-xs leading-tight text-gray-400'
        style={{ width: `${labelWidth.toString()}px` }}
      >
        <div className='w-full self-end pb-0.5 pr-2 text-right'>all-day</div>
      </div>
      <div className='grid flex-auto grid-cols-1 grid-rows-1 pt-0.5'>
        {allDayEvents.map((event) => {
          return (
            <div
              key={event.id}
              className={classNames(
                'mb-0.5 truncate rounded-md px-2 text-xs font-semibold',
                event.color ? '' : 'event-content-background-blue'
              )}
              style={{
                backgroundColor: addAlphaToColor(event.color, 0.22),
                color: event.color
                  ? darkenColor(
                      desaturateColor(event.color, 30),
                      isDarkMode ? -30 : 50
                    )
                  : 'gray',
              }}
            >
              {event.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}

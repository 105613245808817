import { useMemo } from 'react'
import { useFetchUserDefault } from './useFetchUserDefault'
import { BlockNoteEditor } from '@packages/blocknote-core'
import { useUserState } from '../providers/UserProvider'

export default function useBulletAndTaskItemCharacters() {
  const user = useUserState()
  const { data: isDashTodo } = useFetchUserDefault('isDashTodo')
  const { data: isAsteriskTodo } = useFetchUserDefault('isAsteriskTodo')

  const taskItemCharacters: string[] = useMemo(() => {
    if (user?.cloudKitUserId && isDashTodo && isAsteriskTodo) {
      const chars = [
        ...(isDashTodo === 'true' ? ['-'] : []),
        ...(isAsteriskTodo === 'true' ? ['*'] : []),
      ]
      BlockNoteEditor.taskItemCharacters = chars
      return chars
    } else {
      return BlockNoteEditor.taskItemCharacters
    }
  }, [user?.cloudKitUserId, isDashTodo, isAsteriskTodo])

  const bulletItemCharacters: string[] = useMemo(() => {
    if (user?.cloudKitUserId && isDashTodo && isAsteriskTodo) {
      const chars = ['-', '*'].filter((char) => !taskItemCharacters.includes(char))
      BlockNoteEditor.bulletItemCharacters = chars
      return chars
    } else {
      return BlockNoteEditor.bulletItemCharacters
    }
  }, [isAsteriskTodo, isDashTodo, taskItemCharacters, user?.cloudKitUserId])

  return { bulletItemCharacters, taskItemCharacters }
}

/* eslint-disable no-console */
import CloudKit from 'tsl-apple-cloudkit'
import {
  Attachment,
  Note,
  NoteType,
  convertAttachmentToFiles,
  fileAttachmentFromString,
  getFilenameFromNoteTitle,
} from '../../utils/syncUtils'
import { database } from '../CloudKitClient'
import { blockNextNotification, blockNextNotificationTag } from './notification'
import { loadNoteFrom, loadNotesFrom } from './noteUtils'
import { performQuery } from './fetch'
import { NotesZone } from '../../hooks/useEnsureZones'

export async function createNote(draft: Note) {
  console.log('[CloudKit] create note', draft)
  if (!database) {
    throw new Error('Database is undefined')
  }

  // Always save as encrypted, an encrypted note has a different note type (asset_calendar_note or asset_project_note)
  // EDIT: Don't save as encrypted, we will later manage that with local encryption, as database encryption is very slow
  // draft.encrypted = true;
  // draft.noteType = isCalendarNote(draft.noteType) ? NoteType.ASSET_CALENDAR_NOTE : draft.isFolder ? NoteType.PROJECT_NOTE : NoteType.ASSET_PROJECT_NOTE;
  // The note is marked as encrypted, so load the content into the assets, CloudKit automatically encrypts assets
  // const contentAsset = fileAttachmentFromString(draft.content);

  const record: CloudKit.RecordToSave = {
    recordType: 'Note',
    fields: {
      noteType: { value: draft.noteType },
      isDir: { value: draft.isFolder ? 1 : 0 },
      filename: { value: draft.filename },
      content: { value: draft.content, type: 'STRING' },
      // title: { value: draft.title, type: 'STRING', isEncrypted: true },
      fileModifiedAt: { value: Date.now() },
      // attachments: draft.isFolder ? {} : { value: [contentAsset] },
    },
  }
  record.recordName = draft.recordName
  const response = await database.saveRecords([record], {
    zoneID: NotesZone,
  })

  if (response.hasErrors) {
    // Remove the recordName from the skip list, so we don't ignore it again
    throw response.errors[0]
  }

  const createdNote = await loadNoteFrom(response.records[0])
  blockNextNotification(createdNote)

  console.log('[CloudKit] finished creating', createdNote.recordChangeTag)
  return createdNote
}

export async function saveNote(
  note: Note,
  content: string,
  attachments: string[],
  modifiedAt: Date = new Date()
) {
  console.log('[CloudKit] saving note', note?.recordChangeTag, attachments)
  if (!database) {
    throw new Error('Database is undefined')
  }

  note.content = content // Update content here so that we get the up-to-date title.

  // Always save as encrypted, an encrypted note has a different note type (asset_calendar_note or asset_project_note)
  // EDIT: Don't save as encrypted, we will later manage that with local encryption
  // note.encrypted = true;
  // note.noteType = isCalendarNote(note.noteType) ? NoteType.ASSET_CALENDAR_NOTE : note.isFolder ? NoteType.PROJECT_NOTE : NoteType.ASSET_PROJECT_NOTE;

  const baseRecord = {
    recordType: 'Note',
    fields: {
      content: { value: content, type: 'STRING' },
      // Save the title in the meta field, so that we can use this instead of the filename (which can't contain special characters like '/', otherwise we get into trouble)
      filename: { value: getFilenameFromNoteTitle(note) },
      // title: { value: readNoteTitleFromContent(note, false), type: 'STRING', isEncrypted: true },
      noteType: { value: note.noteType },
      fileModifiedAt: { value: modifiedAt?.getTime() ?? Date.now() },
    },
  }

  const record =
    note.recordName && note.recordChangeTag
      ? ({
          ...baseRecord,
          recordName: note.recordName,
          recordChangeTag: note.recordChangeTag,
        } as CloudKit.RecordToSave)
      : (baseRecord as CloudKit.RecordToCreate)

  const attachmentList = (await convertAttachmentToFiles(attachments)) ?? []
  const attachmentsFiles = attachmentList
    .map((attachment: Attachment) => attachment.file)
    .filter((file): file is File => file !== undefined) as unknown as Blob

  record.fields['attachments'] = { value: attachmentsFiles }

  // The note is marked as encrypted, so load the content into the assets, CloudKit automatically encrypts assets
  if (
    note.encrypted ||
    note.noteType === NoteType.ASSET_CALENDAR_NOTE ||
    note.noteType === NoteType.ASSET_PROJECT_NOTE
  ) {
    // Get an asset version of the content
    const contentAsset = fileAttachmentFromString(content)

    // Prepend to attachments
    record.fields['attachments'].value.unshift(contentAsset)

    // Remove the content field
    delete record.fields['content']
  }

  const response = await database.saveRecords([record], { zoneID: NotesZone })

  if (response.hasErrors) {
    // Remove the recordName from the skip list, so we don't ignore it again
    throw response.errors[0]
  }

  const savedNote = await loadNoteFrom(response.records[0])
  blockNextNotification(savedNote)

  console.log('[CloudKit] finished saving', savedNote.recordChangeTag)
  return savedNote
}

// Uploads the title, fileModifiedAt and fiilename of the note(s), using the 'fields' array limit the fields that are uploaded
export async function saveNoteMeta(
  notes: Note[],
  fields: string[] = ['filename', 'fileModifiedAt', 'title']
) {
  console.log('[CloudKit] save notes', notes.length, notes)

  if (!database) {
    throw new Error('Database is undefined')
  }

  // Check if all notes are complete
  const isComplete = notes.every(
    (note) => note.recordName && note.recordChangeTag
  )
  if (!isComplete) {
    throw new Error('Note needs to have a recordName and a recordChangeTag')
  }

  // Function to split notes into batches, because we can only upload up to 200 records at once
  const splitIntoBatches = (notes: Note[], batchSize: number): Note[][] => {
    const batches = []
    for (let i = 0; i < notes.length; i += batchSize) {
      const batch = notes.slice(i, i + batchSize)
      batches.push(batch)
    }
    return batches
  }

  // Split notes into batches of 200
  const noteBatches = splitIntoBatches(notes, 200)
  let results: Map<string, Note> = new Map<string, Note>()

  // Process each batch
  for (const batch of noteBatches) {
    const recordsToSave: CloudKit.RecordToSave[] = batch.map((note) => {
      const record: CloudKit.RecordToSave = {
        recordType: 'Note',
        recordName: note.recordName,
        recordChangeTag: note.recordChangeTag,
        fields: {},
      }

      // EDIT: We stopped saving into the encrypted title, because it takes up way too much performance and makes the full database query slow
      // if (fields.includes('title')) {
      //   record.fields.title = { value: note.title, type: 'STRING', isEncrypted: true };
      // }

      if (fields.includes('filename')) {
        record.fields.filename = { value: note.filename }
      }

      if (fields.includes('fileModifiedAt')) {
        record.fields.fileModifiedAt = { value: Date.now() }
      }

      return record
    })

    console.log('[CloudKit] recordsToSave', recordsToSave)

    // Save each batch of records
    const response = await database.saveRecords(recordsToSave, {
      zoneID: NotesZone,
    })
    console.log('[CloudKit] response', response)

    if (response.hasErrors) {
      // Remove the recordNames from the skip list, so we don't ignore them again

      throw response.errors[0]
    } else {
      const notesMap = await loadNotesFrom(response.records)
      for (const note of notesMap.values()) {
        blockNextNotification(note)
      }
      results = new Map([...results, ...notesMap])
    }
  }

  console.log('[CloudKit] finished saving notes')
  return results
}

export async function deleteNotes(recordNames: string[]) {
  console.log('[CloudKit] deleting notes', recordNames)
  if (!database) {
    throw new Error('Database is undefined')
  }
  const dedublicatedRecordNames = [...new Set(recordNames)]

  const response = await database.deleteRecords(dedublicatedRecordNames, {
    zoneID: NotesZone,
  })

  if (response.hasErrors) {
    throw response.errors[0]
  } else {
    for (const recordName of dedublicatedRecordNames) {
      blockNextNotificationTag(recordName)
    }
  }

  console.log('[CloudKit] finished deleting', dedublicatedRecordNames)
  return dedublicatedRecordNames
}

export async function hasPrivateNotes(): Promise<boolean> {
  const fetchOptions: CloudKit.RecordFetchOptions = {
    desiredKeys: ['filename'],
  }

  const query: CloudKit.Query = {
    recordType: 'Note',
  }

  const response = await performQuery(query, fetchOptions, false)
  const records = response.records

  // console.log('[CloudKit] hasPrivateNotes', records)
  return records.length > 0
}
/* eslint-enable no-console */

import { createClickableMark } from "../api/createClickableMark";
import { MantineThemeOverride } from "@mantine/core";

export const Mention = (
  onMentionTagClicked?: (event: MouseEvent) => void,
  onLoadMentions?: (
    prefix: string,
    keyword: string
  ) => Array<string | { display: string; paste: string }>,
  theme?: MantineThemeOverride
) =>
  createClickableMark({
    name: "mention",
    suggestionChar: "@",
    suggestionPrefix: "@",
    dataAttrOverride: "data-hashtag",
    regex:
      /(^|\s|["'([{])(?![@][\d\p{P}]+($|\s))([@]([^\p{P}\s]|[-_/])+?\(.*?\)|[@]([^\p{P}\s]|[-_/])+)/gu,
    attrsMap: {
      hashtag: 3,
      href: 3,
    },
    onMarkClicked: onMentionTagClicked,
    onLoadSuggestionItems: onLoadMentions,
    theme: theme,
  });

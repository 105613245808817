export const convertTo24HourFormat = (time: string) => {
  const [timePart, period] = time.split(' ')
  const [hours, minutes] = timePart.split(':')
  const numericHours = parseInt(hours, 10)

  if (period === 'PM' && numericHours < 12) {
    return `${numericHours + 12}:${minutes}`
  }
  if (period === 'AM' && numericHours === 12) {
    return `00:${minutes}`
  }
  return numericHours < 10 ? `0${numericHours}:${minutes}` : timePart
}

import classNames from 'classnames'
import { ReferenceBlock } from '../editor/NoteReference'
import { NotesSection } from './Search'

type Props = {
  section: NotesSection
  index: number
  handleRevealNote: (_recordName: string) => void
  keyword: string
}

export default function SectionComponent({ section, index, handleRevealNote, keyword }: Props) {
  const blockCount = section.noteResults.reduce(
    (acc, noteResult) => acc + noteResult.blocks.length,
    0
  )
  return (
    <div key={index} className="p-3">
      <h2
        className={classNames(
          'text-xs font-semibold uppercase text-zinc-500 dark:text-zinc-400 pl-3 py-0.5',
          section.teamSpaceId ? 'bg-green-200 dark:bg-green-950' : 'bg-zinc-100 dark:bg-zinc-900'
        )}
      >{`${section.title} (${blockCount})`}</h2>
      <div className="flex flex-col px-3">
        {section.noteResults.map((noteResult, index) => (
          <div key={index} className="py-1 border-t-2 border-zinc-100 dark:border-zinc-800 mt-5">
            <div className="flex items-start gap-2">
              <i className="far fa-file-lines text-sm opacity-70"></i>
              <h3 className="text-sm font-semibold">{noteResult.title}</h3>
            </div>
            <div className="flex flex-col">
              {noteResult.blocks.map((block, index) => (
                <ReferenceBlock
                  key={index}
                  result={block}
                  onSelectNote={() => handleRevealNote(noteResult.recordName)}
                  keyword={keyword}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

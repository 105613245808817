import { useEffect, useMemo, useRef } from 'react'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import {
  BlockNoteEditor,
  DefaultBlockSchema,
  PartialBlock,
} from '@packages/blocknote-core'
import {
  Note,
  NoteType,
  SourceDatabase,
  Timeframe,
  filenameToDate,
} from '../../utils/syncUtils'
import { useDebounce } from 'usehooks-ts'
import { Dayjs } from 'dayjs'
import SectionComponent from './SectionComponent'
import CalendarSectionComponent from './CalendarSectionComponent'

function NoMatches() {
  return (
    <div
      className='flex flex-col items-center justify-center gap-4 p-3'
      style={{ height: 'calc(100vh - 60px' }}
    >
      <i className='far fa-search text-7xl text-zinc-700'></i>
      <h2 className='text-xl font-normal'>No Matches</h2>
      <p style={{ marginBottom: '25vh' }}>
        Search for anything inside your notes.
      </p>
    </div>
  )
}

type Section = NotesSection | CalendarSection

export type NotesSection = {
  title: string
  type: 'notes'
  teamSpaceId?: string
  noteResults: NoteResult[]
}

type NoteResult = {
  recordName: string
  title: string
  blocks: SearchContentResult[]
}

export type CalendarSection = {
  title: string
  type: 'calendar'
  teamSpaceId?: string
  months: Month[]
}

type Month = {
  month: number
  year: number
  calendarResults: CalendarResult[]
}

type CalendarResult = {
  recordName: string
  date: Dayjs
  timeframe: Timeframe
  blocks: SearchContentResult[]
}

export type SearchContentResult = {
  recordName: string
  source: SourceDatabase
  noteType: NoteType
  filename: string
  parent?: string
  block: PartialBlock<DefaultBlockSchema>
  content: string
  lineIndex: number
}

function getTeamSpace(
  note: Note,
  teamProjectNotesMap: Map<string, Note>
): Note {
  const parentNote = teamProjectNotesMap.get(note.parent)
  if (parentNote?.noteType === NoteType.TEAM_SPACE) {
    return parentNote
  } else {
    return getTeamSpace(parentNote, teamProjectNotesMap)
  }
}

function searchContent(query: string, note: Note): SearchContentResult[] {
  const results: SearchContentResult[] = []
  const content = note.content
  const recordName = note.recordName

  if (!recordName) {
    return results
  }

  if (query.length <= 1 || !content) {
    return results
  }

  const lines = content.split('\n')
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    // case insensitive search
    if (line.toLowerCase().includes(query.toLowerCase())) {
      const block = BlockNoteEditor.notePlanToBlocks(line, '')[0]
      results.push({
        recordName: recordName,
        source: note.source,
        noteType: note.noteType,
        filename: note.filename,
        parent: note.parent,
        block: block,
        content: line,
        lineIndex: i,
      })
    }
  }

  return results
}

function searchNotes(
  query: string,
  privateProjectNotesMap: Map<string, Note> | undefined,
  privateCalendarNotesMap: Map<string, Note> | undefined,
  teamProjectNotesMap: Map<string, Note> | undefined,
  teamCalendarNotesMap: Map<string, Note> | undefined
): Section[] {
  const results: Section[] = []

  let resultsCount = 0
  const resultsLimit = 1000
  let blocksCount = 0
  const blocksLimit = 1000

  const teamCalendarSections: CalendarSection[] = []
  if (teamCalendarNotesMap) {
    try {
      teamCalendarNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        const teamSpace: Note = getTeamSpace(note, teamProjectNotesMap)

        // prepare data
        const { date, timeframe } = filenameToDate(note.filename)
        const calendarResult: CalendarResult = {
          recordName: note.recordName,
          date,
          timeframe,
          blocks: searchContent(query, note),
        }

        // add to results
        if (calendarResult.blocks.length > 0) {
          const section = teamCalendarSections.find(
            (section) => section.teamSpaceId === teamSpace.recordName
          )
          if (section) {
            const month = section.months?.find(
              (month) =>
                month?.month === date.month() && month?.year === date.year()
            )
            if (month) {
              month.calendarResults.push(calendarResult)
            } else {
              section.months.push({
                month: date.month(),
                year: date.year(),
                calendarResults: [calendarResult],
              })
            }
          } else {
            teamCalendarSections.push({
              type: 'calendar',
              title: teamSpace.title + ' Calendar',
              teamSpaceId: teamSpace.recordName,
              months: [
                {
                  month: date.month(),
                  year: date.year(),
                  calendarResults: [calendarResult],
                },
              ],
            })
          }
          resultsCount += 1
          blocksCount += calendarResult.blocks.length
        }
      })
    } catch (e) {
      // do nothing
      // workaround to break the forEach loop
    }
    if (teamCalendarSections.length > 0) {
      teamCalendarSections.forEach((section) => {
        // descending order
        section.months.sort((a, b) => b.year - a.year || b.month - a.month)
        section.months.forEach((month) =>
          month.calendarResults.sort((a: CalendarResult, b: CalendarResult) => {
            // put week notes at the end of the week
            const correctedA =
              a.timeframe === 'week' ? a.date.add(6, 'day') : a.date
            const correctedB =
              b.timeframe === 'week' ? b.date.add(6, 'day') : b.date
            return correctedB.diff(correctedA)
          })
        )
      })
      results.push(...teamCalendarSections)
    }
  }

  const teamSections: NotesSection[] = []
  if (teamProjectNotesMap) {
    try {
      teamProjectNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        if (note.noteType === NoteType.TEAM_SPACE) {
          return
        }

        const teamSpace: Note = getTeamSpace(note, teamProjectNotesMap)

        // prepare data
        const noteResult: NoteResult = {
          recordName: note.recordName,
          title: note.title,
          blocks: searchContent(query, note),
        }

        // add to results
        if (noteResult.blocks.length > 0) {
          const section = teamSections.find(
            (section) => section.teamSpaceId === teamSpace.recordName
          )
          if (section) {
            section.noteResults.push(noteResult)
          } else {
            teamSections.push({
              type: 'notes',
              title: teamSpace.title,
              teamSpaceId: teamSpace.recordName,
              noteResults: [noteResult],
            })
          }
          resultsCount += 1
          blocksCount += noteResult.blocks.length
        }
      })
    } catch (e) {
      // do nothing
      // workaround to break the forEach loop
    }

    if (teamSections.length > 0) {
      // ascending order
      teamSections.forEach((section) =>
        section.noteResults.sort((a, b) => a.title.localeCompare(b.title))
      )
      results.push(...teamSections)
    }
  }

  // Sort in ascending order, so notes and calendar sections of the same team space are grouped together
  results.sort((a, b) => a.title.localeCompare(b.title))

  const privateCalendarSection: CalendarSection = {
    type: 'calendar',
    title: teamSections.length > 0 ? 'Private calendar' : 'Calendar',
    months: [],
  }
  if (privateCalendarNotesMap) {
    try {
      privateCalendarNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        // prepare data
        const { date, timeframe } = filenameToDate(note.filename)
        const calendarResult: CalendarResult = {
          recordName: note.recordName,
          date,
          timeframe,
          blocks: searchContent(query, note),
        }

        // add to results
        if (date && calendarResult.blocks.length > 0) {
          const month = privateCalendarSection.months.find(
            (month) =>
              month.month === date.month() && month.year === date.year()
          )
          if (month) {
            month.calendarResults.push(calendarResult)
          } else {
            privateCalendarSection.months.push({
              month: date.month(),
              year: date.year(),
              calendarResults: [calendarResult],
            })
          }
          resultsCount += 1
          blocksCount += calendarResult.blocks.length
        }
      })
    } catch (e) {
      // do nothing
      // workaround to break the forEach loop
    }

    if (privateCalendarSection.months.length > 0) {
      // descending order
      privateCalendarSection.months.sort(
        (a, b) => b.year - a.year || b.month - a.month
      )
      privateCalendarSection.months.forEach((month) =>
        month.calendarResults.sort((a: CalendarResult, b: CalendarResult) => {
          // put week notes at the end of the week
          const correctedA =
            a.timeframe === 'week' ? a.date.add(6, 'day') : a.date
          const correctedB =
            b.timeframe === 'week' ? b.date.add(6, 'day') : b.date
          return correctedB.diff(correctedA)
        })
      )
      results.push(privateCalendarSection)
    }
  }

  const privateSection: NotesSection = {
    type: 'notes',
    title: teamSections.length > 0 ? 'Private notes' : 'Notes',
    noteResults: [],
  }
  if (privateProjectNotesMap) {
    try {
      privateProjectNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        // prepare data
        const noteResult: NoteResult = {
          recordName: note.recordName,
          title: note.title,
          blocks: searchContent(query, note),
        }

        // add to results
        if (noteResult.blocks.length > 0) {
          privateSection.noteResults.push(noteResult)
          resultsCount += 1
          blocksCount += noteResult.blocks.length
        }
      })
    } catch (e) {
      // do nothing
      // workaround to break the forEach loop
    }

    if (privateSection.noteResults.length > 0) {
      // ascending order
      privateSection.noteResults.sort((a, b) => a.title.localeCompare(b.title))
      results.push(privateSection)
    }
  }

  return results
}

export default function Search({
  query,
  onSearch,
}: {
  query: string
  onSearch: (_query: string) => void
}) {
  const debouncedQuery = useDebounce(query, 300)
  const {
    privateProjectNotes,
    privateCalendarNotes,
    teamProjectNotes,
    teamCalendarNotes,
    handleRevealNote,
  } = useSidebarProvider()
  const results: Section[] = useMemo(
    () =>
      searchNotes(
        debouncedQuery,
        privateProjectNotes,
        privateCalendarNotes,
        teamProjectNotes,
        teamCalendarNotes
      ),
    [
      debouncedQuery,
      privateCalendarNotes,
      privateProjectNotes,
      teamCalendarNotes,
      teamProjectNotes,
    ]
  )
  const inputRef = useRef<HTMLInputElement>(null)

  // scroll to top when query changes
  useEffect(() => {
    const searchResults = document.querySelector('.search-results')
    searchResults?.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior })
  }, [debouncedQuery])

  // add command+a to select all text in input
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        inputRef.current?.select()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  })

  return (
    <div className='note-body flex flex-col'>
      <div className='flex w-full flex-row items-center gap-10 p-3'>
        <div className='flex flex-row items-center'>
          <i className='far fa-search mr-2 text-xl opacity-70'></i>
          <h1 className='text-xl'>Search</h1>
        </div>
        <div className='relative flex-grow'>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <i className='fas fa-search'></i>
          </span>
          <input
            ref={inputRef}
            type='text'
            className='form-input block w-full rounded-md border-2 px-4 py-1 pl-10 leading-5 dark:border-zinc-700 dark:bg-zinc-800'
            placeholder='Search'
            value={query}
            onChange={(e) => onSearch(e.target.value)}
            autoFocus={true}
          />
          {query.length > 0 && (
            <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
              <i
                className='fas fa-times-circle cursor-pointer text-lg'
                onClick={() => {
                  onSearch('')
                  // focus on input
                  inputRef.current?.focus()
                }}
              ></i>
            </span>
          )}
        </div>
      </div>
      <div
        className='search-results overflow-x-hidden overflow-y-scroll'
        style={{ maxHeight: 'calc(100vh - 60px)' }}
      >
        {results.length > 0 ? (
          results.map((section, index) => {
            if (section.type === 'notes') {
              return (
                <SectionComponent
                  key={index}
                  section={section}
                  index={index}
                  handleRevealNote={handleRevealNote}
                  keyword={debouncedQuery}
                />
              )
            }
            if (section.type === 'calendar') {
              return (
                <CalendarSectionComponent
                  key={index}
                  section={section}
                  index={index}
                  handleRevealNote={handleRevealNote}
                  keyword={debouncedQuery}
                />
              )
            }
          })
        ) : (
          <NoMatches />
        )}
      </div>
    </div>
  )
}

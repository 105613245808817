import CodeBlockLowlight, {
  CodeBlockLowlightOptions,
} from "@tiptap/extension-code-block-lowlight";
import { getBlockInfoFromPos } from "../../../helpers/getBlockInfoFromPos";

// When I rename this, don't forget to rename it also in the handleEnter func which is in the shortcuts file
export const CodeBlockLowlightBlockContent =
  CodeBlockLowlight.extend<CodeBlockLowlightOptions>({
    name: "codefence",
    group: "blockContent",

    addKeyboardShortcuts() {
      return {
        Tab: () => {
          const { contentType } = getBlockInfoFromPos(
            this.editor.state.doc,
            this.editor.state.selection.from
          )!;

          if (contentType.name === "codefence") {
            this.editor.commands.insertContent("\t");
            return true;
          }

          return false;
        },
        "Shift-Tab": () => {
          const { contentType } = getBlockInfoFromPos(
            this.editor.state.doc,
            this.editor.state.selection.from
          )!;

          if (contentType.name === "codefence") {
            // Get the char before the cursor and if it's space or tab, delete it
            const charBeforeCursor = this.editor.state.doc.textBetween(
              this.editor.state.selection.from - 1,
              this.editor.state.selection.from
            );

            if (charBeforeCursor === " " || charBeforeCursor === "\t") {
              this.editor.commands.deleteRange({
                from: this.editor.state.selection.from - 1,
                to: this.editor.state.selection.from,
              });
            }
            return true;
          }

          return false;
        },
      };
    },
  });

export type User = null | {
  cloudKitUserId?: string
  supabaseUserId?: string
  email?: string
  confirmed_at?: Date
}

export type UserMetaData = { cloudKitUserId?: string; hasNotes?: string }

export enum AuthType {
  CLOUDKIT = 'cloudkit',
  SUPABASE = 'supabase',
  CLOUDKIT_SUPABASE = 'cloudkit-supabase',
}
// TODO make email and confirmed_at non-optional

export type AuthenticatedUser =
  | {
      authType: AuthType.CLOUDKIT
      userId: string
    }
  | {
      authType: AuthType.SUPABASE
      userId: string
      teamUserId: string
      email?: string
      confirmed_at?: Date
    }
  | {
      authType: AuthType.CLOUDKIT_SUPABASE
      userId: string // CloudKit user ID
      teamUserId: string // Supabase user ID
      email?: string
      confirmed_at?: Date
    }

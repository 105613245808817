import { throttle } from 'lodash' // TODO: utilize useHooks or use single lodash modules to take advantage of tree shaking
import { getRowNumber, getRowNumberAndDate } from './event'
import { TEMP_EVENT_ID } from '../data'
import { getSpan } from './event'
import { CalendarEvent } from '../../CalendarEvent'
import { PlaceholderEvent } from '../types'

export const throttleSetTemporaryEvent = throttle(
  (
    event,
    currentEvents,
    onUpdateCurrentEvents,
    setPlaceholderEvent,
    calendarEventDrag,
    startingDragDate,
    setStartingDragDate
  ) => {
    const { rowNumber, eventDateRoundedTo15Minutes } =
      getRowNumberAndDate(event)

    if (calendarEventDrag) {
      const temporaryEvent = {
        id: TEMP_EVENT_ID,
        content: calendarEventDrag.content,
        position: `${rowNumber} / span 2`,
        startDate: eventDateRoundedTo15Minutes,
        endDate: eventDateRoundedTo15Minutes.add(30, 'minute'),
      }
      if (startingDragDate) {
        const difference = eventDateRoundedTo15Minutes.diff(
          startingDragDate,
          'minute'
        )
        const newStartDate =
          difference < 0
            ? calendarEventDrag.startDate.subtract(-difference, 'minute')
            : calendarEventDrag.startDate.add(difference, 'minute')
        const newEndDate =
          difference < 0
            ? calendarEventDrag.endDate.subtract(-difference, 'minute')
            : calendarEventDrag.endDate.add(difference, 'minute')
        const span = getSpan(newStartDate, newEndDate)
        const newRowNumber = getRowNumber(newStartDate)
        temporaryEvent.startDate = newStartDate
        temporaryEvent.endDate = newEndDate
        temporaryEvent.position = `${newRowNumber} / span ${span}`
      } else {
        setStartingDragDate(eventDateRoundedTo15Minutes)
        const span = getSpan(
          calendarEventDrag.startDate,
          calendarEventDrag.endDate
        )
        const newRowNumber = getRowNumber(calendarEventDrag.startDate)
        temporaryEvent.startDate = calendarEventDrag.startDate
        temporaryEvent.endDate = calendarEventDrag.endDate
        temporaryEvent.position = `${newRowNumber} / span ${span}`
      }

      onUpdateCurrentEvents([
        ...currentEvents.filter(
          (event: CalendarEvent) => event.id !== TEMP_EVENT_ID
        ),
        temporaryEvent,
      ])
    } else {
      const placeholderEvent: PlaceholderEvent = {
        position: `${rowNumber} / span 2`,
        startDate: eventDateRoundedTo15Minutes,
        endDate: eventDateRoundedTo15Minutes.add(30, 'minute'),
      }
      setPlaceholderEvent(placeholderEvent)
    }
  },
  25
)

import { Mark, mergeAttributes } from "@tiptap/core";
import { createBreakMarkPlugin } from "../api/breakMarkPlugin";

export interface TimeStringOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    timeString: {
      applyTimeString: () => ReturnType;
      toggleTimeString: () => ReturnType;
      removeTimeString: () => ReturnType;
    };
  }
}

export const TimeString = Mark.create({
  name: "timeString",
  parseHTML() {
    return [{ tag: "span" }];
  },
  renderHTML({ HTMLAttributes }) {
    // console.log({ HTMLAttributes }, this.options.HTMLAttributes);
    return [
      "span",
      mergeAttributes(
        {
          "data-content-type": "time-string",
        },
        HTMLAttributes
      ),
      0,
    ];
  },

  addCommands() {
    return {
      applyTimeString:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleTimeString:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      removeTimeString:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      createBreakMarkPlugin({
        markName: this.name,
      }),
    ];
  },
});

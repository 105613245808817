import { useMemo } from 'react'
import { NoteReference, useReferences } from '../providers/ReferencesProvider'
import { NoteType, isPrivateNote, isTeamspaceNote } from '../utils/syncUtils'

export enum ReferenceType {
  PRIVATE = 'private',
  TEAM = 'team',
  ALL = 'all',
}

export function useNoteReferences(type: ReferenceType) {
  const references = useReferences()

  const filteredReferences = useMemo(() => {
    if (type === ReferenceType.ALL) {
      return references
    }

    const isRelevantNoteType = (noteType: NoteType) => {
      switch (type) {
        case ReferenceType.PRIVATE: {
          return isPrivateNote(noteType)
        }
        case ReferenceType.TEAM: {
          return isTeamspaceNote(noteType)
        }
        default: {
          return false
        }
      }
    }

    const filtered = new Map<string, NoteReference>()
    for (const [key, value] of references.entries()) {
      if (isRelevantNoteType(value.noteType)) {
        filtered.set(key, value)
      }
    }
    return filtered
  }, [references, type])

  return filteredReferences
}

type TrialBannerProps = {
  trialEndDate: string
  isTrialRunning?: string
  isGuest?: boolean
  onClick: () => void
}

export function TrialBanner({
  trialEndDate,
  isTrialRunning,
  isGuest,
  onClick,
}: TrialBannerProps) {
  const endDate = new Date(trialEndDate)
  const today = new Date()
  const diffTime = endDate.getTime() - today.getTime()
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return (
    <div
      role='button'
      tabIndex={0}
      className={`flex w-full cursor-pointer items-center justify-center ${
        isGuest ? 'bg-blue-100 p-0.5' : 'bg-orange-100 py-2'
      }`}
      onClick={onClick}
    >
      <p className='m-0 text-sm text-gray-900 dark:text-gray-900'>
        {isGuest && isTrialRunning !== 'true' ? (
          <span className='mb-0' style={{ fontWeight: 500 }}>
            Guest Mode
          </span>
        ) : !trialEndDate || isTrialRunning === 'expired' ? (
          <span className='mb-0' style={{ fontWeight: 500 }}>
            Your trial has expired. Subscribe now to continue using NotePlan
            (save 10% off)
          </span>
        ) : (
          `You have ${diffDays.toString()} days left on your free trial. Subscribe before trial ends »`
        )}
      </p>
    </div>
  )
}

import { getWeekdayNames } from '../../utils/locale'

export function WeekDays() {
  return (
    <div className='grid grid-cols-7 text-xs font-medium text-gray-400'>
      {getWeekdayNames().map((day) => (
        <div key={`weekday-${day}`}>{day.toUpperCase()}</div>
      ))}
    </div>
  )
}

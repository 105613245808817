import { useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import {
  cachedNotesContext,
  removeFromCacheByRecordNames,
} from '../providers/CachedNotesProvider'
import { cacheKeys, teamKeys } from '../utils/queryKeyFactory'
import { leaveTeamspace } from '../lib/supabase/invitation'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'

export function useLeaveTeamspace() {
  const user = useUserState()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })

  return useSafeMutation({
    mutationFn: ({ teamspaceID }: { teamspaceID: string }) => {
      if (!teamspaceID) {
        return Promise.resolve(undefined)
      }

      trackEvent('WEB - Team Member Left', {
        teamspaceID,
        supabaseUserId: user?.supabaseUserId,
      })

      if (user?.supabaseUserId) {
        return leaveTeamspace(teamspaceID)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (_data, { teamspaceID }) => {
      trackEvent('WEB - Team Member Left', {
        teamspaceID,
        supabaseUserId: user?.supabaseUserId,
      })

      // Remove the teamspace from the shared teamspace notes
      if (user?.supabaseUserId) {
        removeFromCacheByRecordNames(
          cachedNotesQueryClient,
          [teamspaceID],
          cacheKeys.teamProjectNotes(user.supabaseUserId)
        )
      }

      // Remove working copies of notes in the teamspace
      queryClient.removeQueries(teamKeys.space(teamspaceID))
    },
    onError: (error) => {
      trackEvent('WEB - Team Member Left Failed', {
        error,
      })
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}

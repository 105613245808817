/**
 * Darkens a given hex color by a specified amount.
 *
 * This function takes a hex color string and an amount by which to darken the color.
 * It returns the darkened color as a hex string.
 *
 * @param color - The hex color string to be darkened. It can start with or without a '#'.
 * @param amount - The amount by which to darken the color. This value is subtracted from each RGB component.
 * @returns - The darkened hex color string. The returned string will include a '#' if the input color included it.
 *
 * @example
 * // Darken the color '#ff0000' by 20 units
 * const darkenedColor = darkenColor('#ff0000', 20);
 * console.log(darkenedColor); // Outputs: '#e60000'
 */
export function darkenColor(color: string, amount: number): string {
  const usePound = color.startsWith('#')
  const num = parseInt(color.slice(1), 16)

  let r = Math.floor(num / (256 * 256)) - amount
  let g = Math.floor((num / 256) % 256) - amount
  let b = (num % 256) - amount

  r = r < 0 ? 0 : r
  g = g < 0 ? 0 : g
  b = b < 0 ? 0 : b

  const rHex = r.toString(16).padStart(2, '0')
  const gHex = g.toString(16).padStart(2, '0')
  const bHex = b.toString(16).padStart(2, '0')

  return (usePound ? '#' : '') + rHex + gHex + bHex
}

/**
 * Reduces the saturation of a given hex color by a specified amount.
 *
 * This function takes a hex color string and a percentage by which to reduce the saturation.
 * It converts the color to HSL, reduces the saturation, and converts back to hex.
 *
 * @param color - The hex color string to desaturate. It can start with or without a '#'.
 * @param amount - The percentage (0-100) by which to reduce saturation.
 * @returns - The desaturated hex color string. The returned string will include a '#' if the input color included it.
 *
 * @example
 * // Reduce saturation of '#ff0000' by 50%
 * const desaturatedColor = desaturateColor('#ff0000', 50);
 * console.log(desaturatedColor); // Outputs a less saturated red
 */
export function desaturateColor(color: string, amount: number): string {
  const usePound = color.startsWith('#')
  const hex = usePound ? color.slice(1) : color

  // Convert hex to RGB
  const r = parseInt(hex.slice(0, 2), 16) / 255
  const g = parseInt(hex.slice(2, 4), 16) / 255
  const b = parseInt(hex.slice(4, 6), 16) / 255

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin

  let h = 0
  let s = 0
  let l = 0

  // Calculate hue
  if (delta === 0) h = 0
  else if (cmax === r) h = ((g - b) / delta) % 6
  else if (cmax === g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)
  if (h < 0) h += 360

  // Calculate lightness
  l = (cmax + cmin) / 2

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // Reduce saturation by amount%
  s = Math.max(0, s * (1 - amount / 100))

  // Convert back to RGB
  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c / 2
  let rr = 0
  let gg = 0
  let bb = 0

  if (0 <= h && h < 60) {
    rr = c
    gg = x
    bb = 0
  } else if (60 <= h && h < 120) {
    rr = x
    gg = c
    bb = 0
  } else if (120 <= h && h < 180) {
    rr = 0
    gg = c
    bb = x
  } else if (180 <= h && h < 240) {
    rr = 0
    gg = x
    bb = c
  } else if (240 <= h && h < 300) {
    rr = x
    gg = 0
    bb = c
  } else if (300 <= h && h < 360) {
    rr = c
    gg = 0
    bb = x
  }

  // Convert back to hex
  const r2 = Math.round((rr + m) * 255)
  const g2 = Math.round((gg + m) * 255)
  const b2 = Math.round((bb + m) * 255)

  const rHex = r2.toString(16).padStart(2, '0')
  const gHex = g2.toString(16).padStart(2, '0')
  const bHex = b2.toString(16).padStart(2, '0')

  return (usePound ? '#' : '') + rHex + gHex + bHex
}

/**
 * Adds alpha to a given hex color.
 *
 * This function takes a hex color string and an alpha value to be added to the color.
 * It returns the color as a hex string with alpha.
 *
 * @param color - The hex color string to which alpha is to be added. It can start with or without a '#'.
 * @param alpha - The alpha value to be added to the color. This value is a decimal between 0 and 1.
 * @returns - The hex color string with alpha. The returned string will include a '#' if the input color included it.
 *
 * @example
 * // Add alpha 0.5 to the color '#ff0000'
 * const colorWithAlpha = addAlphaToColor('#ff0000', 0.5);
 * console.log(colorWithAlpha); // Outputs: '#ff000080'
 */
export function addAlphaToColor(
  color: string | undefined,
  alpha: number | undefined
): string | undefined {
  if (color === undefined || alpha === undefined) {
    return undefined
  }

  const usePound = color.startsWith('#')
  const hex = usePound ? color.slice(1) : color

  // Check if the color already has an alpha component
  if (hex.length === 8) {
    // Replace the existing alpha component
    const newHex = hex.slice(0, 6)
    const a = Math.round(alpha * 255)
    const aHex = a.toString(16).padStart(2, '0')
    return (usePound ? '#' : '') + newHex + aHex
  }

  const num = parseInt(hex, 16)

  const r = Math.floor(num / (256 * 256))
  const g = Math.floor((num / 256) % 256)
  const b = num % 256

  const a = Math.round(alpha * 255)

  const rHex = r.toString(16).padStart(2, '0')
  const gHex = g.toString(16).padStart(2, '0')
  const bHex = b.toString(16).padStart(2, '0')
  const aHex = a.toString(16).padStart(2, '0')

  return (usePound ? '#' : '') + rHex + gHex + bHex + aHex
}

import CloudLoading from '../../components/CloudLoading'
import { copyMarkdown } from '../../utils/commons'
import OpenButton from '../calendar/OpenButton'
import { showSignOut, useUserState } from '../../providers/UserProvider'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import { useIsMobile } from '../../hooks/useIsMobile'
import { NoteType } from '../../utils/syncUtils'
import { NoteTitle } from './NoteTitle'
import { useIsOnline } from '../../providers/NetworkProvider'
import classNames from 'classnames'

type Props = {
  needsUpload: boolean
  showCalendar: boolean
  toggleCalendar: () => void
  noteType: NoteType
}

export const NoteHeader = ({
  needsUpload,
  showCalendar,
  toggleCalendar,
  noteType,
}: Props) => {
  const user = useUserState()
  const selectedDateDispatch = useSelectedDateDispatch()
  const isMobile = useIsMobile()
  const isTeamItem =
    noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE ||
    noteType === NoteType.TEAM_SPACE_NOTE
  const isOnline = useIsOnline()

  return (
    <div
      className={classNames(
        'flex justify-between pt-1.5 pb-1 relative',
        { 'border-b-2 border-dotted border-green-300': isTeamItem },
        { 'opacity-10': !isOnline }
      )}
    >
      {/* To show the user that this is shared note (so that there are no accidents)  */}
      <div id='spacer-note-heading' className='w-2.5'></div>
      <div className='note-header ml-auto mr-auto flex w-full justify-between pr-5'>
        <NoteTitle
          onToday={() => {
            selectedDateDispatch({ type: 'today' })
          }}
        />

        <div className='flex items-center justify-center'></div>
        <div className='flex'>
          <button
            className='mr-2 hidden py-1'
            onClick={(_e) => {
              void copyMarkdown()
            }}
          >
            Copy Markdown
          </button>
          <div id='copy-done-check' className='hidden'>
            <i className='fas fa-check'></i>
          </div>
          <button
            data-tooltip-id='my-tooltip'
            data-tooltip-content='Previous day (⌃⌘←)'
            type='button'
            className='flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25'
            onClick={() => {
              selectedDateDispatch({ type: 'shift', direction: -1 })
            }}
          >
            <span className='sr-only'>Previous day</span>
            <i
              className='fa-solid fa-arrow-left h-5 w-5 text-sm'
              aria-hidden='true'
            />
          </button>

          <button
            data-tooltip-id='my-tooltip'
            data-tooltip-content='Next day (⌃⌘→)'
            type='button'
            className='flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25'
            onClick={() => {
              selectedDateDispatch({ type: 'shift', direction: 1 })
            }}
          >
            <span className='sr-only'>Next day</span>
            <i
              className='fa-solid fa-arrow-right h-5 w-5 text-sm'
              aria-hidden='true'
            />
          </button>
          {isMobile && (
            <div className='ml-8 w-full'>
              <OpenButton
                onClick={toggleCalendar}
                showCalendar={showCalendar}
              />
            </div>
          )}
        </div>
      </div>

      <div className='flex pr-2'>
        <CloudLoading needsUpload={needsUpload} />
        <button
          data-tooltip-id='my-tooltip'
          data-tooltip-content='Logout'
          type='button'
          className='flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25'
          onClick={() => {
            showSignOut(!!user?.supabaseUserId, !!user?.cloudKitUserId)
          }}
        >
          <i className='fas fa-user'></i>
        </button>
      </div>
      {!isOnline && <div className='absolute left-0 top-0 h-full w-full' />}
    </div>
  )
}

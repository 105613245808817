import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
} from '@tanstack/react-query'
import { useUserDispatch } from '../providers/UserProvider'

export function useSafeMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const setUser = useUserDispatch()
  return useMutation({
    ...options,
    onError: (error, variables, context) => {
      // handle CloudKit auth errors
      if (
        error &&
        typeof error === 'object' &&
        'ckErrorCode' in error &&
        error.ckErrorCode === 'AUTHENTICATION_REQUIRED'
      ) {
        setUser?.(null)
        window.location.href = '/' // Reload the page right away
      }
      // handle Supabase auth errors
      if (
        error &&
        typeof error === 'object' &&
        'code' in error &&
        error.code === '42501'
      ) {
        setUser?.((prevUser) => {
          if (prevUser?.cloudKitUserId) {
            return { cloudKitUserId: prevUser.cloudKitUserId }
          }
          return null
        })
      }

      if (typeof options.onError === 'function') {
        options.onError(error, variables, context)
      }
    },
  })
}

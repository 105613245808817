import { useState, useEffect } from 'react'
import { AuthType } from '../utils/User'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { supabaseClient } from '../lib/SupabaseClient'
import { REALTIME_CHANNEL_STATES } from '@supabase/supabase-js'
import { useQueryClient } from '@tanstack/react-query'
import { cachedNotesContext } from '../providers/CachedNotesProvider'
import { notesChannel, subscribeToChannel } from '../lib/supabase/notification'

export function useSupabaseSubscription() {
  const user = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })
  const [isRegistered, setIsRegistered] = useState<boolean | undefined>(
    undefined
  )

  useEffect(() => {
    if (
      user.authType !== AuthType.SUPABASE &&
      user.authType !== AuthType.CLOUDKIT_SUPABASE
    ) {
      setIsRegistered(undefined)
      return
    }

    function checkRegistrationStatus() {
      const isSupabaseConnected = supabaseClient.realtime.isConnected()

      const channels = supabaseClient.realtime.getChannels()
      const isAnyChannelJoined = channels.some(
        (channel) =>
          channel.subTopic === notesChannel &&
          channel.state === REALTIME_CHANNEL_STATES.joined
      )

      setIsRegistered(isSupabaseConnected && isAnyChannelJoined)
    }
    const intervalId = setInterval(checkRegistrationStatus, 60_000)
    window.addEventListener('focus', checkRegistrationStatus)

    checkRegistrationStatus()

    return () => {
      clearInterval(intervalId)
      window.removeEventListener('focus', checkRegistrationStatus)
    }
  }, [user.authType])

  useEffect(() => {
    if (
      user.authType !== AuthType.SUPABASE &&
      user.authType !== AuthType.CLOUDKIT_SUPABASE
    ) {
      return
    }

    if (isRegistered === false) {
      console.debug('supabase subscription is off -> subscribing')
      subscribeToChannel(
        user,
        queryClient,
        cachedNotesQueryClient,
        setIsRegistered
      )
    }
    if (isRegistered) {
      console.debug('supabase subscription is on')
    }
  }, [cachedNotesQueryClient, isRegistered, queryClient, user])

  return isRegistered
}

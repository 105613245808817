/* eslint-disable no-console */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Note,
  NoteType,
  calendarFilenameToTitle,
  filenameToKey,
  findMatches,
  isCalendarNote,
} from '../utils/syncUtils'
import {
  usePrivateCalendarNotes,
  usePrivateProjectNotes,
  useTeamCalendarNotes,
  useTeamProjectNotes,
} from '../hooks/useNotesFactory'
import {
  BlockNoteEditor,
  DefaultBlockSchema,
  PartialBlock,
} from '@packages/blocknote-core'
import { CacheData } from './CachedNotesProvider'

export type FromNote = {
  title: string
  blocks: PartialBlock<DefaultBlockSchema>[]
  ranges: Range[]
}

export type NoteReference = {
  noteType: NoteType
  incoming: Map<string, FromNote>
  outgoing: Set<string>
}

export type NoteReferenceMap = Map<string, NoteReference>

const ReferencesContext = createContext<NoteReferenceMap | undefined>(undefined)

export function ReferencesProvider({ children }: { children: ReactNode }) {
  const [references, setReferences] = useState<NoteReferenceMap>(new Map())
  const [noteChanges, setNoteChanges] = useState<Map<string, string>>(new Map())

  const { data: privateCalendarNotes } = usePrivateCalendarNotes()
  const { data: privateProjectNotes } = usePrivateProjectNotes()
  const { data: teamCalendarNotes } = useTeamCalendarNotes()
  const { data: teamProjectNotes } = useTeamProjectNotes()

  useEffect(() => {
    console.log('[ReferencesProvider] Refreshing the references')

    const allNotes = new Map<string, Note>([
      ...(privateCalendarNotes?.map ?? []),
      ...(privateProjectNotes?.map ?? []),
      ...(teamCalendarNotes?.map ?? []),
      ...(teamProjectNotes?.map ?? []),
    ])

    // Get a list of changed notes by comparing recordChangeTags
    // Batch state updates instead of updating for each note
    const changedNotes = new Map<string, Note>()
    const newChangeTags = new Map<string, string>()

    for (const [recordName, note] of allNotes.entries()) {
      const previousChangeTag = noteChanges.get(recordName)
      const currentChangeTag = note.recordChangeTag

      // If the note is new or its recordChangeTag has changed, include it
      if (!previousChangeTag || previousChangeTag !== currentChangeTag) {
        changedNotes.set(recordName, note)
      }

      // Collect new change tags in a single map
      if (note.recordChangeTag) {
        newChangeTags.set(recordName, note.recordChangeTag)
      }
    }

    // Single state update with all changes
    if (newChangeTags.size > 0) {
      setNoteChanges((previous) => {
        const updated = new Map([...previous, ...newChangeTags])
        if (
          updated.size !== previous.size ||
          [...updated].some(([key, value]) => previous.get(key) !== value)
        ) {
          return updated
        }
        return previous
      })
    }

    // Now just update the references of the changed notes
    if (changedNotes.size > 0) {
      const computedReferences = computeReferences(
        changedNotes,
        allNotes,
        references,
        true
      )
      setReferences(computedReferences)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privateCalendarNotes,
    privateProjectNotes,
    teamCalendarNotes,
    teamProjectNotes,
  ])

  return (
    <ReferencesContext.Provider value={references}>
      {children}
    </ReferencesContext.Provider>
  )
}

export const useReferences = () => {
  const context = useContext(ReferencesContext)
  if (!context) {
    throw new Error('useReferences must be used within a ReferencesProvider')
  }
  return context
}

function computeReferences(
  notes: Map<string, Note>,
  existingNotes: Map<string, Note> | undefined = undefined,
  existingReferences: NoteReferenceMap | undefined = undefined,
  isUpdate = false
): NoteReferenceMap {
  const references = existingReferences ?? new Map<string, NoteReference>()
  const lookupNotes = existingNotes ?? notes

  if (!(lookupNotes instanceof Map)) {
    throw new TypeError('lookupNotes must be a Map')
  }

  const referenceRegex = /\[\[([^\]]+)\]\]/g
  const scheduleRegex =
    /(?<=\s|^)>(\d{4}(?:-\d{2}(?:-\d{2})?|-(?:W\d{2}|Q[1-4])))\b/g

  // Create a title to note map for quick lookup
  // TODO: use byTitle queries instead
  const titleToNoteMap = new Map<string, Note>()
  for (const note of lookupNotes.values()) {
    const title = isCalendarNote(note.noteType)
      ? filenameToKey(note.filename)
      : note.title ?? 'Untitled'
    titleToNoteMap.set(title, note)
  }

  notes.forEach((note) => {
    if (!note.content) {
      return
    }

    // console.log('Computing for note', note)

    const results = findMatches(note, [
      { regex: referenceRegex, group: 1, cleanRegex: null, replace: '' },
      { regex: scheduleRegex, group: 1, cleanRegex: null, replace: '' },
    ])

    const outgoing: Set<string> = new Set()

    results.forEach((result) => {
      const referencedNote = titleToNoteMap.get(result.match)
      // if (!referencedNote) {
      //   return
      // }

      let referencedNoteID = result.match // Use the match as the ID if we don't find a note, specifically for calendar notes, we still need to store the reference

      // Reference found, use the title in case of calendar notes and the recordName in case of regular notes
      if (
        referencedNote?.recordName &&
        !isCalendarNote(referencedNote.noteType)
      ) {
        referencedNoteID = referencedNote.recordName
      }

      // If the outgoing set doesn't contain this reference yet, remove old references and add it (otherwise we will duplicate what's already there)
      if (
        note.recordName &&
        !outgoing.has(referencedNoteID) &&
        references.has(referencedNoteID)
      ) {
        // To avoid duplicates, remove existing incoming references from the currently scanned note in the matched note
        references.get(referencedNoteID)?.incoming.delete(note.recordName)
      }

      // Add the id as outgoing reference, so we can track them in case this note gets deleted, then we know where to remove them
      outgoing.add(referencedNoteID)

      // If there's no reference entry, create one
      if (!references.has(referencedNoteID)) {
        references.set(referencedNoteID, {
          noteType: referencedNote?.noteType ?? NoteType.CALENDAR_NOTE,
          incoming: new Map<string, FromNote>(),
          outgoing: new Set<string>(),
        })
      }

      // Add the incoming reference, if it doesn't exist yet
      const referencedNoteReferences = references.get(referencedNoteID)
      if (!referencedNoteReferences?.incoming.has(note.recordName)) {
        referencedNoteReferences?.incoming.set(note.recordName, {
          title: isCalendarNote(note.noteType)
            ? calendarFilenameToTitle(note.filename, true)
            : note.title ?? 'Untitled',
          blocks: [],
          ranges: [],
        })
      }

      // Get the note and assign the block and ranges, so we can show it in the references and know where it is in case the user clicks on it
      const fromNote = referencedNoteReferences?.incoming.get(note.recordName)
      const block = BlockNoteEditor.notePlanToBlocks(result.line, '')[0]

      // Don't add scheduled tasks
      if (block.props?.['scheduled']) {
        return
      }

      fromNote?.blocks.push(block)

      const from = result.range.from
      const to = result.range.to
      fromNote?.ranges.push({ from, to })
    })

    // If it's an update, we need to remove outgoing references from the target notes that don't exist anymore
    if (isUpdate && references.has(note.recordName)) {
      const oldOutgoing = references.get(note.recordName)?.outgoing ?? new Set()
      const toRemove = [...oldOutgoing].filter((o) => !outgoing.has(o))
      for (const r of toRemove) {
        const target = references.get(r)
        if (target) {
          target.incoming.delete(note.recordName)
        }
      }
    }

    // Create a reference entry if there's none and the outgoing links are non-empty, otherwise we don't need to store an empty list
    if (!references.has(note.recordName) && outgoing.size > 0) {
      references.set(note.recordName, {
        noteType: note.noteType,
        incoming: new Map<string, FromNote>(),
        outgoing: new Set<string>(),
      })
    }

    // Store the outgoing links = results, so we can match and remove them later
    if (references.has(note.recordName)) {
      const noteReferences = references.get(note.recordName)
      noteReferences.outgoing = outgoing
      if (outgoing.size === 0 && noteReferences.incoming.size === 0) {
        references.delete(note.recordName)
      }
    }
  })

  return references
}

/**
 * Remove the references of the deleted note.
 * In addition to deleting the incoming references of the given note,
 * we need to search for the outgoing references and remove the incoming references from the target notes.
 */
// function removeReference(references: NoteReferenceMap, noteID: string) {
//   const newReferences = new Map(references) // Copy the references to avoid mutating the original and to trigger react state updates
//   const noteReferences = newReferences.get(noteID)

//   if (noteReferences) {
//     for (const outgoingReference of noteReferences.outgoing) {
//       const reference = newReferences.get(outgoingReference)
//       if (reference) {
//         reference.incoming.delete(noteID)
//       }
//     }
//     newReferences.delete(noteID)
//   }

//   return newReferences
//}
/* eslint-enable no-console */

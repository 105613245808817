import { CalendarEvent } from '../../CalendarEvent'
import { minBy } from 'lodash' // TODO: utilize useHooks or use single lodash modules to take advantage of tree shaking
import { NumberSize } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import { updateContentNodesAndBlock } from './updateContent'

export function handleResizeStop(
  event: CalendarEvent,
  segmentHeight: number,
  delta: NumberSize,
  direction: Direction
) {
  const segmentDuration = segmentHeight / 15
  const deltaDuration = delta.height / segmentDuration

  let newStartDate = event.startDate
  let newEndDate = event.endDate

  if (direction === 'top') {
    newStartDate = event.startDate.add(-deltaDuration, 'minute')
    const closestMinute =
      minBy([0, 15, 30, 45, 60], (s) => Math.abs(newStartDate.minute() - s)) ??
      0
    newStartDate = newStartDate.minute(closestMinute)
  } else {
    newEndDate = event.endDate.add(deltaDuration, 'minute')
    const closestMinute =
      minBy([0, 15, 30, 45, 60], (s) => Math.abs(newEndDate.minute() - s)) ?? 0
    newEndDate = newEndDate.minute(closestMinute)
  }
  updateContentNodesAndBlock(event.id, event, newStartDate, newEndDate)
}

import { useEffect, useRef } from 'react'
import { useCreateNote } from '../hooks/useCreateNote'
import { useSelectedRecordNameDispatch } from '../providers/SelectedRecordNameProvider'
import { v4 as uuid } from 'uuid'
import { NoteType } from '../utils/syncUtils'
import { useIsFetching } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'

export function GoogleMeetSummary() {
  const isFetching = useIsFetching()
  const user = useUserState()
  const setSelectedRecordName = useSelectedRecordNameDispatch()
  const createNoteMutation = useCreateNote(
    (note) => {
      if (note.recordName) {
        setSelectedRecordName(note.recordName)
      }
      sessionStorage.removeItem('summary')
      hasAttemptedCreation.current = false // Reset on success
    },
    (_error) => {
      // console.error('Failed to create note:', error)
      hasAttemptedCreation.current = false // Reset on error to allow retry
    }
  )
  const hasAttemptedCreation = useRef(false)

  useEffect(() => {
    if (user && isFetching === 0 && !hasAttemptedCreation.current) {
      const summary = sessionStorage.getItem('summary')
      if (summary) {
        hasAttemptedCreation.current = true
        setTimeout(() => {
          const title = summary.split('\n')[0].replaceAll('#', '').trim()
          createNoteMutation.mutate({
            recordName: uuid(),
            filename: title,
            content: summary,
            noteType: NoteType.PROJECT_NOTE,
          })
        }, 2000) // 3 seconds delay
      }
    }
  }, [createNoteMutation, user, isFetching])

  return <></>
}

function OpenButton({ onClick, showCalendar }) {
  return (
    <div className="flex justify-center items-center sticky top-0 max-h-screen h-full">
      <button onClick={onClick} className="-ml-6 text-base bg-transparent opacity-25 hover:opacity-100 transition md:hidden">
        {showCalendar ? <i className="fa-regular fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
      </button>
    </div>
  )
}
export default OpenButton

import { Node } from "prosemirror-model";

/**
 * Get a TipTap node by id
 */
export function getNodeById(
  id: string,
  doc: Node
): { node: Node | undefined; posBeforeNode: number | undefined } {
  let targetNode: Node | undefined = undefined;
  let posBeforeNode: number | undefined = undefined;

  doc.firstChild!.descendants((node, pos) => {
    // Skips traversing nodes after node with target ID has been found.
    if (targetNode) {
      return false;
    }

    // Keeps traversing nodes if block with target ID has not been found.
    if (node.type.name !== "blockContainer" || node.attrs.id !== id) {
      return true;
    }

    targetNode = node;
    posBeforeNode = pos + 1;

    return false;
  });

  if (targetNode === undefined || posBeforeNode === undefined) {
    console.log(
      "Error: Could not find block in the editor with matching ID =  " + id
    );
    // throw Error("Could not find block in the editor with matching ID =  " + id);
  }

  return {
    node: targetNode,
    posBeforeNode: posBeforeNode,
  };
}

export function getPreviousNodeOfId(
  id: string,
  doc: Node
): { node: Node | undefined; pos: number | undefined } {
  let previousNodePos: number | undefined = undefined;
  let previousNode: Node | undefined = undefined;
  let targetNode: Node | undefined = undefined;

  doc.descendants((node, pos) => {
    // Skip if we found what we searched for
    if (targetNode) {
      return false;
    }

    // Skip non containers, we don't want the text nodes, etc.
    if (node.type.name !== "blockContainer") {
      return true;
    }

    // We found the node we were looking for, stop here
    if (node.attrs.id === id) {
      targetNode = node; // Cache it, so we can "stop" the loop
      return false;
    }

    // Cache the previous node info
    previousNodePos = pos;
    previousNode = node;
    return true;
  });

  return { node: previousNode, pos: previousNodePos };
}

import { Editor } from "@tiptap/core";
import { Node } from "prosemirror-model";
import { NodeSelection, Plugin, PluginKey, Selection } from "prosemirror-state";
import * as pv from "prosemirror-view";
import { EditorView } from "prosemirror-view";
import styles from "../../editor.module.css";
import { getBlockInfoFromPos } from "../Blocks/helpers/getBlockInfoFromPos";
import { SlashMenuPluginKey } from "../SlashMenu/SlashMenuExtension";
import {
  BlockSideMenu,
  BlockSideMenuDynamicParams,
  BlockSideMenuFactory,
  BlockSideMenuStaticParams,
} from "./BlockSideMenuFactoryTypes";
import { DraggableBlocksOptions } from "./DraggableBlocksExtension";
import { MultipleNodeSelection } from "./MultipleNodeSelection";
import { BlockNoteEditor } from "../../BlockNoteEditor";
import { BlockSchema, PartialBlock } from "../Blocks/api/blockTypes";
import { getNodeById } from "../../api/util/nodeUtil";
import { blockToNode } from "../../api/nodeConversions/nodeConversions";
import { DefaultBlockSchema } from "../Blocks/api/defaultBlocks";
import { PartialInlineContent } from "../Blocks/api/inlineContentTypes";
import { v4 as uuidv4 } from "uuid";
import { uniqueFilename } from "../Blocks/inline/inlineAttachment";

const serializeForClipboard = (pv as any).__serializeForClipboard;
// code based on https://github.com/ueberdosis/tiptap/issues/323#issuecomment-506637799

let dragImageElement: Element | undefined;

function getDraggableBlockFromCoords(
  coords: { left: number; top: number },
  view: EditorView
) {
  if (!view.dom.isConnected) {
    // view is not connected to the DOM, this can cause posAtCoords to fail
    // (Cannot read properties of null (reading 'nearestDesc'), https://github.com/TypeCellOS/BlockNote/issues/123)
    return undefined;
  }

  let pos = view.posAtCoords(coords);
  if (!pos) {
    return undefined;
  }
  let node = view.domAtPos(pos.pos).node as HTMLElement;

  if (node === view.dom) {
    // mouse over root
    return undefined;
  }

  while (
    node &&
    node.parentNode &&
    node.parentNode !== view.dom &&
    !node.hasAttribute?.("data-id")
  ) {
    node = node.parentNode as HTMLElement;
  }
  if (!node) {
    return undefined;
  }
  return { node, id: node.getAttribute("data-id")! };
}

function blockPositionFromCoords(
  coords: { left: number; top: number },
  view: EditorView
) {
  let block = getDraggableBlockFromCoords(coords, view);

  if (block && block.node.nodeType === 1) {
    // TODO: this uses undocumented PM APIs? do we need this / let's add docs?
    const docView = (view as any).docView;
    let desc = docView.nearestDesc(block.node, true);
    if (!desc || desc === docView) {
      return null;
    }
    return desc.posBefore;
  }
  return null;
}

function blockPositionsFromSelection(selection: Selection, doc: Node) {
  // Absolute positions just before the first block spanned by the selection, and just after the last block. Having the
  // selection start and end just before and just after the target blocks ensures no whitespace/line breaks are left
  // behind after dragging & dropping them.
  let beforeFirstBlockPos: number;
  let afterLastBlockPos: number;

  // Even the user starts dragging blocks but drops them in the same place, the selection will still be moved just
  // before & just after the blocks spanned by the selection, and therefore doesn't need to change if they try to drag
  // the same blocks again. If this happens, the anchor & head move out of the block content node they were originally
  // in. If the anchor should update but the head shouldn't and vice versa, it means the user selection is outside a
  // block content node, which should never happen.
  const selectionStartInBlockContent =
    doc.resolve(selection.from).node().type.spec.group === "blockContent";
  const selectionEndInBlockContent =
    doc.resolve(selection.to).node().type.spec.group === "blockContent";

  // Ensures that entire outermost nodes are selected if the selection spans multiple nesting levels.
  const minDepth = Math.min(selection.$anchor.depth, selection.$head.depth);

  if (selectionStartInBlockContent && selectionEndInBlockContent) {
    // Absolute positions at the start of the first block in the selection and at the end of the last block. User
    // selections will always start and end in block content nodes, but we want the start and end positions of their
    // parent block nodes, which is why minDepth - 1 is used.
    const startFirstBlockPos = selection.$from.start(minDepth - 1);
    const endLastBlockPos = selection.$to.end(minDepth - 1);

    // Shifting start and end positions by one moves them just outside the first and last selected blocks.
    beforeFirstBlockPos = doc.resolve(startFirstBlockPos - 1).pos;
    afterLastBlockPos = doc.resolve(endLastBlockPos + 1).pos;
  } else {
    beforeFirstBlockPos = selection.from;
    afterLastBlockPos = selection.to;
  }

  return { from: beforeFirstBlockPos, to: afterLastBlockPos };
}

function setDragImage(view: EditorView, from: number, to = from) {
  if (from === to) {
    // Moves to position to be just after the first (and only) selected block.
    to += view.state.doc.resolve(from + 1).node().nodeSize;
  }

  // Parent element is cloned to remove all unselected children without affecting the editor content.
  const parentClone = view.domAtPos(from).node.cloneNode(true) as Element;
  const parent = view.domAtPos(from).node as Element;

  const getElementIndex = (parentElement: Element, targetElement: Element) =>
    Array.prototype.indexOf.call(parentElement.children, targetElement);

  const firstSelectedBlockIndex = getElementIndex(
    parent,
    // Expects from position to be just before the first selected block.
    view.domAtPos(from + 1).node.parentElement!
  );
  const lastSelectedBlockIndex = getElementIndex(
    parent,
    // Expects to position to be just after the last selected block.
    view.domAtPos(to - 1).node.parentElement!
  );

  for (let i = parent.childElementCount - 1; i >= 0; i--) {
    if (i > lastSelectedBlockIndex || i < firstSelectedBlockIndex) {
      parentClone.removeChild(parentClone.children[i]);
    }
  }

  // dataTransfer.setDragImage(element) only works if element is attached to the DOM.
  unsetDragImage();
  dragImageElement = parentClone;

  // Limit the width of the dragImageElement if it's an image, otherwise it comes out very big
  const imageElement = dragImageElement.querySelector("img");
  if (imageElement) {
    imageElement.style.width = "300px";
  }

  // TODO: This is hacky, need a better way of assigning classes to the editor so that they can also be applied to the
  //  drag preview.
  const classes = view.dom.className.split(" ");
  const inheritedClasses = classes
    .filter(
      (className) =>
        !className.includes("bn") &&
        !className.includes("ProseMirror") &&
        !className.includes("editor")
    )
    .join(" ");

  dragImageElement.className =
    dragImageElement.className +
    " " +
    styles.dragPreview +
    " " +
    inheritedClasses;

  document.body.appendChild(dragImageElement);
}

function unsetDragImage() {
  if (dragImageElement !== undefined) {
    document.body.removeChild(dragImageElement);
    dragImageElement = undefined;
  }
}

function dragStart(e: DragEvent, view: EditorView) {
  if (!e.dataTransfer) {
    return;
  }

  const editorBoundingBox = view.dom.getBoundingClientRect();

  let coords = {
    left: editorBoundingBox.left + editorBoundingBox.width / 2, // take middle of editor
    top: e.clientY,
  };

  let pos = blockPositionFromCoords(coords, view);
  if (pos != null) {
    const selection = view.state.selection;
    const doc = view.state.doc;

    const { from, to } = blockPositionsFromSelection(selection, doc);

    const draggedBlockInSelection = from <= pos && pos < to;
    const multipleBlocksSelected =
      selection.$anchor.node() !== selection.$head.node() ||
      selection instanceof MultipleNodeSelection;

    if (draggedBlockInSelection && multipleBlocksSelected) {
      view.dispatch(
        view.state.tr.setSelection(MultipleNodeSelection.create(doc, from, to))
      );
      setDragImage(view, from, to);
    } else {
      view.dispatch(
        view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos))
      );
      setDragImage(view, pos);
    }

    let slice = view.state.selection.content();
    let { dom, text } = serializeForClipboard(view, slice);

    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/html", dom.innerHTML);
    e.dataTransfer.setData("text/plain", text);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setDragImage(dragImageElement!, 0, 0);
    view.dragging = { slice, move: true };
  }
  // Hack to hide the calendar event list while dragging a task from the editor, so that it doesn't interfere with the drag preview
  const calendarEventList = document.getElementById("calendar-events-list");
  calendarEventList?.style.setProperty("pointer-events", "none");
}

export type BlockMenuViewProps<BSchema extends BlockSchema> = {
  tiptapEditor: Editor;
  editor: BlockNoteEditor<BSchema>;
  blockMenuFactory: BlockSideMenuFactory<BSchema>;
  horizontalPosAnchoredAtRoot: boolean;
};

export class BlockMenuView<BSchema extends BlockSchema> {
  editor: BlockNoteEditor<BSchema>;
  private ttEditor: Editor;

  // When true, the drag handle with be anchored at the same level as root elements
  // When false, the drag handle with be just to the left of the element
  horizontalPosAnchoredAtRoot: boolean;

  horizontalPosAnchor: number;

  blockMenu: BlockSideMenu<BSchema>;

  hoveredBlock: HTMLElement | undefined;

  // Used to check if currently dragged content comes from this editor instance.
  isDragging = false;
  menuOpen = false;
  menuFrozen = false;
  isMouseDown = false;

  private lastPosition: DOMRect | undefined;

  constructor({
    tiptapEditor,
    editor,
    blockMenuFactory,
    horizontalPosAnchoredAtRoot,
  }: BlockMenuViewProps<BSchema>) {
    this.editor = editor;
    this.ttEditor = tiptapEditor;
    this.horizontalPosAnchoredAtRoot = horizontalPosAnchoredAtRoot;
    this.horizontalPosAnchor = (
      this.ttEditor.view.dom.firstChild! as HTMLElement
    ).getBoundingClientRect().x;

    this.blockMenu = blockMenuFactory(this.getStaticParams());

    document.body.addEventListener("drop", this.onDrop, true);
    document.body.addEventListener("dragover", this.onDragOver);
    this.ttEditor.view.dom.addEventListener("dragstart", this.onDragStart);

    // Shows or updates menu position whenever the cursor moves, if the menu isn't frozen.
    document.body.addEventListener("mousemove", this.onMouseMove, true);

    // Hides and unfreezes the menu whenever the user selects the editor with the mouse or presses a key.
    // TODO: Better integration with suggestions menu and only editor scope?
    document.body.addEventListener("mousedown", this.onMouseDown, true);
    document.body.addEventListener("keydown", this.onKeyDown, true);

    // For tracking the isMouseDown state. We shouldn't move the menu while the user is dragging to select text or it will jump and select too much when we hover over the left hover buttons
    document.body.addEventListener("mouseup", this.onMouseUp, true);
  }

  handleTimeblockDrop = (event: DragEvent) => {
    const item = event.dataTransfer?.getData("text/plain");
    if (item) {
      // Attempt to parse the item as json
      try {
        const data = JSON.parse(item);
        if (data.type === "timeblock" && data.id) {
          // Get block with id data.id
          const blockNode = getNodeById(
            data.id,
            this.editor._tiptapEditor.state.doc
          );

          if (blockNode && blockNode.node && blockNode.posBeforeNode) {
            const content = blockNode.node.lastChild;
            var start = blockNode.posBeforeNode + 2;

            // Loop of all children of content
            content?.forEach((node) => {
              node.marks.forEach((mark) => {
                if (mark.type.name === "timeString") {
                  // Delete the mark
                  const tr = this.editor._tiptapEditor.state.tr.delete(
                    start,
                    start + node.nodeSize + 1
                  );

                  this.editor._tiptapEditor.view.dispatch(tr);
                }
              });

              start += node.nodeSize;
            });
          }

          event.preventDefault();
          return;
        }
      } catch (e) {
        // console.log(e)
      }
    }
  };

  handleFileDrop = (event: DragEvent) => {
    function createBlock(
      url: string,
      name: string,
      extension: string,
      editor: BlockNoteEditor<BSchema>,
      pos: number
    ) {
      // Remove parantheses of the name
      name = name.replace(/\(/g, "").replace(/\)/g, "");
      const filename = uuidv4() + "." + extension;

      // Create an inlineAttachment mark
      const content: PartialInlineContent = {
        type: "text",
        // Get the last path component of the filename
        text: name,
        styles: { inlineAttachment: true },
        attrs: {
          filename: filename,
          downloadUrl: url,
          downloaded: true,
          title: uniqueFilename(name, editor._tiptapEditor.state),
          src: url,
          href: url,
        },
      };

      let block: PartialBlock<DefaultBlockSchema> = {
        type: "paragraph",
        content: [content],
        children: [],
      };

      const node = blockToNode(block, editor._tiptapEditor.schema);

      if (pos) {
        // Insert the attachment
        const tr = editor._tiptapEditor.state.tr.insert(pos, node);
        editor._tiptapEditor.view.dispatch(tr);
        event.preventDefault();
        return;
      }
    }

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      // console.log(files[0])
      // Get the url to the blob of the file
      const file = files[0];

      // Get the drop position
      const pos = this.ttEditor.view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });

      if (file && pos) {
        // Create a new FileReader instance
        const reader = new FileReader();

        // Set up the onload event handler for the reader
        reader.onload = () => {
          // Create a blob
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });

          // Create a url to the blob
          const url = URL.createObjectURL(blob);

          console.log(url, blob, reader.result);
          const extension =
            file.name.split(".").pop() ?? file.type.split("/").pop() ?? "png";
          createBlock(url, file.name, extension, this.editor, pos.pos);
        };

        // Read the file as a data URL
        reader.readAsArrayBuffer(file);
      }
    }
  };

  handleWeekReferenceBlockDrop = (event: DragEvent) => {
    const text = event.dataTransfer?.getData("text/plain");
    if (text) {
      // Attempt to add text to the editor
      try {
        const data = JSON.parse(text);
        if ("id" in data && "text" in data) {
          // Get the drop position
          const pos = this.ttEditor.view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          if (pos) {
            // Get the block at the position
            const blockInfo = getBlockInfoFromPos(
              this.ttEditor.state.doc,
              pos.pos
            );

            // convert text to blocks
            const blocks = BlockNoteEditor.notePlanToBlocks(data.text, "[]");

            // append the blocks to the editor
            this.editor.insertBlocks(blocks, blockInfo?.id ?? "", "after");

            // signal that the week reference was dropped
            window.postMessage(
              { type: "droppedWeekReferenceBlock", id: data.id },
              "*"
            );

            event.preventDefault();
            return;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  onMouseUp = () => {
    this.isMouseDown = false;
  };

  /**
   * Sets isDragging when dragging text.
   */
  onDragStart = () => {
    this.isDragging = true;
  };

  /**
   * If the event is outside the editor contents,
   * we dispatch a fake event, so that we can still drop the content
   * when dragging / dropping to the side of the editor
   */
  onDrop = (event: DragEvent) => {
    const editorContainer: HTMLElement | null = document.querySelector(
      ".editor-container"
    ) as HTMLElement;
    let isInsideEditorContainer: boolean =
      editorContainer?.contains(event.target as HTMLElement) ?? true;

    // console.log(event.dataTransfer?.files[0])
    if (isInsideEditorContainer) {
      this.handleTimeblockDrop(event);
      this.handleFileDrop(event);
      this.handleWeekReferenceBlockDrop(event);
    }

    this.isMouseDown = false;
    if ((event as any).synthetic || !this.isDragging) {
      return;
    }

    let pos = this.ttEditor.view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    this.isDragging = false;

    // only if the drop is outside the editor but inside the editor container
    if ((!pos || pos.inside === -1) && isInsideEditorContainer) {
      const evt = new Event("drop", event) as any;
      const editorBoundingBox = (
        this.ttEditor.view.dom.firstChild! as HTMLElement
      ).getBoundingClientRect();
      evt.clientX = editorBoundingBox.left + editorBoundingBox.width / 2;
      evt.clientY = event.clientY;
      evt.dataTransfer = event.dataTransfer;
      evt.preventDefault = () => event.preventDefault();
      evt.synthetic = true; // prevent recursion
      console.log("dispatch fake drop");
      this.ttEditor.view.dom.dispatchEvent(evt);
    }
  };

  /**
   * If the event is outside the editor contents,
   * we dispatch a fake event, so that we can still drop the content
   * when dragging / dropping to the side of the editor
   */
  onDragOver = (event: DragEvent) => {
    if ((event as any).synthetic || !this.isDragging) {
      return;
    }

    let pos = this.ttEditor.view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    const editorContainer: HTMLElement | null = document.querySelector(
      ".editor-container"
    ) as HTMLElement;
    let isInsideEditorContainer: boolean =
      editorContainer?.contains(event.target as HTMLElement) ?? true;

    // only if the dragging is outside the editor but inside the editor container
    if ((!pos || pos.inside === -1) && isInsideEditorContainer) {
      const evt = new Event("dragover", event) as any;
      const editorBoundingBox = (
        this.ttEditor.view.dom.firstChild! as HTMLElement
      ).getBoundingClientRect();
      evt.clientX = editorBoundingBox.left + editorBoundingBox.width / 2;
      evt.clientY = event.clientY;
      evt.dataTransfer = event.dataTransfer;
      evt.preventDefault = () => event.preventDefault();
      evt.synthetic = true; // prevent recursion
      // console.log("dispatch fake dragover");
      this.ttEditor.view.dom.dispatchEvent(evt);
    }
  };

  onKeyDown = (_event: KeyboardEvent) => {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.blockMenu.hide();
    }

    this.menuFrozen = false;
  };

  onMouseDown = (event: MouseEvent) => {
    this.isMouseDown = true;

    if (this.blockMenu.element?.contains(event.target as HTMLElement)) {
      return;
    }

    if (this.menuOpen) {
      this.menuOpen = false;
      this.blockMenu.hide();
    }

    this.menuFrozen = false;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.menuFrozen || this.isMouseDown === true) {
      return;
    }

    // Editor itself may have padding or other styling which affects
    // size/position, so we get the boundingRect of the first child (i.e. the
    // blockGroup that wraps all blocks in the editor) for more accurate side
    // menu placement.
    const editorBoundingBox = (
      this.ttEditor.view.dom.firstChild! as HTMLElement
    ).getBoundingClientRect();
    // We want the full area of the editor to check if the cursor is hovering
    // above it though.
    const editorOuterBoundingBox =
      this.ttEditor.view.dom.getBoundingClientRect();
    const cursorWithinEditor =
      event.clientX >= editorOuterBoundingBox.left &&
      event.clientX <= editorOuterBoundingBox.right &&
      event.clientY >= editorOuterBoundingBox.top &&
      event.clientY <= editorOuterBoundingBox.bottom;

    // Doesn't update if the mouse hovers an element that's over the editor but
    // isn't a part of it or the side menu.
    if (
      // Cursor is within the editor area
      cursorWithinEditor &&
      // An element is hovered
      event &&
      event.target &&
      // Element is outside the editor
      this.ttEditor.view.dom !== event.target &&
      !this.ttEditor.view.dom.contains(event.target as HTMLElement) &&
      // Element is outside the side menu
      this.blockMenu.element !== event.target &&
      !this.blockMenu.element?.contains(event.target as HTMLElement)
    ) {
      if (this.menuOpen) {
        this.menuOpen = false;
        this.blockMenu.hide();
      }

      return;
    }

    this.horizontalPosAnchor = editorBoundingBox.x;

    // Gets block at mouse cursor's vertical position.
    const coords = {
      left: editorBoundingBox.left + editorBoundingBox.width / 2, // take middle of editor
      top: event.clientY,
    };
    const block = getDraggableBlockFromCoords(coords, this.ttEditor.view);

    // Closes the menu if the mouse cursor is beyond the editor vertically.
    if (!block || !this.editor.isEditable) {
      if (this.menuOpen) {
        this.menuOpen = false;
        this.blockMenu.hide();
      }

      return;
    }

    // Doesn't update if the menu is already open and the mouse cursor is still hovering the same block.
    if (
      this.menuOpen &&
      this.hoveredBlock?.hasAttribute("data-id") &&
      this.hoveredBlock?.getAttribute("data-id") === block.id
    ) {
      return;
    }

    this.hoveredBlock = block.node;

    // Gets the block's content node, which lets to ignore child blocks when determining the block menu's position.
    const blockContent = block.node.firstChild as HTMLElement;

    if (!blockContent) {
      return;
    }

    // Shows or updates elements.
    if (this.editor.isEditable) {
      if (!this.menuOpen) {
        this.menuOpen = true;
        this.blockMenu.render(this.getDynamicParams(), true);
      } else {
        this.blockMenu.render(this.getDynamicParams(), false);
      }
    }
  };

  destroy() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.blockMenu.hide();
    }
    document.body.removeEventListener("mousemove", this.onMouseMove);
    document.body.removeEventListener("dragover", this.onDragOver);
    this.ttEditor.view.dom.removeEventListener("dragstart", this.onDragStart);
    document.body.removeEventListener("drop", this.onDrop);
    document.body.removeEventListener("mousedown", this.onMouseDown);
    document.body.removeEventListener("keydown", this.onKeyDown);
    document.body.removeEventListener("mouseup", this.onMouseUp);
  }

  addBlock(shouldAddNewLine: boolean = true) {
    this.menuOpen = false;
    this.menuFrozen = true;
    this.blockMenu.hide();

    const blockContent = this.hoveredBlock!.firstChild! as HTMLElement;
    const blockContentBoundingBox = blockContent.getBoundingClientRect();

    const pos = this.ttEditor.view.posAtCoords({
      left: blockContentBoundingBox.left + blockContentBoundingBox.width / 2,
      top: blockContentBoundingBox.top + blockContentBoundingBox.height / 2,
    });
    if (!pos) {
      return;
    }

    const blockInfo = getBlockInfoFromPos(this.ttEditor.state.doc, pos.pos);
    if (blockInfo === undefined) {
      return;
    }

    // EDIT Mar 28, 2024: Don't create a new line, just edit the one we have
    const { contentNode, endPos } = blockInfo;

    // Creates a new block if current one is not empty for the suggestion menu to open in.
    if (shouldAddNewLine && contentNode.textContent.length !== 0) {
      const newBlockInsertionPos = endPos + 1;
      const newBlockContentPos = newBlockInsertionPos + 2;

      this.ttEditor
        .chain()
        .BNCreateBlock(newBlockInsertionPos)
        .BNUpdateBlock(newBlockContentPos, { type: "paragraph", props: {} })
        .setTextSelection(newBlockContentPos)
        .run();
    } else {
      this.ttEditor.commands.setTextSelection(endPos);
    }

    // Focuses and activates the suggestion menu.
    this.ttEditor.view.focus();
    this.ttEditor.view.dispatch(
      this.ttEditor.view.state.tr.scrollIntoView().setMeta(SlashMenuPluginKey, {
        // TODO import suggestion plugin key
        activate: true,
        type: "drag",
      })
    );
  }

  getStaticParams(): BlockSideMenuStaticParams<BSchema> {
    return {
      editor: this.editor,
      addBlock: (shouldAddNewLine: boolean) => this.addBlock(shouldAddNewLine),
      blockDragStart: (event: DragEvent) => {
        // Sets isDragging when dragging blocks.
        this.isDragging = true;
        dragStart(event, this.ttEditor.view);
      },
      blockDragEnd: () => unsetDragImage(),
      freezeMenu: () => {
        this.menuFrozen = true;
      },
      unfreezeMenu: () => {
        this.menuFrozen = false;
      },
      getReferenceRect: () => {
        if (!this.menuOpen) {
          if (this.lastPosition === undefined) {
            throw new Error(
              "Attempted to access block reference rect before rendering block side menu."
            );
          }

          return this.lastPosition;
        }

        const blockContent = this.hoveredBlock!.firstChild! as HTMLElement;
        const blockContentBoundingBox = blockContent.getBoundingClientRect();
        if (this.horizontalPosAnchoredAtRoot) {
          blockContentBoundingBox.x = this.horizontalPosAnchor;
        }
        this.lastPosition = blockContentBoundingBox;

        // Set the position of the sidemenu top left instead of center
        blockContentBoundingBox.y -= blockContentBoundingBox.height / 2 - 11;

        // Excpetion: it's a heading, there we need to adjust things a bit
        if (blockContent.getAttribute("data-content-type") === "heading") {
          const level = parseInt(
            blockContent.getAttribute("data-level") ?? "1"
          );

          switch (level) {
            case 1:
              blockContentBoundingBox.y += 10;
              break;
            case 2:
              blockContentBoundingBox.y += 8;
              break;
            default:
              blockContentBoundingBox.y += 4;
              break;
          }
        }

        return blockContentBoundingBox;
      },
    };
  }

  getDynamicParams(): BlockSideMenuDynamicParams<BSchema> {
    return {
      block: this.editor.getBlock(this.hoveredBlock!.getAttribute("data-id")!)!,
    };
  }
}

export const createDraggableBlocksPlugin = <BSchema extends BlockSchema>(
  options: DraggableBlocksOptions<BSchema>
) => {
  return new Plugin({
    key: new PluginKey("DraggableBlocksPlugin"),
    view: () =>
      new BlockMenuView({
        tiptapEditor: options.tiptapEditor,
        editor: options.editor,
        blockMenuFactory: options.blockSideMenuFactory,
        horizontalPosAnchoredAtRoot: true,
      }),
  });
};

import * as supabase from './supabase/fetch'
import * as cloudkit from './cloudkit/fetch'
import { NoteType, isTeamspaceNote } from '../utils/syncUtils'
import { AuthType, AuthenticatedUser } from '../utils/User'

export async function fetchNotes(
  user: AuthenticatedUser,
  noteType: NoteType,
  lastModifiedDate?: Date,
  shouldLoadStubs = false
) {
  if (isTeamspaceNote(noteType)) {
    if (
      user.authType === AuthType.SUPABASE ||
      user.authType === AuthType.CLOUDKIT_SUPABASE
    ) {
      return {
        map: await supabase.fetchNotes({
          currentUserId: user.teamUserId,
          noteType,
          lastModifiedDate,
          shouldLoadStubs,
        }),
        version: 0,
      }
    }
    throw new Error('Teamspace notes are not supported on CloudKit')
  } else {
    switch (user.authType) {
      case AuthType.CLOUDKIT:
      case AuthType.CLOUDKIT_SUPABASE: {
        return {
          map: shouldLoadStubs
            ? await cloudkit.fetchNoteStubs(noteType)
            : await cloudkit.fetchNotes({
                noteType,
                lastModifiedDate,
              }),
          version: 0,
        }
      }
      case AuthType.SUPABASE: {
        return {
          map: await supabase.fetchNotes({
            currentUserId: user.userId,
            noteType,
            lastModifiedDate,
            shouldLoadStubs,
          }),
          version: 0,
        }
      }
    }
  }
}
